import React, { useEffect, useState } from 'react'
import { CheckboxGroup, RadioGroup } from '../Elements'
import { NestedModifiers } from '../'

import { connect } from 'react-redux'

const Modifiers = (props) => {
	const { product, menu, handleSetModifiers, modErrorGroupId } = props

	return (
		<div className='mx-4'>
			{product.modifiers.map((modifier, index) => {
				const minSelection = modifier.modifierGroup.minimumSelections
				const maxSelection = modifier.modifierGroup.maximumSelections > 1 ? modifier.modifierGroup.maximumSelections : 1
				const modItems = modifier.modItems
				const [selectedModifiers, setSelectedModifiers] = useState([])
				const [limitReached, setLimitReached] = useState(false)

				useEffect(() => {
					if (maxSelection > selectedModifiers.length) {
						setLimitReached(false)
					} else {
						setLimitReached(true)
					}
				}, [JSON.stringify(selectedModifiers)])

				if (maxSelection > 1 || minSelection === 0) {
					return (
						<div className='mb-4' key={index}>
							{minSelection === 0 ? (
								<label
									className={`font-semibold mb-3 text-sm text-gray-600 inline-block`}
								>
									{modifier.modifierGroup.name} - LIMIT{' '}
									{maxSelection}
								</label>
							) : (
								<label
									className={`font-semibold mb-3 text-sm text-gray-600 inline-block`}
								>
									{modifier.modifierGroup.name} - LIMIT{' '}
									{maxSelection} (REQUIRED {minSelection})
								</label>
							)}
							<form>
								{modItems.map((modItem) => {
									if (modItem.nestedModifiers) {
										for(let i=0; i<modItem.nestedModifiers.length; i++){
											for(let j=0; j<modItem.nestedModifiers[i].nestedModItems.length; j++){
												modItem.nestedModifiers[i].nestedModItems[j]['parentModifierId']=modItem.id
												modItem.nestedModifiers[i].nestedModItems[j]['nestedModifier']=true
											}
										}
									}

									const [checked, setChecked] =
										useState(false)
									useEffect(() => {
										if (checked) {
											const tempArray = selectedModifiers
											tempArray.push(modItem)
											setSelectedModifiers(tempArray)
											handleSetModifiers(modItem, 'add')
											if (
												maxSelection >
												selectedModifiers.length
											) {
												setLimitReached(false)
											} else {
												setLimitReached(true)
											}
										} else {
											const reducedModifiers =
												selectedModifiers.filter(
													(e) => e !== modItem
												)

											// removing nested mods
											if (modItem.nestedModifiers) {
												for(const nestedMod of modItem.nestedModifiers){
													for(const nestedModItem of nestedMod.nestedModItems){
														handleSetModifiers(nestedModItem,'remove')
													}
												}
											}

											// parent mod
											handleSetModifiers(
												modItem,
												'remove'
											)

											setSelectedModifiers(
												reducedModifiers
											)
										}
									}, [checked])
									return (
										<div>
											{limitReached ? (
												<div>
													{selectedModifiers.includes(
														modItem
													) ? (
														<div className='my-2'>
															<CheckboxGroup
															className='my-2'
															label={
																modItem.price
																	.amount > 0
																	? `${
																			modItem.name
																	  } (+${modItem.price.amount.toFixed(
																			2
																	  )})`
																	: modItem
																			.price
																			.amount <
																	  0
																	? `${
																			modItem.name
																	  } (${modItem.price.amount.toFixed(
																			2
																	  )})`
																	: modItem.name
															}
															name={modItem.id}
															defaultChecked={
																checked
															}
															onChange={() => {
																setChecked(
																	!checked
																)
															}}
														/>
														{checked && modItem.nestedModifiers ? (
															<NestedModifiers
																nestedModifiers={
																	modItem.nestedModifiers
																}
																handleSetModifiers={
																	handleSetModifiers
																}
																modErrorGroupId={
																	modErrorGroupId
																}
															></NestedModifiers>
														) : null}
														</div>
													) : (
													 <div className='my-2'>
														<CheckboxGroup
															disabled='disabled'
															className='my-2'
															label={
																modItem.price
																	.amount > 0
																	? `${
																			modItem.name
																	  } (+${modItem.price.amount.toFixed(
																			2
																	  )})`
																	: modItem
																			.price
																			.amount <
																	  0
																	? `${
																			modItem.name
																	  } (${modItem.price.amount.toFixed(
																			2
																	  )})`
																	: modItem.name
															}
															name={modItem.id}
															defaultChecked={
																checked
															}
															onChange={() => {}}
														/>
													</div>
													)}
												</div>
											) : (
												<div className='my-2'>
												<CheckboxGroup
													className='my-2'
													label={
														modItem.price.amount > 0
															? `${
																	modItem.name
															  } (+${modItem.price.amount.toFixed(
																	2
															  )})`
															: modItem.price
																	.amount < 0
															? `${
																	modItem.name
															  } (${modItem.price.amount.toFixed(
																	2
															  )})`
															: modItem.name
													}
													name={modItem.id}
													defaultChecked={checked}
													onChange={() => {
														setChecked(!checked)
													}}
												/>
												{checked && modItem.nestedModifiers ? (
														<NestedModifiers
															nestedModifiers={
																modItem.nestedModifiers
															}
															handleSetModifiers={
																handleSetModifiers
															}

															modErrorGroupId={
																modErrorGroupId
															}
														></NestedModifiers>
													) : null}
												</div>
											)}
										</div>
									)
								})}
							</form>
							{limitReached && (
								<div className='text-red-400 text-sm'>
									Limit reached for this modifier
								</div>
							)}
							{modErrorGroupId == modifier.modifierGroup.id && (
								<div className='text-red-400 text-sm'>
									Required {minSelection}
								</div>
							)}
						</div>
					)
				} else {
					const modItemRadios = []
					const [radioSelected, setRadioSelected] = useState(-1)
					modItems.map((modItem, modIndex) => {
						modItemRadios.push({
							value: modIndex,
							label:
								modItem.price.amount > 0
									? `${
											modItem.name
									  } (+${modItem.price.amount.toFixed(2)})`
									: modItem.price.amount < 0
									? `${
											modItem.name
									  } (${modItem.price.amount.toFixed(2)})`
									: modItem.name,
						})

						if (modItem.nestedModifiers) {
							for(let i=0; i<modItem.nestedModifiers.length; i++){
								for(let j=0; j<modItem.nestedModifiers[i].nestedModItems.length; j++){
									modItem.nestedModifiers[i].nestedModItems[j]['parentModifierId']=modItem.id
									modItem.nestedModifiers[i].nestedModItems[j]['nestedModifier']=true
								}
							}
						}
					})
					return (
						<div className='mb-4' key={index}>
							{minSelection > 0 ?  
							<RadioGroup
								labelClassName='font-semibold'
								name={modifier.modifierGroup.name}
								value={modifier.modifierGroup.name}
								label={
									minSelection !== 0
										? modifier.modifierGroup.name +
										  ' - (REQUIRED 1)'
										: modifier.modifierGroup.name +
										  ' - LIMIT 1'
								}
								radios={modItemRadios}
								onChange={(modIndex) => {
									handleSetModifiers(
										modItems[modIndex],
										'add',
										'radio',
										modItems
									)
								}}
							/> : null}
							{radioSelected != -1 && (
								<div>
									{
										modItems.map((modItem, modIndex) => {
											if(modIndex==radioSelected && modItem.nestedModifiers){
												return(
													<NestedModifiers
														nestedModifiers={modItem.nestedModifiers}
														handleSetModifiers={
															handleSetModifiers
														}
														modErrorGroupId={modErrorGroupId}
													></NestedModifiers>
												)
											}
										})
									}
								</div>

							)}
							{modErrorGroupId == modifier.modifierGroup.id && (
								<div className='text-red-400 text-sm'>
									Required {minSelection}
								</div>
							)}
						</div>
					)
				}
			})}
		</div>
	)
}

const mapStateToProps = ({ Menu }) => ({
	menu: Menu.menu,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Modifiers)
