import {connect} from 'react-redux';
import React, {useEffect, useState} from 'react';
import { Header, Footer } from "../../components";
import { Link } from "react-router-dom";
import { BiHome } from "react-icons/bi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { FaChevronRight } from "react-icons/fa";
import { GiPaperBagOpen } from "react-icons/gi";
import {
  getUser,
} from '../../store/actions';
import {BounceLoader} from 'react-spinners'

const ReturnLandingPage = (state) => {
	const {
        user,
        onGetUser,
    } = state

    const bounceLoaderColor = '#507f74'

	useEffect(() => {
        onGetUser()
    }, [onGetUser])    
  
  if (!user)
    return (
        <div className='flex items-center h-screen'>
            <BounceLoader
                className='m-auto'
                color={bounceLoaderColor}
            ></BounceLoader>
        </div>
    )

  return (
    <div className="h-screen w-screen">
      <Header label="Return Containers" />
      <div className="mt-[3.25rem]">
      {!user.franchiseeSlug && (
          <>
            <Link to="/returns/pickup">
              <div className="border-b border-gray-200 flex items-center justify-between">
                <div className="flex items-center px-5 w-5/6">
                  <div className="bg-white">
                    <BiHome className="text-[25px] text-green-400 mr-5" />
                  </div>
                  <div className="text-xl text-green-600 my-5">
                    Return at your door
                    <p className="text-sm text-gray-600 font-bold">
                      Schedule a pickup by a courier
                    </p>
                  </div>
                </div>
                <FaChevronRight className="text-green-600 mr-5" />
              </div>
            </Link>
            <Link to="/returns/third-party">
              <div className="border-b border-gray-200 flex items-center justify-between">
                <div className="flex items-center px-5 w-5/6">
                  <div className="bg-white">
                    <GiPaperBagOpen className="text-[25px] text-green-400 mr-5" />
                  </div>
                  <div className="text-xl text-green-600 my-5">
                    Return with grocery delivery
                    <p className="text-sm text-gray-600 font-bold">
                      Hand your containers back to the courier delivering your
                      grocery order from select merchants
                    </p>
                  </div>
                </div>
                <FaChevronRight className="text-green-600 mr-5" />
              </div>
            </Link>
          </>
        )}
        <Link to="/returns/locations">
          <div className="border-b border-gray-200 flex items-center justify-between">
            <div className="flex items-center px-5 w-5/6">
              <div className="bg-white">
                <HiOutlineOfficeBuilding className="text-[25px] text-green-400 mr-5" />
              </div>
              <div className="text-xl text-green-600 my-5">
                Return in person
                <p className="text-sm text-gray-600 font-bold">
                  Select a dropoff location and confirm your return
                </p>
              </div>
            </div>
            <FaChevronRight className="text-green-600 mr-5" />
          </div>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = ({ User }) => ({
  user: User.user,
  loadingUser: User.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnLandingPage);


{
  /* <svg class="absolute t-0 z-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#000b29" fill-opacity="1" d="M0,32L48,80C96,128,192,224,288,224C384,224,480,128,576,112C672,96,768,160,864,154.7C960,149,1056,75,1152,90.7C1248,107,1344,213,1392,266.7L1440,320L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> */
}
