export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const VALIDATE_PHONE = 'VALIDATE_PHONE'
export const VALIDATE_PHONE_SUCCESS = 'VALIDATE_PHONE_SUCCESS'
export const VALIDATE_PHONE_FAIL = 'VALIDATE_PHONE_FAIL'
export const UPDATE_MAILCHIMP_MERGE_FIELDS = 'UPDATE_MAILCHIMP_MERGE_FIELDS'
export const UPDATE_MAILCHIMP_MERGE_FIELDS_SUCCESS = 'UPDATE_MAILCHIMP_MERGE_FIELDS_SUCCESS'
export const UPDATE_MAILCHIMP_MERGE_FIELDS_FAIL = 'UPDATE_MAILCHIMP_MERGE_FIELDS_FAIL'
export const GET_USER_BY_PHONE = 'GET_USER_BY_PHONE'
export const GET_USER_BY_USERID_PHONE = 'GET_USER_BY_USERID_PHONE'
export const UPDATE_USER_BY_PHONE = 'UPDATE_USER_BY_PHONE'
export const GET_USER_BOX_COUNT = 'GET_USER_BOX_COUNT'
export const GET_USER_BOX_COUNT_SUCCESS = 'GET_USER_BOX_COUNT_SUCCESS'
export const GET_USER_BOX_COUNT_FAIL = 'GET_USER_BOX_COUNT_FAIL'
export const CAPTURE_USER = 'CAPTURE_USER'
export const CAPTURE_USER_SUCCESS = 'CAPTURE_USER_SUCCESS'
export const CAPTURE_USER_FAIL = 'CAPTURE_USER_FAIL'
export const CAPTURE_IP = 'CAPTURE_IP'
export const CAPTURE_IP_SUCCESS = 'CAPTURE_IP_SUCCESS'
export const CAPTURE_IP_FAIL = 'CAPTURE_IP_FAIL'