import {
	CONFIRM_SUBSCRIPTION,
	CONFIRM_SUBSCRIPTION_FAIL,
	CONFIRM_SUBSCRIPTION_SUCCESS,
	CREATE_SUBSCRIPTION,
	CREATE_SUBSCRIPTION_FAIL,
	CREATE_SUBSCRIPTION_SUCCESS,
} from './actionTypes'

export const createSubscription = (payload) => ({
	type: CREATE_SUBSCRIPTION,
	payload: payload,
})

export const createSubscriptionSuccess = (payload) => ({
	type: CREATE_SUBSCRIPTION_SUCCESS,
	payload: payload,
})

export const createSubscriptionFail = (error) => ({
	type: CREATE_SUBSCRIPTION_FAIL,
	payload: error,
})

export const confirmSubscription = (payload) => ({
	type: CONFIRM_SUBSCRIPTION,
	payload: payload,
})

export const confirmSubscriptionSuccess = (payload) => ({
	type: CONFIRM_SUBSCRIPTION_SUCCESS,
	payload: payload,
})

export const confirmSubscriptionFail = (error) => ({
	type: CONFIRM_SUBSCRIPTION_FAIL,
	payload: error,
})
