import { GET_OFFICE, GET_OFFICE_SUCCESS, GET_OFFICE_ERROR } from './actionTypes';

const initialState = {
	loadingOffice: false,
	office: null,
	officeError: null,
};

const officeReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_OFFICE:
			return {
				...state,
				loadingOffice: true,
				office: null,
				officeError: null,
			};
		case GET_OFFICE_SUCCESS:
			return {
				...state,
				loadingOffice: false,
				office: action.payload,
				officeError: null,
			};
		case GET_OFFICE_ERROR:
			return {
				...state,
				loadingOffice: false,
				office: null,
				officeError: action.payload,
			};
		default:
			return state;
	}
};

export default officeReducer;
