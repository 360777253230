import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	SOCIAL_LOGIN_GOOGLE,
	LOGIN_USER_FAIL,
	SOCIAL_LOGIN_GOOGLE_FAIL,
	SOCIAL_LOGIN_GOOGLE_SUCCESS,
	LOGOUT_USER_FAIL,
	SOCIAL_REGISTER_GOOGLE,
} from './actionTypes'

const initialState = {
	error: null,
	loading: false,
	loginAttemptStatus: null,
}

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state,
				loading: true,
				loginAttemptStatus: 'attempting',
			}
			break
		case LOGIN_USER_SUCCESS:
			state = {
				...state,
				loading: false,
				loginAttemptStatus: 'completed',
			}
			break
		case LOGIN_USER_FAIL:
			state = {
				error: action.payload,
				loading: false,
				loginAttemptStatus: 'completed',
			}
			break
		case LOGOUT_USER:
			state = { ...state }
			break
		case LOGOUT_USER_SUCCESS:
			state = {
				...state,
			}
			break
		case LOGOUT_USER_FAIL:
			state = {
				...state,
				error: action.payload,
			}
			break
		case SOCIAL_LOGIN_GOOGLE:
			state = {
				...state,
				loading: true,
				loginAttemptStatus: 'attempting',
			}
			break
		case SOCIAL_REGISTER_GOOGLE:
			state = {
				...state,
				loading: true,
				loginAttemptStatus: 'attempting',
			}
			break
		case SOCIAL_LOGIN_GOOGLE_SUCCESS:
			state = {
				...state,
				loading: false,
				loginAttemptStatus: 'completed',
			}
			break
		case SOCIAL_LOGIN_GOOGLE_FAIL:
			state = {
				...state,
				error: action.payload,
				loading: false,
				loginAttemptStatus: 'completed',
			}
			break
		default:
			state = { ...state }
			break
	}
	return state
}

export default login
