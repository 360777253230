import {
	CONNECT_TO_CLIENT_FAILED,
	CONNECT_TO_CLIENT,
	CONNECT_TO_CLIENT_SUCCESSFUL,
} from './actionTypes'

const initialState = {
	connectionError: false,
	errorMessage: null,
	connecting: false,
	connected: false,
}

const oauth = (state = initialState, action) => {
	switch (action.type) {
		case CONNECT_TO_CLIENT:
			state = {
				...state,
				connecting: true,
				connectionError: false,
				errorMessage: null,
			}
			break
		case CONNECT_TO_CLIENT_SUCCESSFUL:
			state = {
				...state,
				connecting: false,
				connected: true,
				connectionError: false,
				errorMessage: null,
			}
			break
		case CONNECT_TO_CLIENT_FAILED:
			state = {
				...state,
				connecting: false,
				connected: false,
				connectionError: true,
				errorMessage: action.payload,
			}
			break
		default:
			state = { ...state }
			break
	}
	return state
}

export default oauth
