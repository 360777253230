import {
	GET_PAYMENT_METHODS,
	GET_PAYMENT_METHODS_FAIL,
	GET_PAYMENT_METHODS_SUCCESS,
	CREATE_PAYMENT_METHOD,
	CREATE_PAYMENT_METHOD_FAIL,
	CREATE_PAYMENT_METHOD_SUCCESS,
	DELETE_PAYMENT_METHOD,
	DELETE_PAYMENT_METHOD_SUCCESS,
	DELETE_PAYMENT_METHOD_FAIL,
	CREATE_PAYMENT_METHOD_CONFIRM,
	CREATE_PAYMENT_METHOD_CONFIRM_SUCCESS,
	CREATE_PAYMENT_METHOD_CONFIRM_FAIL,
	STORE_PAYMENT_METHOD,
	STORE_PAYMENT_METHOD_SUCCESS,
	STORE_PAYMENT_METHOD_FAIL,
	GET_PAYMENTS,
	GET_PAYMENTS_SUCCESS,
	GET_PAYMENTS_FAIL,
	RESET_CARD_ADDED, CHARGE_PAYMENT_METHOD, CHARGE_PAYMENT_METHOD_SUCCESS, CHARGE_PAYMENT_METHOD_FAIL,
	CHARGE_GUEST_PAYMENT,
	CHARGE_GUEST_PAYMENT_SUCCESS,
	CHARGE_GUEST_PAYMENT_FAIL,
	STORE_GUEST_PAYMENT,
	STORE_GUEST_PAYMENT_SUCCESS,
	STORE_GUEST_PAYMENT_FAIL,
} from './actionTypes'
import { RESET_CARD_RESPONSE, RESET_CARD_RESPONSE_SUCCESS, RESET_CARD_RESPONSE_ERROR } from './actionTypes';

export const getPayments = (payload) => ({
	type: GET_PAYMENTS,
	payload: payload,
})

export const getPaymentsSuccess = (payload) => ({
	type: GET_PAYMENTS_SUCCESS,
	payload: payload,
})

export const getPaymentsFail = (error) => ({
	type: GET_PAYMENTS_FAIL,
	payload: error,
})

export const getPaymentMethods = (payload) => ({
	type: GET_PAYMENT_METHODS,
	payload: payload,
})

export const getPaymentMethodsSuccess = (payload) => ({
	type: GET_PAYMENT_METHODS_SUCCESS,
	payload: payload,
})

export const getPaymentMethodsFail = (error) => ({
	type: GET_PAYMENT_METHODS_FAIL,
	payload: error,
})

export const createPaymentMethod = (payload) => ({
	type: CREATE_PAYMENT_METHOD,
	payload: payload,
})

export const createPaymentMethodSuccess = (payload) => ({
	type: CREATE_PAYMENT_METHOD_SUCCESS,
	payload: payload,
})

export const createPaymentMethodFail = (error) => ({
	type: CREATE_PAYMENT_METHOD_FAIL,
	payload: error,
})

export const createPaymentMethodConfirm = (payload) => ({
	type: CREATE_PAYMENT_METHOD_CONFIRM,
	payload: payload,
})

export const createPaymentMethodConfirmSuccess = (payload) => ({
	type: CREATE_PAYMENT_METHOD_CONFIRM_SUCCESS,
	payload: payload,
})

export const createPaymentMethodConfirmFail = (error) => ({
	type: CREATE_PAYMENT_METHOD_CONFIRM_FAIL,
	payload: error,
})

export const storePaymentMethod = (payload) => ({
	type: STORE_PAYMENT_METHOD,
	payload: payload,
})

export const storePaymentMethodSuccess = (payload) => ({
	type: STORE_PAYMENT_METHOD_SUCCESS,
	payload: payload,
})

export const storePaymentMethodFail = (error) => ({
	type: STORE_PAYMENT_METHOD_FAIL,
	payload: error,
})

export const deletePaymentMethod = (payload) => ({
	type: DELETE_PAYMENT_METHOD,
	payload: payload,
})

export const deletePaymentMethodSuccess = (payload) => ({
	type: DELETE_PAYMENT_METHOD_SUCCESS,
	payload: payload,
})

export const deletePaymentMethodFail = (error) => ({
	type: DELETE_PAYMENT_METHOD_FAIL,
	payload: error,
})

export const resetCardAdded = () => ({
	type: RESET_CARD_ADDED,
})

export const chargePaymentMethod = (payload) => ({
	type: CHARGE_PAYMENT_METHOD,
	payload: payload,
})

export const chargePaymentMethodSuccess = (payload) => ({
	type: CHARGE_PAYMENT_METHOD_SUCCESS,
	payload: payload,
})

export const chargePaymentMethodFail = (error) => ({
	type: CHARGE_PAYMENT_METHOD_FAIL,
	payload: error,
})

export const resetCardResponse = () => ({
	type: RESET_CARD_RESPONSE,
});

export const resetCardResponseSuccess = (payload) => ({
	type: RESET_CARD_RESPONSE_SUCCESS,
	payload: payload,
});

export const resetCardResponseError = (payload) => ({
	type: RESET_CARD_RESPONSE_ERROR,
	payload: payload,
});

export const chargeGuestPayment = (payload) => ({
	type: CHARGE_GUEST_PAYMENT,
	payload: payload,
})

export const chargeGuestPaymentSuccess = (payload) => ({
	type: CHARGE_GUEST_PAYMENT_SUCCESS,
	payload: payload,
})

export const chargeGuestPaymentFail = (error) => ({
	type: CHARGE_GUEST_PAYMENT_FAIL,
	payload: error,
})

export const storeGuestPayment = (payload) => ({
	type: STORE_GUEST_PAYMENT,
	payload: payload,
})

export const storeGuestPaymentSuccess = (payload) => ({
	type: STORE_GUEST_PAYMENT_SUCCESS,
	payload: payload,
})

export const storeGuestPaymentFail = (error) => ({
	type: STORE_GUEST_PAYMENT_FAIL,
	payload: error,
})