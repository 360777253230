import {
	getUser,
	updateUser,
	getAppInfo,
	getPaymentMethods,
	resetCardAdded,
	storePaymentMethod,
	resetCardResponse,
	membershipSignup,
	resetMembership,
} from '../../store/actions'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import ReactConfetti from 'react-confetti'
import { BounceLoader } from 'react-spinners'
import { GiPartyPopper } from 'react-icons/gi'
import {
	BounceLoaderCentered,
	Button,
	CheckboxGroup,
	Input,
	RadioGroup,
	TextArea,
} from '../../components/Elements'
import { Link } from 'react-router-dom'

const MembershipSignUpForm = (props) => {
	const {
		loadingAddition,
		onStorePaymentMethod,
		paymentMethods,
		loadingPaymentMethods,
		onUpdateUser,
		updateUserLoading,
		userUpdateCompleted,
		user,
		loadingUser,
		onGetUser,
		cardAdded,
		paymentError,
		onResetCardAdded,
		onGetAppInfo,
		appInfo,

		onSignupMember,
		onResetMembership,
		membershipLoading,
		membershipError,
		memberRegistered,

		loggedIn,
		existingMember,
	} = props

	const bounceLoaderColor = '#507f74'
	const [cardRadios, setCardRadios] = useState([])
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const stripe = useStripe()
	const elements = useElements()
	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	const [stripeLoading, setStripeLoading] = useState(false)
	const [showCardForm, setShowCardForm] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const navigate = useNavigate()
	const currentDate = new Date()
	const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }
	const [selectedTier, setSelectedTier] = useState(null)

	const handleTierSelect = (tier) => {
		setSelectedTier(tier)
	}

	const getTierPrice = () => {
		if (!selectedTier) return null

		const tier = appInfo.dzMembershipTiers[selectedTier]
		return `$${tier.renewalFee}/${
			tier.cadence
		} starting ${currentDate.toLocaleDateString('en-US', dateOptions)}`
	}

	useEffect(() => {
		// console.log('user payment useeffect triggered')
		if (user && user.paymentMethods) {
			makeCardRadios(user.paymentMethods)
		}
	}, [user?.paymentMethods])

	const makeCardRadios = (paymentMethods) => {
		let radios = []
		paymentMethods.forEach(function (paymentMethod, index) {
			radios.push({
				value: paymentMethod.id,
				label: `${paymentMethod.card.brand.toUpperCase()} ....${
					paymentMethod.card.last4
				} - Exp. ${paymentMethod.card.exp_month}/${
					paymentMethod.card.exp_year
				}`,
				checked: index === 0,
			})
			if (index === 0) {
				setSelectedPaymentMethod(paymentMethod.id)
			}
		})
		setCardRadios(radios)
	}

	const handleAddCardSubmit = async (event) => {
		setStripeLoading(true)
		event.preventDefault()
		if (elements == null) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})
		if (result.error) {
			setStripeLoading(false)
		} else {
			setStripeLoading(false)
			onStorePaymentMethod({
				stripeCustomerId: user.stripeId,
				paymentMethodId: result.paymentMethod.id,
				phone: user.phone,
			})

			setShowCardForm(false)
		}
	}

	useEffect(() => {
		if (paymentMethods.length > 0) {
			onGetUser()
			makeCardRadios(paymentMethods)
		}
	}, [paymentMethods])

	const handleSubmitOrder = async (e) => {
		e.preventDefault()
		const memberPayload = {
			defaultPaymentMethod: selectedPaymentMethod,
			tierSlug: selectedTier,
			userPhone: user.phone,
		}
		onSignupMember(memberPayload)
	}

	useEffect(() => {
		if (memberRegistered) {
			// UPDATE
			setShowSuccess(true)
			onResetMembership()
		}
	}, [memberRegistered])

	useEffect(() => {
		onGetUser()
		onResetMembership()
		onResetCardAdded()
		onGetAppInfo()
	}, [])

	if (showSuccess) {
		return (
			<div className='w-full h-full lg:h-screen-3/4 flex flex-col justify-center items-center overflow-hidden'>
				<ReactConfetti
					className='w-full'
					colors={['#f38b74']}
				/>
				<h1 className='font-vollkorn text-green-600 text-4xl text-center'>
					Successful!
				</h1>
				<GiPartyPopper className='text-orange-600 text-[250px]' />
				{/* UPDATE */}
				<div>Welcome to the DeliverZero Membership Program.</div>
				<Button
					link='/user/account'
					className='px-10 w-[260px] mt-5 shadow-light-grey'
					text='View My Account'
				/>
			</div>
		)
	} else {
		return (
			<div className='flex flex-col'>
				<div className='mx-3 overflow md:overflow-y-auto md:scrollbar mx-auto justify-center'>
					<div className='mt-5 md:mt-12 font-header font-bold text-green-600 text-3xl mb-3'>
						Activate your membership today!
					</div>
					{loggedIn && (
						<div className='pt-3 md:pt-7 pb-1 md:pb-5'>
							<div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
								Contact Information
							</div>
							<div className='flex flex-col sm:flex-row'>
								<div className='flex flex-col mr-4 max-w-300'>
									<div className='font-semibold'>
										{user.firstName} {user.lastName}
									</div>
									<div className=''>{user.email}</div>
									<div className=''>{user.phone}</div>
								</div>
							</div>
						</div>
					)}
					<div className='pt-3 md:pt-7 pb-1 md:pb-5'>
						<div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
							DeliverZero+ Membership Options
						</div>
						{appInfo.dzMembershipTiers?.tierOne &&
						appInfo.dzMembershipTiers?.tierTwo ? (
							<div className='flex flex-col sm:flex-row justify-between'>
								<div
									className={`flex flex-col mr-4 sm:w-[50%] border p-4 rounded-lg shadow-md cursor-pointer ${
										selectedTier === 'tierOne'
											? 'border-green-600'
											: ''
									}`}
									onClick={() => handleTierSelect('tierOne')}
								>
									<div className='font-bold text-xl mb-2'>
										{appInfo.dzMembershipTiers.tierOne.name}
									</div>
									<div className='text-lg font-semibold'>
										$
										{
											appInfo.dzMembershipTiers.tierOne
												.renewalFee
										}
										/
										{
											appInfo.dzMembershipTiers.tierOne
												.cadence
										}
									</div>
									<ul class='ml-3 list-disc'>
										<li>
											Access to exclusive reuse merch via
											the DeliverZero Shop
										</li>
										<li>
											An online community of fellow
											passionate reusers
										</li>
										<li>Lower unreturned container fees</li>
										{/* <li>
											A subscriber-only Apple Wallet card that helps identify the nearest reuse-enabled restaurants!
										</li> */}
									</ul>
								</div>
								<div
									className={`flex flex-col mr-4 sm:w-[50%] border p-4 rounded-lg shadow-md mt-4 sm:mt-0 cursor-pointer ${
										selectedTier === 'tierTwo'
											? 'border-green-600'
											: ''
									}`}
									onClick={() => handleTierSelect('tierTwo')}
								>
									<div className='font-bold text-xl mb-2'>
										{appInfo.dzMembershipTiers.tierTwo.name}
									</div>
									<div className='text-lg font-semibold'>
										$
										{
											appInfo.dzMembershipTiers.tierTwo
												.renewalFee
										}
										/
										{
											appInfo.dzMembershipTiers.tierTwo
												.cadence
										}
									</div>
									<ul class='ml-3 list-disc'>
										<p className='-ml-3'>
											All features of Tier 1{' '}
											<span className='font-header'>
												PLUS
											</span>
										</p>
										<li>
											Reimbursed opt-in fees (99¢) for
											ordering in reusable containers
										</li>
										<li>
											Free curbside pickups of your used
											containers
										</li>
									</ul>
								</div>
							</div>
						) : (
							<BounceLoaderCentered container='div' />
						)}
					</div>

					{loggedIn && existingMember ? (
						<div className='px-4'>
							<h1 className='text-3xl lg:text-5xl font-header text-white w-full lg:w-4/5 mt-8 mb-6'>
								Thanks for being a member!
							</h1>
							<div className='flex justify-start text-center lg:w-4/5 mb-6 lg:mb-12 lg:mt-0'>
								<Button
									text='View my Account'
									color='green'
									size='sm'
									className='flex w-[210px] shadow-light-grey'
									onClick={() => {
										navigate('/user/account')
									}}
								/>
							</div>
						</div>
					) : !loggedIn ? (
						<div className='px-4'>
							<h1 className='text-xl lg:text-2xl font-header w-full lg:w-4/5 mt-8 mb-6'>
								Create an account to get started!
							</h1>
							<div className='flex justify-start text-center lg:w-4/5 mb-6 lg:mb-12 lg:mt-0'>
								<Button
									text='Sign up'
									color='red'
									size='sm'
									className='flex w-[210px] shadow-light-grey'
									onClick={() => {
										navigate('/sign-up/membership')
									}}
								/>
							</div>
							<h2 className='text-lg lg:text-xl font-header text-start w-full lg:w-4/5 mt-8 mb-6'>
								Already have an account?{' '}
								<Link
									to='/login/membership'
									className='underline'
								>
									Login here.
								</Link>
							</h2>
						</div>
					) : (
						<div className='pt-3 md:pt-7 mb-2 pb-1'>
							<div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
								Payment
							</div>
							<>
								<div className='mt-1 mb-2 text-lg font-semibold'>
									Select a card for this order
								</div>
								{!loadingAddition &&
									!stripeLoading &&
									!loadingUser &&
									!loadingPaymentMethods && (
										<div>
											{user.paymentMethods &&
											cardRadios.length > 0 ? (
												<div>
													<RadioGroup
														className='mt-1 sm:mt-0'
														name='sortBy'
														radios={cardRadios}
														onChange={(
															paymentMethodId
														) => {
															const prevForm =
																memberPayload
															setSelectedPaymentMethod(
																paymentMethodId
															)
														}}
													/>
												</div>
											) : (
												<div>
													<div className='font-semibold'>
														No cards on file
													</div>
												</div>
											)}
										</div>
									)}
								{loadingPaymentMethods && (
									<div className='mt-3 text-center'>
										<BounceLoaderCentered container='div' />
									</div>
								)}
								{loadingAddition && (
									<div className='mt-3 text-center'>
										<BounceLoaderCentered container='div' />
									</div>
								)}
								{loadingUser && (
									<div className='mt-3 text-center'>
										<BounceLoaderCentered container='div' />
									</div>
								)}
								{stripeLoading && (
									<div className='mt-3 text-center'>
										<BounceLoaderCentered container='div' />
									</div>
								)}
								<div className='w-[90%] max-w-[440px] mb-3'>
									<div>
										{!showCardForm && (
											<div>
												<button
													className='my-3 text-lg underline text-green-600 font-semibold'
													onClick={() => {
														setShowCardForm(true)
													}}
												>
													Add a card
												</button>
											</div>
										)}
										{showCardForm && (
											<div className='shadow-light-grey  bg-white rounded-xl p-6'>
												<div className='mt-4'>
													<div className='text-sm'>
														Enter card info below
													</div>

													<form
														onSubmit={
															handleAddCardSubmit
														}
													>
														<CardElement
															options={
																cardElementOptions
															}
														/>
														{!loadingAddition &&
															!stripeLoading &&
															!loadingUser && (
																<div>
																	<Button
																		className='mt-2 px-3'
																		text='Add Card'
																		size='sm'
																		submit={
																			true
																		}
																	/>
																</div>
															)}
													</form>
												</div>
												<div>
													{!loadingAddition &&
														!stripeLoading &&
														!loadingUser && (
															<button
																className='mt-6 text-[16px] underline text-green-600 font-semibold'
																onClick={() => {
																	setShowCardForm(
																		false
																	)
																}}
															>
																Cancel
															</button>
														)}
												</div>
											</div>
										)}
									</div>
								</div>
							</>
						</div>
					)}
					{loggedIn && (
						<div className='flex justify-center sm:justify-start mx-auto'>
							{updateUserLoading && (
								<div className='flex items-center h-screen-no-header-mobile sm:h-screen-no-header'>
									<BounceLoaderCentered container='div' />
								</div>
							)}
							<div className='justify-center sm:justify-start flex flex-col mb-6 text-4xl'>
								{membershipError && (
									<div className='rounded-[5px] mb-1 px-2 bg-red-400 w-full justify-self-end text-white text-base shadow-light-grey'>
										An error occured while submitting your
										order. Try again or contact us.
									</div>
								)}
								{membershipLoading ? (
									<BounceLoaderCentered container='div' />
								) : !selectedPaymentMethod ||
								  selectedPaymentMethod === '' ||
								  loadingAddition ||
								  !selectedTier ? (
									<Button
										text='Join now'
										className='w-[250px]'
										color='disabled'
									/>
								) : (
									<>
										<div className='text-xl font-semibold mb-4'>
											{getTierPrice()}
										</div>
										<Button
											text='Join now'
											className='shadow-light-grey w-[250px]'
											onClick={handleSubmitOrder}
										/>
									</>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ User, Payments, AppInfo, Membership }) => ({
	user: User.user,
	loadingUser: User.loading,
	loggedIn: User.loggedIn,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
	paymentError: Payments.error,
	appInfo: AppInfo.appInfo,

	membershipLoading: Membership.loading,
	memberRegistered: Membership.memberRegistered,
	membershipError: Membership.error,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
	onResetCardAdded: () => dispatch(resetCardAdded()),
	onGetPaymentMethods: (userStripeId) =>
		dispatch(getPaymentMethods(userStripeId)),
	onResetCardAdded: () => dispatch(resetCardResponse()),

	onSignupMember: (submitOrderPayload) =>
		dispatch(membershipSignup(submitOrderPayload)),
	onResetMembership: () => dispatch(resetMembership()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MembershipSignUpForm)
