import React from "react";
import { Footer } from "../../components";
import Header from "../../components/Header";

function WhyReuse() {
  return (
    <>
      <Header label={"Why Reuse"} showBackButton />
      <div className="flex flex-col justify-center mt-5">
        <div className="w-[90%] md:w-4/5 max-w-[950px] mx-auto mb-10">
          <div className="font-header text-green-600 text-xl md:text-4xl mb-3 md:mb-6">
            Single-use packaging is a climate problem
          </div>
          <div className="text-base md:text-[22px] sm:mb-5 mb-10 leading-7">
            Single-use packaging litters our streets, pollutes our waterways,
            piles up in landfills, contributes to air pollution, and adds to
            greenhouse gas emissions.
          </div>
          <div className="text-sm md:text-base sm:mb-3 mb-6 leading-7">
            → In the US alone, over{" "}
            <span className="font-semibold">
              561 billion disposable food service items
            </span>
          </div>
          <div className="text-sm md:text-base  sm:mb-3 mb-6 leading-7">
            → Every billion takeout containers are equivalent to at least{" "}
            <span className="font-semibold">
              166,000 tons of greenhouse gas emissions
            </span>{" "}
            and at least{" "}
            <span className="font-semibold">357 million gallons of water</span>{" "}
            consumption.
          </div>
          <div className="text-sm md:text-base  sm:mb-3 mb-6 leading-7">
            → 21% of single-use containers are used for on-site dining and 79%
            are used for take-out and delivery. We can’t address the single-use
            packaging problem without addressing takeout and delivery
          </div>
          <div className="text-sm md:text-base sm:mb-9 mb-18 leading-7">
            →{" "}
            <a
              href="https://phys.org/news/2022-05-plastic-recycled.html#:~:text=%22The%20current%202021%20U.S.%20plastic,rate%20may%20be%20even%20lower.%22"
              target="_blank"
              className="text-green-600 underline font-semibold"
              rel="noreferrer"
            >
              Recent studies
            </a>{" "}
            show that in the US, just 5% of its plastic waste is actually
            recycled
          </div>

          <div className="font-header text-green-600 mt-12 text-xl sm:text-4xl mb-3 sm:mb-6">
            False solutions worsen the problem
          </div>
          <div className="text-base md:text-[22px] sm:mb-5 mb-10 leading-7">
            Single-use plastics are extremely damaging, but replacing plastics
            with compostables is actually even more harmful
          </div>
          <div className="text-sm md:text-base sm:mb-3 mb-6 leading-7">
            → The last industry-wide survey conducted by BioCycle in 2019
            concluded that only 3.9% of the 4,700 composting facilities in the
            US take food scraps.{" "}
            <span className="font-semibold">
              Of the relatively small number of compost facilities that accept
              feedstock other than yard material, only 15% accept fiber-based
              and bioplastic packaging
            </span>
            . This number is not expected to increase as the number of
            facilities increase for several reasons, some of which are listed in
            a letter signed by all Oregon compost facilities stating they will
            no longer accept compostable products
          </div>
          <div className="text-sm md:text-base  sm:mb-3 mb-6 leading-7">
            → Replacing all single-use conventional packaging with single-use
            BPI certified compostable packaging would require a sacrifice of
            over 133,486,327 acres of land to grow the required 769,281,017,668
            pounds of corn as feedstock, equivalent to ⅓ of all global annual
            corn production, for packaging that will only be used for a few
            minutes.
          </div>
          <div className="text-sm md:text-base  sm:mb-9 mb-18 leading-7">
            → Replacing conventional single-use packaging with compostable
            single-use packaging does not reduce litter, overhead costs for
            restaurants (in fact, it does the opposite), or hauling costs for
            NYC. Replacing one type of single-use material for another does not
            help NYC, or the global community, to mitigate climate change and
            only further reinforces inefficient, business-as-usual practices.
          </div>

          <div className="font-header text-green-600 mt-12 text-xl sm:text-4xl mb-3 sm:mb-6">
            Let's compare:
          </div>
          <div className="text-base md:text-[22px] sm:mb-5 mb-10 leading-7">
            How do takeout packaging materials stack up against each other?
          </div>
          <div>
            <img src="https://i.imgur.com/2PUVWeB.jpg" />
          </div>

          <div className="text-xs">
            Based on the most current peer-reviewed research that incorporates
            the full lifecycle of containers and based on each reusable
            container achieving a 1,000 cycle lifespan
          </div>

          <div className="font-header text-green-600 mt-12 text-xl sm:text-4xl mb-3 sm:mb-6">
            Reuse is the Solution.
          </div>
          <div className="text-base md:text-[22px] sm:mb-5 mb-10 leading-7">
            If DeliverZero’s service was used for 1 million containers:
          </div>
          <div className="text-sm md:text-base sm:mb-3 mb-6 leading-7">
            → Over{" "}
            <span className="font-semibold">
              25 tons of packaging waste would be diverted
            </span>{" "}
            from landfills when compared to single-use plastics and compostables
          </div>
          <div className="text-sm md:text-base  sm:mb-3 mb-6 leading-7">
            → Over{" "}
            <span className="font-semibold">
              440,000 gallons of water would be saved
            </span>{" "}
            when compared to compostables
          </div>
          <div className="text-sm md:text-base  sm:mb-9 mb-18 leading-7">
            → Over{" "}
            <span className="font-semibold">
              110 tons of GHG Emissions would be avoided
            </span>{" "}
            when compared to single-use plastic and compostables.
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyReuse;
