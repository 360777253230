import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input, Button, Alert } from './Elements'
import {
	loginUser,
	loginUserSuccess,
	socialLoginGoogle,
	socialRegisterGoogle,
} from '../store/auth/login/actions'
import { getUser } from '../store/auth/user/actions'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { realm } from '../helpers/realm'
import { BounceLoader } from 'react-spinners'

const LoginCard = (props) => {
	const {
		user,
		loggedIn,
		onLoginUser,
		loginLoading,
		loginError,
		loginAttemptStatus,
		onGetUser,
		onGoogleLogin,
		onGoogleRegister,
		userLoading,
		path,
	} = props
	const useQuery = () => new URLSearchParams(useLocation().search)

	const [passwords, setPasswords] = useState({
		password: '',
		confirmPassword: '',
	})
	const [missingPasswordError, setMissingPasswordError] = useState(false)
	const [missingConfirmError, setMissingConfirmError] = useState(false)
	const [passwordsMatchError, setPasswordsMatchError] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)

	const bounceLoaderColor = '#507f74'

	const navigate = useNavigate()
	const location = useLocation()

	const errorMessageLookUp = {
		'password must be between 6 and 128 characters':
			'Passwords must be at least 6 characters.',
		'invalid password':
			'Password is invalid. Try again or reset your password below',
	}

	const checkPasswords = () => {
		if (passwords.password.length < 6 && passwords.password.length > 0) {
			setMissingPasswordError(true)
			setErrorMessage('Passwords must be at least 6 characters.')
		} else if (passwords.password !== passwords.confirmPassword) {
			setMissingConfirmError(true)
			setErrorMessage('Passwords do not match.')
		} else {
			setMissingPasswordError(false)
			setMissingConfirmError(false)
			setPasswordsMatchError(false)
			setErrorMessage(false)
		}
	}

	useEffect(() => {
		checkPasswords()
	}, [passwords])

	const handleChange = (e) => {
		if (e.target.name == 'password') {
			setMissingPasswordError(false)
		}
		if (e.target.name == 'confirmPassword') {
			setMissingConfirmError(false)
		}
		setPasswords({ ...passwords, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		// if (loggedIn && path) {
		// 	navigate(path)
		// } else if (loggedIn) {
		if (loggedIn) {
			navigate('/user/accountLanding')
		}
	}, [loggedIn])

	const token = useQuery().get('token')
	const tokenId = useQuery().get('tokenId')

	const handleSubmit = async (event) => {
		event.preventDefault()
		if (
			!missingPasswordError &&
			!missingConfirmError &&
			!passwordsMatchError
		) {
			await realm.emailPasswordAuth
				.resetPassword({
					password: passwords.password,
					token,
					tokenId,
				})
				.then(() => {
					navigate('/login')
				})
		}
	}

	return (
		<div className='w-11/12 h-full bg-white overflow-hidden shadow-light-grey rounded-xl max-w-lg m-auto'>
			<div className='text-xl md:px-4 py-5 sm:p-6 m-6 justify-center items-center'>
				<form
					className='form-horizontal'
					onSubmit={handleSubmit}
				>
					{errorMessage && (
						<Alert
							type='warning'
							content={errorMessage}
							className='text-sm mb-10'
						></Alert>
					)}
					<Input
						type='password'
						label='NEW PASSWORD'
						name='password'
						error={missingPasswordError}
						className='bg-white border-2 border-gray-300 mb-6'
						onChange={handleChange}
					/>
					<Input
						type='password'
						label='CONFIRM PASSWORD'
						name='confirmPassword'
						error={missingConfirmError}
						className='bg-white border-2 border-gray-300'
						onChange={handleChange}
					/>
					{!loginLoading && !userLoading && (
						<div>
							<Button
								color='green'
								full
								size='sm'
								text='Submit'
								className='mb-3 mt-3 py-1'
								submit={true}
								// onClick = {buttonSubmit}
							/>
						</div>
					)}
					{loginLoading && (
						<div className='mt-3 text-center'>
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						</div>
					)}

					{userLoading && (
						<div className='mt-3 text-center'>
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						</div>
					)}
					<div className='text-center text-sm mt-5'>
						New to DeliverZero?{' '}
						<Link
							to='/sign-up'
							className='text-green-600 underline font-semibold'
						>
							Create an account.
						</Link>
					</div>
				</form>
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Login, Path }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	loginLoading: Login.loading,
	loginError: Login.error,
	loginAttemptStatus: Login.loginAttemptStatus,
	userLoading: User.loading,
	path: Path.path,
})

const mapDispatchToProps = (dispatch) => ({
	onLoginSuccess: (user) => dispatch(loginUserSuccess(user)),
	onLoginUser: (credentials) => dispatch(loginUser(credentials)),
	onGoogleLogin: (res) => dispatch(socialLoginGoogle(res)),
	onGoogleRegister: (payload) => dispatch(socialRegisterGoogle(payload)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard)
