import React, { useState } from 'react'

const DistanceBar = (props) => {
	const {
		defaultDistanceBarValue,
		distanceBarValue,
		setDistanceBarValue,
		setMapBool,
	} = props

	const handleSelectDistance = (distanceInMiles) => {
		setMapBool(false)
		setDistanceBarValue(distanceInMiles)
	}

	return (
		<div className='flex flex-col items-center w-screen h-full px-4 '>
			<div className='text-sm lg:text-base w-[80%] sm:w-[60%] mx-4'>
				<form action='#'>
					<label
						htmlFor='miles'
						className=' text-gray-700 w-full'
					>
						Distance {distanceBarValue} miles
					</label>
					<input
						type='range'
						min='1'
						name='miles'
						id='miles'
						max='25'
						defaultValue={defaultDistanceBarValue}
						className='w-full h-2 bg-green-400 rounded-full appearance-none'
						onChange={(event) =>
							handleSelectDistance(event.target.value)
						}
					/>
				</form>

			</div>
		</div>
	)
}

export default DistanceBar
