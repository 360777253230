import { realm } from "../helpers/realm";

export const createStop = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const stops = client.db("caas").collection("logistics");

    if (payload.length > 1) {
        const existingPackingList = await stops.findOne({
            date: payload[0].date,
            market: payload[0].market,
        });

        if (!existingPackingList) {
            return stops.insertOne({
                date: payload[0].date,
                market: payload[0].market,
                stops: payload,
            });
        } else {
            return stops.updateOne(
                {
                    date: payload[0].date,
                    market: payload[0].market,
                },
                { $push: { stops: payload } }
            );
        }
    } else {
        const existingPackingList = await stops.findOne({
            date: payload.date,
            market: payload.market,
        });

        if (!existingPackingList) {
            return stops.insertOne({
                date: payload.date,
                market: payload.market,
                stops: [{ ...payload }],
            });
        } else {
            return stops.updateOne(
                {
                    date: payload.date,
                    market: payload.market,
                },
                { $push: { stops: { ...payload } } }
            );
        }
    }
};

export const updateStop = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const client = realm.currentUser.mongoClient("RealmService");
    const stops = client.db("caas").collection("logistics");
    const existingPackingList = await stops.findOne({
        date: payload.date,
        market: payload.market,
    });

    if (!existingPackingList) {
        throw new Error(
            `No document found for date: ${payload.date} and market ${payload.market}`
        );
    }

    if (!payload.stopId) {
        throw new Error("stopId is required in the payload for updateStop");
    }

    const stopId = payload.stopId;

    const update = await stops.updateOne(
        {
            date: payload.date,
            market: payload.market,
            "stops.stopId": stopId,
        },
        {
            $set: {
                "stops.$.status": payload.status,
                "stops.$.driverNote": payload.driverNote,
                "stops.$.actuals": payload.actuals,
                "stops.$.image": payload.image,
            },
        }
    );

    return update;
};

export const deleteStop = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const stops = client.db("caas").collection("logistics");
    const existingPackingList = await stops.findOne({
        date: payload.date,
        market: payload.market,
    });

    if (!existingPackingList) {
        throw new Error(
            `No document found for date: ${payload.date} and market ${payload.market}`
        );
    }

    if (!payload.stopId) {
        throw new Error("stopId is required in the payload for deleteStop");
    }

    const stopId = payload.stopId;
    const deleteStop = await stops.updateOne(
        { date: payload.date, market: payload.market },
        { $pull: { stops: { stopId } } }
    );

    return deleteStop;
};

export const getAllLogistics = async ({ filter, options }) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const stops = client.db("caas").collection("logistics");

    let queryFilter = filter ? {} : {};

    if (filter) {
        if (Array.isArray(filter)) {
            queryFilter = { $and: filter };
        } else {
            queryFilter = filter;
        }
    }

    const logistics = await stops.find(queryFilter, options);

    let totalCount = 0;

    logistics.forEach((doc) => {
        if (doc.stops && Array.isArray(doc.stops)) {
            totalCount += doc.stops.length;
        }
    });

    return { totalCount, logistics };
};

export const resetSuccess = () => {
    return true;
};

export const logisticsAlert = async (payload) => {
    // console.log("payload", payload)
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const alertResponse = await realm.currentUser.callFunction(
        "alertSlack",
        payload
    );

    return alertResponse;
};

export const createPendingPickup = async (payload) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const pendingPickups = client.db("caas").collection("pending_pickup");

    const existingDoc = await pendingPickups.findOne({
        timestamp: payload.timestamp,
        "user.phone": payload.user.phone,
    });

    if (existingDoc) {
        const updateResult = await pendingPickups.updateOne(
            { _id: existingDoc._id },
            { $set: payload }
        );

        if (updateResult.modifiedCount === 1) {
            return updateResult;
        } else {
            throw new Error("Error updating existing pickup request.");
        }
    } else {
        const insertResult = await pendingPickups.insertOne(payload);
        return insertResult;
    }
};

// once all customer pick ups --> pending pickups collection can remove transactions search
// export const getPendingPickups = async (filter) => {
//     try {
//         if (!realm.currentUser) {
//             throw new Error('Unauthorized');
//         };

//         const client = realm.currentUser.mongoClient('RealmService');
//         const pendingPickups = client.db('caas').collection('pending_pickup');

//         const queryFilter = filter ? {$and: filter} : {};

//         return pendingPickups.find(queryFilter, { $limit: 100 })

//     } catch (error) {
//         console.error('Error in getAllLogistics:', error.message);
//         throw error;
//     };
// };

export const getPendingPickups = async (filter) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const pendingPickups = client.db("caas").collection("pending_pickup");
    const transactions = client.db("caas").collection("transactions");

    const transactionFilter = {
        timestamp: {
            $gte: filter[0].timestamp.$gte,
            $lt: filter[0].timestamp.$lt,
        },
        returnAtDoor: true,
        reverseLogisticsPartner: { $in: ["flot", "deliverzero"] },
    };

    const [pendingPickupsResult, transactionsResult] = await Promise.all([
        pendingPickups.find(transactionFilter, { $limit: 100 }),
        transactions.find(transactionFilter, { $limit: 100 }),
    ]);

    let marketResult = [];
    if (filter && filter[0].market) {
        marketResult = await checkMarketPolygonLocal({
            transactionArray: [...pendingPickupsResult, ...transactionsResult],
            market: filter[0].market,
        });
    }

    return marketResult;
};

export const getPickupsByPhone = async (payload) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const pendingPickups = client.db("caas").collection("pending_pickup");

    const pickups = await pendingPickups.find({
        "user.phone": payload.phone,
    });

    return pickups;
};

export const getIncompleteStops = async (filter) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const stops = client.db("caas").collection("logistics");

    const aggregationPipeline = [
        { $unwind: "$stops" },
        { $match: { "stops.status": "incomplete" } },
        {
            $group: {
                _id: "$_id",
                stops: { $push: "$stops" },
            },
        },
        { $limit: 100 },
    ];

    const results = await stops.aggregate(aggregationPipeline);
    const resultsArr = [];
    const resultStops = [];
    if (results.length > 0) {
        for (const result of results) {
            resultStops.push(result.stops);
        }
        if (resultStops.length > 0) {
            for (const doc of resultStops) {
                const marketResult = await getMarketLocal(doc[0].address);
                const market = marketResult.market;
                const addressArr = doc[0].address.split(",");
                const stateZip = addressArr[addressArr.length - 2];
                const stateZipArr = stateZip.split(" ");
                const state = stateZipArr[1];
                const zip = stateZipArr[2];
                if (filter) {
                    if (filter.includes(market)) {
                        resultsArr.push({
                            ...doc[0],
                            state: state,
                            market: market,
                        });
                    }
                } else {
                    resultsArr.push({
                        ...doc[0],
                        state: state,
                        market: market,
                    });
                }
            }
        }
    }

    // console.log("resultsArr", resultsArr)
    return resultsArr;
};

export const getNewRestaurantStops = async (filter) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const leads = client.db("caas").collection("leads");

    const aggregationPipeline = [
        {
            $addFields: {
                fullDocument: "$$ROOT",
            },
        },
        {
            $project: {
                fullAddress: 1,
                signUpComplete: 1,
                containersDropped: 1,
                fullDocument: 1,
            },
        },
        {
            $match: {
                signUpComplete: true,
                $or: [
                    { containersDropped: { $exists: false } },
                    { containersDropped: false },
                ],
            },
        },
    ];

    const results = await leads.aggregate(aggregationPipeline);
    const resultsArr = [];

    for (const doc of results) {
        const marketResult = await getMarketLocal(doc.fullAddress);
        const market = marketResult.market;
        const addressArr = doc.fullAddress.split(",");
        const stateZip = addressArr[addressArr.length - 2];
        const stateZipArr = stateZip.split(" ");
        const state = stateZipArr[1];
        const zip = stateZipArr[2];
        if (filter) {
            if (filter.includes(market)) {
                resultsArr.push({ ...doc, state: state, market: market });
            }
        } else {
            resultsArr.push({ ...doc, state: state, market: market });
        }
    }

    // console.log("resultsArr", resultsArr)
    return resultsArr;
};

export const getInventory = async (filter) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const transactions = client.db("caas").collection("transactions");
    const containers = client.db("caas").collection("containers");
    const containerIdsDocs = await containers.find({}, { containerId: 1, _id: 0 });
    const containerIds = containerIdsDocs.map((doc) => doc.containerId);
    // console.log("containerIds", containerIds);

    const stages = [
        {
            $lookup: {
                from: "nodes",
                localField: "node",
                foreignField: "slug",
                as: "nodeInfo",
            },
        },
        {
            $unwind: {
                path: "$nodeInfo",
                preserveNullAndEmptyArrays: true,
            },
        },
    ];

    if (filter) {
        stages.push({ $match: filter });
    }

    const groupFields = {
        _id: "$node",
        totalBoxCount: { $sum: { $ifNull: ["$boxCount", 0] } },
        lastInventoryUpdate: { $max: "$timestamp" },
        name: { $first: "$nodeInfo.name" },
    };

    containerIds.forEach((containerId) => {
        groupFields[containerId] = {
            $sum: {
                $cond: {
                    if: { $lt: [{ $ifNull: ["$boxCount", 0] }, 0] },
                    then: { $multiply: [{ $ifNull: [`$boxCountByContainer.${containerId}`, 0] }, -1] },
                    else: { $ifNull: [`$boxCountByContainer.${containerId}`, 0] },
                },
            },
        };
    });

    const groupStage = {
        $group: groupFields,
    };
    stages.push(groupStage);

    // console.log("stages", stages);

    return transactions.aggregate(stages);
};

export const getInventoryLocal = async (filter) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const transactions = client.db("caas").collection("transactions");
    const containers = client.db("caas").collection("containers");
    const containerIdsDocs = await containers.find({}, { containerId: 1, _id: 0 });
    const containerIds = containerIdsDocs.map((doc) => doc.containerId);
    // console.log("containerIds", containerIds);

    const stages = [
        {
            $lookup: {
                from: "nodes",
                localField: "node",
                foreignField: "slug",
                as: "nodeInfo",
            },
        },
        {
            $unwind: {
                path: "$nodeInfo",
                preserveNullAndEmptyArrays: true,
            },
        },
    ];

    if (filter) {
        stages.push({ $match: filter });
    }

    const groupFields = {
        _id: "$node",
        totalBoxCount: { $sum: { $ifNull: ["$boxCount", 0] } },
        lastInventoryUpdate: { $max: "$timestamp" },
        name: { $first: "$nodeInfo.name" },
    };

    containerIds.forEach((containerId) => {
        groupFields[containerId] = {
            $sum: {
                $cond: {
                    if: { $lt: [{ $ifNull: ["$boxCount", 0] }, 0] },
                    then: { $multiply: [{ $ifNull: [`$boxCountByContainer.${containerId}`, 0] }, -1] },
                    else: { $ifNull: [`$boxCountByContainer.${containerId}`, 0] },
                },
            },
        };
    });

    const groupStage = {
        $group: groupFields,
    };
    stages.push(groupStage);

    // console.log("stages", stages);

    return transactions.aggregate(stages);
};

export const adjustInventory = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const { nodeSlug, desiredQuantities } = payload;
    const currentInventoryResponse = await getInventoryLocal({ node: nodeSlug });
    const currentInventory = await currentInventoryResponse;
    const currentInventoryQuantities = currentInventory[0];
    delete currentInventoryQuantities._id; 
    
    const adjustments = {};
    Object.keys(desiredQuantities).forEach((containerType) => {
        let currentQuantity = currentInventoryQuantities[containerType] || 0;
        if (containerType === 'boxCount') {
            currentQuantity = currentInventoryQuantities['totalBoxCount'] || 0;
        }
        const desiredQuantity = desiredQuantities[containerType];
        adjustments[containerType] = desiredQuantity - currentQuantity;
    });

    // console.log("Current Inventory for Node:", currentInventoryQuantities);
    // console.log("Adjustments:", adjustments);
    return adjustments;
};

export const getLogisticsDashboard = async (payload) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const getDashAggr = await realm.currentUser.callFunction(
        "aggregation/fetchLogisticsDashboard",
        payload
    );

    // console.log("getDashAggr", getDashAggr);
    return getDashAggr;
};

export const getReturnBinBoxCount = async (payload) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const nodes = client.db("caas").collection("nodes");
    const node = await nodes.findOne({ slug: payload });
    return node.balance;
};

export const getMarket = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const market = await realm.currentUser.callFunction(
        "logistics/getMarket",
        payload
    );

    return market;
};

export const getMarketLocal = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const market = await realm.currentUser.callFunction(
        "logistics/getMarket",
        payload
    );

    return market;
};

export const checkMarketPolygonLocal = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const market = await realm.currentUser.callFunction(
        "logistics/marketPolygonCheck",
        payload
    );

    return market;
};

/// logic added for uber direct ///
export const getUberDirectQuote = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const quoteResponse = await realm.currentUser.callFunction(
        "uberDirect/getQuote",
        payload
    );

    return quoteResponse;
};

export const createUberDirectRequest = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const uberResponse = await realm.currentUser.callFunction(
        "uberDirect/createDelivery",
        payload
    );

    return uberResponse;
};

export const updateUberDirect = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const uberResponse = await realm.currentUser.callFunction(
        "uberDirect/updateDelivery",
        payload
    );

    return uberResponse;
};

export const getAllUberDirect = async () => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const uberResponse = await realm.currentUser.callFunction(
        "uberDirect/fetchAllDeliveries"
    );

    return uberResponse;

    ////// commented out code below fetches from mongo collection rather than from uberDirect API

    // const client = realm.currentUser.mongoClient("RealmService");
    // const uberdirect = client.db("caas").collection("uberdirect");
    // const allRequests = await uberdirect.find({ status: { $ne: "delivered" } });

    // const requestsWithFormattedDate = allRequests.map(request => {
    //     const updatedTimestamp = new Date(request.updateTimestamp);
    //     return { ...request, updateTimestamp: updatedTimestamp };
    // });

    // const sortedRequests = requestsWithFormattedDate.sort((a, b) => b.updateTimestamp - a.updateTimestamp);

    // return sortedRequests;
};

export const cancelUberDirect = async (id) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const uberResponse = await realm.currentUser.callFunction(
        "uberDirect/cancelDelivery",
        id
    );

    return uberResponse;
};

export const getUberDirectRequestById = async (id) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const uberResponse = await realm.currentUser.callFunction(
        "uberDirect/getDelivery",
        id
    );

    return uberResponse;

    ////// commented out code below fetches from mongo collection rather than from uberDirect API

    // const client = realm.currentUser.mongoClient("RealmService");
    // const uberdirect = client.db("caas").collection("uberdirect");
    // const request = await uberdirect.findOne({ orderId: id });

    // return request;
};

export const getUberDirectRequestDocById = async (id) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const uberdirect = client.db("caas").collection("uberdirect");
    const request = await uberdirect.findOne({ id: id });

    return request;
};

export const getUberDirectQuoteDropoff = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const quoteResponse = await realm.currentUser.callFunction(
        "uberDirect/getQuoteDropoff",
        payload
    );

    return quoteResponse;
};

export const getUberDirectQuoteDropoffNoNode = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const quoteResponse = await realm.currentUser.callFunction(
        "uberDirect/getQuoteDropoffNoNode",
        payload
    );

    return quoteResponse;
};

export const getProofOfDelivery = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const response = await realm.currentUser.callFunction(
        "uberDirect/proofOfDelivery",
        payload
    );

    return response;
};

export const getMarketObj = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const markets = client.db("caas").collection("markets");
    const marketObj = await markets.findOne({ market: payload });

    return marketObj;
};

export const createPackingListTransactions = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const response = await realm.currentUser.callFunction(
        "logistics/createPackingListTransactions",
        payload
    );

    return response;
};

