import {
	GET_APP_INFO,
	GET_APP_INFO_SUCCESS,
	GET_APP_INFO_FAILURE,
	GET_BANNER,
	GET_BANNER_SUCCESS,
	GET_BANNER_FAILURE,
} from './actionTypes'
export const getAppInfo = () => ({
	type: GET_APP_INFO,
})

export const getAppInfoSuccess = (payload) => ({
	type: GET_APP_INFO_SUCCESS,
	payload: payload,
})
export const getAppInfoFailure = (payload) => ({
	type: GET_APP_INFO_FAILURE,
	payload: payload,
})

export const getBanner = () => ({
	type: GET_BANNER,
})

export const getBannerSuccess = (payload) => ({
	type: GET_BANNER_SUCCESS,
	payload: payload,
})

export const getBannerFailure = (payload) => ({
	type: GET_BANNER_FAILURE,
	payload: payload,
})