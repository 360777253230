import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getOrders,
	getOneOrder,
	submitOrder,
	adminGetOneOrder,
} from '../../model/orders'
import {
	getOneOrderFail,
	getOneOrderSuccess,
	getOrdersFail,
	getOrdersSuccess,
	submitOrderFail,
	submitOrderSuccess,
} from './actions'

import {
	GET_ORDERS,
	GET_ONE_ORDER,
	SUBMIT_ORDER,
	ADMIN_GET_ONE_ORDER,
} from './actionTypes'

function* fetchOrders(action) {
	try {
		const orders = yield call(getOrders, action.payload)
		yield put(getOrdersSuccess(orders))
	} catch (error) {
		yield put(getOrdersFail(error))
	}
}

function* fetchOneOrder(action) {
	try {
		const oneOrder = yield call(getOneOrder, action.payload)
		yield put(getOneOrderSuccess(oneOrder))
	} catch (error) {
		yield put(getOneOrderFail(error))
	}
}

function* adminFetchOneOrder(action) {
	try {
		const oneOrder = yield call(adminGetOneOrder, action.payload)
		yield put(getOneOrderSuccess(oneOrder))
	} catch (error) {
		yield put(getOneOrderFail(error))
	}
}

function* completeOrder(action) {
	try {
		const oneOrder = yield call(submitOrder, action.payload)
		yield put(submitOrderSuccess(oneOrder))
	} catch (error) {
		yield put(submitOrderFail(error))
	}
}

function* ordersSaga() {
	yield takeEvery(GET_ORDERS, fetchOrders)
	yield takeEvery(GET_ONE_ORDER, fetchOneOrder)
	yield takeEvery(SUBMIT_ORDER, completeOrder)
	yield takeEvery(ADMIN_GET_ONE_ORDER, adminFetchOneOrder)
}

export default ordersSaga
