import {
	ADMIN_UPLOAD_ORDERS, ADMIN_UPLOAD_ORDERS_ERROR, ADMIN_UPLOAD_ORDERS_SUCCESS,
	DISPUTE_TRANSACTION,
	DISPUTE_TRANSACTION_SUCCESS,
	GET_NODE_TRANSACTIONS,
	GET_NODE_TRANSACTIONS_FAIL,
	GET_NODE_TRANSACTIONS_SUCCESS,
	GET_ONE_TRANSACTION,
	GET_ONE_TRANSACTION_SUCCESS,
	GET_TRANSACTIONS,
	GET_TRANSACTIONS_BY_CLIENT,
	GET_TRANSACTIONS_FAIL,
	GET_TRANSACTIONS_SUCCESS, UPDATE_ONE_TRANSACTION, UPDATE_ONE_TRANSACTION_SUCCESS, UPDATE_ONE_TRANSACTION_ERROR
} from './actionTypes'

const initialState = {
	transactions: [],
	error: {},
	loadingTransactions: true,
	transactionsCount: 0,
	oneTransaction: null,
	successfulDispute: false,
	totalBoxCount: 0,
	nodeTransactions: [],
	nodeTransactionCount: 0,
	ordersUploading:false,
	ordersUploaded:false,
	ordersUploadError:null,
	updatingTransaction: false,
	updatedTransaction: false,
	updateTransactionError: null,
}

const transactions = (state = initialState, action) => {
	switch (action.type) {
		case GET_TRANSACTIONS:
			return {
				...state,
				loadingTransactions: true,
			}
		case GET_TRANSACTIONS_SUCCESS:
			const transactions = action.payload[0]
			return {
				...state,
				loadingTransactions: false,
				transactions: transactions.data,
				transactionsCount: transactions.pagination[0]
					? transactions.pagination[0].total
					: 0,
				error: false,
				totalBoxCount: 0,
			}

		case GET_TRANSACTIONS_FAIL:
			return {
				...state,
				loadingTransactions: false,
				error: action.payload,
			}
		case GET_ONE_TRANSACTION:
			return {
				...state,
				loadingTransactions: true,
			}
		case GET_ONE_TRANSACTION_SUCCESS:
			return {
				...state,
				loadingTransactions: false,
				error: false,
				oneTransaction: action.payload,
				updatingTransaction: false,
				updatedTransaction:true,
			}
		case DISPUTE_TRANSACTION:
			return {
				...state,
				loadingTransactions: true,
				successfulDispute: false,
			}
		case DISPUTE_TRANSACTION_SUCCESS:
			return {
				...state,
				loadingTransactions: false,
				error: false,
				oneTransaction: action.payload,
				successfulDispute: true,
			}
		case GET_TRANSACTIONS_BY_CLIENT:
			return {
				...state,
				loadingTransactions: true,
				error: false,
			}
		case GET_NODE_TRANSACTIONS:
			return {
				...state,
				loadingTransactions: true,
			}
		case GET_NODE_TRANSACTIONS_SUCCESS:
			const nodeTransactions = action.payload[0]
			return {
				...state,
				loadingTransactions: false,
				nodeTransactions: nodeTransactions.data,
				nodeTransactionsCount: nodeTransactions.pagination[0]
					? nodeTransactions.pagination[0].total
					: 0,
				error: false,
				totalBoxCount: 0,
			}

		case GET_NODE_TRANSACTIONS_FAIL:
			return {
				...state,
				loadingTransactions: false,
				error: action.payload,
			}

		case ADMIN_UPLOAD_ORDERS:
			return {
				...state,
				ordersUploaded: false,
				ordersUploading: true,
				ordersUploadError: null,
			}
		case ADMIN_UPLOAD_ORDERS_SUCCESS:
			return {
				...state,
				ordersUploaded: true,
				ordersUploading: false,
				ordersUploadError: null,
			}
		case ADMIN_UPLOAD_ORDERS_ERROR:
			return {
				...state,
				ordersUploaded: false,
				ordersUploading: false,
				ordersUploadError: action.payload,
			}

		case UPDATE_ONE_TRANSACTION:
			return {
				...state,
				updatingTransaction: true,
				updatedTransaction: false,
				updateTransactionError: null,
			}
		case UPDATE_ONE_TRANSACTION_SUCCESS:
			return {
				...state,
				updatingTransaction: false,
				updatedTransaction: true,
				updateTransactionError: null,
			}
		case UPDATE_ONE_TRANSACTION_ERROR:
			return {
				...state,
				updatingTransaction: false,
				updatedTransaction: false,
				updateTransactionError: action.payload,
			}
		default:
			return state
	}
}

export default transactions
