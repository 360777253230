import {
	GET_MENU_BY_SLUG,
	GET_MENU_SUCCESS,
	GET_MENU_FAIL,
	GET_PRODUCT,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
	GET_DELIVERECT_DISPATCH_AVAILABILITY,
	GET_DELIVERECT_DISPATCH_AVAILABILITY_SUCCESS,
	GET_DELIVERECT_DISPATCH_AVAILABILITY_FAIL
} from './actionTypes'

const initialState = {
	menu: null,
	loadingMenu: false,
	menuError: null,
	menuNotFound: false,
	product: null,
	loadingProduct: false,
	productError: null,
	productNotFound: false,
	dispatch: null,
	loadingDispatch: false,
	dispatchError: null
}

const menu = (state = initialState, action) => {
	switch (action.type) {
		case GET_MENU_BY_SLUG:
			return {
				...state,
				loadingMenu: true,
			}
		case GET_MENU_SUCCESS:
			return {
				...state,
				loadingMenu: false,
				menu: action.payload,
			}

		case GET_MENU_FAIL:
			return {
				...state,
				loadingMenu: false,
				menuError: action.payload,
			}
		case GET_PRODUCT:
			return {
				...state,
				loadingProduct: true,
			}
		case GET_PRODUCT_SUCCESS:
			return {
				...state,
				loadingProduct: false,
				product: action.payload,
			}

		case GET_PRODUCT_FAIL:
			return {
				...state,
				loadingProduct: false,
				productError: action.payload,
			}
		case GET_DELIVERECT_DISPATCH_AVAILABILITY:
			return {
				...state,
				loadingDispatch: true,
			}
		case GET_DELIVERECT_DISPATCH_AVAILABILITY_SUCCESS:
			return {
				...state,
				loadingDispatch: false,
				dispatch: action.payload,
			}

		case GET_DELIVERECT_DISPATCH_AVAILABILITY_FAIL:
			return {
				...state,
				loadingDispatch: false,
				dispatchError: action.payload,
			}
		default:
			return state
	}
}
export default menu
