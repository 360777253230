// import PropTypes from 'prop-types'
import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { connect } from 'react-redux'
import {
	getUser,
	registerUser,
	socialLoginGoogle,
	socialRegisterGoogle,
} from '../../store/actions'
import { Footer, Header } from '../../components'
import RegisterCardCC from '../../components/RegisterCardCC'
import { Elements } from '@stripe/react-stripe-js'

const RegisterCC = (props) => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	return (
		<>
			<Header label="Sign Up" showBackButton={false} />
			<div className='h-full w-full flex'>
				<div className='w-full min-h-[900px] flex flex-col items-center justify-center bg-gradient-to-tr to-yellow-400 from-yellow-600 lg:w-1/2'>
					<div className='h-full w-full'>
					<Elements stripe={stripePromise}>
						<RegisterCardCC />
					</Elements>
					</div>
				</div>
				<div className='h-full w-full hidden lg:overflow-hidden lg:w-1/2 lg:flex'>
					<img
						className='overflow-hidden object-cover h-full w-full'
						src='https://i.imgur.com/31JfaBe.jpg'
						alt='DeliverZero Reusable Containers'
					/>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = ({ User, Account, Path, Login }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	registrationLoading: Account.loading,
	registrationAttemptStatus: Account.registrationAttemptStatus,
	userLoading: User.loading,
	registrationError: Account.registrationError,
	path: Path.path,
	loginLoading: Login.loading,
	loginError: Login.error,
	loginAttemptStatus: Login.loginAttemptStatus,
})

const mapDispatchToProps = (dispatch) => ({
	onRegisterUser: (user) => dispatch(registerUser(user)),
	onGoogleLogin: (res) => dispatch(socialLoginGoogle(res)),
	onGoogleRegister: (payload) => dispatch(socialRegisterGoogle(payload)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCC)
