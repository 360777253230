import {
	GET_USER_ADDRESS_LOCATION,
	GET_USER_ADDRESS_LOCATION_SUCCESS,
	GET_USER_ADDRESS_LOCATION_FAIL,
	GET_GPS_LOCATION,
	GET_GPS_LOCATION_SUCCESS,
	GET_GPS_LOCATION_FAIL,
	CAPTURE_GPS,
	CAPTURE_GPS_FAIL,
	CAPTURE_GPS_SUCCESS,
} from './actionTypes'

const initialState = {
	userLocation: null,
	userLocationError: null,
	loadingLocation: false,
	gpsLocationFound: false,
	loading: false,
	gps: null,
	error: null,
}

const locations = (state = initialState, action) => {
	switch (action.type) {
		case GET_USER_ADDRESS_LOCATION:
			return {
				...state,
				loading: true,
			}
		case GET_USER_ADDRESS_LOCATION_SUCCESS:
			// console.log('action.payload', action.payload)
			return {
				...state,
				loading: false,
				userLocation: action.payload,
			}
		case GET_USER_ADDRESS_LOCATION_FAIL:
			return {
				...state,
				loading: false,
				userLocationError: action.payload,
			}
		case GET_GPS_LOCATION:
			return {
				...state,
				loading: true,
				userLocation: null,
			}
		case GET_GPS_LOCATION_SUCCESS:
			// console.log(action.payload)
			return {
				...state,
				loading: false,
				userLocation: action.payload,
				gpsLocationFound: true,
			}
		case GET_GPS_LOCATION_FAIL:
			return {
				...state,
				loading: false,
				userLocationError: action.payload,
			}
		case CAPTURE_GPS:
			return {
				...state,
				loading: true,
			}
		case CAPTURE_GPS_SUCCESS:
			const gps = action.payload
			return {
				...state,
				loading: false,
				gps: gps,
			}
		case CAPTURE_GPS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export default locations
