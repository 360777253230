import React from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'

function Privacy() {
	return (
		<>
			<Header label={"Privacy Policy"} showBackButton={true}/>
			<div className='flex flex-col justify-center'>
				<div className='w-[90%] max-w-[950px] px-5 mx-auto pt-10 pb-10 overflow-hidden'>
					<div>
						<p className='mb-3'>
							This Privacy Policy describes our practices in
							connection with privacy and also describes the
							Personal Information we collect through our
							websites, including DeliverZero.com and related
							webpages (collectively, the "Sites"). As used in
							this Privacy Policy, "DeliverZero," "we," "us," and
							"our" means DeliverZero, Inc. and its subsidiaries
							and affiliates, including DeliverZero, Inc.
						</p>
						<p className='mb-3'>
							By accessing and/or using the Sites, you agree to
							all the terms and conditions of this Privacy Policy
							and our Terms of Use. I f you do not agree to all
							the terms and conditions of this Privacy Policy, do
							not use the Sites.
						</p>
						<ol>
							<li>
								<strong>
									{' '}
									What Types of Information Do We and Our
									Third Party Service Providers Collect from
									You and in What Circumstances Do We Collect
									It?
								</strong>
							</li>
						</ol>
						<p className='mb-3'>
							We, and our third party service providers who
							perform services on our behalf, collect information
							in a variety of circumstances when you visit and use
							our Sites.
						</p>
						<p className='mb-3'>
							For example, we may collect information:
						</p>
						<ul className='mb-3'>
							<li>
								- when you register for our service, sign up for
								our mailing list, enter a contest or sweepstakes
								or communicate with us;
							</li>
							<li>
								- when you use and interact with our Sites and
								service, such as when you place an order, post a
								review, or interact with other users or with
								restaurants on the Sites;
							</li>
							<li>- when you make a payment for services;</li>
							<li>
								- if you log in through a social network or
								authentication service, such as Facebook or
								Google, these services will authenticate your
								identity and may provide you the option to share
								Personal Information (such as your name, email
								address, address book and contacts, or other
								information) with us; and
							</li>
							<li>
								- if you give permission for the Sites to access
								your address book or camera roll on your device,
								for example, to send an invitation to a friend
								or to upload a photo to the Sites.
							</li>
						</ul>
						<p className='mb-3'>
							The information we collect includes Personal
							Information that can uniquely identify you (whether
							alone or in combination with other data or
							information), such as your name, postal address,
							telephone number, email address, date of birth, or
							similar data. Personal Information also includes
							certain sensitive information related to your
							finances or, for business accounts, related to the
							finances of your employer, such as a credit card
							number or other payment account number (including
							the three [3] or four [4] digit validation code for
							your credit card). In order for you to take
							advantage of the services provided through the
							Sites, we may require that you furnish Personal
							Information or, for certain business accounts, that
							your employer furnish Personal Information for you.
							We may also collect non-personal information from
							you, including information about your use of our
							Sites, but when such non-personal information is
							linked to your Personal Information, we will treat
							it as Personal Information. We may also receive
							information about you from third party sources, such
							as other users of our Sites, drivers, merchants, and
							other partners.
						</p>
						<p className='mb-3'>
							Information collected automatically when you visit
							or use our Sites. We and our third party service
							providers may collect certain types of usage
							information when you visit our Sites, read our
							emails, or otherwise engage with us. We may use this
							information to enhance and personalize your user
							experience, to monitor and improve our websites and
							services, and for other similar purposes. In some
							instances, we and our third party service providers
							may combine this usage information with Personal
							Information. If we do combine any usage information
							with Personal Information, the combined information
							will be treated by us as Personal Information
							hereunder (as long as it is so combined).
						</p>
						<p className='mb-3'>
							We and our third party partners use tracking
							technologies, including cookies, web beacons,
							embedded scripts, location-identifying technologies,
							file information, and similar technology, to
							automatically collect usage and device information,
							such as:
						</p>
						<ul className='mb-3'>
							<li>
								- Information about your device and its
								software, including your IP address, browser
								type, Internet service provider, device
								type/model/manufacturer, operating system, date
								and time stamp, and a unique ID that allows us
								to identify your browser, mobile device, or your
								account (including, for example, a persistent
								device identifier or an Ad ID), and other
								similar information. We may also work with third
								party partners to employ technologies, including
								the application of statistical modeling tools,
								that permit us to recognize and contact you
								across multiple devices.
							</li>
							<li>
								- Information about the way you access and use
								our Sites, for example, the site from which you
								came and the site to which you are going when
								you leave our Sites, the pages you visit, the
								links you click, whether you open emails or
								click the links contained in emails, whether you
								access the Sites from multiple devices, and
								other actions you take on the Sites.
							</li>
							<li>
								- Analytics information. We may collect
								analytics data or use third party analytics
								tools such as Google Analytics to help us
								measure traffic and usage trends for the Sites
								and to understand more about the demographics of
								our users. You can learn more about Google's
								practices at{' '}
								<a href='http://www.google.com/policies/privacy/partners'>
									http://www.google.com/policies/privacy/partners
								</a>
								.
							</li>
						</ul>
						<p className='mb-3'>
							If you would prefer not to accept cookies, most
							browsers will allow you to: (i) change your browser
							settings to notify you when you receive a cookie,
							which lets you choose whether or not to accept it;
							(ii) disable existing cookies; or (iii) set your
							browser to automatically reject cookies; however,
							doing so may negatively impact your experience using
							the Sites, as some features and services on our
							Sites may not work properly. You may also set your
							email options to prevent the automatic downloading
							of images that may contain technologies that would
							allow us to know whether you have accessed our email
							and performed certain functions with it. See Section
							4 below to learn more about our online advertising
							practices.
						</p>
						<p className='mb-3'>
							How We Respond to Do Not Track Signals. Although we
							do our best to honor the privacy preferences of our
							users, we are currently unable to respond to Do Not
							Track signals set by your browser.
						</p>
						<p className='mb-3'>
							Location Information. With your consent, we may
							obtain information about your physical location
							through geolocation features on your device,
							including GPS (e.g. latitude and/or longitude). We
							may use this information to improve order efficiency
							and enhance your user experience. We may also infer
							your location based on other information we collect.
							For example, your IP Address indicates the general
							geographic region from which you are connecting to
							the Internet.
						</p>
						<ol start='2'>
							<li>
								<strong>
									{' '}
									How Do We and Our Third Party Service
									Providers Use Information Collected from
									You?
								</strong>
							</li>
						</ol>
						<p className='mb-3'>
							We and our third party service providers may use
							Personal Information:
						</p>
						<ul className='mb-3'>
							<li>
								- to process your registration on our Sites,
								including verifying your information to ensure
								its accuracy and validity.
							</li>
							<li>
								- to fulfill your requests (e.g., to allow you
								to order food through our restaurant partners
								and have it delivered to you, or to provide you
								with information that you request).
							</li>
							<li>
								- to fulfill your purchases (e.g., to process
								credit card payments in connection with your
								food order).
							</li>
							<li>
								- to customize content on our Sites (e.g., the
								restaurants that will deliver to your location).
							</li>
							<li>
								- to send you important information regarding
								the Sites, such as certain changes to our terms,
								conditions, policies, and/or other
								administrative information. Because this
								information may be material to your use of the
								Sites, you may not opt out of receiving such
								communications.
							</li>
							<li>
								- to inform you of products, programs, services,
								and promotions that we believe may be of
								interest to you, including, without limitation,
								adding you to our mailing lists and sending you
								emails or push-notifications from time to time,
								and permitting you to participate in
								sweepstakes, contests, and similar promotions
								(collectively, "Promotions").
							</li>
							<li>
								- to evaluate your eligibility for certain types
								of Promotions, offers, products, or services
								that may be of interest to you.
							</li>
							<li>
								- to permit you to refer us to your friends so
								that we may send them a promotional offer
								through the Sites. If you wish to use this
								feature, you may be required to provide us with,
								and we may use, your friend's name, email
								address, and other information you share with
								us. By using this functionality, you affirm that
								you are entitled to use and provide us with your
								friend's name and email address for this
								purpose. We will not use your friend's Personal
								Information for any purpose other than to allow
								a promotional offer to be sent and/or to
								troubleshoot any issues with respect to such
								promotional offer, unless we disclose such other
								purpose to you at the time you provide your
								friend's Personal Information to us.
							</li>
							<li>
								- to perform analytics and conduct customer
								research.
							</li>
							<li>
								- for our internal business purposes, such as
								data analysis, audits, fraud prevention,
								developing new products and/or features,
								enhancing the Sites, improving our services,
								identifying usage trends, and determining the
								effectiveness of our promotional campaigns.
							</li>
							<li>
								- to enforce our corporate reporting obligations
								and our Terms of Use, or to comply with
								applicable laws.
							</li>
							<li>
								- for purposes disclosed at the time you provide
								your Personal Information or as otherwise set
								forth in this Privacy Policy.
							</li>
						</ul>
						<p className='mb-3'>
							We and our third party service providers may also
							use aggregate Personal Information in a manner such
							that the end-product does not uniquely identify you
							or any other user of the Sites. For example, we may
							use Personal Information to calculate the percentage
							of our users who have a particular telephone area
							code.
						</p>
						<ol start='3'>
							<li>
								<strong>
									{' '}
									How Do We and Our Third Party Service
									Providers Share Your Personal Information
									with Others?
								</strong>
							</li>
						</ol>
						<p className='mb-3'>
							We and our third party service providers may
							disclose Personal Information:
						</p>
						<ul className='mb-3'>
							<li>
								- to our third party service providers that
								provide services on our behalf, such as payment
								processing, website hosting, data analysis,
								infrastructure provisioning, IT services,
								customer service, email delivery services,
								targeted advertising and marketing, and other
								similar services.
							</li>
							<li>
								- to third parties such as attorneys, collection
								agencies, tribunals or law enforcement
								authorities pursuant to valid requests in
								connection with alleged violations of our Terms
								of Use or other alleged contract violations,
								actual or alleged infringement, actual or
								suspected harm to persons or property, or
								alleged violations of laws, rules, or
								regulations.
							</li>
							<li>
								- to third parties as needed to detect, prevent,
								or otherwise address actual or suspected fraud,
								harassment, security or technical issues,
								violations of any law, rule, regulation or the
								policies of the Sites, and to verify your
								purchase to holder(s) of any credit card(s) or
								other payment account(s) used to place order(s)
								using your account or your information.
							</li>
							<li>
								- as required to fulfill your order (e.g., to
								our restaurant partners to enable them to
								fulfill your order).
							</li>
							<li>
								- to restaurants and brands from which you have
								placed orders through the Sites as well as their
								affiliates and third party service providers.
								These restaurants, brands, and their affiliates,
								may use your information as permitted by their
								own privacy policies, which could include
								sending you marketing communications and other
								promotional content.
							</li>
							<li>
								- to identify you to any person with whom you
								interact through the Sites.
							</li>
							<li>
								- to third party sponsors of Promotions
								(irrespective of whether such Promotions are
								hosted by us), or otherwise in accordance with
								the rules applicable to such Promotions. You
								should carefully review the rules, if any, of
								each Promotion in which you participate, as they
								may contain additional important information
								about our, a sponsor's, and/or other third
								parties' use of your Personal Information. To
								the extent that the rules of those Promotions
								concerning the treatment of your Personal
								Information conflict with this Privacy Policy,
								the rules of those Promotions shall control.
							</li>
							<li>
								- to third party partners in connection with
								integration, co-marketing and certain other
								coordinated efforts.
							</li>
							<li>
								- to an affiliate or other third party in
								connection with a reorganization, merger, sale,
								joint venture, assignment, transfer, or other
								disposition of all or any portion of our
								business, assets, or stock (including without
								limitation in connection with any bankruptcy
								and/or similar proceedings).
							</li>
							<li>
								- as we believe to be appropriate: (a) under
								applicable law, including laws outside your
								country of residence; (b) to comply with legal
								process; (c) to respond to requests from public
								and government authorities, including public and
								government authorities outside your country of
								residence; (d) to enforce our terms and
								conditions; (e) to protect our operations and/or
								those of any of our affiliates; (f) to protect
								our rights, privacy, safety, and/or property,
								and/or that of our affiliates, you, and/or
								others; (g) to permit us to pursue available
								remedies and/or limit the damages that we may
								sustain; and (h) for recordkeeping purposes.
							</li>
						</ul>
						<p className='mb-3'>
							We may also share Personal Information which has
							been aggregated or de-identified in a manner such
							that the end-product does not uniquely identify you
							or any other user of the Sites.
						</p>
						<ol start='4'>
							<li>
								<strong>
									{' '}
									Third Party Tracking and Online Advertising.
								</strong>
							</li>
						</ol>
						<p className='mb-3'>
							We participate in interest-based advertising and use
							third party advertising companies to serve you
							targeted advertisements based on your online
							browsing history and your interests. We permit third
							party online advertising networks, social media
							companies, and other third party services to collect
							information about your use of our Sites over time so
							that they may play or display ads on our Sites, on
							other websites, apps, or services you may use, and
							on other devices you may use. Typically, though not
							always, the information used for interest-based
							advertising is collected through cookies or similar
							tracking technologies. We may share a common account
							identifier (such as an email address or user ID) or
							hashed data with our third party advertising
							partners to help identify you across devices. We and
							our third party partners use this information to
							make the advertisements you see online more relevant
							to your interests, as well as to provide
							advertising-related services such as reporting,
							attribution, analytics, and market research.
						</p>
						<p className='mb-3'>
							To learn more about interest-based advertising and
							how you may be able to opt-out of some of this
							advertising, you may wish to visit the Network
							Advertising Initiative's ("NAI") online resources,
							available at{' '}
							<a href='http://www.networkadvertising.org/choices'>
								http://www.networkadvertising.org/choices
							</a>
							, and/or the Digital Advertising Alliance's ("DAA")
							resources, available at{' '}
							<a href='http://www.aboutads.info/choices'>
								http://www.aboutads.info/choices
							</a>
							. You may also be able to set your browser to delete
							or notify you of cookies by actively managing the
							settings on your browser or mobile device. Please
							note, however, that some advertising opt-outs may
							not be effective unless your browser is set to
							accept cookies. Furthermore, if you use a different
							device, change browsers, or delete the opt-out
							cookies, you may need to perform the opt-out task
							again.
						</p>
						<p className='mb-3'>
							You may also be able to limit certain interest-based
							mobile advertising through the settings on your
							mobile device by selecting "limit ad tracking" (iOS)
							or "opt out of interest-based ads" (Android).
						</p>
						<p className='mb-3'>
							Google Analytics and Advertising. We may also
							utilize certain forms of display advertising and
							other advanced features through Google Analytics,
							such as Remarketing with Google Analytics, Google
							Display Network Impression Reporting, and Google
							Analytics Demographics and Interest Reporting. These
							features enable us to use first-party cookies (such
							as the Google Analytics cookie) and third party
							cookies (such as the DoubleClick advertising cookie)
							or other third party cookies together to inform,
							optimize, and display ads based on your past visits
							to the Sites. You may control your advertising
							preferences or opt out of certain Google advertising
							products by visiting the Google Ads Preferences
							Manager, currently available at{' '}
							<a href='https://google.com/ads/preferences%2520'>
								https://google.com/ads/preferences
							</a>
							, or by vising NAI's online resources at{' '}
							<a href='http://www.networkadvertising.org/choices'>
								http://www.networkadvertising.org/choices
							</a>
							.
						</p>
						<ol start='5'>
							<li>
								<strong>
									{' '}
									Profiles and Interactive Areas.
								</strong>
							</li>
						</ol>
						<p className='mb-3'>
							We and our third party service providers may make
							available through the Sites certain features and
							services (for example, ratings and reviews, photo
							uploads, and chat functionality) to which you may be
							able to post information and materials. Please note
							that any information you provide in connection with
							such services may become public, and may be
							available to visitors to the Sites and to the
							general public, including without limitation the
							ratings, reviews, and photographs that accompany
							many restaurant listings. We urge you to exercise
							discretion and caution when deciding to disclose
							your Personal Information, and/or any other
							information and/or materials, on the Sites. NEITHER
							WE, NOR ANY OF OUR THIRD PARTY SERVICE PROVIDERS,
							NOR ANY OTHER PERSON OR ENTITY DULY AUTHORIZED BY US
							AND/OR BY OUR THIRD PARTY SERVICE PROVIDERS, IS OR
							SHALL BE RESPONSIBLE FOR ANY USE BY ANY UNAUTHORIZED
							PERSON OR ENTITY OF ANY PERSONAL INFORMATION YOU
							DISCLOSE IN THE MANNER DESCRIBED IN THIS SECTION 5
							THROUGH THE SITES.
						</p>
						<ol start='6'>
							<li>
								<strong> Third Party Sites.</strong>
							</li>
						</ol>
						<p className='mb-3'>
							Except as expressly set forth herein, this Privacy
							Policy does not address, and neither we nor our
							third party service providers is responsible for,
							the privacy, information, or other practices of
							other websites and/or any third parties, including
							without limitation any of our affiliates and/or any
							third party operating any site to which the Sites
							contain a link. For clarity, the inclusion of a link
							to a third party website on the Sites does not imply
							endorsement of the linked site by us or by our
							affiliates. Please note that your access and/or use
							of any third party websites, including your
							provision of any information, materials and/or other
							content to such sites, is entirely at your own risk.
							We encourage you to read the policies and terms of
							every website you visit.
						</p>
						<ol start='7'>
							<li>
								<strong> Security.</strong>
							</li>
						</ol>
						<p className='mb-3'>
							We and our third party service providers use
							reasonable organizational, technical, and
							administrative measures designed to protect Personal
							Information under our control. However, no data
							transmission over the Internet or data storage
							system can be guaranteed to be 100% secure. If you
							have reason to believe that your interaction with us
							is not secure (for example, if you feel that the
							security of any account you might have with us has
							been compromised), you must immediately notify us of
							the problem by contacting us in accordance with the
							"Contacting Us" section below.
						</p>
						<ol start='8'>
							<li>
								<strong> Unsubscribe.</strong>
							</li>
						</ol>
						<p className='mb-3'>
							If you do not wish to receive certain
							marketing-related emails from us, you may
							unsubscribe from receiving them by clicking on the
							"unsubscribe" link at the bottom of any of those
							emails. You may also unsubscribe from receiving
							certain order-related text (SMS) or multimedia (MMS)
							messages by replying "STOP" to the number sending
							the message. Please see our Terms of Use, for more
							information on communications from or on behalf of
							Grubhub.
						</p>
						<ol start='9'>
							<li>
								<strong>
									{' '}
									Control Over Your Personal Information.
								</strong>
							</li>
						</ol>
						<p className='mb-3'>
							You may review, correct, and/or update certain
							elements of your Personal Information by adjusting
							your preferences in the "Your account" section of
							the Sites. However, neither we nor our third party
							service providers are responsible for altering
							Personal Information from the databases and/or other
							records of third parties with whom we and our third
							party service providers have previously shared your
							Personal Information. If you are a California
							resident under the age of 18 and want to remove your
							Personal Information from our publicly-displayed
							content, you can contact us directly at
							hello@deliverzero.com. We may not be able to modify
							or delete your information in all circumstances.
						</p>
						<ol start='10'>
							<li>
								<strong> Data Retention.</strong>
							</li>
						</ol>
						<p className='mb-3'>
							We and our third party service providers will retain
							Personal Information for at least the period
							reasonably necessary to fulfill the purposes
							outlined in this Privacy Policy, unless a longer
							retention period is required or permitted by law. If
							we delete some or all of your Personal Information,
							we may continue to retain and use aggregate or
							anonymous data previously collected and/or aggregate
							or anonymize your Personal Information.
						</p>
						<ol start='11'>
							<li>
								<strong>
									{' '}
									Note Regarding the Use of the Sites by
									Children.
								</strong>
							</li>
						</ol>
						<p className='mb-3'>
							Under no circumstances are the Sites directed to
							and/or intended for use by individuals under the age
							of thirteen (13), and we do not knowingly collect
							Personal Information from children under age 13. If
							you believe that we might have any information from
							a child under 13, please contact us at
							hello@deliverzero.com so that we may delete it as
							soon as possible.
						</p>
						<ol start='12'>
							<li>
								<strong> Disclaimer.</strong>
							</li>
						</ol>
						<p className='mb-3'>
							If you choose to access the Sites, you do so at your
							own risk and are responsible for complying with all
							applicable laws, rules, and regulations. We may
							limit the availability of the Sites, in whole or in
							part, to any person, geographic area, and/or
							jurisdiction we choose, at any time and in our sole
							discretion. By using the Sites and submitting any
							Personal Information, you consent to the transfer of
							Personal Information to other countries, which may
							provide a different level of data security than your
							country of residence provides.
						</p>
						<ol start='13'>
							<li>
								<strong> [Omitted]</strong>
							</li>
							<li>
								<strong> Changes to this Policy.</strong>
							</li>
						</ol>
						<p className='mb-3'>
							We may change this Privacy Policy from time to time
							and without prior notice, except that if we make a
							material change, we will notify you prior to the
							effective date of the change. Changes to this
							Privacy Policy will be effective as soon as they are
							posted, unless a later effective date is provided.
							By continuing to access and/or use the Sites after
							the effective date of a revised Privacy Policy, you
							agree to be bound by the revised Privacy Policy. For
							clarity, if you do not agree to the new terms of the
							Privacy Policy, do not continue using the Sites. The
							most current version of this policy will always be
							posted on the Sites.
						</p>
						<ol start='15'>
							<li>
								<strong> Contacting Us.</strong>
							</li>
						</ol>
						<p className='mb-3'>
							If you have any questions regarding this Privacy
							Policy, please contact us by email at
							hello@deliverzero.com.
						</p>
						<p className='mb-3'>
							Please note that email communications will not
							necessarily be secure; accordingly, please do not
							include credit card information and/or other
							sensitive Personal Information in your email
							correspondence with us.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default Privacy
