import {
	GET_PLATFORMS,
	GET_PLATFORMS_FAIL,
	GET_PLATFORMS_SUCCESS,
	GET_PLATFORM_BY_KEY,
	GET_PLATFORM_BY_KEY_FAIL,
	GET_PLATFORM_BY_KEY_SUCCESS,
	RESET_PLATFORM,
} from './actionTypes'

export const getPlatforms = () => ({
	type: GET_PLATFORMS,
})

export const getPlatformsSuccess = (platforms) => ({
	type: GET_PLATFORMS_SUCCESS,
	payload: platforms,
})

export const getPlatformsFail = (error) => ({
	type: GET_PLATFORMS_FAIL,
	payload: error,
})

export const getPlatformByKey = (payload) => ({
	type: GET_PLATFORM_BY_KEY,
	payload: payload,
})

export const getPlatformByKeySuccess = (platform) => ({
	type: GET_PLATFORM_BY_KEY_SUCCESS,
	payload: platform,
})

export const getPlatformByKeyFail = (error) => ({
	type: GET_PLATFORM_BY_KEY_FAIL,
	payload: error,
})

export const resetPlatform = () => ({
	type: RESET_PLATFORM,
})
