import {
	CREATE_SUBSCRIPTION,
	CREATE_SUBSCRIPTION_SUCCESS,
	CREATE_SUBSCRIPTION_FAIL,
	CONFIRM_SUBSCRIPTION,
	CONFIRM_SUBSCRIPTION_SUCCESS,
	CONFIRM_SUBSCRIPTION_FAIL,
} from './actionTypes'

const initialState = {
	createdSubscription: null,
	creating: false,
	error: true,
	confirming: false,
	confirmed: false,
}

const subscriptions = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_SUBSCRIPTION: {
			return {
				...state,
				creating: true,
			}
		}
		case CREATE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				creating: false,
				createdSubscription: action.payload,
			}

		case CREATE_SUBSCRIPTION_FAIL:
			return {
				...state,
				creating: false,
				error: action.payload,
			}

		case CONFIRM_SUBSCRIPTION: {
			return {
				...state,
				confirming: true,
			}
		}
		case CONFIRM_SUBSCRIPTION_SUCCESS:
			// console.log(action)
			return {
				...state,
				confirming: false,
				confirmed: action.payload,
			}

		case CONFIRM_SUBSCRIPTION_FAIL:
			return {
				...state,
				confirming: false,
				error: action.payload,
			}

		default:
			return state
	}
}

export default subscriptions
