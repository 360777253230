import React, { useEffect, useState } from "react";
import { Header, Footer } from "../../components";
import { connect } from "react-redux";
import {
    Button,
    CheckboxGroup,
    Input,
    RadioGroup,
    TextArea,
} from "../../components/Elements";
import { BounceLoader } from "react-spinners";
import {
    getRlPartner,
    getRlPartnersByPolygon,
    createPendingPickup,
    createTransaction,
    resetTransaction,
    getUser,
    getAppInfo,
    chargePaymentMethod,
    resetCardResponse,
    resetSuccess,
    getUberDirectQuote,
    createUberDirectRequest,
    validatePromo,
    updatePromo,
    resetPromo,
    getPickupsByPhone,
    alertSlack,
} from "../../store/actions";
import {
    dayNames,
    getNextTwoDatesOfDay,
    monthNames,
    sortDateArray,
} from "../../helpers/dates";
import ReactConfetti from "react-confetti";
import { MdOutlineCelebration } from "react-icons/md";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CreditCardForm from "../../components/Stripe/CreditCardForm";
import { Navigate, useNavigate } from "react-router";
import PendingPickupsPopUp from "../../components/Returns/PendingPickupsPopUp";

const ReturnAtDoor = (props) => {
    const {
        user,
        partners,
        loadingPartners,
        onGetRlPartnersByPolygon,
        onCreateTransaction,
        transaction,
        creatingTransaction,
        onResetTransaction,
        onChargeCustomer,
        chargingCard,
        chargingCardResponse,
        chargingCardError,
        onGetAppInfo,
        appInfo,
        paymentMethods,
        onGetUser,
        onResetCardResponse,
        onCreatePendingPickup,
        pendingPickup,
        pendingPickups,
        pickupSuccess,
        pickupLoading,
        pickupError,
        onGetPendingPickupsByPhone,
        loading,
        uberRequest,
        uberQuote,
        error,
        requestError,
        onGetUberDirectQuote,
        onCreateUberDirectRequest,
        onResetSuccess,
        onValidatePromo,
        promoMessageType,
        promoIsActive,
        promo,
        onUpdatePromo,
        onResetPromo,
        updatedPromo,
        onAlertSlack,
    } = props;
    const google = window.google;
    const [streetNumber, setStreetNumber] = useState("");
    const [streetName, setStreetName] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [stateName, setStateName] = useState("");
    const [address, setAddress] = useState("");
    const [coords, setCoords] = useState([]);
    const [address2, setAddress2] = useState("");
    const [useDefaultAddress, setUseDefaultAddress] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const bounceLoaderColor = "#507f74";
    const [geoCodedAddress, setGeoCodedAddress] = useState(false);
    const [availableDates, setAvailableDates] = useState([]);
    const [availableTimesPartners, setAvailableTimesPartners] = useState({});
    const [availableOndemand, setAvailableOndemand] = useState([]);
    const [ondemandPickupSelected, setOndemandPickupSelected] = useState(false);
    const [availableDatesObj, setAvailableDatesObj] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [qty, setQty] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedReturnDate, setSelectedReturnDate] = useState(null);
    const [selectedReturnPartner, setSelectedReturnPartner] = useState({});
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [pickupNote, setPickupNote] = useState("");
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
    const [cardRadios, setCardRadios] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
    const [timeoutExpired, setTimeoutExpired] = useState(false);
    const [tipAmount, setTipAmount] = useState(0);
    const [uberdirectTotal, setUberdirectTotal] = useState(0);
    const [providedCode, setProvidedCode] = useState("");
    const [promoMessage, setPromoMessage] = useState("");
    const [invalidAddress, setInvalidAddress] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [filteredPickups, setFilteredPickups] = useState([]);
    const [pendingDates, setPendingDates] = useState([]);
    const [isMember, setIsMember] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        onGetAppInfo();
        onResetTransaction();
        onResetSuccess();
        onResetCardResponse();
    }, []);

    useEffect(() => {
        onGetUser();
    }, [JSON.stringify(paymentMethods)]);

    useEffect(() => {
        setShowSearchBar(true);
    }, [window.google?.maps]);

    useEffect(() => {
        setSelectedReturnPartner({});
        // setSelectedPaymentMethod('')
        if (!geoCodedAddress) {
            geocodeAddress();
        }
    }, [address]);

    useEffect(() => {
        if (user && user.phone) {
            onGetPendingPickupsByPhone({ phone: user.phone });
        }
        if (
            user?.dzMemberSubscription?.enabled &&
            user.dzMemberSubscription?.tierSlug === "tierTwo"
        ) {
            setIsMember(true);
        }
    }, [user]);

    useEffect(() => {
        if (pendingPickups) {
            const currentDate = new Date();
            const filteredPickups = pendingPickups.filter(
                (pickup) => new Date(pickup.timestamp) > currentDate
            );
            setFilteredPickups(filteredPickups);
            const dates = [];
            for (const pickup of filteredPickups) {
                dates.push(pickup.timestamp.toString().slice(0, 10));
            }
            setPendingDates(dates);
        }
    }, [pendingPickups]);

    const handleOpenPopUp = () => {
        setOpenPopUp(!openPopUp);
    };

    useEffect(() => {
        setSelectedReturnPartner({});
        // setSelectedPaymentMethod('')
        if (google && google.maps && !geoCodedAddress) {
            geocodeAddress();
        }
    }, [google, google?.maps]);

    useEffect(() => {
        if (geoCodedAddress) {
            validateAddress();
        }
    }, [address, geoCodedAddress, addressError]);

    const validateAddress = () => {
        setErrorMessage("");
        if (
            !streetName ||
            streetName === "" ||
            !streetNumber ||
            streetNumber === ""
        ) {
            setInvalidAddress(true);
            setErrorMessage("Please enter a valid address");
        } else {
            setInvalidAddress(false);
            setErrorMessage("");
            if (address && address !== geoCodedAddress) {
                geocodeAddress();
            }
        }
    };

    const reverseGeocodeAddress = async (coords) => {
        setAddressError(false);
        let geocoder = new google.maps.Geocoder();
        await geocoder.geocode(
            { location: coords },
            function (results, status) {
                if (
                    status === google.maps.GeocoderStatus.OK &&
                    results.length > 0
                ) {
                    setGeoCodedAddress(true);
                    setAddress(results[0].formatted_address);
                } else {
                    setAddressError(true);
                    alert("Invalid address");
                }
            }
        );
    };

    useEffect(() => {
        const lastSearchedAddress = JSON.parse(
            localStorage.getItem("lastSearchedAddress")
        );
        if (lastSearchedAddress && lastSearchedAddress.address) {
            setGeoCodedAddress(false);
            setAddress(lastSearchedAddress.address);
            setTimeoutExpired(true);
        } else if (user && user.lastSearchedAddress) {
            setGeoCodedAddress(false);
            setAddress(user.lastSearchedAddress);
            setTimeoutExpired(true);
        } else if (!address) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const crd = position.coords;
                    reverseGeocodeAddress({
                        lat: parseFloat(crd.latitude),
                        lng: parseFloat(crd.longitude),
                    });
                    setTimeoutExpired(true);
                },
                (e) => {
                    if (user && user.address) {
                        setAddress(user.address);
                    }
                    timeoutId = setTimeout(() => {
                        setTimeoutExpired(true);
                    }, 5000);
                },
                { enableHighAccuracy: true, timeout: 5000 }
            );
        }
    }, [user, user?.lastSearchedAddress, navigator, JSON.stringify(navigator)]);

    const geocodeAddress = async () => {
        // console.log('IN GEOCODER', address)
        // Geocode the address
        setAddressError(false);
        let geocoder = new google.maps.Geocoder();
        await geocoder.geocode(
            {
                address: address,
            },
            function (results, status) {
                if (
                    status === google.maps.GeocoderStatus.OK &&
                    results.length > 0
                ) {
                    // set it to the correct, formatted address if it's valid
                    setGeoCodedAddress(true);
                    setAddress(results[0].formatted_address);
                    setStreetName("");
                    setStreetNumber("");
                    results[0].address_components.forEach((element) => {
                        if (element.types.includes("postal_code")) {
                            setZipCode(element.short_name);
                        } else if (element.types.includes("route")) {
                            setStreetName(element.short_name);
                        } else if (element.types.includes("street_number")) {
                            setStreetNumber(element.short_name);
                        } else if (
                            element.types.includes("sublocality") ||
                            element.types.includes("locality")
                        ) {
                            setCity(element.short_name);
                        } else if (
                            element.types.includes(
                                "administrative_area_level_1"
                            )
                        ) {
                            setStateName(element.short_name);
                        }
                    });
                    setCoords([
                        results[0].geometry.location.lat(),
                        results[0].geometry.location.lng(),
                    ]);
                } else {
                    setAddressError(true);
                    // // show an error if it's not
                    // alert("Invalid address");
                }
            }
        );
    };

    const handleAddressSubmit = async (e) => {
        e.preventDefault();
        // console.log(address)
        // Geocode the address
        geocodeAddress();
    };

    const handleAddressChange = (newAddress) => {
        setStreetName("");
        setStreetNumber("");
        if (newAddress.target) {
            setAddress(newAddress.target.value);
            setPromoMessage("");
            onResetPromo();
            onResetSuccess();
        } else if (newAddress.formatted_address) {
            newAddress.address_components.forEach((element) => {
                if (element.types.includes("postal_code")) {
                    setZipCode(element.short_name);
                } else if (element.types.includes("route")) {
                    setStreetName(element.short_name);
                } else if (element.types.includes("street_number")) {
                    setStreetNumber(element.short_name);
                } else if (
                    element.types.includes("sublocality") ||
                    element.types.includes("locality")
                ) {
                    setCity(element.short_name);
                } else if (
                    element.types.includes("administrative_area_level_1")
                ) {
                    setStateName(element.short_name);
                }
            });
            setAddress(newAddress.formatted_address);
            setCoords([
                newAddress.geometry.location.lat(),
                newAddress.geometry.location.lng(),
            ]);
            setPromoMessage("");
            onResetPromo();
            onResetSuccess();
        }
    };

    const handleAddress2Change = (apt) => {
        if (apt.target) {
            setAddress2(apt.target.value);
        }
    };

    useEffect(() => {
        if (useDefaultAddress) {
            geocodeAddress();
        }
    }, [useDefaultAddress]);

    useEffect(() => {
        if (coords && coords.length != 0) {
            setAvailableDates([]);
            setAvailableDatesObj({});
            setAvailableTimesPartners({});
            setOndemandPickupSelected(false);
            onGetRlPartnersByPolygon(coords);
        }
    }, [coords]);

    useEffect(() => {
        let availDobj = {};
        let availD = [];
        // WILL NEED TO REMOVE
        let availOndemandPartners = [
            // {
            // 	_id: '65d6517741b4f21a2ecdc265',
            // 	partner: 'uberdirect',
            // 	availability: 'onDemand',
            // 	pickupPolygon: [
            // 		[40.42395, -111.78985],
            // 		[40.42361, -111.78903],
            // 		[40.42308, -111.78905],
            // 		[40.42286, -111.78996],
            // 		[40.42299, -111.79071],
            // 		[40.42363, -111.79064],
            // 	],
            // 	bufferDays: 1,
            // 	type: 'scheduled_pickup',
            // 	availableTimes: {
            // 		Monday: '8am - 5pm',
            // 	},
            // },
        ];
        let availTimesPartners = {};
        if (partners && partners.length > 0) {
            partners.forEach((partner) => {
                if (partner.availability === "onDemand") {
                    availOndemandPartners.push(partner);
                } else {
                    partner.availability.forEach((day) => {
                        let nextTwoDates = getNextTwoDatesOfDay(
                            day,
                            partner.bufferDays
                        );
                        // console.log(nextTwoDates)
                        availD = availD.concat(nextTwoDates);
                        nextTwoDates.forEach((date) => {
                            availDobj[date] = partner;
                        });
                    });
                    if (partner.availableTimes) {
                        availTimesPartners[partner.partner] =
                            partner.availableTimes; // maps from partner name to obj of available times
                    }
                }
            });
        }
        setAvailableDatesObj(availDobj);
        setAvailableDates([...new Set(sortDateArray(availD))]);
        setAvailableOndemand(availOndemandPartners);
        setAvailableTimesPartners(availTimesPartners);
    }, [partners]);

    const handleOnDemandClicked = () => {
        setOndemandPickupSelected(true);
        const quotePayload = {
            timestamp: new Date(),
            type: "boxesIn",
            boxCount: qty,
            user: {
                phone: user.phone,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                pickupAddress:
                    address2 != ""
                        ? "Unit: " + address2 + "; " + address
                        : address,
                street: streetNumber + " " + streetName,
                unit: address2,
                city: city,
                state: stateName,
                postal: zipCode,
                coords: coords,
                paymentMethodId: selectedPaymentMethod,
            },
            returnAtDoor: true,
            reverseLogisticsPartner: availableOndemand[0],
            pickupNote: pickupNote,
        };
        // console.log(quotePayload)
        onGetUberDirectQuote(quotePayload);
    };

    const createUberdirectPickup = () => {
        let payload = {
            ...uberQuote,
            boxCount: qty,
            pickupNote: pickupNote,
            tip: tipAmount,
        };
        if (promoMessage === "Promo code applied!" || isMember) {
            payload = {
                ...uberQuote,
                boxCount: qty,
                pickupNote: pickupNote,
                tip: 1,
            };
        }
        onCreateUberDirectRequest(payload);
    };

    const submitTransaction = () => {
        const transactionPayload = {
            timestamp: selectedReturnDate,
            type: "boxesIn",
            boxCount: qty,
            user: {
                phone: user.phone,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                pickupAddress:
                    address2 != ""
                        ? "Unit: " + address2 + "; " + address
                        : address,
                street: streetNumber + " " + streetName,
                unit: address2,
                city: city,
                state: stateName,
                postal: zipCode,
                coords: coords,
                paymentMethodId: selectedPaymentMethod,
            },
            returnAtDoor: true,
            reverseLogisticsPartner: selectedReturnPartner.partner,
            pickupNote: pickupNote,
        };
        // console.log("transactionPayload", transactionPayload)
        if (
            selectedReturnPartner.partner === "deliverzero" ||
            selectedReturnPartner.partner === "flot" ||
            selectedReturnPartner.partner === "gently" ||
            selectedReturnPartner.partner === "returnmates"
        ) {
            onCreatePendingPickup(transactionPayload);
        } else {
            onCreateTransaction(transactionPayload);
        }
    };

    useEffect(() => {
        if (uberQuote && uberQuote.quote) {
            const fee = parseFloat(JSON.parse(uberQuote.quote).fee) / 100;
            setUberdirectTotal(fee);
            setSelectedReturnDate(new Date());
            setSelectedReturnPartner(availableOndemand[0]); // since uberdirect is the only onDemand partner
        }
    }, [uberQuote]);

    useEffect(() => {
        if (uberRequest) {
            setShowSuccess(true);
            return function cleanup() {
                onResetTransaction();
                onResetSuccess();
                onResetCardResponse();
                onResetPromo();
            };
        }
    }, [uberRequest]);

    useEffect(() => {
        if (requestError && requestError !== null) {
            setErrorMessage("Could not create a request for on demand pickup");
            onAlertSlack({
                channel: "#api-errors",
                text:
                    "<@U05CHR1JENT>, Could not create a request for on demand pickup for " +
                    user.phone +
                    " " +
                    user.email +
                    " from: " +
                    address +
                    " Error: " +
                    JSON.stringify(requestError) +
                    (chargingCardError
                        ? ", Card error: " + JSON.stringify(chargingCardError)
                        : ", no charging error"),
            });
        }
    }, [requestError]);

    // for testing without charging
    // useEffect(() => {
    // 	if (uberRequest) {
    // 		if (promoIsActive) {
    // 			onUpdatePromo({ promo: promo, promoCode: providedCode, userPhone: user.phone })
    // 		}
    // 	}
    // }, [uberRequest])

    useEffect(() => {
        if ((transaction || pickupSuccess) && selectedReturnDate) {
            setShowSuccess(true);
        }
        onResetTransaction();
        onResetSuccess();
        onResetCardResponse();
    }, [transaction, pickupSuccess]);

    const submitHandler = (e) => {
        e.preventDefault();
        setErrorMessage("");
        if (qty > 0) {
            if (selectedReturnDate) {
                if (selectedReturnPartner.partner == "uberdirect") {
                    if (
                        (promoMessage === "Promo code applied!" &&
                        pickupNote.length <= 280) ||
						isMember
                    ) {
                        createUberdirectPickup();
                    } else if (selectedPaymentMethod == "") {
                        setErrorMessage("Please select a card");
                    } else if (pickupNote.length > 280) {
                        // do nothing, error is already shown
                    } else {
                        onChargeCustomer({
                            userId: user.userId,
                            paymentMethodId: selectedPaymentMethod,
                            totalChargeAmount: uberdirectTotal,
                            type: "return_pickup",
                            uberdirect: true,
                        });
                    }
                } else if (
                    selectedReturnPartner.pickupFee &&
                    selectedReturnPartner.pickupFee > 0
                ) {
                    if (selectedPaymentMethod !== "") {
                        onChargeCustomer({
                            userId: user.userId,
                            paymentMethodId: selectedPaymentMethod,
                            totalChargeAmount: selectedReturnPartner.pickupFee,
                            type: "return_pickup",
                        });
                    } else {
                        setErrorMessage("Please select a card");
                    }
                } else if (
                    selectedReturnPartner.partner !== "gently" &&
                    selectedReturnPartner.partner !== "uberdirect"
                ) {
                    if (selectedPaymentMethod !== "") {
                        onChargeCustomer({
                            userId: user.userId,
                            paymentMethodId: selectedPaymentMethod,
                            totalChargeAmount: appInfo.pickupAtDoorFee,
                            type: "return_pickup",
                        });
                    } else {
                        setErrorMessage("Please select a card");
                    }
                } else {
                    if (streetName === "" || streetNumber === "") {
                        setErrorMessage(
                            "Please select a valid address with street name and number"
                        );
                    } else {
                        validateAddress();
                        submitTransaction();
                    }
                }
            } else {
                setErrorMessage("Please select a return date");
            }
        } else {
            setErrorMessage("Please enter a quantity greater than 0");
        }
    };

    useEffect(() => {
        setErrorMessage("");
        if (
            promoMessage === "Promo code applied!" &&
            selectedReturnPartner.partner === "uberdirect"
        ) {
            createUberdirectPickup();
            onUpdatePromo({
                promo: promo,
                promoCode: providedCode,
                userPhone: user.phone,
            });
		} else if (selectedReturnPartner.partner === 'uberdirect' && isMember) {
			createUberdirectPickup()
        } else if (chargingCardResponse === "success") {
            if (selectedReturnPartner.partner != "uberdirect") {
                submitTransaction();
                onResetCardResponse();
            } else {
                createUberdirectPickup();
            }
        } else if (chargingCardResponse === "error") {
            setErrorMessage("Error while charging your card!");
        }
    }, [chargingCardResponse, promoMessage]);

    useEffect(() => {
        if (chargingCardError) {
            setErrorMessage("Error while charging your card!");
        }
    }, [chargingCardError, promoMessage, isMember]);

    useEffect(() => {
        // console.log('user payment useeffect triggered')
        if (user && user.paymentMethods) {
            makeCardRadios(user.paymentMethods);
        }
    }, [user?.paymentMethods, JSON.stringify(user?.paymentMethods)]);

    const makeCardRadios = (paymentMethods) => {
        // console.log(paymentMethods)
        let radios = [];
        paymentMethods.forEach(function (paymentMethod, index) {
            radios.push({
                value: paymentMethod.id,
                label: `${paymentMethod.card.brand.toUpperCase()} ....${
                    paymentMethod.card.last4
                } - Exp. ${paymentMethod.card.exp_month}/${
                    paymentMethod.card.exp_year
                }`,
                checked: index === 0,
            });
            if (index === 0) {
                setSelectedPaymentMethod(paymentMethod.id);
            }
        });
        setCardRadios(radios);
    };

    // ! VALIDATE PROMO
    const handleCodeValidation = (e) => {
        e.preventDefault();
        if (selectedReturnPartner.partner === "uberdirect") {
            onValidatePromo({
                promoCode: providedCode,
                phone: user.phone,
                node: null,
                uberDirect: true,
            });
        } else {
            onValidatePromo({
                promoCode: providedCode,
                phone: user.phone,
                node: null,
            });
        }
    };

    const promoMessages = {
        promoValid: "Promo code applied!",
        promoInvalid: "Promo code is invalid",
        promoExpired: "Promo code has expired",
        promoAlreadyUsed: "Promo code is no longer valid",
        promoNotYetActive: "Promo code is not yet active",
        promoNotValidForUser: "Promo code is not valid",
        promoNotValidForNode: "Promo code is not valid for this restaurant",
        giftCardValid: "Gift card applied!",
        giftCardInvalid: "Gift card is invalid",
        giftCardBalanceZero: "Gift card balance is $0.00",
        promoNotValidForUberDirect:
            "Promo code not valid for on demand pickups",
        promoOnlyValidForUberDirect:
            "Promo code is only valid for on demand pickups",
    };

    useEffect(() => {
        if (promoMessageType) {
            setPromoMessage(promoMessages[promoMessageType]);
        }
    }, [promoMessageType]);

    if (user) {
        return (
            <>
                <Header label="Return At Door" showBackButton={true} />
                <div className="min-h-screen-no-header sm:h-screen w-full flex flex-col justify-between mt-[3.25rem]">
                    {showSuccess && selectedReturnDate ? (
                        <div className="h-full my-5 flex flex-col justify-center items-center overflow-hidden">
                            <ReactConfetti colors={["#f38b74"]} />
                            <h1 className="font-vollkorn text-green-600 text-4xl text-center">
                                You're all set!
                            </h1>
                            {ondemandPickupSelected ? (
                                <div className="mx-3 text-base text-center">
                                    A courier is being dispatched shortly to
                                    pick up your containers. Keep an eye out for
                                    a text with updates!
                                </div>
                            ) : (
                                <div className="mx-3 text-base text-center">
                                    Remember to leave your containers out for
                                    pick up on{" "}
                                    {dayNames[selectedReturnDate.getDay()]},
                                    {` `}
                                    {
                                        monthNames[
                                            selectedReturnDate.getMonth()
                                        ]
                                    }{" "}
                                    {selectedReturnDate.getDate()}
                                </div>
                            )}

                            <MdOutlineCelebration className="text-orange-600 text-[250px]" />
                            <Button
                                link="/user/accountLanding"
                                className="px-10 w-[260px] mt-5 shadow-light-grey"
                                text="View My Account"
                            />
                        </div>
                    ) : (
                        // <div className='w-full h-full flex flex-col items-center justify-start bg-yellow-400'>
                        // 	<div className='w-11/12 h-full sm:h-fit sm:min-w-[85%] bg-white overflow-auto scrollbar shadow-light-grey rounded-xl max-w-lg m-auto p-6 mt-6 mb-20 sm:mx-2'>
                        <div className="w-full h-screen flex flex-col items-center justify-start bg-yellow-400">
                            <div className="w-11/12 h-full sm:min-w-[85%] bg-white shadow-light-grey rounded-xl max-w-lg m-auto p-6 mt-6 mb-20 sm:mx-2 overflow-auto">
                                <div className="text-left sm:text-center text-base md:text-[20px] font-semibold">
                                    Schedule a pickup to return containers at
                                    your door. Leave your containers in a safe
                                    spot for our couriers to pick up.
                                </div>
                                <div className="mt-2 text-left text-xs sm:text-center font-light">
                                    Our pickups take advantage of existing empty
                                    courier legs and use optimized route
                                    technology, so that that there's minimal
                                    added emissions.
                                </div>
                                {filteredPickups &&
                                    filteredPickups.length > 0 && (
                                        <div className="flex flex-col w-full mx-auto justify-center items-center">
                                            <Button
                                                text="View Upcoming Pickups"
                                                size="xs"
                                                color="green"
                                                onClick={() => {
                                                    handleOpenPopUp();
                                                }}
                                                className="px-3 py-2 mt-2 w-[175px]"
                                            />
                                            {openPopUp && (
                                                <PendingPickupsPopUp
                                                    handleOpenPopUp={
                                                        handleOpenPopUp
                                                    }
                                                    pendingPickups={
                                                        filteredPickups
                                                    }
                                                    openPopUp={openPopUp}
                                                />
                                            )}{" "}
                                        </div>
                                    )}
                                <div className="mt-5 text-left sm:text-center text-base font-light">
                                    Select your pickup address
                                </div>
                                <div className="mb-1">
                                    {user.address !== "" && (
                                        <CheckboxGroup
                                            label="Use default address"
                                            name="default-address"
                                            className="my-3"
                                            defaultChecked={false}
                                            onChange={(val) => {
                                                if (val.target.checked) {
                                                    setCoords([]);
                                                    setGeoCodedAddress(false);
                                                    setAddress(user.address);
                                                }
                                                setUseDefaultAddress(
                                                    val.target.checked
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                                {useDefaultAddress ? (
                                    <div className="text-left">
                                        <div>{user.address}</div>
                                        {user.address2 &&
                                            user.address2 != "" && (
                                                <div>Unit: {user.address2}</div>
                                            )}
                                    </div>
                                ) : (
                                    <div className="text-left">
                                        <div className="pt-1">
                                            <label
                                                className={`text-sm text-gray-600 font-poppins ${
                                                    addressError &&
                                                    "text-red-600"
                                                } sm:w-24 -translate-y-5 pb-1`}
                                                htmlFor="address"
                                            >
                                                ADDRESS
                                            </label>
                                            {!timeoutExpired ? (
                                                <BounceLoader
                                                    className="m-auto"
                                                    color={bounceLoaderColor}
                                                ></BounceLoader>
                                            ) : (
                                                showSearchBar && (
                                                    <Input
                                                        type=""
                                                        label=""
                                                        name="address"
                                                        onChange={
                                                            handleAddressChange
                                                        }
                                                        autoCompleteAddress={
                                                            true
                                                        }
                                                        className="bg-white border-2 border-gray-300 mb-3 w-full sm:w-5/6"
                                                        value={address}
                                                        onKeyDown={(e) => {
                                                            handleAddressChange(
                                                                e
                                                            );
                                                            e.key === "Enter" &&
                                                                handleAddressSubmit(
                                                                    e
                                                                );
                                                        }}
                                                    />
                                                )
                                            )}
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <div className="pt-1">
                                                <label
                                                    className={`text-sm text-gray-600 font-poppins ${
                                                        addressError &&
                                                        "text-red-600"
                                                    } sm:w-24 -translate-y-5 pb-1`}
                                                    htmlFor="address"
                                                >
                                                    UNIT
                                                </label>
                                                <Input
                                                    type=""
                                                    name="unit"
                                                    onChange={
                                                        handleAddress2Change
                                                    }
                                                    onKeyDown={(e) => {
                                                        e.key === "Enter" &&
                                                            e.preventDefault();
                                                    }}
                                                    defaultValue={
                                                        user.address2
                                                            ? user.address2
                                                            : ""
                                                    }
                                                    className="bg-white border-2 border-gray-300 mb-3"
                                                />
                                            </div>
                                            {/*<Button*/}
                                            {/*    size='sm'*/}
                                            {/*    className='text-xs px-1 mt-3 sm:ml-2'*/}
                                            {/*    text='Find Pickup Date'*/}
                                            {/*    onClick = {handlePickupDateSearch}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                )}
                                <div className="flex flex-col md:flex-row my-3">
                                    <div className="w-full md:mr-3 md:w-2/5">
                                        <div className="text-left text-base font-light">
                                            Available Pickup Times
                                        </div>
                                        {loadingPartners ? (
                                            <div className="mt-3 text-center">
                                                <BounceLoader
                                                    className="m-auto"
                                                    color={bounceLoaderColor}
                                                ></BounceLoader>
                                            </div>
                                        ) : (
                                            <div className="sm:w-[240px]">
                                                {availableOndemand.length > 0 &&
                                                    (loading ? (
                                                        <BounceLoader
                                                            className="m-auto"
                                                            color={
                                                                bounceLoaderColor
                                                            }
                                                        ></BounceLoader>
                                                    ) : ondemandPickupSelected &&
                                                      !uberQuote ? (
                                                        <div className="font-bold text-sm justify-start text-left">
                                                            Sorry, no on demand
                                                            pickups available at
                                                            this time.
                                                        </div>
                                                    ) : ondemandPickupSelected &&
                                                      uberQuote ? (
                                                        uberQuote.code ===
                                                        "address_undeliverable" ? (
                                                            <div className="font-bold text-sm justify-start text-left text-red-400">
                                                                Sorry, no on
                                                                demand pickups
                                                                are available in
                                                                your area at
                                                                this time.
                                                                Please try again
                                                                later or{" "}
                                                                <a
                                                                    className="underline cursor-pointer"
                                                                    onClick={() => {
                                                                        navigate(
                                                                            "/returns/locations"
                                                                        );
                                                                    }}
                                                                >
                                                                    return your
                                                                    containers
                                                                    to the
                                                                    nearest
                                                                    return
                                                                    location
                                                                </a>
                                                            </div>
                                                        ) : (
                                                            <div className="font-bold justify-start text-left">
                                                                Please fill out
                                                                remaining
                                                                details to
                                                                request your
                                                                pickup!
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div>
                                                            {availableOndemand.map(
                                                                (
                                                                    onDemandPartner,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Button
                                                                            size="sm"
                                                                            className="my-1"
                                                                            key={
                                                                                index
                                                                            }
                                                                            color={
                                                                                "green"
                                                                            }
                                                                            onClick={
                                                                                handleOnDemandClicked
                                                                            }
                                                                            text={
                                                                                "ASAP"
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                            {/*<div className="font-bold text-xs justify-start text-left mb-3">*/}
                                                            {/*    For on demand,*/}
                                                            {/*    we'll dispatch a*/}
                                                            {/*    courier right*/}
                                                            {/*    away to pick up*/}
                                                            {/*    your returns*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    ))}
                                                {partners &&
                                                partners.length > 0 ? (
                                                    <div>
                                                        {availableDates.map(
                                                            (date, index) => {
                                                                if (index < 5) {
                                                                    if (
                                                                        pendingDates &&
                                                                        pendingDates.includes(
                                                                            date
                                                                                .toString()
                                                                                .slice(
                                                                                    0,
                                                                                    10
                                                                                )
                                                                        )
                                                                    ) {
                                                                        return (
                                                                            <Button
                                                                                size="sm"
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="my-1"
                                                                                color="disabled"
                                                                                text={
                                                                                    <div>
                                                                                        <span className="text-xs">
                                                                                            PENDING
                                                                                            PICKUP:
                                                                                        </span>{" "}
                                                                                        <span className="text-base">
                                                                                            {date
                                                                                                .toString()
                                                                                                .slice(
                                                                                                    0,
                                                                                                    10
                                                                                                )}
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                                onClick={() => {
                                                                                    handleOpenPopUp();
                                                                                }}
                                                                            />
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <Button
                                                                                size="sm"
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="my-1"
                                                                                color={
                                                                                    selectedReturnDate ===
                                                                                    date
                                                                                        ? "green"
                                                                                        : "green-outlined"
                                                                                }
                                                                                onClick={() => {
                                                                                    setSelectedReturnDate(
                                                                                        date
                                                                                    );
                                                                                    setSelectedReturnPartner(
                                                                                        availableDatesObj[
                                                                                            date
                                                                                        ]
                                                                                    );
                                                                                    setOndemandPickupSelected(
                                                                                        false
                                                                                    );
                                                                                    setPromoMessage(
                                                                                        ""
                                                                                    );
                                                                                    onResetPromo();
                                                                                    onResetSuccess();
                                                                                }}
                                                                                text={
                                                                                    availableDatesObj[
                                                                                        date
                                                                                    ]
                                                                                        .pickupFee &&
                                                                                    availableDatesObj[
                                                                                        date
                                                                                    ]
                                                                                        .pickupFee >
                                                                                        0
                                                                                        ? `${dayNames[
                                                                                              date.getDay()
                                                                                          ].slice(
                                                                                              0,
                                                                                              3
                                                                                          )}, ${monthNames[
                                                                                              date.getMonth()
                                                                                          ].slice(
                                                                                              0,
                                                                                              3
                                                                                          )} ${date.getDate()} $${availableDatesObj[
                                                                                              date
                                                                                          ].pickupFee.toFixed(
                                                                                              2
                                                                                          )} ${
                                                                                              availableTimesPartners[
                                                                                                  availableDatesObj[
                                                                                                      date
                                                                                                  ]
                                                                                                      .partner
                                                                                              ] &&
                                                                                              availableTimesPartners[
                                                                                                  availableDatesObj[
                                                                                                      date
                                                                                                  ]
                                                                                                      .partner
                                                                                              ][
                                                                                                  dayNames[
                                                                                                      date.getDay()
                                                                                                  ]
                                                                                              ]
                                                                                                  ? availableTimesPartners[
                                                                                                        availableDatesObj[
                                                                                                            date
                                                                                                        ]
                                                                                                            .partner
                                                                                                    ][
                                                                                                        dayNames[
                                                                                                            date.getDay()
                                                                                                        ]
                                                                                                    ]
                                                                                                  : ""
                                                                                          }`
                                                                                        : `${dayNames[
                                                                                              date.getDay()
                                                                                          ].slice(
                                                                                              0,
                                                                                              3
                                                                                          )}, ${monthNames[
                                                                                              date.getMonth()
                                                                                          ].slice(
                                                                                              0,
                                                                                              3
                                                                                          )} ${date.getDate()}
																				  ${
                                                                                      availableTimesPartners[
                                                                                          availableDatesObj[
                                                                                              date
                                                                                          ]
                                                                                              .partner
                                                                                      ] &&
                                                                                      availableTimesPartners[
                                                                                          availableDatesObj[
                                                                                              date
                                                                                          ]
                                                                                              .partner
                                                                                      ][
                                                                                          dayNames[
                                                                                              date.getDay()
                                                                                          ]
                                                                                      ]
                                                                                          ? availableTimesPartners[
                                                                                                availableDatesObj[
                                                                                                    date
                                                                                                ]
                                                                                                    .partner
                                                                                            ][
                                                                                                dayNames[
                                                                                                    date.getDay()
                                                                                                ]
                                                                                            ]
                                                                                          : ""
                                                                                  }`
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        )}
                                                    </div>
                                                ) : availableOndemand.length >
                                                  0 ? (
                                                    <div></div>
                                                ) : (
                                                    <div>
                                                        No pickup available yet
                                                        for that address!
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex flex-col">
                                        {/* PROMO */}
                                        {selectedReturnPartner &&
                                            selectedReturnPartner.partner ===
                                                "uberdirect" && (
                                                <div className="flex flex-col mt-3 md:mt-0 md:ml-6">
                                                    <div className="w-full h-full">
                                                        <div className="text-left text-base font-light">
                                                            Promo Code
                                                        </div>
                                                        <Input
                                                            label=""
                                                            className="text-left"
                                                            onChange={(e) =>
                                                                setProvidedCode(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <Button
                                                            text="Apply"
                                                            size="xs"
                                                            className="flex justify-center items-center my-2 px-2 h-fit max-w-[100px]"
                                                            onClick={(e) =>
                                                                handleCodeValidation(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    {promoMessage &&
                                                        promoMessage !==
                                                            "Promo code applied!" && (
                                                            <div className="my-4 text-red-400">
                                                                {promoMessage}
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        <div className="mt-3 md:mt-0 md:ml-6">
                                            <div className="text-left text-base font-light">
                                                Special Instructions
                                            </div>
                                            <TextArea
                                                name="Special Instructions"
                                                placeholder="Include special instructions for our drivers here"
                                                // description='Maximum of 250 characters'
                                                className="!h-20 !mt-1"
                                                onChange={(e) => {
                                                    setPickupNote(e);
                                                }}
                                            />
                                            {(selectedReturnPartner &&
                                                selectedReturnPartner.partner ===
                                                    "uberdirect") ||
                                                (ondemandPickupSelected && (
                                                    <>
                                                        <span className="text-xs flex flex-row my-1">
                                                            Character count:{" "}
                                                            {pickupNote &&
                                                            pickupNote.length >
                                                                280 ? (
                                                                <span className="text-red-600 ml-1">
                                                                    {" "}
                                                                    {
                                                                        pickupNote.length
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <span className="text-green-600 ml-1">
                                                                    {" "}
                                                                    {
                                                                        pickupNote.length
                                                                    }
                                                                </span>
                                                            )}
                                                        </span>
                                                        {pickupNote &&
                                                            pickupNote.length >
                                                                280 && (
                                                                <div className="text-red-600 text-sm mb-3">
                                                                    Special
                                                                    Instructions
                                                                    cannot
                                                                    exceed 280
                                                                    characters
                                                                </div>
                                                            )}
                                                    </>
                                                ))}
                                        </div>
                                        <div className="w-full md:ml-6 md:w-3/5 mt-3 md:mt-0">
                                            {/* {partners &&
                                                selectedReturnPartner &&
                                                Object.keys(
                                                    selectedReturnPartner
                                                ).length !== 0 &&
                                                selectedReturnPartner.partner ===
                                                        "uberdirect" && (
                                                    <div>
                                                        {user.paymentMethods
                                                            .length === 0 ? (
                                                            <div className="text-left mt-2 mb-4">
                                                                <div className="mb-1">
                                                                    This pick up
                                                                    option has a
                                                                    fee. Add a
                                                                    card to
                                                                    continue
                                                                </div>
                                                                <Elements
                                                                    stripe={
                                                                        stripePromise
                                                                    }
                                                                >
                                                                    <CreditCardForm />
                                                                </Elements>
                                                            </div>
                                                        ) : (
                                                            <div className="text-left mt-2 mb-4">
                                                                <div className="mb-1">
                                                                    This pick up
                                                                    option has a
                                                                    fee. Select
                                                                    a card to
                                                                    continue
                                                                </div>
                                                                {cardRadios.length >
                                                                    0 && (
                                                                    <div>
                                                                        <RadioGroup
                                                                            className="mt-1 sm:mt-0"
                                                                            name="sortBy"
                                                                            radios={
                                                                                cardRadios
                                                                            }
                                                                            onChange={(
                                                                                paymentMethodId
                                                                            ) => {
                                                                                setSelectedPaymentMethod(
                                                                                    paymentMethodId
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )} */}

                                            <div className="text-left text-base font-light">
                                                Tell us how many containers
                                                you're returning
                                            </div>
                                            <div className="mt-3 border-2 rounded-full mb-3 sm:mb-0 sm:w-[175px] w-full text-center flex flex-row justify-between px-3 items-center">
                                                <span
                                                    color="default"
                                                    className="text-4xl text-gray-300 w-1/3 cursor-pointer"
                                                    onClick={() => {
                                                        if (qty >= 2) {
                                                            setQty(qty - 1);
                                                        }
                                                    }}
                                                >
                                                    -
                                                </span>
                                                <div className="text-2xl">
                                                    {qty}
                                                </div>
                                                <span
                                                    color="default"
                                                    className="text-2xl text-gray-300 w-1/3 cursor-pointer"
                                                    onClick={() => {
                                                        // if (qty < user.boxCount)
                                                        setQty(qty + 1);
                                                    }}
                                                >
                                                    +
                                                </span>
                                            </div>
                                            {selectedReturnPartner &&
                                                selectedReturnPartner.partner &&
                                                (selectedReturnPartner.partner !=
                                                    "gently" ||
                                                    selectedReturnPartner.partner ===
                                                        "uberdirect") && (
                                                    <div>
                                                        {promoMessage ===
                                                        "Promo code applied!" ? (
                                                            <div className="my-4 text-green-600">
                                                                {promoMessage}
                                                            </div>
                                                        ) : isMember ? (
                                                            <div className='my-4 text-green-600'>
                                                                Free pickup! Thanks
                                                                for being a
                                                                DeliverZero+ member.
                                                            </div>
                                                        ) : !user.paymentMethods ||
                                                          (user.paymentMethods &&
                                                              user
                                                                  .paymentMethods
                                                                  .length ===
                                                                  0) ? (
                                                            <div className="text-left mt-3 mb-4">
                                                                <div className="mb-1">
                                                                    {selectedReturnPartner.partner ===
                                                                    "uberdirect" ? (
                                                                        uberQuote &&
                                                                        uberQuote.quote ? (
                                                                            <div className="mb-2">
                                                                                <b>
                                                                                    Pickup
                                                                                    fee:{" "}
                                                                                    {`$${(
                                                                                        JSON.parse(
                                                                                            uberQuote.quote
                                                                                        )
                                                                                            .fee /
                                                                                        100
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}`}
                                                                                </b>
                                                                                <div className="text-left mt-2 mb-2">
                                                                                    Tip
                                                                                    amount:
                                                                                    $
                                                                                    {
                                                                                        tipAmount
                                                                                    }
                                                                                </div>
                                                                                <RadioGroup
                                                                                    horizontal
                                                                                    name="tips"
                                                                                    radios={[
                                                                                        {
                                                                                            label: "$1",
                                                                                            value: 1,
                                                                                        },
                                                                                        {
                                                                                            label: "$3",
                                                                                            value: 3,
                                                                                        },
                                                                                        {
                                                                                            label: "$5",
                                                                                            value: 5,
                                                                                        },
                                                                                        {
                                                                                            label: "None",
                                                                                            value: 0,
                                                                                        },
                                                                                    ]}
                                                                                    onChange={(
                                                                                        tip
                                                                                    ) => {
                                                                                        setTipAmount(
                                                                                            tip
                                                                                        );
                                                                                        const fee =
                                                                                            parseFloat(
                                                                                                JSON.parse(
                                                                                                    uberQuote.quote
                                                                                                )
                                                                                                    .fee
                                                                                            ) /
                                                                                            100;
                                                                                        const totalAmount =
                                                                                            fee +
                                                                                            parseFloat(
                                                                                                tip
                                                                                            );
                                                                                        const roundedTotal =
                                                                                            totalAmount.toFixed(
                                                                                                2
                                                                                            );
                                                                                        setUberdirectTotal(
                                                                                            roundedTotal
                                                                                        );
                                                                                        // console.log(
                                                                                        //     "TOTAL",
                                                                                        //     uberdirectTotal
                                                                                        // );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <BounceLoader
                                                                                className="m-auto"
                                                                                color={
                                                                                    bounceLoaderColor
                                                                                }
                                                                            ></BounceLoader>
                                                                        )
                                                                    ) : (
                                                                        <b>
                                                                            Pickup
                                                                            fee:{" "}
                                                                            {`$${appInfo.pickupAtDoorFee.toFixed(
                                                                                2
                                                                            )}`}
                                                                        </b>
                                                                    )}
                                                                </div>
                                                                {selectedReturnPartner.partner ===
                                                                "uberdirect" ? (
                                                                    <div className="mb-1 text-sm">
                                                                        <b>
                                                                            Add
                                                                            a
                                                                            card
                                                                            to
                                                                            continue.
                                                                        </b>
                                                                    </div>
                                                                ) : (
                                                                    <div className="mb-1 text-sm">
                                                                        <b>
                                                                            Add
                                                                            a
                                                                            card
                                                                            to
                                                                            continue.
                                                                        </b>
                                                                    </div>
                                                                )}
                                                                <Elements
                                                                    stripe={
                                                                        stripePromise
                                                                    }
                                                                >
                                                                    <CreditCardForm />
                                                                </Elements>
                                                            </div>
                                                        ) : (
                                                            <div className="text-left mt-3 mb-4">
                                                                <div className="mb-1">
                                                                    {selectedReturnPartner.partner ===
                                                                    "uberdirect" ? (
                                                                        uberQuote &&
                                                                        uberQuote.quote ? (
                                                                            <div className="mb-5">
                                                                                <b>
                                                                                    Pickup
                                                                                    fee:{" "}
                                                                                    {`$${(
                                                                                        JSON.parse(
                                                                                            uberQuote.quote
                                                                                        )
                                                                                            .fee /
                                                                                        100
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}`}
                                                                                </b>
                                                                                <div className="text-left mt-2 mb-2">
                                                                                    Tip
                                                                                    amount:
                                                                                    $
                                                                                    {
                                                                                        tipAmount
                                                                                    }
                                                                                </div>
                                                                                <RadioGroup
                                                                                    horizontal
                                                                                    name="tips"
                                                                                    radios={[
                                                                                        {
                                                                                            label: "$1",
                                                                                            value: 1,
                                                                                        },
                                                                                        {
                                                                                            label: "$3",
                                                                                            value: 3,
                                                                                        },
                                                                                        {
                                                                                            label: "$5",
                                                                                            value: 5,
                                                                                        },
                                                                                        {
                                                                                            label: "None",
                                                                                            value: 0,
                                                                                        },
                                                                                    ]}
                                                                                    onChange={(
                                                                                        tip
                                                                                    ) => {
                                                                                        setTipAmount(
                                                                                            tip
                                                                                        );
                                                                                        const fee =
                                                                                            parseFloat(
                                                                                                JSON.parse(
                                                                                                    uberQuote.quote
                                                                                                )
                                                                                                    .fee
                                                                                            ) /
                                                                                            100;
                                                                                        const totalAmount =
                                                                                            fee +
                                                                                            parseFloat(
                                                                                                tip
                                                                                            );
                                                                                        const roundedTotal =
                                                                                            totalAmount.toFixed(
                                                                                                2
                                                                                            );
                                                                                        setUberdirectTotal(
                                                                                            roundedTotal
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <BounceLoader
                                                                                className="m-auto"
                                                                                color={
                                                                                    bounceLoaderColor
                                                                                }
                                                                            ></BounceLoader>
                                                                        )
                                                                    ) : (
                                                                        <b>
                                                                            Pickup
                                                                            fee:{" "}
                                                                            {`$${appInfo.pickupAtDoorFee.toFixed(
                                                                                2
                                                                            )}`}
                                                                        </b>
                                                                    )}
                                                                </div>
                                                                {selectedReturnPartner.partner ===
                                                                "uberdirect" ? (
                                                                    <b>
                                                                        Select a
                                                                        card to
                                                                        continue.
                                                                    </b>
                                                                ) : (
                                                                    <div className="mb-1 text-sm">
                                                                        <b>
                                                                            Select
                                                                            a
                                                                            card
                                                                            to
                                                                            continue.
                                                                        </b>
                                                                    </div>
                                                                )}
                                                                {cardRadios.length >
                                                                    0 && (
                                                                    <div>
                                                                        <RadioGroup
                                                                            className="mt-1 sm:mt-0"
                                                                            name="sortBy"
                                                                            radios={
                                                                                cardRadios
                                                                            }
                                                                            onChange={(
                                                                                paymentMethodId
                                                                            ) => {
                                                                                setSelectedPaymentMethod(
                                                                                    paymentMethodId
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            {creatingTransaction ||
                                            chargingCard ? (
                                                <BounceLoader
                                                    className="m-auto"
                                                    color={bounceLoaderColor}
                                                ></BounceLoader>
                                            ) : (
                                                <div>
                                                    {invalidAddress ||
                                                    qty < 1 ||
                                                    (partners &&
                                                        selectedReturnPartner &&
                                                        Object.keys(
                                                            selectedReturnPartner
                                                        ).length !== 0 &&
                                                        selectedReturnPartner.pickupFee &&
                                                        selectedReturnPartner.pickupFee >
                                                            0 &&
                                                        selectedPaymentMethod ===
                                                            "" &&
                                                        promoMessage !==
                                                            "Promo code applied!" &&
                                                            !isMember) ? (
                                                        <Button
                                                            text="Submit"
                                                            size="sm"
                                                            color="gray"
                                                            onClick={() => {}}
                                                            className="px-3 py-2 mt-2 w-[175px]"
                                                        />
                                                    ) : (
                                                        <div>
                                                            {!selectedReturnPartner ||
                                                            (selectedReturnPartner &&
                                                                Object.keys(
                                                                    selectedReturnPartner
                                                                ).length ===
                                                                    0) ||
                                                            (selectedPaymentMethod ===
                                                                "" &&
                                                                ![
                                                                    "uberdirect",
                                                                    "gently",
                                                                ].includes(
                                                                    selectedReturnPartner.partner
                                                                )) ||
                                                            qty < 1 ||
                                                            (selectedReturnPartner &&
                                                                selectedReturnPartner.partner ===
                                                                    "uberdirect" &&
                                                                (!uberQuote ||
                                                                    !isMember ||
                                                                    (selectedPaymentMethod ===
                                                                        "" &&
                                                                        promoMessage !==
                                                                            "Promo code applied!") ||
                                                                    qty <
                                                                        1)) ? (
                                                                <Button
                                                                    text="Submit"
                                                                    size="sm"
                                                                    color="gray"
                                                                    onClick={() => {}}
                                                                    className="px-3 py-2 mt-2 w-[175px]"
                                                                />
                                                            ) : (
                                                                <Button
                                                                    text="Submit"
                                                                    size="sm"
                                                                    color="green"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        submitHandler(
                                                                            e
                                                                        )
                                                                    }
                                                                    className="px-3 py-2 mt-2 w-[175px]"
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {errorMessage !== "" && (
                                                <div className="text-red-400 mt-2 text-left font-semibold text-sm">
                                                    {errorMessage}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
							className='h-32 bg-red-400 w-full transform translate-y-[-20px]'
							style={{
								clipPath:
									'polygon( 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%, 100% 100%, 0% 100%)',
							}}
						></div> */}
                        </div>
                    )}
                </div>
            </>
        );
    } else {
        return (
            <div className=" h-full w-full flex flex-col justify-between">
                <Header label="Return At Door" showBackButton={true} />
                <div className="flex flex-col items-center h-screen-no-header w-full text-center  bg-yellow-400 lg:h-screen-no-header mt-[3.25rem]">
                    <h1 className="p-8 text-6xl text-green-600 font-header sm:text-6xl ">
                        Schedule a Pickup
                    </h1>
                    <div className="flex flex-col mx-[10%] w-[80%] h-[750px] py-3 px-5 mb-12 shadow-lighter-grey bg-white rounded-[10px]">
                        <div className="text-left sm:text-center text-base md:text-[22px] font-light">
                            Return containers at your door. Leave your
                            containers in a safe spot for our couriers to pick
                            up.
                        </div>
                        <div className="mt-3 text-left sm:text-center text-base font-light">
                            Select your pickup address
                        </div>
                        <div className="mt-3 text-center">
                            <BounceLoader
                                className="m-auto"
                                color={bounceLoaderColor}
                            ></BounceLoader>
                        </div>
                    </div>
                    {/* <div
						className='h-32 bg-red-400 w-full transform translate-y-[-20px]'
						style={{
							clipPath:
								'polygon( 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%, 100% 100%, 0% 100%)',
						}}
					></div> */}
                </div>
            </div>
        );
    }
};
const mapStateToProps = ({
    User,
    AppInfo,
    ReverseLogistics,
    TransactionsCreate,
    Payments,
    Logistics,
    Pickups,
    Promos,
}) => ({
    user: User.user,
    loadingUser: User.loading,
    appInfo: AppInfo.appInfo,
    partners: ReverseLogistics.partners,
    loadingPartners: ReverseLogistics.loadingPartners,
    partnersError: ReverseLogistics.partnersError,
    transaction: TransactionsCreate.transactionResult,
    createTransactionError: TransactionsCreate.error,
    creatingTransaction: TransactionsCreate.creating,
    partner: ReverseLogistics.partner,
    loadingPartner: ReverseLogistics.loadingPartner,
    partnerError: ReverseLogistics.partnerError,
    chargingCard: Payments.chargingCard,
    chargingCardError: Payments.chargingCardError,
    chargingCardResponse: Payments.chargingCardResponse,
    paymentMethods: Payments.paymentMethods,
    pickupLoading: Pickups.pickupLoading,
    pickupSuccess: Pickups.pickupSuccess,
    pickupError: Pickups.pickupError,
    pendingPickups: Pickups.pendingPickups,
    pendingPickups: Pickups.pendingPickups,
    loading: Logistics.loading,
    uberRequest: Logistics.uberRequest,
    uberQuote: Logistics.uberQuote,
    error: Logistics.error,
    requestError: Logistics.requestError,
    promoError: Promos.error,
    promoIsActive: Promos.promoIsActive,
    promoLoading: Promos.loading,
    promoMessageType: Promos.promoMessageType,
    promo: Promos.promo,
    updatedPromo: Promos.updatedPromo,
});

const mapDispatchToProps = (dispatch) => ({
    onGetUser: () => dispatch(getUser()),
    onGetAppInfo: () => dispatch(getAppInfo()),
    onGetRlPartnersByPolygon: (coords) =>
        dispatch(getRlPartnersByPolygon(coords)),
    onGetRlPartner: (query) => dispatch(getRlPartner(query)),
    onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
    onResetTransaction: () => dispatch(resetTransaction()),
    onResetSuccess: () => dispatch(resetSuccess()),
    onResetPromo: () => dispatch(resetPromo()),
    onChargeCustomer: (payload) => dispatch(chargePaymentMethod(payload)),
    onResetCardResponse: () => dispatch(resetCardResponse()),
    onCreatePendingPickup: (payload) => dispatch(createPendingPickup(payload)),
    onGetUberDirectQuote: (payload) => dispatch(getUberDirectQuote(payload)),
    onCreateUberDirectRequest: (payload) =>
        dispatch(createUberDirectRequest(payload)),
    onValidatePromo: (promoCode) => dispatch(validatePromo(promoCode)),
    onUpdatePromo: (payload) => dispatch(updatePromo(payload)),
    onGetPendingPickupsByPhone: (payload) =>
        dispatch(getPickupsByPhone(payload)),
    onAlertSlack: (payload) => dispatch(alertSlack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnAtDoor);

{
    /* <svg class="absolute t-0 z-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#000b29" fill-opacity="1" d="M0,32L48,80C96,128,192,224,288,224C384,224,480,128,576,112C672,96,768,160,864,154.7C960,149,1056,75,1152,90.7C1248,107,1344,213,1392,266.7L1440,320L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg> */
}
