import {
	SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAILURE
} from './actionTypes'
export const sendSMS = (payload) => ({
	type: SEND_SMS,
	payload: payload,
})

export const sendSMSSuccess = (payload) => ({
	type: SEND_SMS_SUCCESS,
	payload: payload,
})
export const sendSMSFailure = (payload) => ({
	type: SEND_SMS_FAILURE,
	payload: payload,
})
