import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Header } from "../../components/";
import { getUser, getAppInfo } from "../../store/actions";
import MembershipCard from "../../components/UserProfile/MembershipCard";

const Membership = (props) => {
    const { onGetAppInfo, onGetUser, appInfo, user, loadingUser } = props;

    useEffect(() => {
        onGetUser();
        onGetAppInfo();
    }, [onGetUser]);

    return (
        <div className="h-auto w-screen">
            <Header label="DeliverZero+" showBackButton={true} />
            <div className="w-full mb-4 flex items-center justify-center mt-[3.25rem]">
                <MembershipCard
                    user={user}
                    appInfo={appInfo}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ AppInfo, User }) => ({
	appInfo: AppInfo.appInfo,
    loadingUser: User.loading,
    user: User.user,
});

const mapDispatchToProps = (dispatch) => ({
	onGetAppInfo: () => dispatch(getAppInfo()),
    onGetUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Membership);
