import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getNodes,
	getNode,
	getNodeBySlug,
	getNodeByQR,
	addNode,
	updateNode,
	getNodeByAdminCode, restaurantAdminSubmitRequest,
} from '../../model/nodes'
import {
	getNodeBySlugSuccess,
	getNodeBySlugFail,
	getNodeSuccess,
	getNodeFail,
	getNodesSuccess,
	getNodesFail,
	getNodeByQRSuccess,
	getNodeByQRFail,
	getNodeByQRNotFound,
	updateSearchNodes,
	addNodeSuccess,
	addNodeFail,
	updateNodeSuccess,
	updateNodeFail,
	getNodeByAdminCodeOrSlugSuccess,
	getNodeByAdminCodeOrSlugFail,
	restaurantAdminSubmitRequestSuccess,
	restaurantAdminSubmitRequestFail,
} from './actions'
import {
	ADD_NODE,
	GET_NODE,
	GET_NODE_BY_ADMIN_CODE,
	GET_NODE_BY_QR,
	GET_NODE_BY_SLUG,
	GET_NODES, RESTAURANT_ADMIN_SUBMIT_REQUEST,
	UPDATE_NODE,
	UPDATE_SEARCH_ADDRESS,
} from './actionTypes'

function* fetchNodes(action) {
	try {
		const nodes = yield call(getNodes, action.payload)
		yield put(getNodesSuccess(nodes))
	} catch (error) {
		yield put(getNodesFail(error))
	}
}

function* fetchNode(action) {
	try {
		const node = yield call(getNode, action.payload)
		yield put(getNodeSuccess(node))
	} catch (error) {
		yield put(getNodeFail(error))
	}
}

function* fetchNodeBySlug(action) {
	try {
		const node = yield call(getNodeBySlug, action.payload)
		yield put(getNodeBySlugSuccess(node))
	} catch (error) {
		yield put(getNodeBySlugFail(error))
	}
}

function* fetchNodeByQR(action) {
	try {
		const node = yield call(getNodeByQR, action.payload)
		if (node) {
			yield put(getNodeByQRSuccess(node))
		} else {
			yield put(getNodeByQRNotFound())
		}
	} catch (error) {
		yield put(getNodeByQRFail(error))
	}
}

function* updateSearchedAddress(action) {
	yield call(updateSearchNodes, action.payload)
}

function* submitNode(action) {
	try {
		const node = yield call(addNode, action.payload)
		yield put(addNodeSuccess(node))
	} catch (error) {
		yield put(addNodeFail(error))
	}
}

function* refreshNode(action) {
	try {
		const node = yield call(updateNode, action.payload)
		yield put(updateNodeSuccess(node))
	} catch (error) {
		yield put(updateNodeFail(error))
	}
}

function* fetchNodeByAdminCode(action) {
	try {
		const node = yield call(getNodeByAdminCode, action.payload)
		yield put(getNodeByAdminCodeOrSlugSuccess(node))
	} catch (error) {
		yield put(getNodeByAdminCodeOrSlugFail(error))
	}
}

function* submitRestaurantRequest(action) {
	try {
		const response = yield call(restaurantAdminSubmitRequest, action.payload)
		yield put(restaurantAdminSubmitRequestSuccess(response))
	} catch (error) {
		yield put(restaurantAdminSubmitRequestFail(error))
	}
}

function* nodesSaga() {
	yield takeEvery(GET_NODES, fetchNodes)
	yield takeEvery(GET_NODE, fetchNode)
	yield takeEvery(GET_NODE_BY_SLUG, fetchNodeBySlug)
	yield takeEvery(GET_NODE_BY_QR, fetchNodeByQR)
	yield takeEvery(UPDATE_SEARCH_ADDRESS, updateSearchedAddress)
	yield takeEvery(ADD_NODE, submitNode)
	yield takeEvery(UPDATE_NODE, refreshNode)
	yield takeEvery(GET_NODE_BY_ADMIN_CODE, fetchNodeByAdminCode)
	yield takeEvery(RESTAURANT_ADMIN_SUBMIT_REQUEST, submitRestaurantRequest)
}

export default nodesSaga
