export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const REDUCE_CART_ITEM = 'REDUCE_CART_ITEM'
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS'
export const CLEAR_CART_FAIL = 'CLEAR_CART_FAIL'
export const GET_CART = 'GET_CART'
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS'
export const GET_CART_ERROR = 'GET_CART_ERROR'
export const TOTAL_CART = 'TOTAL_CART'
export const ADD_CART_TO_USER = 'ADD_CART_TO_USER'
export const GET_CART_FROM_USER = 'GET_CART_FROM_USER'
export const UPDATE_DELIVERY_OPTION = 'UPDATE_DELIVERY_OPTION'

export const GET_CART_NODE_BY_SLUG = 'GET_CART_NODE_BY_SLUG'
export const GET_CART_NODE_BY_SLUG_FAIL = 'GET_CART_NODE_BY_SLUG_FAIL'
export const GET_CART_NODE_BY_SLUG_SUCCESS = 'GET_CART_NODE_BY_SLUG_SUCCESS'
