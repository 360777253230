import React, { useEffect, useState } from 'react'
import { Header, Footer } from '../../components'
import { Link, useParams } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/bi'
import { connect } from 'react-redux'
import { getNodeBySlug } from '../../store/nodes/actions'
import { BounceLoader } from 'react-spinners'
import { Button } from '../../components/Elements'
import { thirdPartyDict } from '../../helpers/clientInfo'

const RedirectPage = (props) => {
	const { node, loadingNode, onGetNodeBySlug } = props
	const bounceLoaderColor = '#507f74'
	const { nodeSlug } = useParams()

	useEffect(() => {
		if (nodeSlug) {
			onGetNodeBySlug(nodeSlug)
		}
	}, [nodeSlug])

	useEffect(() => {
	}, [node])

	return (
		<>
			{node ? (
				<>
					<Header label={"Order Now"} showBackButton redirectPage/>
					<div className='h-fit min-h-full sm:h-full w-full flex flex-col mt-[3.25rem]'>
            			<div className='w-full h-full flex flex-col items-center justify-start bg-yellow-400 flex-grow'>	
						<h1 className='mt-3 mb-0 px-6 text-2xl text-green-600 text-center font-header'>
							Before we send you to {node.name}
						</h1>
						<div className='w-11/12 h-fit sm:min-w-[85%] bg-white shadow-light-grey rounded-xl max-w-lg m-auto p-6 mt-3 my-[100px] sm:mx-2 sm:py-3'>
							<div className='flex flex-col md:flex-row mb-3'>
								<div className='text-left mt-2 w-full md:w-1/2 pr-0 md:pr-6 text-base md:text-lg leading-5'>
									Remember you must add "Use DeliverZero Reusable
									Containers" to your cart!
								</div>
								<div className='text-left mt-3 md:mt-0 w-full md:w-1/2 text-base md:text-lg border-2'>
									<img
										src='https://i.imgur.com/2GrmDIr.png'
									/>
								</div>
							</div>
							<div className="flex flex-col sm:flex-row justify-center items-center">
								{node.thirdPartyClient && (
									<div>
										<div className="mt-0 mb-3 text-lg">Order Through</div>
										{Object.keys(node.thirdPartyClient).map((client, index)=>{
											return(
												<div className='mb-3' key={index}>
													<Button
														text={`${thirdPartyDict[client].name}`}
														href={node.thirdPartyClient[client]}
														color={client}
														size='sm'
														className='text-[14px] shadow-light-grey font-semibold mx-auto py-1 px-6 mb-12'
														icon={<BiLinkExternal />}
														iconPosition='right'
													/>
												</div>
											)
										})}
									</div>
								)}
								<hr className="inline-block sm:hidden my-2"/>
								<div className="sm:inline-block hidden border-l border-[1px] border-gray-100 mx-6 h-[100px]"/>
								{node.directOrdering && node.directOrderingClient && (
									<div className="text-center mb-2">
										<div className="mt-0 sm:mt-4 mb-3 text-lg">Order Directly</div>
										<Button
											size='xs'
											className='text-[14px] shadow-light-grey font-semibold mx-auto py-1 px-6 mb-12'
											text='Order with DeliverZero'
											link={`/marketplace/${node.slug}`}
										/>
									</div>
								)}
							</div>
						</div>
						{/* <Link
							to='/'
							className='text-green-600 underline font-semibold leading-5 mt-2 mb-2'
						>
							Go Back
						</Link> */}
						</div>
					</div>
				</>
			) : (
				<>
					<Header label={"Order Now"} showBackButton/>
					<div className='flex flex-col items-center min-h-min w-full text-center  bg-yellow-400 lg:h-screen-no-header'>
						<div className='mt-3 text-center'>
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						</div>
					</div>
				</>
			)}
		</>
	)
}
const mapStateToProps = ({ Nodes }) => ({
	loadingNode: Nodes.loadingNode,
	node: Nodes.node,
})

const mapDispatchToProps = (dispatch) => ({
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage)
