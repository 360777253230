import React from 'react'

function UserProfileCard({
	content = '',
	className = '',
	titleClassName = '',
	title = '',
	stat = '',
	icon = null,
	statSize = 'text-6xl',
}) {
	const finalClass = `${className} w-full flex sm:flex-row justify-between rounded-xl bg-white p-6`
	return (
		<div className={finalClass}>
			<div className='mx-0 lg:mx-5 w-full'>
				<h3
					className={`my-2 block font-header ${titleClassName} text-green-400`}
				>
					{title}
				</h3>
				{content}
			</div>
		</div>
	)
}

export default UserProfileCard
