import React from 'react'
import { FaUser } from 'react-icons/fa'

function Avatar({
	className = '',
	image = '',
	size = 'base',
	status = '',
	statusBottom = false,
	...newProps
}) {
	let finalClass = `${className} relative rounded-full`
	if (!image) finalClass += ' bg-gray-300 flex items-center justify-center'
	if (size === 'xs') {
		finalClass += ' w-4 h-4'
	} else if (size === 'sm') {
		finalClass += ' w-8 h-8'
	} else if (size === 'base') {
		finalClass += ' w-12 h-12'
	} else if (size === 'lg') {
		finalClass += ' w-16 h-16'
	} else if (size === 'xl') {
		finalClass += ' w-20 h-20'
	} else if (size === '2xl') {
		finalClass += ' w-24 h-24'
	}

	return (
		<div
			className={finalClass}
			{...newProps}
		>
			{image ? (
				<img
					src={image}
					className='absolute left-0 top-0 w-full h-full rounded-full object-cover'
				/>
			) : (
				<FaUser />
			)}
		</div>
	)
}

export default Avatar
