import { call, put, takeEvery } from 'redux-saga/effects'
import {GET_APP_INFO, GET_BANNER} from './actionTypes'
import {getAppInfoSuccess, getAppInfoFailure, getBannerSuccess, getBannerFailure} from './actions'
import {findAppInfo,findBannerInfo} from "../../model/appInfo";

function* fetchAppInfo() {
	try {
		// console.log('IN SAGA')
		const appInfo = yield call(findAppInfo)
		yield put(getAppInfoSuccess(appInfo))
	} catch (error) {
		yield put(getAppInfoFailure(error))
	}
}


function* fetchBannerInfo() {
	try {
		// console.log('IN SAGA')
		const bannerInfo = yield call(findBannerInfo)
		yield put(getBannerSuccess(bannerInfo))
	} catch (error) {
		yield put(getBannerFailure(error))
	}
}

function* appInfoSaga() {
	yield takeEvery(GET_APP_INFO, fetchAppInfo)
	yield takeEvery(GET_BANNER,fetchBannerInfo)
}

export default appInfoSaga
