import React, { useState, useEffect, useRef } from 'react'

function SlidingTabs({
	className = '',
	tabs = [],
	onChange = () => {},
	scrollToSelection,
	...newProps
}) {
	const [activeTab, setActiveTab] = useState(0)
	const prevActiveTab = useRef(0)
	const handleSelection = (selection) => {
		scrollToSelection(selection)
	}
	let finalClass = `${className} w-full flex border-b-2 border-grey-300 overflow-x-auto no-scrollbar pt-4`
	let tabClassName =
		' flex mx-4 mb-2 font-medium cursor-pointer whitespace-nowrap'
	// let sliderClassName = 'w-full block absolute bottom-0 left-0 h-1 bg-red-300 transition-transform duration-300 ease-out'
	const numTabs = tabs.length
	// if (numTabs === 1) sliderClassName += ' w-full'
	// else if (numTabs === 2) sliderClassName += ' w-1/2'
	// else if (numTabs === 3) sliderClassName += ' w-1/3'
	// else if (numTabs === 4) sliderClassName += ' w-1/4'
	// else if (numTabs === 5) sliderClassName += ' w-1/5'
	// if (activeTab === 0) sliderClassName += ' transform translate-x-0'
	// else if (activeTab === 1) sliderClassName += ' transform translate-x-full'
	// else if (activeTab === 2) sliderClassName += ' transform translate-x-double'
	// else if (activeTab === 3) sliderClassName += ' transform translate-x-triple'
	// else if (activeTab === 4) sliderClassName += ' transform translate-x-quad'

	useEffect(() => {
		const handleScroll = () => {
			let prevTab = 0
			for (let i = 0; i < tabs.length; i++) {
				const element = document.getElementById(tabs[i].value)
				const rect = element.getBoundingClientRect()
				const isLastTab = i === tabs.length - 1
				if (isLastTab ? rect.bottom <= window.innerHeight : rect.top >= 0 && rect.top <= 500) {
					setActiveTab(i)
					if (activeTab !== prevActiveTab.current) {
						scrollToActiveTab(i, tabs[i].value)
						prevActiveTab.current = activeTab
					}
					break
				} else if (rect.top > 500) {
					if (i > 0) {
						setActiveTab(i-1)
						if (activeTab !== prevActiveTab.current) {
							scrollToActiveTab(i-1, tabs[i-1].value)
							prevActiveTab.current = activeTab
						}
						break
					} else {
						setActiveTab(i)
						if (activeTab !== prevActiveTab.current) {
							scrollToActiveTab(i, tabs[i].value)
							prevActiveTab.current = activeTab
						}
						break
					}
				}
				prevTab = i
			}
		}
	  
		window.addEventListener('scroll', handleScroll)
	  
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [tabs, activeTab])

	const scrollToActiveTab = (index, value) => {
		const container = document.getElementById('tabs-container')
		const tab = document.getElementById(`${activeTab === index && 'tabs-container'} ${value}`)
		
		if (container && tab) {
			const tabLeftOffset = tab.offsetLeft
			const containerHalfWidth = container.clientWidth / 2
			const tabHalfWidth = tab.clientWidth / 2

			let scrollPosition = tabLeftOffset - containerHalfWidth + tabHalfWidth
			const maxScroll = container.scrollWidth - container.clientWidth
			scrollPosition = Math.min(maxScroll, Math.max(0, scrollPosition))

		  	container.scrollLeft = scrollPosition
		}
	}
	  

	const AllTabs = tabs.map((t, index) => {
		const isObject = typeof t === 'object'
		let name = isObject ? t.label : t
		let value = isObject ? t.value : t
		let icon = isObject ? t.icon : null
		return (
			<div
				key={`${value}-${index}`}
				className={`relative ${tabClassName} ${
					activeTab === index && 'border-b-2 border-red-300'
				}`}
				id={`${activeTab === index && 'tabs-container'} ${value}`}
				onClick={(e) => {
					setActiveTab(() => {
						// handleTabClick(index, value)
						onChange(value)
						handleSelection(value)
						scrollToActiveTab(index, value)
						return index
					})
				}}
			>
				{icon && (
					<div className='w-6 sm:w-auto inline-block'>
						{React.cloneElement(icon, { className: 'mr-2' })}
					</div>
				)}
				{name}
			</div>
		)
	})

	return (
		<div
			className={finalClass}
			{...newProps}
			id='tabs-container'
		>
			<div className='relative flex'>
				{AllTabs}
			</div>
		</div>
	)
}

export default SlidingTabs