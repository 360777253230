import React, { useEffect, useState } from 'react'
import { getUser, updateUser, getMembershipSavings } from '../../store/actions'
import { connect } from 'react-redux'
import { Button, BounceLoaderCentered } from '../Elements'
import { useNavigate } from 'react-router-dom'

const MembershipCard = (state) => {
	const {
		user,
		onGetUser,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		onGetMembershipSavings,
		savings,
		loading,
		appInfo,
	} = state
	const [existingMember, setExistingMember] = useState(false)
	const [tierName, setTierName] = useState('')
	let navigate = useNavigate()
	const dateOptions = { month: 'short', day: 'numeric' }

	useEffect(() => {
		if (user && user.phone) {
			onGetMembershipSavings(user.phone)
		}
	}, [user?.phone])

	useEffect(() => {
		if (userUpdateCompleted) {
			onGetUser()
		}
	}, [userUpdateCompleted])

	useEffect(() => {
		if (user?.dzMemberSubscription?.enabled) {
			setExistingMember(true)
		}
	}, [user])

	useEffect(() => {
		if (user?.dzMemberSubscription?.tierSlug) {
			const tierSlug = user.dzMemberSubscription.tierSlug
			const tierName = appInfo?.dzMembershipTiers?.[tierSlug]?.name || ''
			setTierName(tierName)
		}
	}, [user, appInfo])

	return (
		<div className='w-full h-auto pl-8 mb-20'>
			<div className='w-full mr-7'>
				{!user && <BounceLoaderCentered container='div' />}
				{existingMember && appInfo ? (
					<div className='mt-3 overflow-visible'>
						<div className='my-3 text-md lg:text-[18px] font-bold italic'>
							{tierName} member since{' '}
							{user.dzMemberSubscription.memberSince.toLocaleDateString(
								'en-US',
								dateOptions
							)}
						</div>
						<div className='mt-3 mb-2 text-md lg:text-[18px]'>
							So far you've saved
						</div>
						{savings && !loading ? (
							<div className='flex flex-col'>
								<div className='flex flex-row my-3'>
									<div className='text-5xl font-header w-min lg:pb-1 mr-3 text-green-600'>
										${savings.thisMonth || 0}
									</div>
									<div className='text-md lg:text-[18px] my-auto w-full'>
										this month
									</div>
								</div>
								<div className='flex flex-row my-3'>
									<div className='text-5xl font-header w-min lg:pb-1 mr-3 text-blue-600'>
										${savings.allTime || 0}
									</div>
									<div className='text-md lg:text-[18px] my-auto w-full mr-3'>
										overall as a subscribed member
									</div>
								</div>
							</div>
						) : (
							<div className='mt-3 text-center'>
								{JSON.stringify(savings)}
								<BounceLoaderCentered container='div' />
							</div>
						)}
						{/* <div className='my-3 text-md lg:text-[18px] overflow-hidden'>
							Tile/tracker to prizes: (!!UPDATE!!)
						</div> */}
						{/* {user.dzMemberSubscription.uberPickupPromos &&
							user.dzMemberSubscription.uberPickupPromos.length >
								0 && (
								<div className='my-3 text-md lg:text-[18px] font-bold'>
									Remaining On Demand Pickup Codes:
									<div>
										{user.dzMemberSubscription.uberPickupPromos.map(
											(promo, index) => (
												<div
													key={index}
													className='text-green-600 font-bold my-2 ml-3'
												>
													{promo}
												</div>
											)
										)}
									</div>
								</div>
							)} */}

						<Button
							text='DeliverZero+ Shop'
							color='green'
							size='sm'
							className='flex w-[210px] mt-3'
							onClick={() => {
								navigate('/membership/join')
							}}
						/>
					</div>
				) : (
					<div className='mt-3 overflow-visible'>
						<div className='flex flex-col justify-start mb-3 lg:mb-12 lg:mt-0'>
							<div className='text-sm w-[90%] text-start mb-6'>
								We think reusers deserve added benefits for
								helping save the planet. That's why we've
								launched
								<span className='font-header'>
									{` `}DeliverZero+
								</span>
								, a membership program that gives you a whole
								new way to engage with the reuse revolution.
								Join today!
								{/* Our $4.99 membership
								tier gives you a special place
								within the DeliverZero
								community, while our $12.99 tier
								is for frequent DeliverZero
								users who want more value the
								more they order in our reusable
								containers. 
								Choose the level
								that best suits your needs and
								join today! */}
							</div>
							<div className='justify-between flex flex-col sm:flex-row'>
								<Button
									text='Join'
									color='green'
									size='sm'
									className='flex w-[210px]'
									onClick={() => {
										navigate('/membership/join')
									}}
								/>
								<Button
									text='DeliverZero+ Shop'
									color='gray'
									size='sm'
									className='flex w-[210px] mt-3'
									onClick={() => {
										window.open('https://www.deliverzero.com/reusables-shop/members', '_blank');
									}}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Membership }) => ({
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	savings: Membership.savings,
	loading: Membership.loading,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetMembershipSavings: (payload) =>
		dispatch(getMembershipSavings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCard)
