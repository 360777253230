import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { getUser, updatePath } from "../../store/actions";
import { Header } from "../../components";
import { realm } from "../../helpers/realm";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

// NOTE: in each individual page, do we want to make the cards itself clickable?

const AccountLanding = (props) => {
  const { onGetUser, user, loggedIn, path, onUpdatePath } = props;
  const location = useLocation();
  let navigate = useNavigate();
  const margin = !loggedIn ? "mt-[3.25rem]" : null

  useEffect(() => {
    onGetUser();
  }, [user]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (
      currentPath !== "/login" &&
      currentPath !== "/sign-up" &&
      currentPath !== '/sign-up-b' &&
      currentPath !== "/office-ordering/login" &&
      currentPath !== "/office-ordering/sign-up"
    ) {
      onUpdatePath(currentPath);
    }
  }, [onUpdatePath]);

  const openSupport = () => {
    window.Intercom("show");
  };

  const handleLogout = () => {
    if (realm.currentUser) {
      realm.currentUser.logOut().then(() => {
        if (path == "/") {
          window.location.reload();
        } else {
          navigate("/");
          window.location.reload();
        }
      });
    }
  };

  return (
    <div className="h-screen w-screen ">
      <Header label="Account" />
      {loggedIn ? (
        <div className="mt-[3.25rem]">
          <Link to="/user/myusage">
            <div className="border-b border-gray-200 flex items-center justify-between">
              <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
                My Usage
              </p>
              <FaChevronRight className="text-green-600 mx-5" />
            </div>
          </Link>
          <Link to="/user/environmentalimpact">
            <div className="border-b border-gray-200 flex items-center justify-between">
              <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
                Environmental Impact
              </p>
              <FaChevronRight className="text-green-600 mx-5" />
            </div>
          </Link>
          <Link to="/user/dzdirectorders">
            <div className="border-b border-gray-200 flex items-center justify-between">
              <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
                DeliverZero Direct Orders
              </p>
              <FaChevronRight className="text-green-600 mx-5" />
            </div>
          </Link>
          <Link to="/user/profileinfo">
            <div className="border-b border-gray-200 flex items-center justify-between">
              <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
                Profile Info
              </p>
              <FaChevronRight className="text-green-600 mx-5" />
            </div>
          </Link>
          <Link to="/giftCard">
            <div className="border-b border-gray-200 flex items-center justify-between">
              <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
                DeliverZero Gift Cards
              </p>
              <FaChevronRight className="text-green-600 mx-5" />
            </div>
          </Link>
        </div>
      ) : null}
      <div
        onClick={openSupport}
        className={`${margin} border-b border-gray-200 flex items-center justify-between`}
      >
        <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
          Contact Support
        </p>
        <FaChevronRight className="text-green-600 mx-5" />
      </div>
      <Link to="/howitworks">
        <div className="border-b border-gray-200 flex items-center justify-between">
          <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
            FAQ
          </p>
          <FaChevronRight className="text-green-600 mx-5" />
        </div>
      </Link>
      <Link to="/membership">
        <div className="border-b border-gray-200 flex items-center justify-between">
          <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
            DeliverZero+
          </p>
          <FaChevronRight className="text-green-600 mx-5" />
        </div>
      </Link>
      {loggedIn ? (
        <div onClick={handleLogout} className="border-b border-gray-200">
          <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
            Logout
          </p>
        </div>
      ) : (
        <div>
          <Link to="/login">
            <div className="border-b border-gray-200">
              <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
                Login
              </p>
            </div>
          </Link>
          <Link to={new Date().getMinutes() % 2 === 0 ? '/sign-up' : '/sign-up-b'}>
            <div className="border-b border-gray-200">
              <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
                Sign Up
              </p>
            </div>
          </Link>{" "}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ User, Path }) => ({
  user: User.user,
  loggedIn: User.loggedIn,
  path: Path.path,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUser: () => dispatch(getUser()),
  onUpdatePath: (path) => dispatch(updatePath(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountLanding);
