import { takeEvery, put, call } from 'redux-saga/effects'

// Login Redux States
import { SEND_RESET_PASSWORD } from './actionTypes'
import { sendResetPasswordError, sendResetPasswordSuccess } from './actions'
import { sendResetPassword } from '../../../model/auth'

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser(action) {
	try {
		const response = yield call(sendResetPassword, action.payload)
		yield put(sendResetPasswordSuccess('success'))
	} catch (error) {
		try {
			//if original try didn't work, try lowercasing all the letters
			const response = yield call(
				sendResetPassword,
				action.payload.toLowerCase()
			)
			yield put(sendResetPasswordSuccess('success'))
		} catch (error) {
			yield put(sendResetPasswordError(error))
		}
	}
}

export function* forgetPasswordSaga() {
	yield takeEvery(SEND_RESET_PASSWORD, forgetUser)
}

export default forgetPasswordSaga
