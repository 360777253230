import React, { useEffect } from 'react'
import { Button } from '../../components/Elements'

const NodeMenuItem = ({ category, handleAddProduct, handleOpenPopUp }) => {
	return (
		<div className='!mt-4 md:m-4 lg:mx-12'>
			<h1
				className='font-header text-green-600 text-2xl md:text-3xl ml-3'
				id={category.categoryName}
			>
				{category.categoryName}
			</h1>
			<div className='lg:grid lg:grid-cols-2 lg:gap-4'>
				{category.items.map((item, index) => {
					{
						if (item.status.saleStatus == 'FOR_SALE')
							return (
								<div className='mb-2 md:mb-0 border-t-2 md:border-0' key={index}>
									<div
										className='flex flex-col flex-1 items-center justify-center mt-4 pt-0 px-3 md:p-4 rounded-lg cursor-pointer text-ellipsis'
										onClick={() => handleOpenPopUp(item)}
										key={item.id}
									>
										<div className='flex flex-row w-full h-full'>
											<div className={`flex flex-col items-start justify-between h-32 ${item.pictures.length > 0 ? 'w-2/3' : 'w-full relative'}`}>
												<div className={`flex justify-between items-center mb-2 ${item.pictures.length > 0 ? 'w-full' : 'w-2/3'}`}>
													<p className='text-sm font-bold inline'>
														{item.name}
													</p>
													<p className='text-sm inline md:hidden mx-2 font-semibold'>
														{new Intl.NumberFormat(
															'en-US',
															{
																style: 'currency',
																currency:
																	item.price
																		.currencyCode,
															}
														).format(item.price.amount)}
													</p>
												</div>
													<p className='text-sm w-full text-ellipsis overflow-auto no-scrollbar h-32'>
														{item.description || ''}
													</p>
												<div className='flex w-full lg:w-[2] flex-col md:flex-row items-end md:items-center justify-end md:justify-between mt-2'>
													<p className='hidden md:inline-block text-sm font-semibold'>
														{new Intl.NumberFormat(
															'en-US',
															{
																style: 'currency',
																currency:
																	item.price
																		.currencyCode,
															}
														).format(item.price.amount)}
													</p>
												</div>
												<div className={`absolute bottom-2 right-0 flex justify-center ${item.pictures.length > 0 ? 'hidden' : 'w-1/3'}`}>
													{item.modifiers.length > 0 ? (
														<Button
															text='Customize'
															color='green-white-fill'
															size='xs'
															className='w-30 lg:px-6 px-2'
															onClick={() =>
																handleOpenPopUp(item)
															}
														/>
													) : (
														<Button
															text='Add to Cart'
															color='green-white-fill'
															size='xs'
															className='w-30 lg:px-6 px-2'
															onClick={(e) =>{
																e.stopPropagation();
																handleAddProduct(item)
															}}

														/>
													)}
												</div>
											</div>
											<div className={`flex items-end justify-center relative ${item.pictures.length > 0 ? 'w-1/3' : 'hidden'} h-32`}>
											{item.pictures.length > 0 && (
												<div className=''>
													<img
														className='h-32 w-32 object-cover rounded-lg'
														src={item.pictures[0].url}
														alt=''
													/>
												</div>
											)}
												<div className='absolute bottom-2 left-0 right-0 flex justify-center'>
													{item.modifiers.length > 0 ? (
														<Button
															text='Customize'
															color='green-white-fill'
															size='xs'
															className='w-30 lg:px-6 px-2'
															onClick={() =>
																handleOpenPopUp(item)
															}
														/>
													) : (
														<Button
															text='Add to Cart'
															color='green-white-fill'
															size='xs'
															className='w-30 lg:px-6 px-2'
															onClick={(e) =>{
																e.stopPropagation();
																handleAddProduct(item)
															}}

														/>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							)
					}
				})}
			</div>
		</div>
	)
}

export default NodeMenuItem
