import { realm } from '../helpers/realm'

export const getContainers = async (filter) => {
    if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const containers = client.db('caas').collection('containers')
    return containers.find({ $and: filter }, { $limit: 100 })
}

export const getContainerById = async (id) => {
    if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const containers = client.db('caas').collection('containers')
	const container = await containers.findOne({ container_id: id })
	return container
}
