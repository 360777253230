import { GET_FAQ, GET_FAQ_SUCCESS, GET_FAQ_FAIL } from './actionTypes'

const initialState = {
	faq: [],
	error: null,
	loadingFAQ: false,
}

const faq = (state = initialState, action) => {
	switch (action.type) {
		case GET_FAQ:
			return {
				...state,
				loadingFAQ: true,
			}

		case GET_FAQ_SUCCESS:
			return {
				...state,
				loadingFAQ: false,
				faq: action.payload,
			}

		case GET_FAQ_FAIL:
			return {
				...state,
				loadingFAQ: false,
				error: action.payload,
			}

		default:
			return state
	}
}

export default faq
