import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
	Button,
	FormGroup,
	Input,
	RadioGroup, SelectGroup,
} from '../../components/Elements'
import { BounceLoader } from 'react-spinners'
import ReactConfetti from 'react-confetti'
import { GiPartyPopper } from 'react-icons/gi'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CreditCardForm from '../../components/Stripe/CreditCardForm'
import {
    getUser,
    createTransaction,
    chargePaymentMethod,
	chargeGuestPayment,
	storeGuestPayment,
	generateGiftCard,
	getPaymentMethods,
} from '../../store/actions'
import { realm } from '../../helpers/realm'

const GiftCardForm = (props) => {
	const {
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		loggedIn,
		onCreateTransaction,
		transaction,
		creatingTransaction,
		onChargeCustomer,
		onChargeGuest,
		onStorePaymentGuest,
		chargingCard,
		chargingCardResponse,
		chargingCardError,
		cardAdded,
		loadingAddition,
		paymentMethods,
		paymentError,
		onGetUser,
		onGenerateGiftCard,
		loading,
		promoError,
		giftCard,
		promoMessageType,
		onGetPaymentMethods,
	} = props
	const bounceLoaderColor = '#507f74'
	const [showSuccess, setShowSuccess] = useState(false)
	const [showSubmitWarning, setShowSubmitWarning] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
	const [cardRadios, setCardRadios] = useState([])
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
	const [form, setForm] = useState({
		amount: 25,
		quantity: 1,
		email: '',
		paymentMethodId: '',
		total: 25,
	})
	const [addCardError, setAddCardError] = useState(false)
	const [stripeLoading, setStripeLoading] = useState(false)
	const elements = useElements()
	const stripe = useStripe()
	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}

	const currentDate = new Date();

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	const submitTransaction = (giftCardResponse) => {
		let transactionPayload;
		if	(!loggedIn) {
			transactionPayload = {
				timestamp: currentDate,
				type: 'giftCard',
				quantity: form.quantity,
				amount: form.amount,
				user: {
					email: form.email,
				},
				giftCard: giftCardResponse,
			}
		} else {
			transactionPayload = {
				timestamp: currentDate,
				type: 'giftCard',
				quantity: form.quantity,
				amount: form.amount,
				user: {
					phone: user.phone,
					email: user.email,
					firstName: user.firstName,
					lastName: user.lastName,
				},
				giftCard: giftCardResponse,
			}
		}

		onCreateTransaction(transactionPayload)
	}

	useEffect(() => {
		if (transaction) {
			setShowSuccess(true)
		}
	}, [transaction])

	useEffect(() => {
		if (user && user.stripeId) {
			onGetPaymentMethods(user.stripeId)
		}
	}, [cardAdded, paymentError])

	const submitHandler = (e) => {
		e.preventDefault()
		setErrorMessage('')
		if (selectedPaymentMethod !== '' && form.email !== '' && typeof form.amount === 'number' && !isNaN(form.amount) && form.amount > 0) {
			onChargeCustomer({
				userId: user.phone,
				paymentMethodId: selectedPaymentMethod,
				totalChargeAmount: form.amount,
				type: 'giftCard',
			})
		} else if (selectedPaymentMethod === '') {
			setErrorMessage('Please select a card')
		} else {
			validateFormFields()
		}
	}

	const submitHandlerGuest = async (e) => {
		e.preventDefault()
		setErrorMessage('')
		if (selectedPaymentMethod !== '' && form.name !== '' && form.email !== '' && typeof form.amount === 'number' && !isNaN(form.amount) && form.amount > 0) {
			onChargeGuest({
				email: form.email,
				phone: form.phone,
				paymentMethodId: selectedPaymentMethod,
				totalChargeAmount: form.amount,
				type: 'giftCard_guest',
			})
		} else if (selectedPaymentMethod === '') {
			setErrorMessage('Please select a card')
		} else {
			validateFormFields()
		}
	}

	const handleAddCardGuest = async (e) => {
		e.preventDefault()
		setErrorMessage('')
		if(checkExistingDzUser()){
			if(validateFormFields()) {
				setStripeLoading(true)
				e.preventDefault()
				setAddCardError(false)
				if (elements == null) {
					return
				}

				const result = await stripe.createPaymentMethod({
					type: 'card',
					card: elements.getElement(CardElement),
				})
				
				if (result.error) {
					setStripeLoading(false)
					setAddCardError(true)
				} else {
					setStripeLoading(false)
					// console.log("result.paymentMethod.id", result.paymentMethod.id)
					onStorePaymentGuest({
						email: form.email,
						phone: form.phone,
						paymentMethodId: result.paymentMethod.id,
					})
					setSelectedPaymentMethod(result.paymentMethod.id)
				}
			}
		}
	}

	const checkExistingDzUser = async () => {
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const userDoc = await users.findOne({ email: form.email })
		if (userDoc) {
			return true
		} else {
			return false
		}
	}

	const validateFormFields = () => {
		if (form.email === '' || !form.email.includes('@')) {
			setErrorMessage('Please enter a valid email address')
		} else {
			setErrorMessage('')
		}
		if (!loggedIn && form.name === '') {
			setErrorMessage('Please enter your name so the recipient knows who this gift card is from!')
		} else {
			setErrorMessage('')
		}
		// if (form.quantity < 1 || form.amount < 0 || isNaN(form.amount) || typeof form.amount !== 'number') {
		if (form.amount < 0 || isNaN(form.amount) || typeof form.amount !== 'number') {
			setErrorMessage('Please enter a quantity greater than 1')
		} else {
			setErrorMessage('')
		}
		if(form.email!=="" && form.email.includes('@')){
			return true
		} else {
			return false
		}
	}

	useEffect(() => {
		validateFormFields()
	  }, [form.email, form.quantity, form.amount])

	useEffect(() => {
		setErrorMessage('')
		if (chargingCardResponse==="success") {
			if (loggedIn) {
				onGenerateGiftCard({
					amount: form.amount,
					quantity: form.quantity,
					email: form.email,
					userEmail: user ? user.email : null,
					stripeCustomerId: user.stripeId,
					paymentMethodId: selectedPaymentMethod,
				})
			} else {
				onGenerateGiftCard({
					amount: form.amount,
					quantity: form.quantity,
					email: form.email,
					name: form.name,
					stripeCustomerId: user.stripeId,
					paymentMethodId: selectedPaymentMethod,
				})
			}
		} else if (chargingCardResponse==="error"){
			setErrorMessage('Error while charging your card!')
		}
	}, [chargingCardResponse])

	useEffect(() => {
		setErrorMessage('')
		if (giftCard && giftCard.length > 0) {
			let cardIds = []
			for (const card of giftCard) {
				cardIds.push(card.giftcardCode)
			}
			submitTransaction(cardIds)
		} 
	}, [giftCard])

	useEffect(() => {
		if (chargingCardError) {
			setErrorMessage('Error while charging your card!')
		}
	}, [chargingCardError])

	useEffect(() => {
		if (user && user.paymentMethods) {
			makeCardRadios(user.paymentMethods)
		}
	}, [user?.paymentMethods, JSON.stringify(user?.paymentMethods), paymentMethods])

	useEffect(() => {
		if (user && user.stripeId) {
			onGetPaymentMethods(user.stripeId)
		}
	}, [user?.stripeId])

	const makeCardRadios = (paymentMethods) => {
		let radios = []
		paymentMethods.forEach(function (paymentMethod, index) {
			radios.push({
				value: paymentMethod.id,
				label: `${paymentMethod.card.brand.toUpperCase()} ....${
					paymentMethod.card.last4
				} - Exp. ${paymentMethod.card.exp_month}/${
					paymentMethod.card.exp_year
				}`,
				checked: index === 0,
			})
			if (index === 0) {
				setSelectedPaymentMethod(paymentMethod.id)
			}
		})
		setCardRadios(radios)
	}

	if (showSuccess) {
        return (
            <div className='w-full h-full lg:h-screen-3/4 flex flex-col justify-start items-center overflow-hidden'>
                <ReactConfetti
                    className='w-full'
                    colors={['#f38b74']}
                />
                <h1 className='font-vollkorn text-green-600 text-4xl text-center'>
                    Gift card purchased!
                </h1>
                <GiPartyPopper className='text-orange-600 text-[250px]'/>
                <Button
                    link='/user/account'
                    className='px-10 w-[260px] mt-5 shadow-light-grey'
                    text='View My Account'
                />
            </div>
        )
    } else {
		return (
			<div className='w-full flex flex-col items-center justify-start min-h-screen-no-header-mobile'>
				<FormGroup className='w-full sm:w-[400px] flex flex-col'>
					<div className='w-full px-2 mb-5'>
						<div className='font-header text-xl font-bold text-start ml-5'>
							<label htmlFor='email'>Recipient's Email</label>
						</div>
						<p className='text-xs text-start ml-5'>
								Enter the gift card recipient's email below
						</p>
						<Input
							type='string'
							name='email'
							defaultValue=''
							className='h-12 text-md'
							onChange={(e) =>
								setForm({
									...form,
									email: e.target.value,
								})
							}
						/>
					</div>
					{!loggedIn && (
						<>
						<div className='w-full px-2 mb-2'>
							<div className='font-header text-xl font-bold text-start ml-5'>
								<label htmlFor='name'>Your Name</label>
							</div>
							<p className='text-xs text-start ml-5'>
								Let them know it's from you – enter your name below
							</p>
							<Input
								type='string'
								name='name'
								defaultValue=''
								className='h-12 text-md'
								onChange={(e) =>
									setForm({
										...form,
										name: e.target.value,
									})
								}
							/>
						</div>
						</>
					)}
					<div className=' w-full px-2 mb-5'>
						<div className='font-header text-xl font-bold text-start ml-5'>
							<label htmlFor='amount'>Amount</label>
						</div>
						<SelectGroup
							name='amount'
							className='h-12'
							onChange={(e) =>
								setForm({
									...form,
									amount: parseInt(e.target.value),
									// total: parseInt(e.target.value) * form.quantity
									total: parseInt(e.target.value)
								})
							}
							options={[
								// { label: '$1', value: 1 },
								{ label: '$25', value: 25 },
								{ label: '$50', value: 50 },
								{ label: '$75', value: 75 },
								{ label: '$100', value: 100 },
							]}
						/>
					</div>
					{/* <div className=' w-full px-2 md:mb-1'>
						<Input
							type='number'
							name='quantity'
							label='Quantity'
							defaultValue='1'
							min='1'
							className='h-12'
							onChange={(e) => {
								setForm({
									...form,
									quantity: parseInt(e.target.value),
									total: parseInt(e.target.value) * form.amount
								})
							}}
						/>
					</div> */}
					<div className='w-full'>
						<div className='px-2 mb-2 mx-auto'>
							<div className='font-header text-xl font-bold text-start ml-5'>
								Payment
							</div>
						</div>
						{!loggedIn ? (
							<form onSubmit={handleAddCardGuest} className='w-full mx-auto'>
								<CardElement options={cardElementOptions} />
								{cardAdded ? (<div className='text-green-600 font-bold'>Card successfully added!</div>) : (<div></div>)}
								{paymentError ? (<div className='text-red-600 font-bold'>There was an error adding your card. Please try again.</div>) : (<div></div>)}
								{!loadingAddition && !stripeLoading && !loadingUser && (
									<div>
										<Button
											className={`mt-2 px-2`}
											text='Add Card'
											size='sm'
											submit={true}
										/>
									</div>
								)}
								{loadingAddition && (
									<div>
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									</div>
								)}
								{stripeLoading && (
									<div>
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									</div>
								)}
								{loadingUser && (
									<div>
										<BounceLoader
											className='m-auto'
											color={bounceLoaderColor}
										></BounceLoader>
									</div>
								)}
							</form>
						) : (
							user && user.paymentMethods && user.paymentMethods.length === 0 ? (
								<div className='w-full my-2 mx-auto'>
									<Elements
										stripe={
											stripePromise
										}
									>
										<CreditCardForm />
									</Elements>
								</div>
							) : (
								<>
								<div className='w-full my-2 mx-auto'>
								<Elements
									stripe={
										stripePromise
									}
								>
									<CreditCardForm />
								</Elements>
								</div>
								<div className='flex justify-center items-center text-left mt-3 mb-4 mx-auto'>
									{cardRadios.length > 0 && (
										<div>
											<RadioGroup
												className='mt-1 sm:mt-0'
												name='sortBy'
												radios={
													cardRadios
												}
												onChange={(
													paymentMethodId
												) => {
													setSelectedPaymentMethod(
														paymentMethodId
													)
												}}
											/>
										</div>
									)}
								</div>
								</>
							) 
						)}
					</div>
					<div className='w-full px-2 my-3 flex flex-row justify-center items-center mx-auto'>
						<div className='font-header text-xl font-bold text-start ml-5'>
							<label htmlFor='quantity'>Total:</label>
						</div>
						<div className='text-green-600 font-bold ml-3 text-xl text-start'>
							{/* ${form.total ? form.total : form.amount * form.quantity} */}
							${form.total ? form.total : form.amount}
						</div>
					</div>
					{(creatingTransaction || chargingCard || loading) ? (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					) : (loggedIn ? (
							<div>
								{(form.email === '' || selectedPaymentMethod === '' || errorMessage !== '')? (
									<>
										<Button
											text='Submit Payment'
											size='sm'
											color='disabled'
											onClick={() => {setShowSubmitWarning(true)}}
											className='mx-auto px-2 my-5'
										/>
										<p className='text-xs text-start ml-5'>
											You will recieve an email confirmation once your payment has been processed.
										</p>
									</>
								) : (
									<>
										<Button
											text='Submit Payment'
											size='sm'
											color='green'
											onClick={(e) =>
												submitHandler(e)
											}
											className='mx-auto px-2 my-5'
										/>
										<p className='text-xs text-start ml-5'>
											You will recieve an email confirmation once your payment has been processed.
										</p>
									</>
								)}
							</div>
						) : (
							<div>
								{(form.email === '' ||
								isNaN(form.amount) || typeof form.amount !== 'number' || form.quantity === '' || selectedPaymentMethod === '') ? (
									<Button
										text='Submit Payment'
										size='sm'
										color='disabled'
										onClick={() => {setShowSubmitWarning(true)}}
										className='mx-auto px-2 my-5'
									/>
								) : (
									<Button
										text='Submit Payment'
										size='sm'
										color='green'
										onClick={(e) =>
											submitHandlerGuest(e)
										}
										className='mx-auto px-2 my-5'
									/>
								)}
							</div>
						)
					)}
					{errorMessage !== '' && (
						<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
							{errorMessage}
						</div>
					)}
					{promoError !== '' && (
						<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
							{promoError}
						</div>
					)}
					{showSubmitWarning && errorMessage === '' && (
						(!loggedIn || (user && user.paymentMethods && user.paymentMethods.length === 0)) ? (
							<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
								Please fill out all form fields and "Add Card" to verify your payment method before submitting
							</div>
						) : (
							<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
								Please fill out all form fields and add or select a payment method before submitting
							</div>
						)
					)}
				</FormGroup>
			</div>
		)
	} 
}

const mapStateToProps = ({
	User,
	TransactionsCreate,
	Payments,
	Promos
}) => ({
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	loggedIn: User.loggedIn,
	transaction: TransactionsCreate.transactionResult,
	createTransactionError: TransactionsCreate.error,
	creatingTransaction: TransactionsCreate.creating,
	chargingCard: Payments.chargingCard,
	chargingCardError: Payments.chargingCardError,
	chargingCardResponse: Payments.chargingCardResponse,
	paymentMethods: Payments.paymentMethods,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	paymentError: Payments.error,
	loading: Promos.loading,
	giftCard: Promos.giftCard,
	promoMessageType: Promos.promoMessageType,
	promoError: Promos.promoError,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
	onChargeCustomer: (payload) => dispatch(chargePaymentMethod(payload)),
	onStorePaymentGuest: (payload) => dispatch(storeGuestPayment(payload)),
	onChargeGuest: (payload) => dispatch(chargeGuestPayment(payload)),
	onGetPaymentMethods: (stripeUserId) => dispatch(getPaymentMethods(stripeUserId)),
	onGenerateGiftCard: (payload) => dispatch(generateGiftCard(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardForm)