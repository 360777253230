import { all, fork } from "redux-saga/effects";

import AppInfoSaga from "./appInfo/saga";
import AuthSaga from "./auth/login/saga";
import CartSaga from "./cart/saga";
import FAQSaga from "./faq/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import FranchiseeSaga from "./franchisee/saga";
import HowItWorksSaga from "./howItWorks/saga";
import LocationSaga from "./location/saga";
import LoginSaga from "./auth/login/saga";
import MenuSaga from "./menu/saga";
import NodesSaga from "./nodes/saga";
import OAuthSaga from "./auth/oauth/saga";
import OrdersSaga from "./orders/saga";
import PathSaga from "./path/saga";
import PaymentsSaga from "./payments/saga";
import PlatformsSaga from "./platforms/saga";
import RestaurantPaymentsSaga from "./restaurantPayments/saga";
import RegisterSaga from "./auth/register/saga";
import ReverseLogisticsSaga from "./reverseLogistics/saga";
import SubscriptionsSaga from "./subscriptions/saga";
import TransactionsCreateSaga from "./transactions/create/saga";
import TransactionsSaga from "./transactions/saga";
import UserSaga from "./auth/user/saga";
import SmsVerifySaga from "./auth/smsVerify/saga";
import AdminSaga from "./admin/saga";
import BoxCountLookUpSaga from "./boxCountLookUp/saga";
import PromosSaga from "./promos/saga";
import NotificationsSaga from "./notifications/saga";
import RAASSaga from "./raas/saga";
import AllRewardsSaga from "./rewards/saga";
import OfficeOrderingSaga from "./officeOrdering/saga";
import AggregationSaga from "./aggregation/saga";
import ContainersSaga from "./containers/saga";
import LogisticsSaga from "./logistics/saga";
import LeadsSaga from "./leads/saga";
import DropoffsSaga from "./dropoffs/saga";
import PickupsSaga from "./pickups/saga";
import MembershipSaga from "./membership/saga";

export default function* rootSaga() {
    yield all([
        AppInfoSaga(),
        AdminSaga(),
        BoxCountLookUpSaga(),
        fork(AuthSaga),
        CartSaga(),
        FAQSaga(),
        ForgetSaga(),
        FranchiseeSaga(),
        HowItWorksSaga(),
        MenuSaga(),
        LocationSaga(),
        LoginSaga(),
        NodesSaga(),
        OAuthSaga(),
        OrdersSaga(),
        PathSaga(),
        PaymentsSaga(),
        PlatformsSaga(),
        PromosSaga(),
        RestaurantPaymentsSaga(),
        RegisterSaga(),
        ReverseLogisticsSaga(),
        SubscriptionsSaga(),
        TransactionsSaga(),
        TransactionsCreateSaga(),
        UserSaga(),
        SmsVerifySaga(),
        NotificationsSaga(),
        RAASSaga(),
        AllRewardsSaga(),
        OfficeOrderingSaga(),
        AggregationSaga(),
        ContainersSaga(),
        LogisticsSaga(),
        LeadsSaga(),
        DropoffsSaga(),
        PickupsSaga(),
        MembershipSaga(),
    ]);
}
