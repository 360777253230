import { realm } from '../helpers/realm'
import { BSON } from 'realm-web'

export const getOrders = (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const orders = client.db('caas').collection('orders')

	const { filter, options } = payload
	const { sort, skip, limit } = options
	const page = skip / limit

	const stages = [
		{
			$lookup: {
				from: 'nodes',
				localField: 'nodeSlug',
				foreignField: 'slug',
				as: 'nodeInfo',
			},
		},
		{
			$unwind: {
				path: '$nodeInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
		{
			$lookup: {
				from: 'users',
				localField: 'userId',
				foreignField: 'userId',
				as: 'userInfo',
			},
		},
		{
			$unwind: {
				path: '$userInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
	]

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}
	stages.push({
		$facet: {
			pagination: [
				{
					$count: 'total',
				},
				{
					$addFields: { page, limit },
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})
	return orders.aggregate(stages)
}

export const getOneOrder = async (orderId) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const orders = client.db('caas').collection('orders')
	const order = await orders.findOne({
		_id: BSON.ObjectId(orderId),
		userId: realm.currentUser.id,
	})
	return order
}

export const adminGetOneOrder = async (orderId) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const orders = client.db('caas').collection('orders')
	const order = await orders.findOne({ _id: BSON.ObjectId(orderId) })
	return order
}

export const submitOrder = async (submitOrderPayload) => {
	const submittedOrder = await realm.currentUser.functions.dzOrderHandler(
		submitOrderPayload
	)

	return submittedOrder
}
