/* PAGE DESCRIPTION: 
        Rewards page that allows users to exchange rewards for discounts at participating merchants 
*/

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Header, Footer } from "../../components";
import { Button } from "../../components/Elements";
import { BounceLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import RewardsTile from "../../components/Marketplace/RewardsTile";
import { getUser } from "../../store/actions";
import { getAllRewards } from "../../store/actions";

const RewardsMarketplace = (props) => {
  const { user, onGetUser, allRewards, onGetAllRewards, updatedUser } = props;

  const bounceLoaderColor = "#507f74";

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [totalRewardPts, setTotalRewardPts] = useState(0);
  const [allRewardsInfo, setAllRewardsInfo] = useState([]);

  const getAllRewards = () => {
    onGetAllRewards({
      filter: {},
      options: {},
    });
  };

  useEffect(() => {
    onGetUser();
  }, [onGetUser, updatedUser]);

  useEffect(() => {
    getAllRewards();
  }, [onGetAllRewards, updatedUser]);

  useEffect(() => {
    if (user) {
      setTotalRewardPts(user?.rewardPoints);
    }
  }, [user, user?.rewardPoints]);

  useEffect(() => {
    setLoading(false);
    setAllRewardsInfo(allRewards);
  }, [allRewards]);

  if (!user)
    return (
      <div className="h-screen w-screen">
        <Header label={"Rewards Marketplace"} />
        <div className="flex items-center h-screen mt-[3.25rem]">
          <BounceLoader
            className="m-auto"
            color={bounceLoaderColor}
          ></BounceLoader>
        </div>
      </div>
    );
  else {
    return (
      <div className="h-screen w-screen">
        <Header label={"Rewards Marketplace"} />
        <div className="flex flex-col justify-center items-center text-center mx-2 mt-[3.25rem]">
          <h2 className="text-lg sm:text-xl md:text-2xl font-body w-full text-center mt-6 px-2">
            Exchange your rewards for discounts to our participating merchants!
          </h2>
          {user.userId ? (
            <h1 className="text-xl md:text-3xl font-body text-black text-center w-full mb-8 mt-8 px-2">
              You currently have{" "}
              <span className="text-green-600 font-header">
                {" "}
                {totalRewardPts}
              </span>{" "}
              reward points
            </h1>
          ) : (
            <a
              className="whitespace-wrap text-3xl font-body text-green-600 underline cursor-pointer mb-8 mt-8"
              rel="noreferrer"
              onClick={() => navigate("/login")}
            >
              Login to Redeem
            </a>
          )}
        </div>
        <div
          className={
            "grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3 xl:grid-cols-3 place-items-center ml-12 mr-12 mb-20 pb-[80px]"
          }
        >
          {allRewardsInfo.length === 0 || loading ? (
            <BounceLoader
              className="m-auto"
              color={bounceLoaderColor}
            ></BounceLoader>
          ) : (
            allRewardsInfo.map((reward, index) => {
              return (
                <RewardsTile
                  brandName={reward.brand}
                  brandDescription={reward.description}
                  rewardCaption={reward.caption}
                  rewardPoints={reward.points}
                  imgSrc={reward.imageSrc}
                  discountCodes={reward.availableCodes}
                  rewardURL={reward.rewardURL}
                  user={user}
                  expirationDate={reward.expirationDate.toLocaleDateString()}
                  key={index}
                  nonUnique={reward.nonUnique ? true : false}
                  redeemedUsers={
                    reward.redeemedUsers ? reward.redeemedUsers : []
                  }
                />
              );
            })
          )}
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ User, AllRewards }) => ({
  user: User.user,
  allRewards: AllRewards.allRewards,
  updatedUser: AllRewards.updatedUser,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUser: () => dispatch(getUser()),
  onGetAllRewards: (payload) => dispatch(getAllRewards(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsMarketplace);
