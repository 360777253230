import {
	MEMBERSHIP_SIGNUP,
	MEMBERSHIP_SIGNUP_FAIL,
	MEMBERSHIP_SIGNUP_SUCCESS,
	GET_MEMBERSHIP_SAVINGS,
	GET_MEMBERSHIP_SAVINGS_FAIL,
	GET_MEMBERSHIP_SAVINGS_SUCCESS,
	GET_MEMBERS,
	GET_MEMBERS_FAIL,
	GET_MEMBERS_SUCCESS,
	RESET_MEMBERSHIP,
} from './actionTypes'

const initialState = {
	error: null,
	loading: false,
	memberRegistered: null,
	savings: null,
	members: [],
	membersCount: null,
}

const reusableProducts = (state = initialState, action) => {
	switch (action.type) {
		case MEMBERSHIP_SIGNUP:
			return {
				...state,
				loading: true,
				error: null,
			}
		case MEMBERSHIP_SIGNUP_SUCCESS:
			return {
				...state,
				loading: false,
				memberRegistered: action.payload,
			}
		case MEMBERSHIP_SIGNUP_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case GET_MEMBERSHIP_SAVINGS:
			return {
				...state,
				loading: true,
				error: null,
			}
		case GET_MEMBERSHIP_SAVINGS_SUCCESS:
			return {
				...state,
				loading: false,
				savings: action.payload,
			}
		case GET_MEMBERSHIP_SAVINGS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case GET_MEMBERS:
			return {
				...state,
				loading: true,
				error: null,
			}
		case GET_MEMBERS_SUCCESS:
			return {
				...state,
				loading: false,
				members: action.payload[0].data,
				membersCount: action.payload[0].pagination[0]?.total || 0,
			}
		case GET_MEMBERS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			}
		case RESET_MEMBERSHIP:
			return {
				...state,
				loading: false,
				memberRegistered: null,
				error: null,
				savings: null,
			}
		default:
			return state
	}
}

export default reusableProducts
