export const GET_ORDERS = 'GET_ORDERS'
export const GET_ONE_ORDER = 'GET_ONE_ORDER'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ONE_ORDER_SUCCESS = 'GET_ONE_ORDER_SUCCESS'
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL'
export const GET_ONE_ORDER_FAIL = 'GET_ONE_ORDER_FAIL'
export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS'
export const SUBMIT_ORDER_FAIL = 'SUBMIT_ORDER_FAIL'
export const ADMIN_GET_ONE_ORDER = 'ADMIN_GET_ONE_ORDER'
export const RESET_SUBMIT_ORDER = 'RESET_SUBMIT_ORDER'