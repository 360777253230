import { realm } from '../helpers/realm'
import { runAggregationApi } from "./aggregation"
import * as Realm from 'realm-web'

export const getUser = async () => {
	if (realm.currentUser) {
		if (realm.currentUser.providerType === 'anon-user') {
			return realm.currentUser
		}
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const user = await users.findOne({ userId: realm.currentUser.id })
		return user
	}
	return await realm.logIn(Realm.Credentials.anonymous())
}

export const updateUser = async (updateObject) => {
	if (realm.currentUser) {
		if (realm.currentUser.providerType === 'anon-user') {
			return realm.currentUser
		}
		const client = realm.currentUser.mongoClient('RealmService')
		const users = client.db('caas').collection('users')
		const update = await users.updateOne(
			{ userId: realm.currentUser.id },
			{ $set: updateObject }
		)
		const user = await users.findOne({ userId: realm.currentUser.id })
		// console.log('in user model',user)
		return user
	}
	// console.log('New anon user')
	return await realm.logIn(Realm.Credentials.anonymous())
}

export const validatePhone = async (phone) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const users = client.db('caas').collection('users')
	const user = await users.findOne({ phone: phone })

	if (user) {
		return true
	} else {
		return false
	}
}

export const updateMailchimpMergeFields = async (user) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const users = client.db('caas').collection('users')
	const thisUser =  await users.findOne({ phone: user.phone })
	const response = await realm.currentUser.callFunction(
		'mailChimp/updateUserMergeFields',
		thisUser
	)
	// console.log('New anon user')
	return response
}

export const getUserByPhone = async (payload) => {
	if (!realm.currentUser){
        const credentials = Realm.Credentials.anonymous();
        const logInResult = await realm.logIn(credentials);
    }
	const client = realm.currentUser.mongoClient('RealmService')
	const users = client.db('caas').collection('users')
	const user = await users.findOne({ phone: payload.phone, returnByTextId:payload.returnByTextId})
	return user
}

export const getUserByUserIdPhone = async (payload) => {
	if (!realm.currentUser){
        const credentials = Realm.Credentials.anonymous();
        const logInResult = await realm.logIn(credentials);
    }
	const client = realm.currentUser.mongoClient('RealmService')
	const users = client.db('caas').collection('users')
	const user = await users.findOne({ phone: payload.phone, userId: payload.userId })
	return user
}

export const updateUserByPhone = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const user = await realm.currentUser.callFunction(
		'returns/updateUserByPhone',
		payload
	)
	return user
}

export const getUserBoxCount = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	let user = {}
	const getTotalPackagingPreventedAggr = await runAggregationApi({
		phone: payload,
		functionName: 'getTotalPackagingPrevented',
	})
	user = getTotalPackagingPreventedAggr.getTotalPackagingPrevented
	return user
}

export const captureUser = async (payload) => {
	const response = await realm.currentUser.callFunction(
		'auth/captureIPRegistration',
		payload
	)
	return response
}

export const captureIP = async (payload) => {
	const response = await realm.currentUser.callFunction(
		'auth/captureIP',
		payload
	)
	return response
}