import {
	GET_USER,
	GET_USER_BOX_COUNT,
	GET_USER_BY_PHONE,
	GET_USER_BY_USERID_PHONE,
	UPDATE_MAILCHIMP_MERGE_FIELDS,
	UPDATE_USER,
	UPDATE_USER_BY_PHONE,
	VALIDATE_PHONE,
	CAPTURE_USER,
	CAPTURE_IP,
} from './actionTypes'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getUserFail,
	getUserSuccess,
	updateUserSuccess,
	validatePhoneFail,
	validatePhoneSuccess,
	updateUserFail, updateMailchimpMergeFieldsSuccess, updateMailchimpMergeFieldsFail,
	getUserBoxCountFail, getUserBoxCountSuccess, captureUserSuccess, captureUserFail,
	captureIPSuccess, captureIPFail,
} from './actions'
import { realm } from '../../../helpers/realm'
import {
	getUser,
	getUserBoxCount,
	getUserByPhone,
	getUserByUserIdPhone,
	updateMailchimpMergeFields,
	updateUser,
	updateUserByPhone,
	validatePhone,
	captureUser,
	captureIP,
} from '../../../model/user'

function* fetchUser(action) {
	try {
		const user = yield call(getUser, action.payload)
		yield put(getUserSuccess(Object.assign(realm.currentUser, user)))
	} catch (error) {
		yield put(getUserFail(error))
	}
}

function* fetchUserBoxCount(action) {
	try {
		const userBoxCount = yield call(getUserBoxCount, action.payload)
		yield put(getUserBoxCountSuccess(userBoxCount))
	} catch (error) {
		yield put(getUserBoxCountFail(error))
	}
}

function* updateToUser(action) {
	try {
		const updatedUser = yield call(updateUser, action.payload)
		// console.log('in user saga',updatedUser)
		yield put(
			updateUserSuccess(Object.assign(realm.currentUser, updatedUser))
		)
	} catch (error) {
		yield put(updateUserFail(error))
	}
}

function* checkPhone(action) {
	try {
		const validatedPhoneBool = yield call(validatePhone, action.payload)
		yield put(validatePhoneSuccess(validatedPhoneBool))
	} catch (error) {
		yield put(validatePhoneFail(error))
	}
}

function* updateToMailchimpMergeFields(action) {
	try {
		const updatedUser = yield call(updateMailchimpMergeFields, action.payload)
		yield put(updateMailchimpMergeFieldsSuccess(updatedUser))
		// console.log('in user saga',updatedUser)
	} catch (error) {
		yield put(updateMailchimpMergeFieldsFail(error))
	}
}

function* fetchUserByPhone(action) {
	try {
		const user = yield call(getUserByPhone, action.payload)
		yield put(getUserSuccess(Object.assign(realm.currentUser, user)))
	} catch (error) {
		yield put(getUserFail(error))
	}
}

function* fetchUserByUserIdPhone(action) {
	try {
		const user = yield call(getUserByUserIdPhone, action.payload)
		yield put(getUserSuccess(Object.assign(realm.currentUser, user)))
	} catch (error) {
		yield put(getUserFail(error))
	}
}

function* updateToUserByPhone(action) {
	try {
		const user = yield call(updateUserByPhone, action.payload)
		yield put(getUserSuccess(Object.assign(realm.currentUser, user)))
	} catch (error) {
		yield put(getUserFail(error))
	}
}

function* captureUserSaga(action) {
	try {
		const userIP = yield call(captureUser, action.payload)
		yield put(captureUserSuccess(userIP))
	} catch (error) {
		yield put(captureUserFail(error))
	}
}

function* captureIPSaga(action) {
	try {
		const userIP = yield call(captureIP, action.payload)
		yield put(captureIPSuccess(userIP))
	} catch (error) {
		yield put(captureIPFail(error))
	}
}

function* userSaga() {
	yield takeEvery(GET_USER, fetchUser)
	yield takeEvery(GET_USER_BOX_COUNT, fetchUserBoxCount)
	yield takeEvery(UPDATE_USER, updateToUser)
	yield takeEvery(UPDATE_USER_BY_PHONE, updateToUserByPhone)
	yield takeEvery(VALIDATE_PHONE, checkPhone)
	yield takeEvery(UPDATE_MAILCHIMP_MERGE_FIELDS, updateToMailchimpMergeFields)
	yield takeEvery(GET_USER_BY_PHONE, fetchUserByPhone)
	yield takeEvery(GET_USER_BY_USERID_PHONE, fetchUserByUserIdPhone)
	yield takeEvery(CAPTURE_USER, captureUserSaga)
	yield takeEvery(CAPTURE_IP, captureIPSaga)
}

export default userSaga
