import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ScrollToTop from './routes/middleware/ScrollToTop'

import App from './App'
import './index.css'
import store from './store'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	// eslint-disable-next-line no-undef
	<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
		<Provider store={store}>
			<BrowserRouter>
				<ScrollToTop />
				<App />
			</BrowserRouter>
		</Provider>
	</GoogleOAuthProvider>
)
