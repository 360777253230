import { call, put, takeEvery } from 'redux-saga/effects'
import { getMenu, getProduct, getDispatchAvailability  } from '../../model/menu'
import {
	getMenuFail,
	getMenuSuccess,
	getProductFail,
	getProductSuccess,
	getDispatchAvailabilitySuccess,
	getDispatchAvailabilityFail
} from './actions'

import { GET_MENU_BY_SLUG, GET_PRODUCT, GET_DELIVERECT_DISPATCH_AVAILABILITY } from './actionTypes'

function* fetchMenuBySlug(action) {
	try {
		const menu = yield call(getMenu, action.payload)
		yield put(getMenuSuccess(menu))
	} catch (error) {
		yield put(getMenuFail(error))
	}
}

function* fetchProduct(action) {
	try {
		// console.log('fetchProduct', action.payload)
		const product = yield call(getProduct, action.payload)
		// console.log(product)
		yield put(getProductSuccess(product))
	} catch (error) {
		yield put(getProductFail(error))
	}
}

function* fetchDispatchAvailability(action) {
	try {
		// console.log('fetchProduct', action.payload)
		const dispatch = yield call(getDispatchAvailability, action.payload)
		// console.log(product)
		yield put(getDispatchAvailabilitySuccess(dispatch))
	} catch (error) {
		yield put(getDispatchAvailabilityFail(error))
	}
}

function* menuSaga() {
	yield takeEvery(GET_MENU_BY_SLUG, fetchMenuBySlug)
	yield takeEvery(GET_PRODUCT, fetchProduct)
	yield takeEvery(GET_DELIVERECT_DISPATCH_AVAILABILITY, fetchDispatchAvailability)
}

export default menuSaga
