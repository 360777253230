import { realm } from "../helpers/realm"
import { runAggregationApi } from "./aggregation"

export const getReturns = async ({ page, paginationLimit, skip, filter, startDate, endDate }) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const transactions = client.db("caas").collection("transactions");
    
    const matchExpressions = [
        { $eq: [{ $ifNull: ['$raasReturn', false] }, false] },
        { $eq: ['$type', 'boxesIn'] },
        { $ne: ['$adjustedBoxCount', true] },
    ]

    if (filter && filter.franchiseeSlug) {
        matchExpressions.push({ $eq: ["$franchiseeSlug", filter.franchiseeSlug] });
    }

    if (filter && filter.reverseLogisticsPartner) {
        matchExpressions.push({ $eq: ["$reverseLogisticsPartner", filter.reverseLogisticsPartner] });
    }

    if (startDate) {
        matchExpressions.push({ $gte: ["$timestamp", startDate] });
    }

    if (endDate) {
        matchExpressions.push({ $lte: ["$timestamp", endDate] });
    }

    const stages = [
        {
            $match: {
                $expr: {
                    $and: matchExpressions,
                    // ...(filter || {}),
                },
            },
        },
        {
            $project: {
                returnImageSource: 0, 
            },
        },
        // { $sort: { timestamp: -1 } },
        {
            $facet: {
                pagination: [
                    { $count: 'total' },
                ],
                data: [
                    { $skip: skip },
                    { $limit: paginationLimit },
                    {
                        $match: {
                            adjustedBoxCount: { $ne: true },
                        },
                    },
                ],
            },
        },
    ];


    const result = await transactions.aggregate(stages);

    return result;

};

export const getReturnsCount = async ({ filter = {}, startDate, endDate }) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const transactions = client.db("caas").collection("transactions");
    // console.log('PRE COUNT', filter)
    const returnsCount = await transactions.count({
        type: "boxesIn",
        adjustedBoxCount: { $ne: true },
        // latePayment: { $ne: true },
        $or: [
            { raasReturn: { $exists: false } },
            { raasReturn: { $ne: true } },
        ],
        ...(filter.franchiseeSlug ? { franchiseeSlug: filter.franchiseeSlug } : {}),
        ...(filter.reverseLogisticsPartner ? { reverseLogisticsPartner: filter.reverseLogisticsPartner } : {}),
        $expr: {
            $and: [
                {
                    $gte: ["$timestamp", startDate]
                },
                {
                    $lte: ["$timestamp", endDate]
                }
            ]
        }
    });
    // console.log('returnsCount', returnsCount)
    return returnsCount;
};

export const getCustomers = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }

    const client = realm.currentUser.mongoClient("RealmService");
    const customers = client.db("caas").collection("users");

    const { filter, options } = payload;
    const { sort, skip, limit } = options;

    const stages = [];

	if (filter) {
		stages.push({ $match: filter })
	}

	if (sort) {
		stages.push({ $sort: sort })
	}

	stages.push({
		$facet: {
			pagination: [ 
				{
					$count: 'total',
				},
			],
			data: [{ $skip: skip }, { $limit: limit }],
		},
	})

    return customers.aggregate(stages);
};

export const getAdminCustomersBoxCount = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized")
    }

    const client = realm.currentUser.mongoClient("RealmService")
    const customers = client.db("caas").collection("users")

    const { filter, options } = payload
    const { sort, skip, limit } = options

    const stages = []

    if (filter) {
        stages.push({ $match: filter })
    }


    if (sort) {
        stages.push({ $sort: sort })
    }

    stages.push({
        $facet: {
            pagination: [
                {
                    $count: "total",
                },
            ],
            data: [{ $skip: skip }, { $limit: limit }],
        },
    })

    // return await customers.aggregate(stages)
    const customersAggregated = await customers.aggregate(stages)
    if (!customersAggregated || customersAggregated[0].data.length === 0) {
        return []
    }

    const updatedData = await Promise.all(
        customersAggregated[0].data.map(async (customer) => {
            const getTotalPackagingPreventedAggr = await runAggregationApi({
                phone: customer.phone,
                functionName: 'getTotalPackagingPrevented',
            })
            const updatedCustomer = {
                ...customer,
                getTotalPackagingPrevented: getTotalPackagingPreventedAggr.getTotalPackagingPrevented,
            }
            return updatedCustomer
        })
    )
    const updatedPayload = {
        data: updatedData,
        pagination: customersAggregated[0].pagination
    }

    return updatedPayload
};

export const getClients = async () => {
    const client = realm.currentUser.mongoClient("RealmService");
    const clients = client.db("caas").collection("clients");
    const allClients = await clients.find({ type: "platform" });

    return allClients;
};

export const getAggregators = async () => {
    const client = realm.currentUser.mongoClient("RealmService");
    const clients = client.db("caas").collection("clients");
    const aggregators = await clients.find({ type: "aggregator" });

    return aggregators;
};

export const getEnvironmentalImpact = async (payload) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const transactions = client.db("caas").collection("transactions");
    console.log(payload)
    const boxesOutMatch = {
        ...payload.filter,
        type: "boxesOut",
        $or: [
            { wholefoods: { $exists: false } },
            { wholefoods: { $ne: true } },
        ],
        timestamp: { $gte: payload.startDate, $lte: payload.endDate },
    };

    const boxesOutAgg = await transactions.aggregate([
        { $match: boxesOutMatch },
        {
            $group: {
                _id: "",
                boxCount: { $sum: "$boxCount" },
            },
        },
        {
            $project: {
                _id: 0,
                totalBoxCount: "$boxCount",
            },
        },
    ]);
    console.log(boxesOutAgg[0])
    const result = {
        totalBoxesOut: boxesOutAgg[0].totalBoxCount,
    };
    //
    let boxesOutReturnEndDate = new Date(payload.endDate);
    boxesOutReturnEndDate.setHours(boxesOutReturnEndDate.getHours() - 504);
    //

    const boxesOutReturnMatch = {
        ...payload.filter,
        timestamp: { $gte: payload.startDate, $lte: boxesOutReturnEndDate },
        type: "boxesOut",
        $or: [
            { wholefoods: { $exists: false } },
            { wholefoods: { $ne: true } },
        ],
    };
    //

    const boxesOutReturnAgg = await transactions.aggregate([
        { $match: boxesOutReturnMatch },
        {
            $group: {
                _id: "",
                boxCount: { $sum: "$boxCount" },
            },
        },
        {
            $project: {
                _id: 0,
                totalBoxCount: "$boxCount",
            },
        },
    ]);
    //
    //
    const boxesInMatch = {
        ...payload.filter,
        type: "boxesIn",
        timestamp: { $gte: payload.startDate, $lte: payload.endDate },
        $or: [
            { raasReturn: { $exists: false } },
            { raasReturn: { $ne: true } },
        ],
        $or: [
            { wholefoods: { $exists: false } },
            { wholefoods: { $ne: true } },
        ],
    };
    //
    const boxesInAgg = await transactions.aggregate([
        { $match: boxesInMatch },
        {
            $group: {
                _id: "",
                boxCount: { $sum: "$boxCount" },
            },
        },
        {
            $project: {
                _id: 0,
                totalBoxCount: "$boxCount",
            },
        },
    ]);
    console.log(boxesInAgg[0])
    console.log(boxesOutReturnAgg[0])
    result["totalBoxesIn"] = boxesInAgg[0] ? boxesInAgg[0].totalBoxCount : 0;
    result["effectiveReturnRate"] = boxesInAgg[0] ?
        boxesInAgg[0].totalBoxCount / boxesOutReturnAgg[0].totalBoxCount : 0;

    return result;
};

export const uploadMenus = async (menuUploadPayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const menuUploadResponse = await realm.currentUser.callFunction(
        "api/manualMenuUpload",
        menuUploadPayload
    );

    return menuUploadResponse;
};

export const createOptimizedRoute = async (routePayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const routeResponse = await realm.currentUser.callFunction(
        "google/CreateOptimizedRoute",
        routePayload
    );

    return routeResponse;
};

export const getDeliverZeroCustomerPickups = async () => {
    const client = realm.currentUser.mongoClient("RealmService");
    const transactions = client.db("caas").collection("transactions");
    const pendingPickups = client.db('caas').collection('pending_pickup');

    const currentDate = new Date();
    
    const currentDayOfWeek = currentDate.getUTCDay();
    const daysUntilNextMonday = (1 - currentDayOfWeek + 7) % 7 || 7;
    const startQueryDate = new Date(currentDate);

    if (currentDayOfWeek === 1) {
        // Today is Monday
    } else {
        // Today is not Monday, set startQueryDate to the following Monday
        startQueryDate.setUTCDate(
            currentDate.getUTCDate() - daysUntilNextMonday
        );
    }

    startQueryDate.setUTCHours(7, 0, 0, 0); // Set time to midnight MST
    const endQueryDate = new Date(currentDate); // Set endQueryDate to currentDate
    endQueryDate.setDate(currentDate.getDate() + 1)
    endQueryDate.setUTCHours(7, 0, 0, 0); // Set time to midnight MST next day
    // console.log(startQueryDate, endQueryDate)

    const dzPickupsTransactions = await transactions.find({
        reverseLogisticsPartner: {
            $in: ["deliverzero", "flot"]
        },
        timestamp: {
            $gte: startQueryDate,
            $lt: endQueryDate,
        },
    });

    const dzPickupsPending = await pendingPickups.find({
        reverseLogisticsPartner: {
            $in: ["deliverzero", "flot"]
        },
        timestamp: {
            $gte: startQueryDate,
            $lt: endQueryDate,
        },
    });

    const dzPickups = [...dzPickupsPending, ...dzPickupsTransactions]
  
    return dzPickups;
};

export const getAdminPlatforms = async () => {
    const client = realm.currentUser.mongoClient('RealmService');
    const clients = client.db('caas').collection('nodes');

    const pipeline = [
      {
        $project: {
          keys: { $objectToArray: '$thirdPartyClient' }
        }
      },
      {
        $unwind: '$keys'
      },
      {
        $group: {
          _id: null,
          distinctKeys: { $addToSet: '$keys.k' }
        }
      },
      {
        $project: {
          _id: 0,
          distinctKeys: 1
        }
      }
    ];
  
    const aggregateKeys = await clients.aggregate(pipeline);
    const distinctKeys = aggregateKeys[0].distinctKeys.filter((key) => key !== "");

    return distinctKeys
};

export const sendSlackAlertApi = async (slackPayload) => {

    const dropoffResponse = await realm.currentUser.callFunction(
        "alertSlack",
        slackPayload
    );

    return dropoffResponse;
};

export const getUsageData = async (payload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const usageData = await realm.currentUser.callFunction(
        "aggregation/getNodeTransactionData",
        payload
    );

    return usageData;
};
