import { connect } from 'react-redux'
import React, {useState, useEffect} from 'react'
import { BsFullscreen } from 'react-icons/bs'
import {BounceLoaderCentered} from "./index";

const DeliveryPickupToggle = (props) => {
	const {
		deliveryOption,
		className,
		pickupOnly = false,
	} = props

	const [deliveryToggle, setDeliveryToggle] = useState(null)

	useEffect(() => {
		if(deliveryOption){
			setDeliveryToggle(deliveryOption)
		}
	}, [deliveryOption])

	const handleSendToParent = (val) => {
		props.onSendToParent(val)
		setDeliveryToggle(val)
	}

	// console.log(pickupOnly)
	if (pickupOnly) {
		return (
			<div
				className={`${className} rounded-[30px] bg-white border-green-600 border w-[90px] items-center flex`}
			>
				<div className='flex flex-row items-center font-semibold text-sm w-full'>
					<button className='rounded-[30px] bg-green-600 h-full w-full flex items-center justify-center text-center text-white py-1'>
						Pickup
					</button>
				</div>
			</div>
		)
	} else {
		return (
			<div
				className={`${className} rounded-[30px] bg-white border-green-600 border w-[180px] items-center flex mx-4 my-3`}
			>
				{deliveryToggle == 'delivery' && (
					<div className='flex flex-row items-center justify-around font-semibold text-sm w-full'>
						<button
							className='rounded-[30px] bg-green-600 h-full w-[90px] flex items-center justify-center text-center text-white py-1'
							onClick={() => handleSendToParent('delivery')}
						>
							Delivery
						</button>
						<button
							className='rounded-[30px] h-full w-[90px] flex items-center justify-center text-center py-1 text-green-600'
							onClick={() => handleSendToParent('pickup')}
						>
							Pickup
						</button>
					</div>
				)}
				{deliveryToggle == 'pickup' && (
					<div className='flex flex-row items-center justify-around font-semibold text-sm w-full'>
						<button
							className='rounded-[30px] h-full w-[90px] flex items-center justify-center text-center py-1 text-green-600'
							onClick={() => handleSendToParent('delivery')}
						>
							Delivery
						</button>
						<button
							className='rounded-[30px] bg-green-600 h-full w-[90px] flex items-center justify-center text-center py-1 text-white'
							onClick={() => handleSendToParent('pickup')}
						>
							Pickup
						</button>
					</div>
				)}
				{deliveryToggle!=="delivery" && deliveryToggle!=="pickup" && (
					<BounceLoaderCentered></BounceLoaderCentered>
				)}
			</div>
		)
	}
}

const mapStateToProps = ({ User }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeliveryPickupToggle)
