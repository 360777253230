import React from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Button } from '../../components/Elements'

function AboutUs() {
	return (
		<>
			<Header label={"About Us"} showBackButton={true}/>
			<div className='flex flex-col justify-center'>
				<div className='w-[90%] md:w-4/5 max-w-[950px] mx-auto mb-[60px]'>
					<div className='font-header text-green-600 text-xl md:text-4xl my-4 md:mb-8'>
						Fight Climate Change & Waste
					</div>
					<div className='font-header text-green-600 text-xl md:text-4xl mb-4 md:mb-8'>
						Order Takeout & Delivery, Sustainably.
					</div>
					<div className='text-sm md:text-[22px] sm:mb-3 mb-6 leading-7'>
						DeliverZero is a network of returnable, reusable food
						containers. We make it easy for restaurants, delivery
						platforms, and POS systems to offer customers the option
						to receive takeout and delivery without a side of trash.
					</div>
					<div className='text-sm md:text-[22px] sm:mb-6 mb-12 leading-7'>
						As a working single mom, our CEO & co-founder Lauren
						Sweeney often relies on the convenience of takeout and
						delivery. As she became increasingly frustrated by how
						much waste came with each delivery (and frequently asked
						herself whether she could really recycle or compost the
						single-use containers she received) Lauren set out on a
						mission to make{' '}
						<span className='font-semibold'>
							reuse easier and more transparent than recycling.
						</span>
					</div>
					<div className='text-sm md:text-[22px] sm:mb-3 mb-6 leading-7'>
						Lauren connected with likeminded people working to solve
						the takeout waste problem, including Ashwin Ramdas and
						Chris Todd, who co-founded Repeater in Colorado. After
						it became clear that our visions aligned, we joined
						forces in the summer of 2022.
					</div>
					<div className='text-sm md:text-[22px] sm:mb-9 mb-18 leading-7'>
						Our small but mighty team is ready to normalize reuse.
						We’re here to make the most climate-friendly option an
						accessible one.
					</div>
					<div className='mt-[30px]'>
						<Button
							text='Why Reuse'
							link='/whyreuse'
							color='green'
							size='sm'
							className='min-w-[150px] px-12 py-1 mb-20'
						/>
					</div>
					<div className='font-header text-green-600 mt-12 text-xl sm:text-4xl mb-4 sm:mb-8'>
						Our Team
					</div>
					<div className='flex flex-col md:flex-row'>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/Dl6zpnF.png'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Lauren Sweeney
							</div>
							<div className='text-[22px] mb-6'>CEO</div>
						</div>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/JyIiB25.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Ashwin Ramdas
							</div>
							<div className='text-[22px] mb-6'>CTO</div>
						</div>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/YZkfK2D.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Christopher Todd
							</div>
							<div className='text-[22px] mb-6'>
								VP Operations & Logistics
							</div>
						</div>
					</div>
					<div className='flex flex-col md:flex-row mt-3'>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/VAjYEUK.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Cesar Alvarez
							</div>
							<div className='text-[22px] mb-6'>CX Lead</div>
						</div>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/RFaPCJk.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Leah Shoot
							</div>
							<div className='text-[22px] mb-6'>VP Strategy</div>
						</div>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/c8GIv58.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Damion Cordova
							</div>
							<div className='text-[22px] mb-6'>Operations Lead</div>
						</div>
					</div>
					<div className='flex flex-col md:flex-row mt-3'>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/HMe38QH.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Greg Kimmich
							</div>
							<div className='text-[22px] mb-6'>Account Executive</div>
						</div>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/uIh1o65.png'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Jason Forgy
							</div>
							<div className='text-[22px] w-[225px] mb-6'>
								VP of Sales & Sales Operations
							</div>
						</div>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/qAlmuy2.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Matt Van Orden
							</div>
							<div className='text-[22px] mb-6'>Sales Hero</div>
						</div>
					</div>
					<div className='flex flex-col md:flex-row mt-3'>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/ujMzLzX.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Joi Wu
							</div>
							<div className='text-[22px] mb-6'>
								Full Stack Engineer
							</div>
						</div>
						<div>
							<div className='w-[225px] h-[225px] rounded-[10px] border-[6px] border-green-400 mr-6 mb-2'>
								<img
									src='https://i.imgur.com/Nx8tSXt.jpg'
									className='rounded-[5px]'
								/>
							</div>
							<div className='text-[22px] text-green-600 font-semibold'>
								Roshni Rao
							</div>
							<div className='text-[22px] mb-6'>
								Full Stack Engineer
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AboutUs
