import { call, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_STOP,
    UPDATE_STOP,
    DELETE_STOP,
    GET_ALL_LOGISTICS,
    LOGISTICS_ALERT,
    CREATE_PENDING_PICKUP,
    GET_PENDING_PICKUPS,
    GET_PICKUPS_BY_PHONE,
    GET_INCOMPLETE_STOPS,
    GET_INVENTORY,
    GET_LOGISTICS_DASHBOARD,
    GET_NEW_RESTAURANT_STOPS,
    GET_RETURN_BIN_BOX_COUNT,
    GET_UBER_DIRECT_QUOTE,
    CREATE_UBER_DIRECT_REQUEST,
    UPDATE_UBER_DIRECT,
    CANCEL_UBER_DIRECT,
    GET_ALL_UBER_DIRECT,
    GET_UBER_DIRECT_REQUEST_BY_ID,
    GET_UBER_DIRECT_QUOTE_DROPOFF,
    GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE,
    GET_UBER_DIRECT_REQUEST_DOC_BY_ID,
    GET_MARKET,
    ADJUST_INVENTORY,
    GET_MARKET_OBJ,
    CREATE_PACKING_LIST_TRANSACTIONS,
    GET_PROOF_OF_DELIVERY,
} from './actionTypes';
import {
    createStopSuccess,
    createStopError,
    updateStopSuccess,
    updateStopError,
    deleteStopSuccess,
    deleteStopError,
    getAllLogisticsSuccess,
    getAllLogisticsError,
    logisticsAlertSuccess,
    logisticsAlertError,
    createPendingPickupSuccess,
    createPendingPickupError,
    getPendingPickupsSuccess,
    getPendingPickupsError,
    getPickupsByPhoneSuccess,
    getPickupsByPhoneError,
    getIncompleteStopsError,
    getIncompleteStopsSuccess,
    getInventoryError,
    getInventorySuccess,
    getLogisticsDashboardError,
    getLogisticsDashboardSuccess,
    getNewRestaurantStopsError,
    getNewRestaurantStopsSuccess,
    getReturnBinBoxCountError,
    getReturnBinBoxCountSuccess,
    getUberDirectQuoteError, 
    getUberDirectQuoteSuccess,
    createUberDirectRequestError,
    createUberDirectRequestSuccess,
    updateUberDirectError,
    updateUberDirectSuccess,
    cancelUberDirectError,
    cancelUberDirectSuccess,
    getAllUberDirectError,
    getAllUberDirectSuccess,
    getUberDirectRequestByIdError,
    getUberDirectRequestByIdSuccess,
    getUberDirectQuoteDropoffError,
    getUberDirectQuoteDropoffSuccess,
    getUberDirectQuoteDropoffNoNodeError,
    getUberDirectQuoteDropoffNoNodeSuccess,
    getUberDirectRequestDocByIdError,
    getUberDirectRequestDocByIdSuccess,
    getMarketSuccess,
    getMarketError,
    adjustInventorySuccess,
    adjustInventoryError,
    getMarketObjError,
    getMarketObjSuccess,
    createPackingListTransactionsError,
    createPackingListTransactionsSuccess,
    getProofOfDeliveryError,
    getProofOfDeliverySuccess,
} from './actions';
import {
    createStop,
    updateStop,
    deleteStop,
    getAllLogistics,
    logisticsAlert,
    getIncompleteStops,
    getInventory,
    getLogisticsDashboard,
    getNewRestaurantStops,
    getReturnBinBoxCount,
    getUberDirectQuote,
    createUberDirectRequest,
    updateUberDirect,
    cancelUberDirect,
    getAllUberDirect,
    getUberDirectRequestById,
    getUberDirectQuoteDropoff,
    getUberDirectQuoteDropoffNoNode,
    getUberDirectRequestDocById,
    getMarket,
    adjustInventory,
    getMarketObj,
    createPackingListTransactions,
    getProofOfDelivery,
} from '../../model/logistics';

function* createStopSaga(action) {
    try {
        const result = yield call(createStop, action.payload);
        yield put(createStopSuccess(result));
    } catch (error) {
        yield put(createStopError(error));
    }
}

function* updateStopSaga(action) {
    try {
        const result = yield call(updateStop, action.payload);
        yield put(updateStopSuccess(result));
    } catch (error) {
        yield put(updateStopError(error));
    }
}

function* deleteStopSaga(action) {
    try {
        const result = yield call(deleteStop, action.payload);
        yield put(deleteStopSuccess(result));
    } catch (error) {
        yield put(deleteStopError(error));
    }
}

function* getAllLogisticsSaga(action) {
    try {
        const result = yield call(getAllLogistics, action.payload);
        yield put(getAllLogisticsSuccess(result));
    } catch (error) {
        yield put(getAllLogisticsError(error));
    }
}
  
function* logisticsAlertSaga(action) {
    try {
        const result = yield call(logisticsAlert, action.payload);
        yield put(logisticsAlertSuccess(result));
    } catch (error) {
        yield put(logisticsAlertError(error));
    }
}

function* fetchIncompleteStops(action) {
    try {
        const result = yield call(getIncompleteStops, action.payload);
        yield put(getIncompleteStopsSuccess(result));
    } catch (error) {
        yield put(getIncompleteStopsError(error));
    }
}

function* fetchInventory(action) {
    try {
        const result = yield call(getInventory, action.payload);
        yield put(getInventorySuccess(result));
    } catch (error) {
        yield put(getInventoryError(error));
    }
}

function* fetchLogisticsDashboard(action) {
    try {
        const result = yield call(getLogisticsDashboard, action.payload);
        yield put(getLogisticsDashboardSuccess(result));
    } catch (error) {
        yield put(getLogisticsDashboardError(error));
    }
}

function* fetchNewRestaurantStops(action) {
    try {
        const result = yield call(getNewRestaurantStops, action.payload);
        yield put(getNewRestaurantStopsSuccess(result));
    } catch (error) {
        yield put(getNewRestaurantStopsError(error));
    }
}

function* fetchReturnBinBoxCount(action) {
    try {
        const result = yield call(getReturnBinBoxCount, action.payload);
        yield put(getReturnBinBoxCountSuccess(result));
    } catch (error) {
        yield put(getReturnBinBoxCountError(error));
    }
}

function* fetchUberDirectQuote(action) {
    try {
        const result = yield call(getUberDirectQuote, action.payload);
        yield put(getUberDirectQuoteSuccess(result));
    } catch (error) {
        yield put(getUberDirectQuoteError(error));
    }
}

function* createUberDirectRequestSaga(action) {
    try {
        const result = yield call(createUberDirectRequest, action.payload);
        yield put(createUberDirectRequestSuccess(result));
    } catch (error) {
        yield put(createUberDirectRequestError(error));
    }
}

function* updateUberDirectSaga(action) {
    try {
        const result = yield call(updateUberDirect, action.payload);
        yield put(updateUberDirectSuccess(result));
    } catch (error) {
        yield put(updateUberDirectError(error));
    }
}

function* getAllUberDirectSaga(action) {
    try {
        const result = yield call(getAllUberDirect, action.payload);
        yield put(getAllUberDirectSuccess(result));
    } catch (error) {
        yield put(getAllUberDirectError(error));
    }
}

function* cancelUberDirectSaga(action) {
    try {
        const result = yield call(cancelUberDirect, action.payload);
        yield put(cancelUberDirectSuccess(result));
    } catch (error) {
        yield put(cancelUberDirectError(error));
    }
}

function* getUberDirectRequestByIdSaga(action) {
    try {
        const result = yield call(getUberDirectRequestById, action.payload);
        yield put(getUberDirectRequestByIdSuccess(result));
    } catch (error) {
        yield put(getUberDirectRequestByIdError(error));
    }
}

function* fetchUberDirectQuoteDropoff(action) {
    try {
        const result = yield call(getUberDirectQuoteDropoff, action.payload);
        yield put(getUberDirectQuoteDropoffSuccess(result));
    } catch (error) {
        yield put(getUberDirectQuoteDropoffError(error));
    }
}

function* fetchUberDirectQuoteDropoffNoNode(action) {
    try {
        const result = yield call(getUberDirectQuoteDropoffNoNode, action.payload);
        yield put(getUberDirectQuoteDropoffNoNodeSuccess(result));
    } catch (error) {
        yield put(getUberDirectQuoteDropoffNoNodeError(error));
    }
}

function* getUberDirectRequestDocByIdSaga(action) {
    try {
        const result = yield call(getUberDirectRequestDocById, action.payload);
        yield put(getUberDirectRequestDocByIdSuccess(result));
    } catch (error) {
        yield put(getUberDirectRequestDocByIdError(error));
    }
}

function* fetchMarket(action) {
	try {
	  const market = yield call(getMarket, action.payload);
	  yield put(getMarketSuccess(market));
	} catch (error) {
	  yield put(getMarketError(error));
	}
}

function* fetchAdjustedInventory(action) {
	try {
	  const market = yield call(adjustInventory, action.payload);
	  yield put(adjustInventorySuccess(market));
	} catch (error) {
	  yield put(adjustInventoryError(error));
	}
}

function* fetchMarketObj(action) {
	try {
	  const market = yield call(getMarketObj, action.payload);
	  yield put(getMarketObjSuccess(market));
	} catch (error) {
	  yield put(getMarketObjError(error));
	}
}

function* fetchPackingListTransactions(action) {
	try {
	  const transactions = yield call(createPackingListTransactions, action.payload);
	  yield put(createPackingListTransactionsSuccess(transactions));
	} catch (error) {
	  yield put(createPackingListTransactionsError(error));
    }
}

function* fetchProofOfDelivery(action) {
	try {
	  const proof = yield call(getProofOfDelivery, action.payload);
	  yield put(getProofOfDeliverySuccess(proof));
	} catch (error) {
	  yield put(getProofOfDeliveryError(error));
	}
}
  
function* logisticsSaga() {
    yield takeEvery(CREATE_STOP, createStopSaga);
    yield takeEvery(UPDATE_STOP, updateStopSaga);
    yield takeEvery(DELETE_STOP, deleteStopSaga);
    yield takeEvery(GET_ALL_LOGISTICS, getAllLogisticsSaga);
    yield takeEvery(LOGISTICS_ALERT, logisticsAlertSaga);
    yield takeEvery(GET_INCOMPLETE_STOPS, fetchIncompleteStops);
    yield takeEvery(GET_INVENTORY, fetchInventory);
    yield takeEvery(GET_LOGISTICS_DASHBOARD, fetchLogisticsDashboard);
    yield takeEvery(GET_NEW_RESTAURANT_STOPS, fetchNewRestaurantStops);    
    yield takeEvery(GET_RETURN_BIN_BOX_COUNT, fetchReturnBinBoxCount);
    yield takeEvery(GET_UBER_DIRECT_QUOTE, fetchUberDirectQuote);
    yield takeEvery(GET_UBER_DIRECT_QUOTE_DROPOFF, fetchUberDirectQuoteDropoff);
    yield takeEvery(GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE, fetchUberDirectQuoteDropoffNoNode);
    yield takeEvery(CREATE_UBER_DIRECT_REQUEST, createUberDirectRequestSaga);
    yield takeEvery(UPDATE_UBER_DIRECT, updateUberDirectSaga);
    yield takeEvery(GET_ALL_UBER_DIRECT, getAllUberDirectSaga);
    yield takeEvery(CANCEL_UBER_DIRECT, cancelUberDirectSaga);
    yield takeEvery(GET_UBER_DIRECT_REQUEST_BY_ID, getUberDirectRequestByIdSaga);
    yield takeEvery(GET_UBER_DIRECT_REQUEST_DOC_BY_ID, getUberDirectRequestDocByIdSaga);
	yield takeEvery(GET_MARKET, fetchMarket);
    yield takeEvery(ADJUST_INVENTORY, fetchAdjustedInventory);
    yield takeEvery(GET_MARKET_OBJ, fetchMarketObj);
    yield takeEvery(CREATE_PACKING_LIST_TRANSACTIONS, fetchPackingListTransactions);
	yield takeEvery(GET_PROOF_OF_DELIVERY, fetchProofOfDelivery);
}

export default logisticsSaga;