import { realm } from '../helpers/realm'

export const findAppInfo = async () => {
    const client = realm.currentUser.mongoClient('RealmService')
    const appInfo = client.db('caas').collection('app_info')
    return appInfo.findOne({ version: 1 })
}

export const findBannerInfo = async () => {
    const client = realm.currentUser.mongoClient('RealmService')
    const bannerInfo = client.db('caas').collection('banner_info')
    return bannerInfo.findOne({ active: true })
}