import {
	GET_MENU_MAP,
	GET_MENU_MAP_SUCCESS,
	GET_MENU_MAP_FAILURE,
	UPDATE_MENU_MAP,
	UPDATE_MENU_MAP_SUCCESS,
	UPDATE_MENU_MAP_FAILURE,
	GET_MENU_MAP_FROM_CLIENT,
} from './actionTypes'

const initialState = {
	itemBoxCountLookUp: null,
	loadingItemBoxCountLookUp: false,
	updatingItemBoxCountLookUp: false,
	itemBoxCountLookUpError: null,
	updatedBoxCountLookUp: false,
}

const boxCountLookUp = (state = initialState, action) => {
	switch (action.type) {
		case GET_MENU_MAP:
			return {
				...state,
				loadingItemBoxCountLookUp: true,
				itemBoxCountLookUpError: null,
			}
		case GET_MENU_MAP_SUCCESS:
			return {
				...state,
				loadingItemBoxCountLookUp: false,
				itemBoxCountLookUp: action.payload,
				itemBoxCountLookUpError: null,
			}
		case GET_MENU_MAP_FAILURE:
			return {
				...state,
				loadingItemBoxCountLookUp: false,
				itemBoxCountLookUpError: action.payload,
			}
		case UPDATE_MENU_MAP:
			return {
				...state,
				updatingItemBoxCountLookUp: true,
				updatedBoxCountLookUp: false,
				itemBoxCountLookUpError: null,
			}
		case UPDATE_MENU_MAP_SUCCESS:
			return {
				...state,
				updatingItemBoxCountLookUp: false,
				itemBoxCountLookUp: action.payload,
				updatedBoxCountLookUp: true,
				itemBoxCountLookUpError: null,
			}
		case UPDATE_MENU_MAP_FAILURE:
			return {
				...state,
				updatingItemBoxCountLookUp: false,
				itemBoxCountLookUpError: action.payload,
				updatedBoxCountLookUp: false,
			}
		case GET_MENU_MAP_FROM_CLIENT:
			return {
				...state,
				itemBoxCountLookUpError: null,
				loadingItemBoxCountLookUp: true,
			}
		default:
			return state
	}
}

export default boxCountLookUp
