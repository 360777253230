import { all } from "redux-saga/effects";
import {realm} from "../helpers/realm";
import * as Realm from "realm-web";

export const getRAASMerchant = async (merchantSlug) => {
    const client = realm.currentUser.mongoClient('mongodb-atlas')
    const merchantProfile = client.db(merchantSlug).collection('merchant_profile')
    const merchant = await merchantProfile.findOne({ slug: merchantSlug })

    return merchant
}

export const getRAASCustomer = async (customerPayload) => {
    const client = realm.currentUser.mongoClient('mongodb-atlas')
    const customers = client.db(customerPayload.merchantSlug).collection('customers')
    const merchantCustomer = await customers.findOne({ phone: customerPayload.phone })

    return merchantCustomer
}

export const insertRAASTransaction = async (payload) => {
    const client = realm.currentUser.mongoClient('mongodb-atlas')
    const transactions = client.db(payload.merchantSlug).collection('transactions')
    return transactions.insertOne(payload.transactionPayload)
}

export const getRAASMerchants = async () => {
    const client = realm.currentUser.mongoClient('RealmService')
    const merchants = client.db('raas_super_admin').collection('merchant_profiles')
	const allMerchants = await merchants.find({}, { slug: 1 });

	return allMerchants
}