import React, { useRef, useState } from "react";
import { Button, TextArea } from "../Elements";
import { Modifiers } from "../";
import useOutsideClick from "../Elements/DetectOutsideClick";
import { MdClear } from "react-icons/md";


function ItemPopUp(props) {
  const { popUpProduct, openPopUp, handleOpenPopUp, handleAddProduct } = props;
  const [modifiers, setModifiers] = useState([]);
  const [itemNotes, setItemNotes] = useState("");
  const [modErrorGroupId, setModErrorGroupId] = useState("");
  const [itemQuantity, setItemQuantity] = useState(1);

  const handleClosePopUp = () => {
    handleOpenPopUp(false);
  };

  const handleIncrement = () => {
    const newQuantity = itemQuantity + 1;
    setItemQuantity(newQuantity);
  };

  const handleDecrement = () => {
    if (itemQuantity > 1) {
      const newQuantity = itemQuantity - 1;
      setItemQuantity(newQuantity);
    }
  };

  const handleSetModifiers = (modItem, action, type, radioMods) => {
    setModifiers(prevModifiers => {
			let updatedModifiers = [...prevModifiers]; // Create a new array using spread syntax
			if (action === 'add') {
				if (type === 'radio') {
					// logic for radios with nested mods
					let radioModSelected = null
					for(let i = 0; i < radioMods.length; i++) {
						let radioMod = radioMods[i]
						for(let j = 0; j < updatedModifiers.length; j++) {
							if(updatedModifiers[j].id == radioMod.id) {
								radioModSelected = radioMod
							}
						}
					}

					// logic for radios with nested mods
					if(radioModSelected && radioModSelected.nestedModifiers) {
						for(const nestedMod of radioModSelected.nestedModifiers) {
							for(const nestedModItem of nestedMod.nestedModItems) {
								// Ensure nested modifiers are uniquely identified by both their ID and their parentModifierId
								updatedModifiers = updatedModifiers.filter(e => !(e.id === nestedModItem.id && e.parentModifierId === nestedModItem.parentModifierId));
							}
						}
					}
				
					// Clearing previous selections if there's no parentModifierId (for top-level radios)
					if(!radioMods[0].parentModifierId) {
						radioMods.forEach(radioMod => {
							updatedModifiers = updatedModifiers.filter(e => e.id !== radioMod.id);
						});
					} else {
						// For nested radios, ensure we're not removing nested modifiers from other parents
						const parentModifierId = radioMods[0].parentModifierId;
						updatedModifiers = updatedModifiers.filter(e => e.id !== modItem.id || e.parentModifierId !== parentModifierId);
					}
				}
				updatedModifiers.push(modItem);
			} else if (action === 'remove') {
				if(modItem.nestedModifier){
					updatedModifiers = updatedModifiers.filter(e => e.id !== modItem.id || e.parentModifierId !== modItem.parentModifierId);
				} else {
					updatedModifiers = updatedModifiers.filter(e => e.id !== modItem.id);
				}
			}
			// console.log("updatedModifiers",updatedModifiers)
			return updatedModifiers;
		});
  };

  const handleAddToCart = (product) => {
		let requiredModifiersPassed = true
		// for parent modifiers
		popUpProduct.modifiers.map((modi) => {
			if (modi.modifierGroup.minimumSelections > 0) {
				let selectedRequiredModCount = modifiers.filter(
					(modif) => modif.modGroupId === modi.modifierGroup.id
				).length
				if (
					selectedRequiredModCount >=
					modi.modifierGroup.minimumSelections
				) {
				} else {
					requiredModifiersPassed = false
					setModErrorGroupId(modi.modifierGroup.id)
				}
			}
		})

		// for nested modifiers
		modifiers.forEach(parentMod => {
			if (parentMod.nestedModifiers) {
				parentMod.nestedModifiers.forEach(nestedModi => {
					if (nestedModi.nestedModItems && nestedModi.nestedModItems[0].parentModifierId === parentMod.id) {
						if (nestedModi.nestedModifierGroup.minimumSelections > 0) {
							// Filter selected nested modifiers for the current nested modifier group
							const selectedNestedMods = modifiers.filter(modif =>
								modif.nestedModGroupId && modif.nestedModGroupId === nestedModi.nestedModifierGroup.id &&
								modif.parentModifierId && modif.parentModifierId === parentMod.id
							);

							const selectedRequiredModCount = selectedNestedMods.length;
							
							// Check if the selected nested modifiers meet the required minimum selections
							if (selectedRequiredModCount < nestedModi.nestedModifierGroup.minimumSelections) {
								requiredModifiersPassed = false;
								setModErrorGroupId(nestedModi.nestedModifierGroup.id);
							}
						}
					}
				});
			}
		});		

		if (requiredModifiersPassed) {
			let parsedMods = []
			modifiers.map((mod) => {
				let modObj = {
					quantity: 1,
					id: mod.id,
					name: mod.name,
					price: mod.price,
					groupName: mod.modGroupName ? mod.modGroupName : mod.nestedModGroupName ? mod.nestedModGroupName : null,
					groupId: mod.modGroupId ? mod.modGroupId : mod.nestedModGroupId ? mod.nestedModGroupId : null,
				}

				if(mod.parentModifierId) {
					modObj.parentModifierId = mod.parentModifierId // indicates nestedModifier
				}
				
				parsedMods.push(modObj)
			})
			for (let i = 0; i < itemQuantity; i++) {
				handleAddProduct({
					...product,
					notes: itemNotes,
					modifiers: parsedMods,
				})
			}
			handleOpenPopUp(false)
		} else {
			// console.log('pee')
		}

		console.log('popup:', product)
	}

  const impactRef = useRef(null);

  useOutsideClick(impactRef, () => {
    handleOpenPopUp(false);
  });

  return (
    <>
      {openPopUp && (
        <>
          <div className="justify-center mt-[3.25rem] mb-[3rem] flex w-full fixed inset-0 z-50 shadow-xl overflow-x-hidden md:bg-gray-800 md:bg-opacity-25 md:backdrop-filter md:backdrop-blur-sm md:py-10">
            <div
              ref={impactRef}
              className="h-full border-0 shadow-xl relative w-full pb-20 overflow-y-auto md:bg-white md:w-2/3 md:rounded-xl"
            >
              <div className="min-h-full relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="overflow-hidden">
                  {popUpProduct.pictures?.length > 0 ? (
                    <>
                      <img
                        className="h-[250px] w-full object-cover"
                        src={popUpProduct.pictures[0].url}
                        alt=""
                      />
                      <div className="fixed top-14 right-3 md:right-[9rem]">
                        <MdClear
                          className="text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl"
                          onClick={handleClosePopUp}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="fixed top-14 right-3">
                      <MdClear
                        className="text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl"
                        onClick={handleClosePopUp}
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col items-start justify-between px-4 py-4 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold mb-1">
                    {popUpProduct.name}
                  </h3>
                  <p className="text-slate-500 text-sm leading-relaxed">
                    {popUpProduct.description}{" "}
                  </p>
                </div>
                <div className="mt-3">
                  <Modifiers
                    product={popUpProduct}
                    handleSetModifiers={handleSetModifiers}
                    modErrorGroupId={modErrorGroupId}
                  />
                </div>
                <div className="mx-4 mb-20 pb-2">
                  <TextArea
                    name="Notes"
                    label="Notes"
                    placeholder="Include any special instructions here"
                    description="Maximum of 250 characters"
                    onChange={(e) => {
                      setItemNotes(e);
                    }}
                  />
                </div>
                {modErrorGroupId != "" && (
                  <div className="w-full justify-self-end text-red-400 text-sm">
                    Check above for errors
                  </div>
                )}
                <div className="py-5 border-t border-solid border-slate-200 rounded-b fixed bottom-14 w-full flex justify-center bg-white md:bottom-10 md:rounded-b-xl md:w-2/3">
                  <div className="border-2 rounded-full text-center flex justify-between items-center">
                    <span
                      color="default"
                      className=" text-gray-300 cursor-pointer text-center justify-center pr-2 pl-4"
                      onClick={() => {
                        handleDecrement();
                      }}
                    >
                      -
                    </span>
                    <div className="text-center">{itemQuantity}</div>
                    <span
                      color="default"
                      className="pl-2 pr-4 text-gray-300 cursor-pointer text-center justify-center"
                      onClick={() => {
                        handleIncrement();
                      }}
                    >
                      +
                    </span>
                  </div>
                  <Button
                    onClick={() => {
                      handleAddToCart(popUpProduct);
                    }}
                    text="Add to Cart"
                    className="px-4 py-1 mx-2 !w-1/2"
                    size="sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}

export default ItemPopUp;
