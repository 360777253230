import { realm } from '../helpers/realm'


export const getUserAddressLocation = async (savedUserLocation) => {

	const lastSearchedAddress = JSON.parse(
		localStorage.getItem('lastSearchedAddress')
	)
	if (lastSearchedAddress) {
		return {
			lat: lastSearchedAddress.lat,
			lng: lastSearchedAddress.lng,
		}
	} else if (savedUserLocation) {
		return {
			lat: savedUserLocation.lat,
			lng: savedUserLocation.lng,
		}
	} else {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const crd = position.coords
					return resolve({ lat: crd.latitude, lng: crd.longitude })
				},
				(e) => {
					console.log("error", e.message)
					// reject(e.message)
					return resolve({
						// lat: crd.latitude, lng: crd.longitude
							lat: 40.7128,
							lng: -74.006,
					}

					)
				},
				{ enableHighAccuracy: true, timeout: 10000 }
			)
		})
	}
}

export const getUserGPSLocation = async () => {
	const gpsLocation = new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const crd = position.coords
				// console.log('CRD:',crd)
				return resolve({ lat: crd.latitude, lng: crd.longitude })
			},
			(e) => {
				console.log("error", e.message)
				reject(e.message)
			},
			{ enableHighAccuracy: true, timeout: 10000 }
		)
	})
	return gpsLocation
}

export const captureGPS = async (payload) => {
    const client = realm.currentUser.mongoClient('RealmService');
    const users = client.db('caas').collection('users');
    const currentUser = realm.currentUser; 

    const user = await users.findOne({ phone: payload.phone });

    if (user && user._id.equals(currentUser.id)) {
        const gpsLocation = await getUserGPSLocation();

        if (gpsLocation.error) {
            return { error: gpsLocation.error };
        }

        const updatedUser = await users.updateOne(
            { phone: payload.phone },
            {
                $set: {
                    lastSeenGPS: gpsLocation,
                },
            }
        );

        return updatedUser;
    } 
};

  
  