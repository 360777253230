import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, updateUser, getAppInfo } from '../../store/actions'
import { useNavigate, Link } from 'react-router-dom'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Button } from '../../components/Elements'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import MembershipSignUpForm from '../../components/Stripe/MembershipSignUpForm'
import {
	FaDollarSign,
	FaPeopleArrows,
	FaShoppingBasket,
	FaMoneyBillWave,
	FaHome,
} from 'react-icons/fa'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const MembershipLanding = (state) => {
	const {
		onGetUser,
		onUpdateUser,
		user,
		path,
		loadingUser,
		loggedIn,
		updateUserLoading,
		userUpdateCompleted,
		onGetAppInfo,
		appInfo,
	} = state

	const bounceLoaderColor = '#507f74'
	const [existingMember, setExistingMember] = useState(false)
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
		onGetAppInfo()
	}, [onGetUser])

	useEffect(() => {
		if (
			loggedIn &&
			user &&
			user.dzMemberSubscription &&
			user.dzMemberSubscription.enabled
		) {
			setExistingMember(true)
		}
	}, [loggedIn, user])

	useEffect(() => {
		if (userUpdateCompleted) {
			onGetUser()
		}
	}, [userUpdateCompleted])

	return (
		<>
            <Header label="DeliverZero+" showBackButton={true} />
			<div className='className="min-h-screen-no-header sm:h-screen w-full flex flex-col justify-between mt-[3.25rem]'>
				<div
					className='mt-6 pb-[3rem] w-full'
				>
					{/* Title text */}
					<div className='z-20 flex flex-col mx-auto max-w-[90%]'>
						<div className='w-full overflow-auto scrollbar'>
							<div className='flex flex-col items-center justify-start w-full'>
								<div className='w-full flex flex-col items-start justify-start'>
									{existingMember ? (
										<div className='px-4'>
											<h1 className='text-3xl lg:text-5xl font-header w-full lg:w-4/5 mt-8 mb-6'>
												Thanks for being a member!
											</h1>
											<div className='flex justify-start text-center lg:w-4/5 mb-6 lg:mb-12 lg:mt-0'>
												<Button
													text='View my Account'
													color='green'
													size='sm'
													className='flex w-[210px] shadow-light-grey'
													onClick={() => {
														navigate(
															'/user/account'
														)
													}}
												/>
											</div>
										</div>
									) : !loggedIn ? (
										<div className='px-4'>
											<div className="justify-start max-w-[90%] md:max-w-[75%] lg:max-w-[70%] xl:max-w-[60%">
												<h2 className='text-2xl lg:text-3xl w-full px-0 sm:px-4 mb-4 italic'>
													Delivering perks to reuse
													enthusiasts
												</h2>
												<p className='w-full px-0 sm:px-4 text-lg lg:text-xl mb-4'>
													We think reusers deserve added
													benefits for helping save the
													planet. That's why we've
													launched
													<span className='font-header'>
														{` `}DeliverZero+
													</span>
													, a membership program that
													gives you a whole new way to
													engage with the reuse
													revolution. {` `}
													{/* Our $4.99 membership
													tier gives you a special place
													within the DeliverZero
													community, while our $12.99 tier
													is for frequent DeliverZero
													users who want more value the
													more they order in our reusable
													containers.  */}
													Choose the level that best suits
													your needs and join today!
												</p>
											</div>
											<h1 className='text-2xl lg:text-4xl font-header w-full lg:w-4/5 mt-8 mb-6'>
												Create an account to get
												started!
											</h1>
											<div
												className='flex justify-start text-center lg:w-4/5 mb-6 lg:mb-10 lg:mt-0'>
												<Button
													text='Sign up'
													color='red'
													size='sm'
													className='flex w-[210px] shadow-light-grey'
													onClick={() => {
														navigate(
															'/sign-up/membership'
														)
													}}
												/>
											</div>
											<h2 className='text-xl lg:text-2xl font-header text-start w-full lg:w-4/5 mt-8 mb-6'>
												Already have an account?{' '}
												<Link
													to='/login/membership'
													className='underline text-green-600'
												>
													Login here.
												</Link>
											</h2>
										</div>
									) : (
										<div
											className='justify-start max-w-[90%] md:max-w-[75%] lg:max-w-[70%] xl:max-w-[60%]'>
											<h2 className='text-2xl lg:text-3xl w-full px-4 mb-4 italic'>
												Delivering perks to reuse
												enthusiasts
											</h2>
											<p className='w-full px-4 text-lg lg:text-xl mb-4'>
												We think reusers deserve added
												benefits for helping save the
												planet. That's why we've
												launched
												<span className='font-header'>
													{` `}DeliverZero+
												</span>
												, a membership program that
												gives you a whole new way to
												engage with the reuse
												revolution. {` `}
												{/* Our $4.99 membership
                                                tier gives you a special place
                                                within the DeliverZero
                                                community, while our $12.99 tier
                                                is for frequent DeliverZero
                                                users who want more value the
                                                more they order in our reusable
                                                containers.  */}
												Choose the level that best suits
												your needs and join today!
											</p>
											{!existingMember && (
												<div className='flex justify-center px-4 text-start lg:w-4/5 mb-6 lg:mb-12 lg:mt-0'>
													<Button
														text='Join now'
														color='red'
														size='sm'
														className='flex w-[210px] shadow-light-grey'
														onClick={() => {
															navigate(
																'/membership/join'
															)
														}}
													/>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					{/* Info tiles */}
					<div className='flex justify-center flex-col mx-auto m-4 sm:m-10'>
						<h2 className='text-2xl lg:text-3xl w-full px-4 mb-4 w-[90%] lg:w-[70%] mx-auto'>
							For <span className='font-header'>$4.99/mo</span>,
							you’ll access..
						</h2>
						<div className='grid grid-cols-1 sm:grid-cols-3 gap-5 row-auto mx-auto w-[90%] lg:w-[70%]'>
							{/*<Link to="/reusables-shop/members">*/}
							<div className='z-10 w-full h-full flex flex-col p-5 lg:p-10 mx-auto shadow-light-grey border-2 border-green-600 bg-white rounded-xl overflow-hidden'>
								<div className='flex flex-col items-center'>
									<div className='flex flex-row text-start w-[90%] items-center mb-3'>
										<div className='w-12 h-12 mr-2 rounded-full bg-gray-200 hover:bg-green-600 flex items-center justify-center'>
											<FaShoppingBasket className='z-20 w-8 h-8 text-green-600 hover:text-white' />
										</div>
										<span className='font-header text-xl lg:text-2xl'>
											Shop
										</span>
									</div>
									<h2 className='text-xl lg:text-[26px] text-start w-[90%]'>
										Exclusive reuse merch via the
										DeliverZero Shop
									</h2>
									{existingMember && (
										<Button
											className='mt-4 text-base'
											size='xs'
											text='Visit Shop'
											onClick={() => {
												window.open('https://www.deliverzero.com/reusables-shop/members', '_blank');
											}}
										></Button>
									)}
								</div>
							</div>
							{/*</Link>*/}
							{/* UPDATE TO LINK TO DISCORD CHANNEL */}

							<div className='z-10 w-full h-full flex flex-col p-5 lg:p-10 mx-auto shadow-light-grey border-2 border-green-600 bg-white rounded-xl overflow-hidden'>
								<div className='flex flex-col items-center'>
									<div className='flex flex-row text-start w-[90%] items-center mb-3'>
										<div className='w-12 h-12 mr-2 rounded-full bg-gray-200 hover:bg-green-600 flex items-center justify-center'>
											<FaPeopleArrows className='z-20 w-8 h-8 text-green-600 hover:text-white' />
										</div>
										<span className='font-header text-xl lg:text-2xl'>
											Connect
										</span>
									</div>
									<h2 className='text-xl lg:text-[26px] text-start w-[90%]'>
										An online community of fellow passionate
										reusers
									</h2>
									{existingMember && (
										<a
											href={
												existingMember &&
												appInfo?.memberCommunityUrl
													? appInfo.memberCommunityUrl
													: 'http://www.deliverzero.com/membership'
											}
										>
											<Button
												className='mt-4 text-base'
												size='xs'
												text='Open Community'
											></Button>
										</a>
									)}
								</div>
							</div>
							<div className='z-10 w-full h-full flex flex-col p-5 lg:p-10 mx-auto shadow-light-grey border-2 border-green-600 bg-white rounded-xl overflow-hidden'>
								<div className='flex flex-col items-center'>
									<div className='flex flex-row text-start w-[90%] items-center mb-3'>
										<div className='w-12 h-12 mr-2 rounded-full bg-gray-200 hover:bg-green-600 flex items-center justify-center'>
											<FaDollarSign className='z-20 w-8 h-8 text-green-600 hover:text-white' />
										</div>
										<span className='font-header text-xl lg:text-2xl'>
											Save
										</span>
									</div>
									<h2 className='text-xl lg:text-[26px] text-start w-[90%]'>
										Lower unreturned container fees
									</h2>
								</div>
							</div>
							{/* <div className="z-10 flex flex-col p-5 lg:p-10 mx-auto shadow-light-grey border-2 border-green-600 bg-white rounded-xl overflow-hidden">
                                <div className="flex flex-col items-center">
                                    <div className="flex flex-row text-start w-[90%] items-center mb-3">
                                        <div className="w-12 h-12 mr-2 rounded-full bg-gray-200 hover:bg-green-600 flex items-center justify-center">
                                        	<FaRedoAlt className="z-20 w-8 h-8 text-green-600 hover:text-white" />
                                        </div>
                                        <span className="font-header text-xl lg:text-2xl">
                                            Reuse
                                        </span>
                                    </div>
                                    <h2 className="text-xl lg:text-[26px] text-start w-[90%]">
                                        A subscriber-only Apple Wallet card that
                                        helps identify the nearest reuse-enabled
                                        restaurants
                                    </h2>
                                </div>
                            </div> */}
						</div>

						<h2 className='text-xl lg:text-3xl w-full px-4 mb-4 w-[90%] lg:w-[70%] mx-auto mt-3 sm:mt-6'>
							Or, upgrade to{' '}
							<span className='font-header'>$12.99/mo</span> and
							also receive…
						</h2>
						<div className='grid grid-cols-1 sm:grid-cols-2 gap-5 row-auto w-[90%] sm:w-[65%] mx-auto'>
							<div className='z-10 flex items-center p-5 lg:py-10 lg:pl-10 mx-auto shadow-light-grey border-2 border-green-600 bg-white rounded-xl'>
								<div className='flex flex-row text-start w-[90%] items-center mb-3'>
									<div className='w-12 h-12 mr-2 rounded-full bg-gray-200 hover:bg-green-600 flex items-center justify-center'>
										<FaMoneyBillWave className='z-20 w-8 h-8 text-green-600 hover:text-white' />
									</div>
									<span className='text-xl lg:text-[26px] ml-3'>
										Reimbursed opt-in fees (99¢) for
										ordering in reusable containers
									</span>
								</div>
							</div>
							<div className='z-10 flex items-center p-5 lg:py-10 lg:pl-10 mx-auto shadow-light-grey border-2 border-green-600 bg-white rounded-xl mb-6'>
								<div className='flex flex-row text-start w-[90%] items-center mb-3'>
									<div className='w-12 h-12 mr-2 rounded-full bg-gray-200 hover:bg-green-600 flex items-center justify-center'>
										<FaHome className='z-20 w-8 h-8 text-green-600 hover:text-white' />
									</div>
									<span className='text-xl lg:text-[26px] ml-3'>
										Free curbside pickups of your used
										containers
									</span>
								</div>
							</div>
						</div>
					</div>

                    {!existingMember && !loggedIn && (
                        <div className='flex flex-col sm:flex-row justify-center items-center mx-auto px-4 text-center mb-12'>
                            <Button
                                text='Sign up'
                                color='red'
                                size='sm'
                                className='flex w-[210px] shadow-light-grey'
                                onClick={() => {
                                    navigate(
                                        '/sign-up/membership'
                                    )
                                }}
                            />
							<span className='flex text-center mt-3 sm:mt-0 text-lg'>OR</span>
                            <Button
                                text='Login'
                                color='red'
                                size='sm'
                                className='flex w-[210px] shadow-light-grey mt-3 sm:mt-0'
                                onClick={() => {
                                    navigate(
                                        '/login/membership'
                                    )
                                }}
                            />
                        </div>
                    )}
				</div>
			</div>
		</>
	)
}

const mapStateToProps = ({ User, Path, Payments, AppInfo }) => ({
	loggedIn: User.loggedIn,
	userError: User.error,
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	path: Path.path,
	appInfo: AppInfo.appInfo,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MembershipLanding)
