import {
	GET_APP_INFO,
	GET_APP_INFO_SUCCESS,
	GET_APP_INFO_FAILURE,
	GET_BANNER,
	GET_BANNER_SUCCESS,
	GET_BANNER_FAILURE,
} from './actionTypes'

const initialState = {
	appInfo: {},
	bannerInfo: null,
	error: null,
}

const appInfo = (state = initialState, action) => {
	switch (action.type) {
		case GET_APP_INFO:
			return {
				...state,
			}
		case GET_APP_INFO_SUCCESS:
			return {
				...state,
				appInfo: action.payload,
			}
		case GET_APP_INFO_FAILURE:
			return {
				...state,
				error: action.payload,
			}
		case GET_BANNER:
			return {
				...state,
			}
		case GET_BANNER_SUCCESS:
			return {
				...state,
				bannerInfo: action.payload,
			}
		case GET_BANNER_FAILURE:
			return {
				...state,
				error: action.payload,
			}

		default:
			return state
	}
}

export default appInfo