import {
	DISPUTE_TRANSACTION,
	DISPUTE_TRANSACTION_SUCCESS,
	GET_NODE_TRANSACTIONS,
	GET_NODE_TRANSACTIONS_FAIL,
	GET_NODE_TRANSACTIONS_SUCCESS,
	GET_ONE_TRANSACTION,
	GET_ONE_TRANSACTION_SUCCESS,
	GET_TRANSACTIONS,
	GET_TRANSACTIONS_BY_CLIENT,
	GET_TRANSACTIONS_FAIL,
	GET_TRANSACTIONS_SUCCESS,
	ADMIN_UPLOAD_ORDERS, ADMIN_UPLOAD_ORDERS_ERROR,
	ADMIN_UPLOAD_ORDERS_SUCCESS, UPDATE_ONE_TRANSACTION, UPDATE_ONE_TRANSACTION_SUCCESS, UPDATE_ONE_TRANSACTION_ERROR
} from './actionTypes'
import { } from "../admin/actionTypes";

export const getTransactions = (payload) => ({
	type: GET_TRANSACTIONS,
	payload: payload,
})

export const getTransactionsSuccess = (payload) => ({
	type: GET_TRANSACTIONS_SUCCESS,
	payload: payload,
})

export const getTransactionsFail = (error) => ({
	type: GET_TRANSACTIONS_FAIL,
	payload: error,
})

export const getOneTransaction = (payload) => ({
	type: GET_ONE_TRANSACTION,
	payload: payload,
})

export const getOneTransactionSuccess = (payload) => ({
	type: GET_ONE_TRANSACTION_SUCCESS,
	payload: payload,
})

export const disputeTransaction = (payload) => ({
	type: DISPUTE_TRANSACTION,
	payload: payload,
})

export const disputeTransactionSuccess = (payload) => ({
	type: DISPUTE_TRANSACTION_SUCCESS,
	payload: payload,
})

export const getTransactionsByClient = (payload) => ({
	type: GET_TRANSACTIONS_BY_CLIENT,
	payload: payload,
})

export const getNodeTransactions = (payload) => ({
	type: GET_NODE_TRANSACTIONS,
	payload: payload,
})

export const getNodeTransactionsSuccess = (payload) => ({
	type: GET_NODE_TRANSACTIONS_SUCCESS,
	payload: payload,
})

export const getNodeTransactionsFail = (error) => ({
	type: GET_NODE_TRANSACTIONS_FAIL,
	payload: error,
})


export const adminUploadOrders = (payload) => ({
	type: ADMIN_UPLOAD_ORDERS,
	payload: payload,
})

export const adminUploadOrdersSuccess = (payload) => ({
	type: ADMIN_UPLOAD_ORDERS_SUCCESS,
	payload: payload,
})

export const adminUploadOrdersError = (payload) => ({
	type: ADMIN_UPLOAD_ORDERS_ERROR,
	payload: payload,
})

export const updateOneTransaction = (payload) => ({
	type: UPDATE_ONE_TRANSACTION,
	payload: payload,
})

export const updateOneTransactionSuccess = (payload) => ({
	type: UPDATE_ONE_TRANSACTION_SUCCESS,
	payload: payload,
})

export const updateOneTransactionError = (payload) => ({
	type: UPDATE_ONE_TRANSACTION_ERROR,
	payload: payload,
})