import { realm } from '../helpers/realm'

export const getGeneratedPromo = async (payload) => {
	// console.log('getGeneratedPromo payload', payload)
	const response = await realm.currentUser.callFunction(
		'promo/generatePromoCodes',
		payload
	)
	// console.log('getGeneratedPromo response', response)
	return response
}

export const validatePromoCode = async (payload) => {
	// console.log('validatePromo payload', payload)
	const response = await realm.currentUser.callFunction(
		'promo/validatePromoCode',
		payload
	)
	// console.log('validatePromo response', response)
	return response
}

export const getGeneratedGiftCard = async (payload) => {
	// console.log('generateGiftCard payload', payload)
	const response = await realm.currentUser.callFunction(
		'promo/generateGiftCard',
		payload
	)
	// console.log('generateGiftCard response', response)
	return response
}

export const validateGiftCardCode = async (payload) => {
	// console.log('validateGiftCard payload', payload)
	const response = await realm.currentUser.callFunction(
		'promo/validateGiftCard',
		payload
	)
	// console.log('validateGiftCard response', response)
	return response
}

export const updateGiftCardBalance = async (payload) => {
	// console.log('updateGiftCardBalance payload', payload)
	const response = await realm.currentUser.callFunction(
		'promo/updateGiftcardBalance',
		payload
	)
	// console.log('updateGiftCardBalance response', response)
	return response
}

export const updatePromoCode = async (payload) => {
	// console.log('updatePromoCode payload', payload)
	const response = await realm.currentUser.callFunction(
		'promo/updatePromo',
		payload
	)
	// console.log('updatePromo response', response)
	return response
}

export const getGiftCardCustomer = async (payload) => {
	// console.log('getGiftCardCustomer payload', payload)
	const response = await realm.currentUser.callFunction(
		'promo/getGiftCardCustomer',
		payload
	)
	// console.log('getGiftCardCustomer response', response)
	return response
}

export const getAllPromoIds = async () => {
	const client = realm.currentUser.mongoClient('RealmService')
	const promos = client.db('caas').collection('promos')
	
	const promoIds = []
  
	const cursor = await promos.find({}, { promoId: 1 })
  
	cursor.forEach((doc) => {
	  promoIds.push(doc.promoId)
	})
  
	return promoIds
}

export const resetPromo = () => {
    return true
}
  