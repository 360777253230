import { call, put, takeEvery } from 'redux-saga/effects';
import { runAggregationSuccess, runAggregationError } from './actions';
import { RUN_AGGREGATION } from './actionTypes';
import {runAggregationApi} from "../../model/aggregation";

function* runAggregation(action) {
    try {
        const result = yield call(runAggregationApi, action.payload);
        yield put(runAggregationSuccess(result));
    } catch (error) {
        yield put(runAggregationError(error));
    }
}

function* aggregationSaga() {
    yield takeEvery(RUN_AGGREGATION, runAggregation);
}

export default aggregationSaga;
