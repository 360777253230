import {realm} from "../helpers/realm";

export const runAggregationApi = async (aggregationPayload) => {
    const functionName = aggregationPayload.functionName
    const aggregationResult = await realm.currentUser.callFunction('aggregation/' + functionName, aggregationPayload)
    const aggregationResultObject = {
        [functionName]: aggregationResult[0]
    }

    return aggregationResultObject
}