import { realm } from "../helpers/realm";

export const getDropoffQuote = async (dropoffPayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const quoteResponse = await realm.currentUser.callFunction(
        "doordash/getDropoffQuote",
        dropoffPayload
    );

    return quoteResponse;
};

export const createDropoff = async (dropoffPayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const dropoffResponse = await realm.currentUser.callFunction(
        "doordash/acceptDropoffQuote",
        dropoffPayload
    );

    return dropoffResponse;
};

export const getDropoffStatus = async (dropoffPayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const dropoffResponse = await realm.currentUser.callFunction(
        "doordash/getDropoffStatus",
        dropoffPayload
    );

    return dropoffResponse;
};

// export const getDropoffs = async () => {
//     const client = realm.currentUser.mongoClient("RealmService");
//     const dropoffs = client.db("caas").collection("dropoffs");
//     const allDropoffs = await dropoffs.find({ status: { $ne: "delivered" } });

//     const dropoffsWithFormattedDate = allDropoffs.map(dropoff => {
//         const updatedTimestamp = new Date(dropoff.updateTimestamp);
//         return { ...dropoff, updateTimestamp: updatedTimestamp };
//     });

//     const sortedDropoffs = dropoffsWithFormattedDate.sort((a, b) => b.updateTimestamp - a.updateTimestamp);

//     return sortedDropoffs;
// };

export const getDropoffs = async (payload) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const dropoffs = client.db("caas").collection("dropoffs");

    const { filter, options } = payload;
    const { sort, skip, limit } = options;

    const stages = [];

    if (filter) {
        stages.push({ $match: filter });
    }

    if (sort) {
        stages.push({ $sort: sort });
    }

    stages.push({
        $facet: {
            pagination: [
                {
                    $count: "total",
                },
            ],
            data: [{ $skip: skip }, { $limit: limit }],
        },
    });

    return dropoffs.aggregate(stages);
};

export const cancelDropoff = async (id) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const dropoffResponse = await realm.currentUser.callFunction(
        "doordash/cancelDropoff",
        id
    );

    return dropoffResponse;
};

export const createOptimizedRoute = async (routePayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const routeResponse = await realm.currentUser.callFunction(
        "google/CreateOptimizedRoute",
        routePayload
    );

    return routeResponse;
};

export const getDeliverZeroCustomerPickups = async () => {
    const client = realm.currentUser.mongoClient("RealmService");
    const transactions = client.db("caas").collection("transactions");
    const pendingPickups = client.db("caas").collection("pending_pickup");

    const currentDate = new Date();

    const currentDayOfWeek = currentDate.getUTCDay();
    const daysUntilNextMonday = (1 - currentDayOfWeek + 7) % 7 || 7;
    const startQueryDate = new Date(currentDate);

    if (currentDayOfWeek === 1) {
        // Today is Monday
    } else {
        // Today is not Monday, set startQueryDate to the following Monday
        startQueryDate.setUTCDate(
            currentDate.getUTCDate() - daysUntilNextMonday
        );
    }

    startQueryDate.setUTCHours(7, 0, 0, 0); // Set time to midnight MST
    const endQueryDate = new Date(currentDate); // Set endQueryDate to currentDate
    endQueryDate.setDate(currentDate.getDate() + 1);
    endQueryDate.setUTCHours(7, 0, 0, 0); // Set time to midnight MST next day
    // console.log(startQueryDate, endQueryDate)

    const dzPickupsTransactions = await transactions.find({
        reverseLogisticsPartner: {
            $in: ["deliverzero", "flot"],
        },
        timestamp: {
            $gte: startQueryDate,
            $lt: endQueryDate,
        },
    });

    const dzPickupsPending = await pendingPickups.find({
        reverseLogisticsPartner: {
            $in: ["deliverzero", "flot"],
        },
        timestamp: {
            $gte: startQueryDate,
            $lt: endQueryDate,
        },
    });

    const dzPickups = [...dzPickupsPending, ...dzPickupsTransactions];

    return dzPickups;
};

export const getAdminPlatforms = async () => {
    const client = realm.currentUser.mongoClient("RealmService");
    const clients = client.db("caas").collection("nodes");

    const pipeline = [
        {
            $project: {
                keys: { $objectToArray: "$thirdPartyClient" },
            },
        },
        {
            $unwind: "$keys",
        },
        {
            $group: {
                _id: null,
                distinctKeys: { $addToSet: "$keys.k" },
            },
        },
        {
            $project: {
                _id: 0,
                distinctKeys: 1,
            },
        },
    ];

    const aggregateKeys = await clients.aggregate(pipeline);
    const distinctKeys = aggregateKeys[0].distinctKeys.filter(
        (key) => key !== ""
    );

    return distinctKeys;
};

export const createGentlyDropoff = async (dropoffPayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const dropoffResponse = await realm.currentUser.callFunction(
        "gently/dropoffRequest",
        dropoffPayload
    );

    return dropoffResponse;
};

export const createGentlyDropoffNoNode = async (dropoffPayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const dropoffResponse = await realm.currentUser.callFunction(
        "gently/dropoffRequestNoNode",
        dropoffPayload
    );

    return dropoffResponse;
};

export const getDropoffById = async (payload) => {
    const client = realm.currentUser.mongoClient("RealmService");
    const dropoffs = client.db("caas").collection("dropoffs");
    const dropoff = await dropoffs.findOne({ orderId: payload });

    return dropoff;
};

export const createFlotDropoff = async (dropoffPayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const dropoffResponse = await realm.currentUser.callFunction(
        "flot/dropoffRequest",
        dropoffPayload
    );

    return dropoffResponse;
};

export const createFlotDropoffNoNode = async (dropoffPayload) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const dropoffResponse = await realm.currentUser.callFunction(
        "flot/dropoffRequestNoNode",
        dropoffPayload
    );

    return dropoffResponse;
};

export const cancelFlotDropoff = async (orderId) => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const response = await realm.currentUser.callFunction(
        "flot/cancelShipment",
        orderId
    );

    return response;
};

export const getAllFlotDropoffs = async () => {
    if (!realm.currentUser) {
        throw new Error("Unauthorized");
    }
    const response = await realm.currentUser.callFunction("flot/listShipments");

    return response;
};

export const resetDropoffSuccess = () => {
    return true;
};
