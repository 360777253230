import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { userRoutes, authRoutes } from './routes/allRoutes'
import PrivateRoute from './routes/middleware/PrivateRoute'
import PageNotFound from './pages/Utility/404'
import { MobileBottomNav } from './components'

function App() {
	return (
		<div className="h-screen w-screen">
			<Routes className=''>
				{userRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={
							<PrivateRoute
								component={route.component}
								path={route.path}
							/>
						}
						key={idx}
					/>
				))}
				{authRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={route.component}
						key={idx}
					/>
				))}
				<Route
					path='*'
					element={<PageNotFound />}
				/>
			</Routes>
			<MobileBottomNav />
		</div>
	)
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps, null)(App)
