import {
	SEND_RESET_PASSWORD,
	SEND_RESET_PASSWORD_ERROR,
	SEND_RESET_PASSWORD_SUCCESS,
} from './actionTypes'

const initialState = {
	sendingResetPassword: false,
	forgetSuccessMsg: null,
	forgetError: null,
}

const forgetPassword = (state = initialState, action) => {
	switch (action.type) {
		case SEND_RESET_PASSWORD:
			state = {
				...state,
				sendingResetPassword: true,
				forgetSuccessMsg: null,
				forgetError: null,
			}
			break
		case SEND_RESET_PASSWORD_SUCCESS:
			state = {
				...state,
				sendingResetPassword: false,
				forgetSuccessMsg: action.payload,
			}
			break
		case SEND_RESET_PASSWORD_ERROR:
			state = {
				...state,
				sendingResetPassword: false,
				forgetError: action.payload,
			}
			break
		default:
			state = { ...state }
			break
	}
	return state
}

export default forgetPassword
