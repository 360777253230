export const GENERATE_PROMO = 'GENERATE_PROMO'
export const GENERATE_PROMO_SUCCESS = 'GENERATE_PROMO_SUCCESS'
export const GENERATE_PROMO_ERROR = 'GENERATE_PROMO_ERROR'
export const VALIDATE_PROMO = 'VALIDATE_PROMO'
export const VALIDATE_PROMO_SUCCESS = 'VALIDATE_PROMO_SUCCESS'
export const VALIDATE_PROMO_ERROR = 'VALIDATE_PROMO_ERROR'
export const GENERATE_GIFT_CARD = 'GENERATE_GIFT_CARD'
export const GENERATE_GIFT_CARD_SUCCESS = 'GENERATE_GIFT_CARD_SUCCESS'
export const GENERATE_GIFT_CARD_ERROR = 'GENERATE_GIFT_CARD_ERROR'
export const VALIDATE_GIFT_CARD = 'VALIDATE_GIFT_CARD'
export const VALIDATE_GIFT_CARD_SUCCESS = 'VALIDATE_GIFT_CARD_SUCCESS'
export const VALIDATE_GIFT_CARD_ERROR = 'VALIDATE_GIFT_CARD_ERROR'
export const UPDATE_GIFT_CARD = 'UPDATE_GIFT_CARD'
export const UPDATE_GIFT_CARD_SUCCESS = 'UPDATE_GIFT_CARD_SUCCESS'
export const UPDATE_GIFT_CARD_ERROR = 'UPDATE_GIFT_CARD_ERROR'
export const UPDATE_PROMO = 'UPDATE_PROMO'
export const UPDATE_PROMO_SUCCESS = 'UPDATE_PROMO_SUCCESS'
export const UPDATE_PROMO_ERROR = 'UPDATE_PROMO_ERROR'
export const GET_GIFT_CARD_CUSTOMER = 'GET_GIFT_CARD_CUSTOMER'
export const GET_GIFT_CARD_CUSTOMER_SUCCESS = 'GET_GIFT_CARD_CUSTOMER_SUCCESS'
export const GET_GIFT_CARD_CUSTOMER_ERROR = 'GET_GIFT_CARD_CUSTOMER_ERROR'
export const GET_PROMO_IDS = 'GET_PROMO_IDS'
export const GET_PROMO_IDS_SUCCESS = 'GET_PROMO_IDS_SUCCESS'
export const GET_PROMO_IDS_ERROR = 'GET_PROMO_IDS_ERROR'
export const RESET_PROMO = 'RESET_PROMO'
