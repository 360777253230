import React from 'react'
import { BounceLoader } from 'react-spinners'
const bounceLoaderColor = '#507f74'

const BounceLoaderCentered = ({ container }) => {
	let finalClass = 'w-full flex justify-center items-center'
	if (container === 'div') { 
		finalClass += ' h-full'
	}
	if (container === 'screen') {
		finalClass += ' h-screen'
	}
  return (
		<div className={finalClass}>
			<BounceLoader
				className='m-auto'
				color={bounceLoaderColor}
			></BounceLoader>
		</div>
  )
}

export default BounceLoaderCentered