import {
	SEND_RESET_PASSWORD,
	SEND_RESET_PASSWORD_SUCCESS,
	SEND_RESET_PASSWORD_ERROR,
} from './actionTypes'

export const sendResetPassword = (email) => {
	return {
		type: SEND_RESET_PASSWORD,
		payload: email,
	}
}

export const sendResetPasswordSuccess = (message) => {
	return {
		type: SEND_RESET_PASSWORD_SUCCESS,
		payload: message,
	}
}

export const sendResetPasswordError = (message) => {
	return {
		type: SEND_RESET_PASSWORD_ERROR,
		payload: message,
	}
}
