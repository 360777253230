export const googleMapsLibraries = ['places']

export const convertMilesToMeters = (miles) => {
	const meters = miles * 1609.34
	return Math.floor(meters)
}

export const calculateDistanceBetweenCoords = (coords1, coords2) => {
	const R = 6378137
	// console.log('coord1',coords1)
	// console.log('coord2',coords2)
	const lat1 = coords1[0]
	const lat2 = coords2[1]
	const lon1 = coords1[1]
	const lon2 = coords2[0]

	const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180
	const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos((lat1 * Math.PI) / 180) *
			Math.cos((lat2 * Math.PI) / 180) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	const d = R * c
	return d
}

export const isAddressInsideDeliveryPolygon = (point, vs) => {
	let x = point[0],
		y = point[1]
	let inside = false
	for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
		let xi = vs[i][0],
			yi = vs[i][1]
		let xj = vs[j][0],
			yj = vs[j][1]

		let intersect =
			yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
		if (intersect) inside = !inside
	}
	return inside
}

