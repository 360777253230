import React, { useState } from 'react'
import { BsInstagram } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Button, FormGroup, Input } from './Elements'
import { BounceLoader } from 'react-spinners'
import { realm } from '../helpers/realm'
import { connect } from 'react-redux'

const Footer = (props) => {
	const { user, path } = props
	const [email, setEmail] = useState('')
	const [submittingEmail, setSubmittingEmail] = useState(false)
	const [emailSuccess, setEmailSuccess] = useState(false)
	const [emailError, setEmailError] = useState('')
	const bounceLoaderColor = '#507f74'

	const handleEmailChange = (emailChange) => {
		setEmail(emailChange.target.value)
	}

	const handleSubscribe = async () => {
		setEmailError('')
		setEmailSuccess(false)
		setSubmittingEmail(true)
		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (re.test(email)) {
			const subscriberStatus = await realm.currentUser.callFunction(
				'mailChimp/AddUserToEmailList',
				{
					email: email,
					phoneNumber: user ? user.phone : '',
					firstName: user ? user.firstName : '',
					lastName: user ? user.lastName : '',
				}
			)
			if (subscriberStatus == 'error') {
				setSubmittingEmail(false)
				setEmailError('An error occured. Try again.')
			} else {
				setEmailSuccess(true)
				setSubmittingEmail(false)
			}
		} else {
			// invalid email, maybe show an error to the user.
			setSubmittingEmail(false)
			setEmailError('Invalid email address. Try again.')
		}
	}

	return (
		<>
			<div className='h-[410px] sm:hidden flex flex-col bg-gray-100 mb-[35px] sm:mb-0'>
				<div className=' pt-6 mt-12 w-full text-center max-w-lg mx-auto'>
					<Link to='/'>
						<img
							className='m-auto w-[179px] text-black-default'
							src='/images/Small primary.svg'
							alt=''
						/>
					</Link>
				</div>
				<div className='my-7 flex flex-row justify-center'>
					<a
						href='https://www.instagram.com/deliverzer0/'
						target='_blank'
						rel='noreferrer'
					>
						<BsInstagram className='text-green-400 text-[44px]' />
					</a>
					<a
						href='https://www.tiktok.com/@deliverzero'
						target='_blank'
						rel='noreferrer'
					>
						<FaTiktok className='ml-3 text-green-400 text-[40px]' />
					</a>
				</div>
				<div className='mb-8 flex flex-row justify-center'>
					<div className='w-[100px] flex flex-col'>
						<Link
							to='/howitworks'
							className='text-sm'
						>
							How it Works
						</Link>
						<Link
							to='/whyreuse'
							className='mt-5 text-sm'
						>
							Why Reuse?
						</Link>
						<Link
							to='/aboutus'
							className='mt-5 text-sm'
						>
							About Us
						</Link>
						<Link
							to='/restaurant-admin/verification'
							className='mt-5 text-sm'
						>
							Merchant Dashboard
						</Link>
					</div>
					<div className='ml-5 flex flex-col'>
						<Link
							to='/rewards-marketplace'
							className='mt-5 text-sm'
						>
							Rewards Marketplace
						</Link>
						<Link
							to='/restaurantpartners'
							className='mt-5 text-sm'
						>
							For Restaurant Partners
						</Link>
						<Link
							to='/enterprise'
							className='mt-5 text-sm'
						>
							DeliverZero at Work
						</Link>
						<Link
							to='/press'
							className='mt-5 text-sm'
						>
							Press
						</Link>
					</div>
				</div>
				<div className='flex flex-row justify-center'>
					<Link
						to='/privacy'
						className='text-sm'
					>
						Privacy Policy
					</Link>
					<Link
						to='/terms'
						className='ml-10 text-sm'
					>
						Terms of Use
					</Link>
				</div>
				<div className='flex mt-2 flex-row justify-center text-xs text-gray-300'>
					<div>DeliverZero &#169; {new Date().getFullYear()}</div>
					<div className='ml-3'>All rights reserved</div>
				</div>
			</div>
			<div className='px-[10%] py-[0.75%] h-100 hidden sm:flex sm:flex-col bg-gray-100'>
				<div className='flex flex-row'>
					<div className='min-w-[230px] flex flex-col'>
						<div className='mt-11 md:mb-[84px] w-[179px] border text-left max-w-lg'>
							<Link to='/'>
								<img
									className='m-auto text-black-default'
									src='/images/Small primary.svg'
									alt=''
								/>
							</Link>
						</div>

						<div className='hidden sm:flex md:hidden my-5 flex-row'>
							<a
								href='https://www.instagram.com/deliverzer0/'
								target='_blank'
								rel='noreferrer'
							>
								<BsInstagram className='text-green-400 text-[44px]' />
							</a>
							<a
								href='https://www.tiktok.com/@deliverzero'
								target='_blank'
								rel='noreferrer'
							>
								<FaTiktok className='ml-3 text-green-400 text-[40px]' />
							</a>
						</div>
					</div>
					<div className='mt-11 ml-10 flex flex-row justify-center'>
						<div className='w-[100px] flex flex-col'>
							<Link
								to='/howitworks'
								className='text-sm'
							>
								How it Works
							</Link>
							<Link
								to='/whyreuse'
								className='mt-5 text-sm'
							>
								Why Reuse?
							</Link>
							<Link
								to='/aboutus'
								className='mt-5 text-sm'
							>
								About Us
							</Link>
							<Link
								to='/restaurant-admin/verification'
								className='mt-5 text-sm'
							>
								Merchant Dashboard
							</Link>
						</div>
						<div className='ml-12 flex flex-col'>
							<Link
								to='/rewards-marketplace'
								className='mt-5 text-sm'
							>
								Rewards Marketplace
							</Link>
							<Link
								to='/restaurantpartners'
								className='mt-5 text-sm'
							>
								For Restaurant Partners
							</Link>
							<Link
								to='/enterprise'
								className='mt-5 text-sm'
							>
								DeliverZero at Work
							</Link>
							<Link
								to='/press'
								className='mt-5 text-sm'
							>
								Press
							</Link>
						</div>
					</div>
					<div className='flex flex-col'>
						<div className='mt-11 ml-10 hidden lg:flex flex-col'>
							<div className='text-sm'>
								Sign up for our newsletter
							</div>
							{submittingEmail ? (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							) : (
								<div>
									{path === '/' ? (
										<FormGroup
											className='w-500px]'
											horizontal
										>
											<div className='flex flex-row'>
												<Input
													placeholder='Enter your email'
													className={`pl-5`}
													name={`newsletter`}
													error={false}
													type='email'
													onChange={handleEmailChange}
												/>
												<Button
													text='Subscribe'
													size='sm'
													color='green'
													className='-ml-[50px] px-2'
													onClick={handleSubscribe}
												/>
											</div>
										</FormGroup>
									) : (
										<FormGroup
											className='w-[250px]'
											horizontal
										>
											<Input
												placeholder='Enter your email'
												className={`relative pl-5`}
												name={`newsletter`}
												error={false}
												type='email'
												onChange={handleEmailChange}
											/>
											<Button
												text='Subscribe'
												size='sm'
												color='green'
												className='absolute ml-[200px] px-4'
												onClick={handleSubscribe}
											/>
										</FormGroup>
									)}
								</div>
							)}

							{emailError !== '' && (
								<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
									{emailError}
								</div>
							)}
							{emailSuccess && (
								<div className='text-green-400 mt-2 text-left font-semibold text-sm'>
									You've successfully subscribed!
								</div>
							)}
						</div>
						<div className='ml-10 mt-11 lg:mt-5 hidden md:flex my-5 flex-row'>
							<a
								href='https://www.instagram.com/deliverzer0/'
								target='_blank'
								rel='noreferrer'
							>
								<BsInstagram className='text-green-400 text-[44px]' />
							</a>
							<a
								href='https://www.tiktok.com/@deliverzero'
								target='_blank'
								rel='noreferrer'
							>
								<FaTiktok className='ml-3 text-green-400 text-[40px]' />
							</a>
						</div>
					</div>
				</div>
				<div className='flex flex-row mt-5'>
					<div className='w-[230px] flex flex-row justify-center text-xs text-gray-300 items-center'>
						<div>DeliverZero &#169; {new Date().getFullYear()}</div>
						<div className='ml-3'>All rights reserved</div>
					</div>
					<div className='flex flex-row justify-center'>
						<Link
							to='/privacy'
							className='ml-10 text-sm'
						>
							Privacy Policy
						</Link>
						<Link
							to='/terms'
							className='ml-10 text-sm'
						>
							Terms of Use
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}
const mapStateToProps = ({ User, Path }) => ({
	user: User.user,
	path: Path.path,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
