import { call, put, takeEvery } from 'redux-saga/effects'
import {
	GET_MENU_MAP,
	GET_MENU_MAP_FROM_CLIENT,
	UPDATE_MENU_MAP,
} from './actionTypes'
import {
	getMenuMapFailure,
	getMenuMapSuccess,
	updateMenuMapFailure,
	updateMenuMapSuccess,
} from './actions'
import {
	getBoxCountLookup,
	getMapFromClient,
	updateBoxCountLookup,
} from '../../model/boxCountLookUp'

function* fetchMenuMap(action) {
	try {
		const returns = yield call(getBoxCountLookup, action.payload)
		yield put(getMenuMapSuccess(returns))
	} catch (error) {
		yield put(getMenuMapFailure(error))
	}
}
function* updateMenuMap(action) {
	try {
		const customers = yield call(updateBoxCountLookup, action.payload)
		yield put(updateMenuMapSuccess(customers))
	} catch (error) {
		yield put(updateMenuMapFailure(error))
	}
}

function* fetchMenuMapFromClient(action) {
	try {
		const returns = yield call(getMapFromClient, action.payload)
		yield put(getMenuMapSuccess(returns))
	} catch (error) {
		yield put(getMenuMapFailure(error))
	}
}

function* boxCountLookUpSaga() {
	yield takeEvery(GET_MENU_MAP, fetchMenuMap)
	yield takeEvery(UPDATE_MENU_MAP, updateMenuMap)
	yield takeEvery(GET_MENU_MAP_FROM_CLIENT, fetchMenuMapFromClient)
}

export default boxCountLookUpSaga
