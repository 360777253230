export const GET_ADMIN_STATUS = 'GET_ADMIN_STATUS'
export const SET_ADMIN_STATUS = 'SET_ADMIN_STATUS'
export const ADMIN_GET_RETURNS = 'GET_RETURNS'
export const ADMIN_GET_RETURNS_SUCCESS = 'GET_RETURNS_SUCCESS'
export const ADMIN_GET_RETURNS_ERROR = 'GET_RETURNS_ERROR'
export const ADMIN_GET_CUSTOMERS = 'ADMIN_GET_CUSTOMERS'
export const ADMIN_GET_CUSTOMERS_SUCCESS = 'ADMIN_GET_CUSTOMERS_SUCCESS'
export const ADMIN_GET_CUSTOMERS_ERROR = 'ADMIN_GET_CUSTOMERS_ERROR'
export const ADMIN_GET_CUSTOMERS_BOX_COUNT = 'ADMIN_GET_CUSTOMERS_BOX_COUNT'
export const ADMIN_GET_CUSTOMERS_BOX_COUNT_SUCCESS = 'ADMIN_GET_CUSTOMERS_BOX_COUNT_SUCCESS'
export const ADMIN_GET_CUSTOMERS_BOX_COUNT_ERROR = 'ADMIN_GET_CUSTOMERS_BOX_COUNT_ERROR'
export const ADMIN_GET_CLIENTS = 'ADMIN_GET_CLIENTS'
export const ADMIN_GET_CLIENTS_SUCCESS = 'ADMIN_GET_CLIENTS_SUCCESS'
export const ADMIN_GET_CLIENTS_ERROR = 'ADMIN_GET_CLIENTS_ERROR'
export const ADMIN_GET_AGGREGATORS = 'ADMIN_GET_AGGREGATORS'
export const ADMIN_GET_AGGREGATORS_SUCCESS = 'ADMIN_GET_AGGREGATORS_SUCCESS'
export const ADMIN_GET_AGGREGATORS_ERROR = 'ADMIN_GET_AGGREGATORS_ERROR'
export const ADMIN_GET_ALL_CUSTOMERS = 'ADMIN_GET_ALL_CUSTOMERS'
export const ADMIN_GET_ALL_CUSTOMERS_SUCCESS = 'ADMIN_GET_ALL_CUSTOMERS_SUCCESS'
export const ADMIN_GET_ALL_CUSTOMERS_ERROR = 'ADMIN_GET_ALL_CUSTOMERS_ERROR'
export const ADMIN_GET_RETURNS_COUNT = 'ADMIN_GET_RETURNS_COUNT'
export const ADMIN_GET_RETURNS_COUNT_SUCCESS = 'ADMIN_GET_RETURNS_COUNT_SUCCESS'
export const ADMIN_GET_ENV_IMPACT = 'ADMIN_GET_ENV_IMPACT'
export const ADMIN_GET_ENV_IMPACT_SUCCESS = 'ADMIN_GET_ENV_IMPACT_SUCCESS'
export const ADMIN_UPLOAD_MENUS = 'ADMIN_UPLOAD_MENUS'
export const ADMIN_UPLOAD_MENUS_SUCCESS = 'ADMIN_UPLOAD_MENUS_SUCCESS'
export const ADMIN_UPLOAD_MENUS_ERROR = 'ADMIN_UPLOAD_MENUS_ERROR'
export const CREATE_ROUTE = 'CREATE_ROUTE'
export const CREATE_ROUTE_SUCCESS = 'CREATE_ROUTE_SUCCESS'
export const CREATE_ROUTE_ERROR = 'CREATE_ROUTE_ERROR'
export const GET_DZ_PICKUPS = 'GET_DZ_PICKUPS'
export const GET_DZ_PICKUPS_SUCCESS = 'GET_DZ_PICKUPS_SUCCESS'
export const GET_DZ_PICKUPS_ERROR = 'GET_DZ_PICKUPS_ERROR'
export const ADMIN_GET_PLATFORMS = 'ADMIN_GET_PLATFORMS'
export const ADMIN_GET_PLATFORMS_SUCCESS = 'ADMIN_GET_PLATFORMS_SUCCESS'
export const ADMIN_GET_PLATFORMS_ERROR = 'ADMIN_GET_PLATFORMS_ERROR'

export const ALERT_SLACK = 'ALERT_SLACK';
export const ALERT_SLACK_SUCCESS = 'ALERT_SLACK_SUCCESS';
export const ALERT_SLACK_ERROR = 'ALERT_SLACK_ERROR';

export const GET_USAGE_DATA = 'GET_USAGE_DATA'
export const GET_USAGE_DATA_SUCCESS = 'GET_USAGE_DATA_SUCCESS'
export const GET_USAGE_DATA_ERROR = 'GET_USAGE_DATA_ERROR'

