import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useState } from 'react'

import { Footer, GiftCardForm, Header } from '../../components'

const GiftCardPurchase = () => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

	return (
		<>
			<Header label="Purchase a Gift Card" showBackButton={true}/>
			<div className='flex flex-col justify-center items-center text-center px-4 py-12 h-fit min-h-full sm:h-full w-full mt-[3.25rem]'>
				<p className='w-1/3 text-center min-w-[300px] mb-6 text-sm md:text-base'>
					Give the gift of sustainable delivery with a DeliverZero gift card.
				</p>
				<div className='w-full flex flex-col justify-center items-center mb-12'>
					<Elements stripe={stripePromise}>
						<GiftCardForm />
					</Elements>
				</div>
			</div>
		</>
	)
}

export default GiftCardPurchase
