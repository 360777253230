import { takeEvery, put, call } from 'redux-saga/effects'

//Account Redux states
import { CONNECT_TO_CLIENT } from './actionTypes'
import { connectToClientSuccessful, connectToClientFailed } from './actions'
import { connectToClient } from '../../../model/auth'

function* attachToClient(action) {
	try {
		const response = yield call(connectToClient, action.payload)
		yield put(connectToClientSuccessful(response))
	} catch (error) {
		yield put(connectToClientFailed(error))
	}
}

function* oAuthSaga() {
	yield takeEvery(CONNECT_TO_CLIENT, attachToClient)
}

export default oAuthSaga
