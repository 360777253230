import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Header,
  ContainerCountCard,
  RewardPointsCard,
  UsageHistoryCard,
} from "../../components/";
import {
  getUser,
  getUserBoxCount,
  getTransactions,
  getAppInfo,
  cancelFirstDeliveryPickup,
  getPickupsByPhone,
} from "../../store/actions";
import { BounceLoaderCentered, Button } from "../../components/Elements";

const MyUsage = (props) => {
  const {
    user,
    onGetUser,
    userBoxCount,
    loadingUserBoxCount,
    boxCountError,
    onGetUserBoxCount,
    transactions,
    loadingTransactions,
    onGetTransactions,
    onGetAppInfo,
    appInfo,
    onCancelFirstDelivery,
    cancelledFirstDelivery,
    cancelingFirstDelivery,
    pickupLoading,
		pickupSuccess,
		pickupError,
		pendingPickups,
		onGetPickups,
  } = props;

  const paginationLimit = 5;
  const [transactionsPage, setTransactionsPage] = useState(1);
  const [withPickups, setWithPickups] = useState([]);

  useEffect(() => {
    onGetUser();
  }, [onGetUser]);

  useEffect(() => {
    if (user && user.phone) {
      getTransactions(transactionsPage);
      onGetUserBoxCount(user.phone);
      onGetPickups({phone: user.phone});
    }
  }, [user, user?.boxCount]);

  useEffect(() => {
    if (user && user.phone) {
      getTransactions(transactionsPage);
    }
  }, [transactionsPage]);

  useEffect(() => {
    onGetAppInfo();
  }, []);

  useEffect(() => {
    if (cancelledFirstDelivery) {
      onGetUser();
    }
  }, [cancelledFirstDelivery]);

	useEffect(() => {
		if (pendingPickups && transactions) {
			if (transactions && transactions.length > 0 && pendingPickups && pendingPickups.length > 0) {
				setWithPickups([...pendingPickups, ...transactions])
			} else if (transactions && transactions.length > 0) {
				setWithPickups([...transactions])
			} else if (pendingPickups && pendingPickups.length > 0) {
				setWithPickups([...pendingPickups])
			}
		} 
	}, [transactions, pendingPickups])

	useEffect(() => {
		console.log("")
	}, [withPickups])

  const getTransactions = (page) => {
    onGetTransactions({
      filter: {
        "user.phone": user.phone,
        type: { $ne: "adminBalanceUpdate" },
      },
      options: {
        sort: {
          timestamp: -1,
        },
        limit: page * paginationLimit,
        skip: 0,
      },
    });
  };

  if (!user) return <BounceLoaderCentered container="screen" />;
  return (
    <div className="h-auto w-screen">
      <Header label="My Usage" showBackButton={true} />
      <div className="flex flex-col items-center justify-center pt-5 mt-[3.25rem]">
        <div className="w-full flex items-center justify-center">
          {userBoxCount ? (
            userBoxCount.currentPackagingOut < 0 ? (
              <ContainerCountCard boxCount={0} />
            ) : (
              <ContainerCountCard boxCount={userBoxCount.currentPackagingOut} />
            )
          ) : (
            <BounceLoaderCentered />
          )}
        </div>
        <Button
          link="/late-payments"
          className="px-5 my-6 shadow-light-grey py-2 !text-xs"
          text="Pay for Late Containers"
          size="sm"
        />
        <div className="w-full mb-4 flex items-center justify-center">
          <RewardPointsCard rewardPoints={user.rewardPoints} />
        </div>
        <div className="w-full mb-4 flex items-center justify-center">
          <UsageHistoryCard
            loadingTransactions={loadingTransactions}
            transactions={withPickups}
            appInfo={appInfo}
            onCancelFirstDelivery={onCancelFirstDelivery}
            cancelingFirstDelivery={cancelingFirstDelivery}
            transactionPage={transactionsPage}
            setTransactionPage={setTransactionsPage}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  User,
  Transactions,
  AppInfo,
  ReverseLogistics,
  Pickups,
}) => ({
  user: User.user,
  userBoxCount: User.userBoxCount,
  loadingUserBoxCount: User.loadingUserBoxCount,
  boxCountError: User.boxCountError,
  transactions: Transactions.transactions,
  transactionsCount: Transactions.transactionsCount,
  loadingTransactions: Transactions.loadingTransactions,
  appInfo: AppInfo.appInfo,
  cancelledFirstDelivery: ReverseLogistics.cancelledFirstDelivery,
  cancelingFirstDeliveryError: ReverseLogistics.cancelingFirstDeliveryError,
  cancelingFirstDelivery: ReverseLogistics.cancelingFirstDelivery,
	pickupLoading: Pickups.pickupLoading,
	pickupSuccess: Pickups.pickupSuccess,
	pickupError: Pickups.pickupError,
	pendingPickups: Pickups.pendingPickups,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUser: () => dispatch(getUser()),
  onGetUserBoxCount: (payload) => dispatch(getUserBoxCount(payload)),
  onGetTransactions: (payload) => dispatch(getTransactions(payload)),
  onGetAppInfo: () => dispatch(getAppInfo()),
  onCancelFirstDelivery: (payload) =>
    dispatch(cancelFirstDeliveryPickup(payload)),
  onGetPickups: (payload) => dispatch(getPickupsByPhone(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyUsage);
