import { call, put, takeEvery } from 'redux-saga/effects';
import { getOfficeSuccess, getOfficeError } from './actions';
import { GET_OFFICE } from './actionTypes';
import {getOfficeApi} from "../../model/officeOrdering";


function* getOffice(action) {
    try {
        const response = yield call(getOfficeApi, action.payload);
        yield put(getOfficeSuccess(response));
    } catch (error) {
        yield put(getOfficeError(error));
    }
}

function* officeSaga() {
    yield takeEvery(GET_OFFICE, getOffice);
}

export default officeSaga;
