import {
    CREATE_STOP,
    CREATE_STOP_SUCCESS,
    CREATE_STOP_ERROR,
    UPDATE_STOP,
    UPDATE_STOP_SUCCESS,
    UPDATE_STOP_ERROR,
    DELETE_STOP,
    DELETE_STOP_SUCCESS,
    DELETE_STOP_ERROR,
    GET_ALL_LOGISTICS,
    GET_ALL_LOGISTICS_SUCCESS,
    GET_ALL_LOGISTICS_ERROR,
    RESET_SUCCESS,
    LOGISTICS_ALERT,
    LOGISTICS_ALERT_SUCCESS,
    LOGISTICS_ALERT_ERROR,
    GET_INCOMPLETE_STOPS,
    GET_INCOMPLETE_STOPS_ERROR,
    GET_INCOMPLETE_STOPS_SUCCESS,
    GET_INVENTORY,
    GET_INVENTORY_ERROR,
    GET_INVENTORY_SUCCESS,
    GET_NEW_RESTAURANT_STOPS,
    GET_NEW_RESTAURANT_STOPS_ERROR,
    GET_NEW_RESTAURANT_STOPS_SUCCESS,
    GET_LOGISTICS_DASHBOARD,
    GET_LOGISTICS_DASHBOARD_SUCCESS,
    GET_LOGISTICS_DASHBOARD_ERROR,
    GET_RETURN_BIN_BOX_COUNT,
    GET_RETURN_BIN_BOX_COUNT_ERROR,
    GET_RETURN_BIN_BOX_COUNT_SUCCESS,
    GET_UBER_DIRECT_QUOTE,
    GET_UBER_DIRECT_QUOTE_SUCCESS,
    GET_UBER_DIRECT_QUOTE_ERROR,
    CREATE_UBER_DIRECT_REQUEST,
    CREATE_UBER_DIRECT_REQUEST_SUCCESS,
    CREATE_UBER_DIRECT_REQUEST_ERROR,
    UPDATE_UBER_DIRECT,
    UPDATE_UBER_DIRECT_SUCCESS,
    UPDATE_UBER_DIRECT_ERROR,
    GET_ALL_UBER_DIRECT,
    GET_ALL_UBER_DIRECT_SUCCESS,
    GET_ALL_UBER_DIRECT_ERROR,
    CANCEL_UBER_DIRECT,
    CANCEL_UBER_DIRECT_SUCCESS,
    CANCEL_UBER_DIRECT_ERROR,
    GET_UBER_DIRECT_REQUEST_BY_ID,
    GET_UBER_DIRECT_REQUEST_BY_ID_SUCCESS,
    GET_UBER_DIRECT_REQUEST_BY_ID_ERROR,
    GET_UBER_DIRECT_QUOTE_DROPOFF,
    GET_UBER_DIRECT_QUOTE_DROPOFF_ERROR,
    GET_UBER_DIRECT_QUOTE_DROPOFF_SUCCESS,
    GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE,
    GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_ERROR,
    GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_SUCCESS,
    GET_UBER_DIRECT_REQUEST_DOC_BY_ID,
    GET_UBER_DIRECT_REQUEST_DOC_BY_ID_ERROR,
    GET_UBER_DIRECT_REQUEST_DOC_BY_ID_SUCCESS,
    GET_MARKET,
	GET_MARKET_SUCCESS,
	GET_MARKET_ERROR,
    ADJUST_INVENTORY,
    ADJUST_INVENTORY_ERROR,
    ADJUST_INVENTORY_SUCCESS,
    GET_MARKET_OBJ,
    GET_MARKET_OBJ_ERROR,
    GET_MARKET_OBJ_SUCCESS,
    CREATE_PACKING_LIST_TRANSACTIONS,
    CREATE_PACKING_LIST_TRANSACTIONS_ERROR,
    CREATE_PACKING_LIST_TRANSACTIONS_SUCCESS,
    GET_PROOF_OF_DELIVERY,
    GET_PROOF_OF_DELIVERY_ERROR,
    GET_PROOF_OF_DELIVERY_SUCCESS,
} from './actionTypes';

export const createStop = (payload) => ({
    type: CREATE_STOP,
    payload: payload,
});

export const createStopSuccess = (payload) => ({
    type: CREATE_STOP_SUCCESS,
    payload: payload,
});

export const createStopError = (payload) => ({
    type: CREATE_STOP_ERROR,
    payload: payload,
});

export const updateStop = (payload) => ({
    type: UPDATE_STOP,
    payload: payload,
});

export const updateStopSuccess = (payload) => ({
    type: UPDATE_STOP_SUCCESS,
    payload: payload,
});

export const updateStopError = (payload) => ({
    type: UPDATE_STOP_ERROR,
    payload: payload,
});

export const deleteStop = (payload) => ({
    type: DELETE_STOP,
    payload: payload,
});

export const deleteStopSuccess = (payload) => ({
    type: DELETE_STOP_SUCCESS,
    payload: payload,
});

export const deleteStopError = (payload) => ({
    type: DELETE_STOP_ERROR,
    payload: payload,
});

export const getAllLogistics = (filter) => ({
    type: GET_ALL_LOGISTICS,
    payload: filter,
});

export const getAllLogisticsSuccess = (payload) => ({
    type: GET_ALL_LOGISTICS_SUCCESS,
    payload: payload,
});

export const getAllLogisticsError = (payload) => ({
    type: GET_ALL_LOGISTICS_ERROR,
    payload: payload,
});

export const resetSuccess = () => {
    return { type: RESET_SUCCESS };
};

export const logisticsAlert = (payload) => ({
    type: LOGISTICS_ALERT,
    payload: payload,
});

export const logisticsAlertSuccess = (payload) => ({
    type: LOGISTICS_ALERT_SUCCESS,
    payload: payload,
});

export const logisticsAlertError = (error) => ({
    type: LOGISTICS_ALERT_ERROR,
    payload: error,
});

export const getIncompleteStops = (filter) => ({
    type: GET_INCOMPLETE_STOPS,
    payload: filter,
});
  
export const getIncompleteStopsSuccess = (payload) => ({
    type: GET_INCOMPLETE_STOPS_SUCCESS,
    payload: payload,
});
  
export const getIncompleteStopsError = (error) => ({
    type: GET_INCOMPLETE_STOPS_ERROR,
    payload: error,
});

export const getNewRestaurantStops = (filter) => ({
    type: GET_NEW_RESTAURANT_STOPS,
    payload: filter,
});
  
export const getNewRestaurantStopsSuccess = (payload) => ({
    type: GET_NEW_RESTAURANT_STOPS_SUCCESS,
    payload: payload,
});
  
export const getNewRestaurantStopsError = (error) => ({
    type: GET_NEW_RESTAURANT_STOPS_ERROR,
    payload: error,
});

export const getInventory = (payload) => ({
    type: GET_INVENTORY,
    payload: payload,
});

export const getInventorySuccess = (payload) => ({
    type: GET_INVENTORY_SUCCESS,
    payload: payload,
});

export const getInventoryError = (error) => ({
    type: GET_INVENTORY_ERROR,
    payload: error,
});

export const getLogisticsDashboard = (payload) => ({
    type: GET_LOGISTICS_DASHBOARD,
    payload: payload,
});

export const getLogisticsDashboardSuccess = (payload) => ({
    type: GET_LOGISTICS_DASHBOARD_SUCCESS,
    payload: payload,
});

export const getLogisticsDashboardError = (error) => ({
    type: GET_LOGISTICS_DASHBOARD_ERROR,
    payload: error,
});

export const getReturnBinBoxCount = (payload) => ({
    type: GET_RETURN_BIN_BOX_COUNT,
    payload: payload,
});

export const getReturnBinBoxCountSuccess = (payload) => ({
    type: GET_RETURN_BIN_BOX_COUNT_SUCCESS,
    payload: payload,
});

export const getReturnBinBoxCountError = (error) => ({
    type: GET_RETURN_BIN_BOX_COUNT_ERROR,
    payload: error,
});

export const getUberDirectQuote = (payload) => ({
    type: GET_UBER_DIRECT_QUOTE,
    payload: payload,
});

export const getUberDirectQuoteSuccess = (payload) => ({
    type: GET_UBER_DIRECT_QUOTE_SUCCESS,
    payload: payload,
});

export const getUberDirectQuoteError = (error) => ({
    type: GET_UBER_DIRECT_QUOTE_ERROR,
    payload: error,
});

export const createUberDirectRequest = (payload) => ({
    type: CREATE_UBER_DIRECT_REQUEST,
    payload: payload,
});

export const createUberDirectRequestSuccess = (payload) => ({
    type: CREATE_UBER_DIRECT_REQUEST_SUCCESS,
    payload: payload,
});

export const createUberDirectRequestError = (error) => ({
    type: CREATE_UBER_DIRECT_REQUEST_ERROR,
    payload: error,
});

export const updateUberDirect = (payload) => ({
    type: UPDATE_UBER_DIRECT,
    payload: payload,
});

export const updateUberDirectSuccess = (payload) => ({
    type: UPDATE_UBER_DIRECT_SUCCESS,
    payload: payload,
});

export const updateUberDirectError = (error) => ({
    type: UPDATE_UBER_DIRECT_ERROR,
    payload: error,
});

export const getAllUberDirect = (payload) => ({
    type: GET_ALL_UBER_DIRECT,
    payload: payload,
});

export const getAllUberDirectSuccess = (payload) => ({
    type: GET_ALL_UBER_DIRECT_SUCCESS,
    payload: payload,
});

export const getAllUberDirectError = (error) => ({
    type: GET_ALL_UBER_DIRECT_ERROR,
    payload: error,
});

export const cancelUberDirect = (id) => ({
    type: CANCEL_UBER_DIRECT,
    payload: id,
});

export const cancelUberDirectSuccess = (payload) => ({
    type: CANCEL_UBER_DIRECT_SUCCESS,
    payload: payload,
});

export const cancelUberDirectError = (error) => ({
    type: CANCEL_UBER_DIRECT_ERROR,
    payload: error,
});

export const getUberDirectRequestById = (id) => ({
    type: GET_UBER_DIRECT_REQUEST_BY_ID,
    payload: id,
});

export const getUberDirectRequestByIdSuccess = (payload) => ({
    type: GET_UBER_DIRECT_REQUEST_BY_ID_SUCCESS,
    payload: payload,
});

export const getUberDirectRequestByIdError = (error) => ({
    type: GET_UBER_DIRECT_REQUEST_BY_ID_ERROR,
    payload: error,
});

export const getUberDirectRequestDocById = (id) => ({
    type: GET_UBER_DIRECT_REQUEST_DOC_BY_ID,
    payload: id,
});

export const getUberDirectRequestDocByIdSuccess = (payload) => ({
    type: GET_UBER_DIRECT_REQUEST_DOC_BY_ID_SUCCESS,
    payload: payload,
});

export const getUberDirectRequestDocByIdError = (error) => ({
    type: GET_UBER_DIRECT_REQUEST_DOC_BY_ID_ERROR,
    payload: error,
});

export const getUberDirectQuoteDropoff = (payload) => ({
    type: GET_UBER_DIRECT_QUOTE_DROPOFF,
    payload: payload,
});

export const getUberDirectQuoteDropoffSuccess = (payload) => ({
    type: GET_UBER_DIRECT_QUOTE_DROPOFF_SUCCESS,
    payload: payload,
});

export const getUberDirectQuoteDropoffError = (error) => ({
    type: GET_UBER_DIRECT_QUOTE_DROPOFF_ERROR,
    payload: error,
});

export const getUberDirectQuoteDropoffNoNode = (payload) => ({
    type: GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE,
    payload: payload,
});

export const getUberDirectQuoteDropoffNoNodeSuccess = (payload) => ({
    type: GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_SUCCESS,
    payload: payload,
});

export const getUberDirectQuoteDropoffNoNodeError = (error) => ({
    type: GET_UBER_DIRECT_QUOTE_DROPOFF_NO_NODE_ERROR,
    payload: error,
});

export const getMarket = (payload) => ({
	type: GET_MARKET,
	payload: payload,
});
  
export const getMarketSuccess = (payload) => ({
	type: GET_MARKET_SUCCESS,
	payload: payload,
});
  
export const getMarketError = (error) => ({
	type: GET_MARKET_ERROR,
	payload: error,
});

export const adjustInventory = (payload) => ({
	type: ADJUST_INVENTORY,
	payload: payload,
});
  
export const adjustInventorySuccess = (payload) => ({
	type: ADJUST_INVENTORY_SUCCESS,
	payload: payload,
});
  
export const adjustInventoryError = (error) => ({
	type: ADJUST_INVENTORY_ERROR,
	payload: error,
});

export const getMarketObj = (payload) => ({
	type: GET_MARKET_OBJ,
	payload: payload,
});
  
export const getMarketObjSuccess = (payload) => ({
	type: GET_MARKET_OBJ_SUCCESS,
	payload: payload,
});
  
export const getMarketObjError = (error) => ({
	type: GET_MARKET_OBJ_ERROR,
	payload: error,
});

export const createPackingListTransactions = (payload) => ({
	type: CREATE_PACKING_LIST_TRANSACTIONS,
	payload: payload,
});
  
export const createPackingListTransactionsSuccess = (payload) => ({
	type: CREATE_PACKING_LIST_TRANSACTIONS_SUCCESS,
	payload: payload,
});
  

export const createPackingListTransactionsError = (error) => ({
	type: CREATE_PACKING_LIST_TRANSACTIONS_ERROR,
	payload: error,
});

export const getProofOfDelivery = (payload) => ({
	type: GET_PROOF_OF_DELIVERY,
	payload: payload,
});
  
export const getProofOfDeliverySuccess = (payload) => ({
	type: GET_PROOF_OF_DELIVERY_SUCCESS,
	payload: payload,
});
  
export const getProofOfDeliveryError = (error) => ({
	type: GET_PROOF_OF_DELIVERY_ERROR,
	payload: error,
});

