import {
	CONNECT_TO_CLIENT,
	CONNECT_TO_CLIENT_FAILED,
	CONNECT_TO_CLIENT_SUCCESSFUL,
} from './actionTypes'

export const connectToClient = (payload) => {
	return {
		type: CONNECT_TO_CLIENT,
		payload: payload,
	}
}

export const connectToClientSuccessful = (payload) => {
	return {
		type: CONNECT_TO_CLIENT_SUCCESSFUL,
		payload: payload,
	}
}

export const connectToClientFailed = (payload) => {
	return {
		type: CONNECT_TO_CLIENT_FAILED,
		payload: payload,
	}
}
