import React, { useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'

function InputGroup({
	className = '',
	label = '',
	name = '',
	type = 'text',
	placeholder = '',
	error = false,
	errorText = '',
	defaultValue = '',
	description = '',
	horizontal = false,
	onChange = () => {},
	autoCompleteAddress = false,
	onKeyDown = () => {},
	autoCompleteQuery,
	onAddressSelect,
	onSearch,
	...newProps
}) {
	const { ref } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		onPlaceSelected: (place) => {
			onChange(place)
			if (place.geometry?.location) {
				if (onAddressSelect) {
					onAddressSelect(place.formatted_address, {
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
					})

					if (onSearch) {
						onSearch(place.formatted_address)
					}
				}
			}
		},
		options: {
			types: ['address'],
			componentRestrictions: { country: 'us' },
			offset: 1,
		},
	})

	const hasError = error || errorText
	const finalClass = `${className} w-full border border-2 border-gray-200 rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow ${
		hasError && `border-red-600`
	}`

	return (
		<div className={horizontal ? 'sm:flex sm:items-center' : ''}>
			{label && (
				<div className={'pb-1'}>
					<label
						className={`text-sm text-gray-600 font-poppins ${
							hasError && 'text-red-600'
						} ${horizontal && 'sm:w-24'}absolute`}
						htmlFor={name}
					>
						{label}
					</label>
				</div>
			)}
			<div className={horizontal ? 'sm:flex-1' : ''}>
				<input
					type={type}
					name={name}
					className={finalClass}
					placeholder={placeholder}
					defaultValue={defaultValue}
					onChange={(e) => {
						onChange(e)
					}}
					onKeyDown={(e) => onKeyDown(e)}
					ref={autoCompleteAddress ? ref : null}
					{...newProps}
				/>
				{description && (
					<span className='mt-2 text-gray-600 text-xs'>
						{description}
					</span>
				)}
				{errorText && (
					<div className='bg-red-200 mt-2 py-2 px-4 text-xs text-red-600 rounded-sm absolute translate-y-2'>
						{errorText}
					</div>
				)}
			</div>
		</div>
	)
}

export default InputGroup
