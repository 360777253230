import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getPaymentMethodsSuccess,
	getPaymentMethodsFail,
	createPaymentMethodSuccess,
	createPaymentMethodFail,
	deletePaymentMethodSuccess,
	deletePaymentMethodFail,
	storePaymentMethodFail,
	storePaymentMethodSuccess,
	getPaymentsSuccess,
	getPaymentsFail, 
	chargePaymentMethodSuccess, 
	chargePaymentMethodFail,
	chargeGuestPaymentSuccess,
	chargeGuestPaymentFail,
	storeGuestPaymentSuccess,
	storeGuestPaymentFail,
} from './actions'
import {
	GET_PAYMENT_METHODS,
	CREATE_PAYMENT_METHOD,
	DELETE_PAYMENT_METHOD,
	STORE_PAYMENT_METHOD,
	GET_PAYMENTS, 
	CHARGE_PAYMENT_METHOD,
	CHARGE_GUEST_PAYMENT,
	STORE_GUEST_PAYMENT,
} from './actionTypes'
import {
	addPaymentMethod, chargePaymentMethod,
	deletePaymentMethod,
	getPaymentMethods,
	getPayments,
	chargeGuestPayment,
	storeGuestPayment,
} from '../../model/payments'
import { realm } from '../../helpers/realm'

function* fetchPaymentMethods(action) {
	// console.log('in fetch payment methodsaction',action)
	// console.log('in fetch payment methods payload', action.payload)
	try {
		const paymentMethods = yield call(getPaymentMethods, action.payload)
		// console.log(paymentMethods)
		yield put(getPaymentMethodsSuccess(paymentMethods))
	} catch (error) {
		yield put(getPaymentMethodsFail(error))
	}
}

function* fetchPayments(action) {
	try {
		const payments = yield call(getPayments, action.payload)
		yield put(getPaymentsSuccess(payments))
	} catch (error) {
		yield put(getPaymentsFail(error))
	}
}

function* initiateAddPaymentMethod(action) {
	const initiateSetupIntent =
		realm.currentUser.functions.stripeInitiateSetupIntent
	try {
		const { client_secret } = yield call(
			initiateSetupIntent,
			action.payload
		)
		yield put(createPaymentMethodSuccess(client_secret))
	} catch (error) {
		yield put(createPaymentMethodFail(error))
	}
}

function* removePaymentMethod(action) {
	try {
		const paymentMethod = yield call(deletePaymentMethod, action.payload)
		yield put(deletePaymentMethodSuccess(paymentMethod))
	} catch (error) {
		yield put(deletePaymentMethodFail(error))
	}
}

function* storePaymentMethod(action) {
	try {
		const paymentMethod = yield call(addPaymentMethod, action.payload)
		yield put(storePaymentMethodSuccess(paymentMethod))
	} catch (error) {
		yield put(storePaymentMethodFail(error))
	}
}

function* chargeCustomer(action) {
	try {
		const paymentMethod = yield call(chargePaymentMethod, action.payload)
		yield put(chargePaymentMethodSuccess(paymentMethod))
	} catch (error) {
		yield put(chargePaymentMethodFail(error))
	}
}

function* chargeGuest(action) {
	try {
		const paymentMethod = yield call(chargeGuestPayment, action.payload)
		yield put(chargeGuestPaymentSuccess(paymentMethod))
	} catch (error) {
		yield put(chargeGuestPaymentFail(error))
	}
}

function* storeGuestPaymentMethod(action) {
	try {
		const paymentMethod = yield call(storeGuestPayment, action.payload)
		yield put(storeGuestPaymentSuccess(paymentMethod))
	} catch (error) {
		yield put(storeGuestPaymentFail(error))
	}
}

function* paymentsSaga() {
	yield takeEvery(STORE_PAYMENT_METHOD, storePaymentMethod)
	yield takeEvery(GET_PAYMENT_METHODS, fetchPaymentMethods)
	yield takeEvery(CREATE_PAYMENT_METHOD, initiateAddPaymentMethod)
	yield takeEvery(DELETE_PAYMENT_METHOD, removePaymentMethod)
	yield takeEvery(GET_PAYMENTS, fetchPayments)
	yield takeEvery(CHARGE_PAYMENT_METHOD, chargeCustomer)
	yield takeEvery(CHARGE_GUEST_PAYMENT, chargeGuest)
	yield takeEvery(STORE_GUEST_PAYMENT, storeGuestPaymentMethod)
}

export default paymentsSaga
