import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Header, OrderHistoryCard } from "../../components/";
import { BounceLoaderCentered } from "../../components/Elements";
import { getUser, getOrders } from "../../store/actions";

const DZDirectOrders = (props) => {
  const { onGetOrders, loadingOrders, orders, user, onGetUser } = props;
  const [orderPage, setOrderPage] = useState(1);
  const paginationLimit = 5;

  useEffect(() => {
    onGetUser();
  }, [onGetUser]);

  useEffect(() => {
    if (user && user.phone) {
      getOrders(orderPage);
    }
  }, [user, user?.boxCount]);

  useEffect(() => {
    if (user && user.phone) {
      getOrders(orderPage);
    }
  }, [orderPage]);

  const getOrders = (page) => {
    onGetOrders({
      filter: {
        "customer.phone": user.phone,
      },
      options: {
        sort: {
          timestamp: -1,
        },
        limit: page * paginationLimit,
        skip: 0,
      },
    });
  };

  return (
    <div className="h-auto w-screen">
      <Header label="Direct Orders" showBackButton={true} />
      <div className="w-full mb-4 flex items-center justify-center mt-[3.25rem]">
        <OrderHistoryCard
          loadingOrders={loadingOrders}
          orders={orders}
          orderPage={orderPage}
          setOrderPage={setOrderPage}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ Orders, User }) => ({
  loadingOrders: Orders.loading,
  orders: Orders.orders,
  ordersCount: Orders.ordersCount,
  loadingUser: User.loading,
  user: User.user,
});

const mapDispatchToProps = (dispatch) => ({
  onGetOrders: (payload) => dispatch(getOrders(payload)),
  onGetUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DZDirectOrders);
