import { call, put, takeEvery } from "redux-saga/effects";
import {
    getFranchiseeChargesSuccess,
    getFranchiseeChargesFail,
  getFranchiseeError,
	getFranchiseeSuccess,
} from "./actions";
import { GET_FRANCHISEE_CHARGES, GET_FRANCHISEE } from "./actionTypes";
import { getFranchiseeCharges, fetchFranchiseeApi } from "../../model/franchisee";

function* fetchFranchiseeCharges(action) {
    try {
        const franchiseeCharges = yield call(
            getFranchiseeCharges,
            action.payload
        );
        yield put(getFranchiseeChargesSuccess(franchiseeCharges));
    } catch (error) {
        yield put(getFranchiseeChargesFail(error));
    }
}

function* getFranchisee(action) {
	try {
		const response = yield call(fetchFranchiseeApi, action.payload);
		yield put(getFranchiseeSuccess(response));
	} catch (error) {
		yield put(getFranchiseeError(error));
	}
}

function* franchiseeSaga() {
	yield takeEvery(GET_FRANCHISEE, getFranchisee);
  yield takeEvery(GET_FRANCHISEE_CHARGES, fetchFranchiseeCharges);
}

export default franchiseeSaga;

