import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Header, ProfileInfoCard } from "../../components/";
import { getUser } from "../../store/actions";
import { BounceLoaderCentered } from "../../components/Elements";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";

const ProfileInfo = (props) => {
  const { user, onGetUser } = props;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

  useEffect(() => {
    onGetUser();
  }, [onGetUser]);

  if (!user) return <BounceLoaderCentered container="screen" />;
  return (
    <div className="h-auto w-screen">
      <Header label="My Info" showBackButton={true} />
      <div className="flex flex-col items-center pt-5 mt-[3.25rem]">
        <Elements stripe={stripePromise}>
          <ProfileInfoCard user={user} />
        </Elements>
        <Link to="/user/manageaccount" className="fixed bottom-14">
          <p className="text-green-600 text-xs font-bold hover:text-green-400 mx-8 my-5">
            Manage Account
          </p>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = ({ User }) => ({ user: User.user });

const mapDispatchToProps = (dispatch) => ({
  onGetUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
