import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Header } from "../../components/";
import { getUser } from "../../store/actions";
import { BounceLoaderCentered } from "../../components/Elements";
import { deleteUser } from "../../model/auth";
import { useNavigate } from "react-router-dom";

const ManageAccount = (props) => {
  const { user, onGetUser } = props;
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [deletePermanently, setDeletePermanently] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    onGetUser();
  }, [onGetUser]);

  useEffect(() => {
    if (deletePermanently) {
      if (realm.currentUser) {
        deleteUser(user.userId).then(() => {
          realm.deleteUser(realm.currentUser).then(() => {
            navigate("/");
          });
        });
      }
    }
  }, [deletePermanently]);

  const handleDeleteAccount = () => {
    setShowDeleteWarning(true);
  };

  const openSupport = () => {
    window.Intercom("show");
  };

  if (!user) return <BounceLoaderCentered container="screen" />;
  return (
    <div className="h-auto w-screen">
      <Header label="Manage Account" showBackButton={true} />
      <div className="flex flex-col mt-[3.25rem]">
        <div
          onClick={openSupport}
          className={`border-b border-gray-200`}
        >
          <p className="text-green-600 font-bold hover:text-green-400 mx-5 my-5">
            Contact Support
          </p>
        </div>
        <div
          className="text-red-400 pl-5 py-5 border-b border-gray-200"
          onClick={handleDeleteAccount}
        >
          Delete Account
        </div>
        {showDeleteWarning && (
          <div className="px-8 mt-2 text-sm pb-20">
            <div>
              Are you sure you want to delete your account? This action cannot
              be undone.
            </div>
            <div className="flex flex-row mt-2">
              <div
                onClick={() => {
                  setDeletePermanently(true);
                }}
              >
                Yes
              </div>
              <div
                className="ml-8"
                onClick={() => {
                  window.location.reload();
                }}
              >
                No
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ User }) => ({ user: User.user });

const mapDispatchToProps = (dispatch) => ({
  onGetUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccount);
