import React from "react";
import { Button } from "../Elements";
import { useNavigate } from "react-router";

const RewardPointsCard = ({ rewardPoints = null }) => {
  const navigate = useNavigate();

  return (
    <div
      className="card w-5/6 flex items-center justify-center"
      onClick={() => navigate("/rewards-marketplace")}
    >
      <div className="text-center justify-center items-center -mt-5">
        <h3 className="mt-2 mb-4 block font-header text-center text-xl text-green-400">
          Total Reward Points
        </h3>
        <div className="mt-3 flex flex-col text-center items-center justify-center">
          <div className="text-[40px] rounded-[80px] font-header px-9 w-min bg-gray-100 text-center m-auto my-2 text-orange-600">
            {rewardPoints}
          </div>
          <Button
            link="/rewards-marketplace"
            className="px-10 mt-5 shadow-light-grey py-1"
            text="Redeem Rewards"
            size="sm"
          />
        </div>
      </div>
    </div>
  );
};

export default RewardPointsCard;
