import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getAllRewardsSuccess,
	getAllRewardsFail,
	removeDiscountCodeSuccess,
	removeDiscountCodeFail,
	deductUserRewardPtsSuccess,
	deductUserRewardPtsFail,
	updateNonUniqueDiscountCodeUsersSuccess,
	updateNonUniqueDiscountCodeUsersFail,
	sendRedeemRewardEmailSuccess,
	sendRedeemRewardEmailFail
} from './actions'
import { GET_ALL_REWARDS, REMOVE_DISCOUNT_CODE, DEDUCT_USER_REWARD_PTS, UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS, SEND_REDEEM_REWARD_EMAIL } from './actionTypes'
import {
	getAllRewards,
	removeDiscountCode,
	deductUserRewardPts,
	updateNonUniqueDiscountCodeUsers,
	sendRedeemRewardEmail
} from '../../model/rewardsMarketplace'
import { realm } from '../../helpers/realm'

function* fetchRewards(action) {
	try {
		const allRewards = yield call(getAllRewards, action.payload)
		yield put(getAllRewardsSuccess(allRewards))
	} catch (error) {
		yield put(getAllRewardsFail(error))
	}
}

function* deleteDiscountCode(action) {
	try {
		const reward = yield call(removeDiscountCode, action.payload)
		yield put(removeDiscountCodeSuccess(reward))
	} catch (error) {
		yield put(removeDiscountCodeFail(error))
	}
}

function* subtractUserRewardPts(action) {
	try {
		const user = yield call(deductUserRewardPts, action.payload)
		yield put(deductUserRewardPtsSuccess(user))
	} catch (error) {
		yield put(deductUserRewardPtsFail(error))
	}
}

function* addNonUniqueDiscountCodeUser(action) {
	try {
		const user = yield call(updateNonUniqueDiscountCodeUsers, action.payload)
		yield put(updateNonUniqueDiscountCodeUsersSuccess(user))
	} catch (error) {
		yield put(updateNonUniqueDiscountCodeUsersFail(error))
	}
}

function* sendEmailToUser(action) {
	try {
		const user = yield call(sendRedeemRewardEmail, action.payload)
		yield put(sendRedeemRewardEmailSuccess(user))
	} catch (error) {
		yield put(sendRedeemRewardEmailFail(error))
	}
}

function* rewardsSaga() {
	yield takeEvery(GET_ALL_REWARDS, fetchRewards)
	yield takeEvery(REMOVE_DISCOUNT_CODE, deleteDiscountCode)
	yield takeEvery(DEDUCT_USER_REWARD_PTS, subtractUserRewardPts)
	yield takeEvery(UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS, addNonUniqueDiscountCodeUser)
	yield takeEvery(SEND_REDEEM_REWARD_EMAIL, sendEmailToUser)
}

export default rewardsSaga
