import {
	GET_LEAD,
	UPDATE_LEAD,
	GET_ALL_LEADS,
	ADD_LEAD,
	GENERATE_DOCUSIGN,
	FINALIZE_NODE,
	GET_SERVICE_AREAS,
	CHECK_PROMO_CODE_VALIDITY,
} from './actionTypes'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getLeadFail,
	getLeadSuccess,
	getAllLeadsFail,
	getAllLeadsSuccess,
	updateLeadFail,
	updateLeadSuccess,
	addLeadFail,
	addLeadSuccess,
	generateDocuSignSuccess,
	generateDocuSignFail,
	finalizeNodeSuccess,
	finalizeNodeFail,
	getServiceAreasSuccess,
	getServiceAreasFail,
	checkPromoCodeValiditySuccess,
	checkPromoCodeValidityFail,
} from './actions'
import {
	getLead,
	getAllLeads,
	updateLead,
	addLead,
	generateDocuSign,
	finalizeNode,
	getServiceAreaPolygons,
	checkPromoCodeValidity,
} from '../../model/leads'

function* fetchLead(action) {
	try {
		const lead = yield call(getLead, action.payload)
		yield put(getLeadSuccess(lead))
	} catch (error) {
		yield put(getLeadFail(error))
	}
}

function* createLead(action) {
	try {
		const lead = yield call(addLead, action.payload)
		yield put(addLeadSuccess(lead))
	} catch (error) {
		yield put(addLeadFail(error))
	}
}

function* fetchAllLeads(action) {
	try {
		const allLeads = yield call(getAllLeads, action.payload)
		yield put(getAllLeadsSuccess(allLeads))
	} catch (error) {
		yield put(getAllLeadsFail(error))
	}
}

function* updateSingleLead(action) {
	try {
		const updatedLead = yield call(updateLead, action.payload)
		yield put(updateLeadSuccess(updatedLead))
	} catch (error) {
		yield put(updateLeadFail(error))
	}
}

function* fetchDocuSign(action) {
	try {
		const docuSignUrl = yield call(generateDocuSign, action.payload)
		yield put(generateDocuSignSuccess(docuSignUrl))
	} catch (error) {
		yield put(generateDocuSignFail(error))
	}
}

function* finalizeNewLead(action) {
	try {
		const finalized = yield call(finalizeNode, action.payload)
		yield put(finalizeNodeSuccess(finalized))
	} catch (error) {
		yield put(finalizeNodeFail(error))
	}
}

function* fetchServiceAreaPolygons(action) {
	try {
		const polygons = yield call(getServiceAreaPolygons, action.payload)
		yield put(getServiceAreasSuccess(polygons))
	} catch (error) {
		yield put(getServiceAreasFail(error))
	}
}

function* validatePromoCode(action) {
	try {
		const promoCodeDoc = yield call(checkPromoCodeValidity, action.payload)
		yield put(checkPromoCodeValiditySuccess(promoCodeDoc))
	} catch (error) {
		yield put(checkPromoCodeValidityFail(error))
	}
}

function* leadSaga() {
	yield takeEvery(GET_LEAD, fetchLead)
	yield takeEvery(ADD_LEAD, createLead)
	yield takeEvery(GET_ALL_LEADS, fetchAllLeads)
	yield takeEvery(UPDATE_LEAD, updateSingleLead)
	yield takeEvery(GENERATE_DOCUSIGN, fetchDocuSign)
	yield takeEvery(FINALIZE_NODE, finalizeNewLead)
	yield takeEvery(GET_SERVICE_AREAS, fetchServiceAreaPolygons)
	yield takeEvery(CHECK_PROMO_CODE_VALIDITY, validatePromoCode)
}

export default leadSaga
