import { realm } from '../helpers/realm'

export const getBoxCountLookup = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const boxCountLookUps = client.db('caas').collection('box_count_look_ups')
	const boxCountLookUp = await boxCountLookUps.findOne({ nodeSlug: payload.nodeSlug, clientId: payload.clientId })
	return boxCountLookUp
}

export const updateBoxCountLookup = async (updatePayload) => {
	const clientId = updatePayload.clientId
	const nodeSlug = updatePayload.nodeSlug
	const itemBoxCountLookUp = updatePayload.itemBoxCountLookUp
	const client = realm.currentUser.mongoClient('RealmService')
	const boxCountLookUps = client.db('caas').collection('box_count_look_ups')
	const update = await boxCountLookUps.updateOne(
		{ nodeSlug: nodeSlug, clientId:clientId },
		{
			$set: {
				itemBoxCountLookUp: itemBoxCountLookUp,
			},
		}
	)
	const boxCountLookUp = await boxCountLookUps.findOne({ nodeSlug: nodeSlug, clientId:clientId  })

	return boxCountLookUp
}

export const getMapFromClient = async (payload) => {
	const nodeSlug = payload.nodeSlug
	const platform = payload.platform
	const client = realm.currentUser.mongoClient('RealmService')
	const functionName = platform + '/getMenuTriggerFromFrontend'
	const getMenuResponse = await realm.currentUser.callFunction(
		functionName,
		nodeSlug
	)
	const boxCountLookUps = client.db('caas').collection('box_count_look_ups')
	const boxCountLookUp = await boxCountLookUps.findOne({ nodeSlug: nodeSlug,clientId:platform })

	return boxCountLookUp
}
