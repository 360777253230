import {
	GET_NODE_BY_SLUG,
	GET_NODE_BY_SLUG_FAIL,
	GET_NODE_BY_SLUG_SUCCESS,
	GET_NODE,
	GET_NODE_FAIL,
	GET_NODE_SUCCESS,
	GET_NODES,
	GET_NODES_FAIL,
	GET_NODES_SUCCESS,
	RESET_NODE,
	GET_NODE_BY_QR,
	GET_NODE_BY_QR_SUCCESS,
	GET_NODE_BY_QR_FAIL,
	GET_NODE_BY_QR_NOT_FOUND,
	UPDATE_SEARCH_ADDRESS,
	ADD_NODE,
	ADD_NODE_SUCCESS,
	ADD_NODE_FAIL,
	UPDATE_NODE,
	UPDATE_NODE_SUCCESS,
	UPDATE_NODE_FAIL,
	GET_NODE_BY_ADMIN_CODE,
	GET_NODE_BY_ADMIN_CODE_SUCCESS,
	GET_NODE_BY_ADMIN_CODE_FAIL,
	RESET_ADMIN_NODE,
	RESTAURANT_ADMIN_SUBMIT_REQUEST, RESTAURANT_ADMIN_SUBMIT_REQUEST_SUCCESS, RESTAURANT_ADMIN_SUBMIT_REQUEST_FAIL,
} from './actionTypes'
import {RESET_CARD_ADDED} from "../payments/actionTypes";

export const getNodes = (payload) => ({
	type: GET_NODES,
	payload: payload,
})

export const getNodesSuccess = (nodes) => ({
	type: GET_NODES_SUCCESS,
	payload: nodes,
})

export const getNodesFail = (error) => ({
	type: GET_NODES_FAIL,
	payload: error,
})

export const getNode = (payload) => ({
	type: GET_NODE,
	payload: payload,
})

export const getNodeSuccess = (node) => ({
	type: GET_NODE_SUCCESS,
	payload: node,
})

export const getNodeFail = (error) => ({
	type: GET_NODE_FAIL,
	payload: error,
})

export const getNodeBySlug = (payload) => ({
	type: GET_NODE_BY_SLUG,
	payload: payload,
})

export const getNodeBySlugSuccess = (node) => ({
	type: GET_NODE_BY_SLUG_SUCCESS,
	payload: node,
})

export const getNodeBySlugFail = (error) => ({
	type: GET_NODE_BY_SLUG_FAIL,
	payload: error,
})

export const getNodeByQR = (payload) => ({
	type: GET_NODE_BY_QR,
	payload: payload,
})

export const getNodeByQRSuccess = (payload) => ({
	type: GET_NODE_BY_QR_SUCCESS,
	payload: payload,
})

export const getNodeByQRNotFound = () => ({
	type: GET_NODE_BY_QR_NOT_FOUND,
})

export const getNodeByQRFail = (error) => ({
	type: GET_NODE_BY_QR_FAIL,
	payload: error,
})

export const updateSearchNodes = (payload) => ({
	type: UPDATE_SEARCH_ADDRESS,
	payload: payload,
})

export const resetNode = () => ({
	type: RESET_NODE,
})

export const addNode = (payload) => ({
	type: ADD_NODE,
	payload: payload,
})

export const addNodeSuccess = (payload) => ({
	type: ADD_NODE_SUCCESS,
	payload: payload,
})

export const addNodeFail = (payload) => ({
	type: ADD_NODE_FAIL,
	payload: payload,
})

export const updateNode = (payload) => ({
	type: UPDATE_NODE,
	payload: payload,
})

export const updateNodeSuccess = (payload) => ({
	type: UPDATE_NODE_SUCCESS,
	payload: payload,
})

export const updateNodeFail = (payload) => ({
	type: UPDATE_NODE_FAIL,
	payload: payload,
})

export const getNodeByAdminCodeOrSlug = (payload) => ({
	type: GET_NODE_BY_ADMIN_CODE,
	payload: payload,
})

export const getNodeByAdminCodeOrSlugSuccess = (payload) => ({
	type: GET_NODE_BY_ADMIN_CODE_SUCCESS,
	payload: payload,
})

export const getNodeByAdminCodeOrSlugFail = (payload) => ({
	type: GET_NODE_BY_ADMIN_CODE_FAIL,
	payload: payload,
})

export const resetAdminNode = () => ({
	type: RESET_ADMIN_NODE,
})

export const restaurantAdminSubmitRequest = (payload) => ({
	type: RESTAURANT_ADMIN_SUBMIT_REQUEST,
	payload: payload,
})

export const restaurantAdminSubmitRequestSuccess = (payload) => ({
	type: RESTAURANT_ADMIN_SUBMIT_REQUEST_SUCCESS,
	payload: payload,
})

export const restaurantAdminSubmitRequestFail = (payload) => ({
	type: RESTAURANT_ADMIN_SUBMIT_REQUEST_FAIL,
	payload: payload,
})