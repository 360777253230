import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'
import {
    Input,
    SelectGroup,
    Badge,
    CheckboxGroup,
    RadioGroup,
    Button,
} from '../Elements'
import {FaSearch, FaAngleDown} from 'react-icons/fa'
import {BsSliders} from 'react-icons/bs'
import {MdClear} from 'react-icons/md'
import {thirdPartyDict} from "../../helpers/clientInfo";

const RestaurantSearchBar = (props) => {
    const {
        nameValue,
        activeNodeCuisines,
        alphaCuisines,
        activeClients,
        onSelectQuery,
        onClientFilter,
        onCourierFilter,
		onOpenNowFilter,
		courierFilterChecked,
		openNowFilterChecked,
        selectedClient,
        deliveryOnlyFilterChecked,
        setDeliveryOnlyFilterChecked,
        handleSortBy,
        sortByType,
        setDistPopupVisible,
    } = props

    const [currentlyTypedNameValue, setCurrentlyTypedNameValue] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const [clientRadios, setClientRadios] = useState([])
    const [showClientRadios, setShowClientRadios] = useState(false)
    const [cuisines, setCuisines] = useState([])

    const handleSubmitFilter = (e) => {
        onSelectQuery(e)
    }

    const handleClientFilter = (e) => {
        onClientFilter(e)
    }

    const handleCourierFilter = (e) => {
        onCourierFilter(e)
    }

	const handleOpenNowFilter = (e) => {
		onOpenNowFilter(e)
	}

    const makeClientRadios = () => {
        const activeClientsRadios = []
        setShowClientRadios(false)
        activeClients.map((client) => {
            activeClientsRadios.push({
                value: client,
                label: thirdPartyDict[client] ? thirdPartyDict[client].name : client.charAt(0).toUpperCase() + client.slice(1),
                checked: client === selectedClient,
            })
        })
        // console.log(selectedClient)
        // console.log(activeClientsRadios)
        setClientRadios(activeClientsRadios)
    }
    useEffect(() => {
        makeClientRadios()
    }, [activeClients,selectedClient]);

    useEffect(() => {
        setShowClientRadios(true)
    }, [clientRadios]);

    return (
        <div className='flex flex-col items-center w-screen px-4 '>
            <div className='w-full h-15 mt-1 relative items-center justify-between flex'>
				<div className='w-full'>
                    <Input
                        placeholder='Search by Restaurant or Cuisine'
                        className='px-10 !text-sm'
                        name='nodeName'
                        error={false}
                        onChange={(e) => {
                            setCurrentlyTypedNameValue(e.target.value)
                            if (e.target.value.length > 2) {
                                handleSubmitFilter(e)
                            } else {
                                handleSubmitFilter({target: {value: ''}})
                            }
                        }}
                        onKeyDown={(e) => {
                            if(e.code === 'Enter') {
                                handleSubmitFilter(e)
                                e.preventDefault();
                                e.target.blur();
                            } else {
                                null
                            }
                        }}
                        value={nameValue || currentlyTypedNameValue}
                    />
                    <FaSearch
                        className='absolute left-0 ml-4 top-1/2 transform -translate-y-1/2 text-green-400 text-lg sm:text-xl'/>
                </div>
                {currentlyTypedNameValue && (
                    <MdClear
                        className='absolute right-[62px] sm:right-[65px] top-1/2 transform -translate-y-1/2 text-white text-lg cursor-pointer bg-green-400 rounded-full w-4 h-4 flex items-center justify-center sm:w-5 sm:h-5'
                        onClick={() => {
                            setCurrentlyTypedNameValue('')
                            handleSubmitFilter({target: {value: ''}})
                        }}
                    />
                )}
                <div
                    className='flex items-center justify-center ml-1 bg-white h-9 w-11 rounded-full'
                    onMouseOver={() => {
                        setShowFilters(true)
                        setDistPopupVisible(false)
                    }}
                    onClick={() => {
                        setShowFilters(true)
                        setDistPopupVisible(false)
                    }}
                >
                    <BsSliders className='text-green-400 text-lg sm:text-xl mx-auto' />
                </div>
                {showFilters && (
                    <div
                        className='rounded-xl bg-white fixed top-0 left-0 right-0 bottom-0 cursor-pointer z-50 shadow-xl overflow-y-auto outline-none pb-[300px]'
                    >
                        <div
                            className='w-full flex flex-row justify-between items-center  sticky top-0 bg-white bg-opacity-100 h-fit z-50'>
                            <MdClear
                                className='text-green-600 mx-4 mt-4 mb-3 w-6 h-6 mx-5 sm:mx-10'
                                onClick={() => {
                                    setShowFilters(false);
                                    setDistPopupVisible(true)
                                }}
                            />
                            <div onClick={() => {
                                setCurrentlyTypedNameValue('');
                                handleSubmitFilter({
                                    target: {value: ''},
                                });
                                onClientFilter('none')
                            }} className="underline text-green-600  mx-5 sm:mx-10"
                            >
                                Reset Filters
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className='mt-1 sm:mt-0 mr-1 ml-8 min-w-[60px]'>
                                Sort By:
                            </div>
                            <RadioGroup
                                className='mt-1 sm:mt-0'
                                name='sortBy'
                                horizontal
                                radios={[
                                    {
                                        value: 'distance',
                                        label: 'Distance',
                                        checked: (sortByType === "distance"),
                                    },
                                    {value: 'name', label: 'Name', checked: (sortByType === "name")},
                                ]}
                                onChange={(sortBy) => {
                                    // console.log(sortBy)
                                    handleSortBy(sortBy)
                                }}
                            />
                        </div>
                        <div className='text-sm lg:text-base w-5/6 mx-8 my-4 sm:mx-10'>
                            <form action='#'>
                                <SelectGroup
                                    label='Search by Cuisine'
                                    placeholder='Select...'
                                    name='color'
                                    options={alphaCuisines.map((cuisine) => {
                                        return {
                                            label: cuisine,
                                            value: cuisine,
                                        }
                                    })}
                                    onChange={(e) => {
                                        handleSubmitFilter(e)
                                        // console.log(e)
                                    }}
                                />
                                <div className='my-4'>
                                    <p className='mb-2'>Popular Categories</p>
                                    {activeNodeCuisines.map((cuisine, index) => (
                                        <Badge
                                            text={`${cuisine}`}
                                            key={`${index}`}
                                            className='mx-1 my-1 inline-block'
                                            onClick={(e) => {
                                                // console.log(e)
                                                handleSubmitFilter({
                                                    target: {
                                                        value: e.target
                                                            .textContent,
                                                    },
                                                })
                                            }}
                                        />
                                    ))}
                                </div>

                                {showClientRadios && (
                                    <div className='mt-4 mb-2'>
                                        <p>Order Through:</p>
                                        <RadioGroup
                                            className='mt-1 sm:mt-0 mb-6'
                                            name='enabled'
                                            horizontal
                                            radios={clientRadios}
                                            onChange={(client) => {
                                                handleClientFilter(client)
                                            }}
                                        />
                                    </div>
                                )}



                                <p>Other Filters:</p>
                                <div className='mt-2 mb-4'>
                                    <CheckboxGroup
                                        label='Only show restaurants who deliver'
                                        name='delivery-only'
                                        defaultChecked={
                                            deliveryOnlyFilterChecked
                                        }
                                        onChange={(check) => {
                                            setDeliveryOnlyFilterChecked(
                                                check.target.checked
                                            )
                                        }}
                                    />
                                </div>
                                <div className='my-4'>
                                    <CheckboxGroup
                                        label='Show restaurants where courier accepts returns'
                                        name='courier-accepts-returns'
                                        defaultChecked={courierFilterChecked}
                                        onChange={(check) => {
                                            handleCourierFilter(
                                                check.target.checked
                                            )
                                        }}
                                    />
                                </div>
                                <div className='my-4'>
                                    <CheckboxGroup
                                        label='Open now'
                                        name='open-now'
                                        defaultChecked={openNowFilterChecked}
                                        onChange={(check) => {
                                            handleOpenNowFilter(
                                                check.target.checked
                                            )
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='fixed bottom-12 left-0 right-0 flex justify-center z-50 bg-white'>
                            <Button
                                color='green'
                                full
                                size='sm'
                                text='Apply'
                                className='mt-3 mb-10 w-5/6 md:w-2/3 mx-auto py-1'
                                onClick={() => {
                                    setShowFilters(false)
                                    setDistPopupVisible(true)
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

RestaurantSearchBar.propTypes = {
    addressValue: PropTypes.any,
    changeMapBoolToFalse: PropTypes.func,
    defaultDistanceBarValue: PropTypes.any,
    distanceBarValue: PropTypes.any,
    nameValue: PropTypes.any,
    onAddressSelect: PropTypes.func,
    onNameQuery: PropTypes.func,
    setDistanceBarValue: PropTypes.func,
    setMapBool: PropTypes.func,
}

export default RestaurantSearchBar
