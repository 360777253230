import React, {useState, useEffect} from 'react'
import {Header, Footer} from '../../components'
import {Input, FormGroup, Button, BounceLoaderCentered} from '../../components/Elements'
import ReactConfetti from 'react-confetti'
import {createTransaction, getThirdPartyPartners, resetTransaction} from '../../store/actions'
import {GiPartyPopper} from 'react-icons/gi'
import {connect} from 'react-redux'

const ThirdPartyPickup = (state) => {
    const {
        user,
        onCreateTransaction,
        onGetThirdPartyPartners,
        partners,
        transactionResult,
        loadingPartners,
        createTransactionError,
        partnerError,
        onResetTransaction,
    } = state

    const [form, setForm] = useState({
        returnPartner: '',
        partnerOrderNumber: '',
        numberReturned: 0,
        returnPartnerName: '',
    })
    const [qty, setQty] = useState(0)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [showInfoPopup, setShowInfoPopup] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [transactionSuccess, setTransactionSuccess] = useState(false)

    useEffect(() => {
        if (transactionResult) {
            setSubmitLoading(false)
            setForm({
                returnPartner: '',
                partnerOrderNumber: '',
                numberReturned: 0,
                returnPartnerName: '',
            })
            setQty(0)
            setErrorMessage(null)
            setShowSuccess(true)
            setTransactionSuccess(true)
        }
        if (createTransactionError || partnerError) {
            setSubmitLoading(false)
            {
                partnerError
                    ? setErrorMessage(partnerError)
                    : setErrorMessage(createTransactionError)
            }
        }

    }, [transactionResult, createTransactionError, partnerError])

    useEffect(() => {
        onGetThirdPartyPartners()
    }, [])

    useEffect(() => {
        onResetTransaction()
    }, [onResetTransaction])

    useEffect(() => {
        if (partners.length)
            setForm({
                ...form,
                returnPartner: partners[0].partner,
                returnPartnerName: partners[0].name,
            })
    }, [partners])

    useEffect(() => {
        setForm({...form, numberReturned: qty})
    }, [qty])

    const submitTransaction = (e) => {
        if(!form.returnDate){
            setErrorMessage('Please complete the form')
            setSubmitLoading(false)
        } else {

            let returnDate = new Date(Date.parse(form.returnDate))


            returnDate.setUTCHours(12)

            e.preventDefault()
            setSubmitLoading(true)
            const transactionPayload = {
                timestamp: returnDate,
                type: 'boxesIn',
                boxCount: form.numberReturned,
                partnerOrderNumber: form.partnerOrderNumber,
                user: {
                    phone: user.phone,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                },
                returnAtDoor: true,
                reverseLogisticsPartner: form.returnPartner,
                returnWithThirdParty: true,
            }

            if (qty > 0 && form.returnPartner && form.partnerOrderNumber) {
                onCreateTransaction(transactionPayload)
                // setSubmitLoading(false)
            } else if (!form.returnPartner || !form.partnerOrderNumber) {
                setErrorMessage('Please complete the form')
                setSubmitLoading(false)
            } else if (qty === 0) {
                setErrorMessage('Please enter a quantity greater than 0')
                setSubmitLoading(false)
            }
        }
    }

    const handleShowInfoPopup = () => {
        // console.log('handleShowInfoPopup')
        setShowInfoPopup(!showInfoPopup)
    }
    return (
        <>
        <Header label={"Return with Grocery Delivery"} showBackButton/>
        <div className='min-h-screen-no-header sm:h-screen w-full flex flex-col justify-between mt-[3.25rem]'>
            <div className='w-full min-h-full flex flex-col items-center justify-start bg-yellow-400'>
                <p className='max-w-[700px] leading-7 mx-[5%] mt-6 sm:mt-[20px] text-center text-sm sm:text-base sm:pb-0'>
                    Simply order from a partner listed below, then let us know your order number and how many containers you're returning. We'll send you a
                    reminder to hand containers to the courier who delivers your order on the day of your order's delivery.
                </p>{' '}
                <div className='hidden sm:flex sm:flex-row justify-center items-center w-full md:w-full'>
                    {partners &&
                    partners.map((thirdparty) => {
                        // console.log(thirdparty.url)
                        return (
                            <a
                                href={thirdparty.url}
                                target='_blank'
                                rel='noreferrer'
                                key={thirdparty.name}
                                className='mx-4'
                            >
                                <img
                                    src={thirdparty.logo}
                                    alt={thirdparty.name}
                                    className='h-auto max-w-[100px] my-6 xl:my-8 xl:max-h-[75px] xl:max-w-[200px]'
                                />
                            </a>
                        )
                    })}
                </div>
                <div className='w-11/12 h-full sm:h-fit sm:min-w-[85%] bg-white overflow-hidden shadow-light-grey rounded-xl max-w-lg m-auto p-6 mt-6 mb-20 sm:mx-2'>
                    <FormGroup className='flex flex-col h-full w-full'>
                        {showSuccess ? (
                            <div>
                                {showSuccess && (
                                    <div className='h-full flex flex-col justify-center items-center'>
                                        <ReactConfetti colors={['#f38b74']}/>
                                        <h1 className='font-vollkorn text-green-600 text-4xl text-center'>
                                            You're all set!
                                        </h1>
                                        <GiPartyPopper className='text-orange-600 text-[250px]'/>
                                        <Button
                                            link='/user/accountLanding'
                                            className='px-10 py-1 w-[260px] mt-5 shadow-light-grey'
                                            text='View My Account'
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {errorMessage && (
                                    <div className='flex flex-col items-center justify-center'>
                                        <h1 className='text-xl font-header text-red-600'>
                                            {errorMessage}
                                        </h1>
                                    </div>
                                )}
                                {(!transactionResult &&
                                    loadingPartners &&
                                    !transactionSuccess) ||
                                (!submitLoading ? (
                                    <div className="text-center w-full">
                                        <div className='flex flex-col mb-4 w-full sm:w-1/2 mx-auto'>
                                            <label
                                                className='text-sm'
                                                htmlFor='returnPartner'
                                            >
                                                Select a partner:
                                            </label>
                                            {partners ? (
                                                <select
                                                    name='returnPartner'
                                                    id='returnPartner'
                                                    className='border-2 rounded-xl p-2 m-2 bg-white border-gray-300'
                                                    onChange={(e) => {
                                                        // console.log(e.target.value)
                                                        const partner =
                                                            partners.find(
                                                                (x) =>
                                                                    x.partner ===
                                                                    e
                                                                        .target
                                                                        .value
                                                            )
                                                        setForm({
                                                            ...form,
                                                            returnPartner:
                                                            e.target
                                                                .value,
                                                            returnPartnerName:
                                                            partner.name,
                                                        })
                                                    }}
                                                >
                                                    {partners.map(
                                                        (partner) => {
                                                            // console.log(partner	)
                                                            return (
                                                                <option
                                                                    key={
                                                                        partner.name
                                                                    }
                                                                    value={
                                                                        partner.partner
                                                                    }
                                                                >
                                                                    {
                                                                        partner.name
                                                                    }
                                                                </option>
                                                            )
                                                        }
                                                    )}
                                                </select>
                                            ) : (
                                                <p className='text-sm'>
                                                    No partners
                                                    available
                                                </p>
                                            )}
                                        </div>
                                        <div className='w-full mx-auto'>
                                            <div className="flex flex-col justify-center items-center w-full">
                                                <div className='pb-1 flex flex-row'>
                                                    <label
                                                        className='text-sm text-gray-600 font-poppins'
                                                    >
                                                        {`${form.returnPartnerName} Order #:`}
                                                    </label>
                                                    {showInfoPopup && (
                                                        <div
                                                            className='w-[250px] absolute bg-white border-2 border-green-400 rounded-xl p-4 shadow-light-grey transform -translate-y-20 translate-x-12'>
                                                            <p className='text-sm'>
                                                                In order to return
                                                                to our grocery
                                                                partners, you must
                                                                first place an order
                                                                with that grocery
                                                                service and enter
                                                                the order number
                                                                here
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div
                                                        onMouseEnter={() =>
                                                            handleShowInfoPopup()
                                                        }
                                                        onMouseLeave={() =>
                                                            handleShowInfoPopup()
                                                        }
                                                        onClick={() =>
                                                            handleShowInfoPopup()
                                                        }
                                                        className='border-2 w-[50px] text-center text-xs h-min border-green-400 text-green-400 hover:bg-green-600 hover:border-green-600 hover:text-white cursor-pointer py-0 px-2  ml-2 rounded-xl'
                                                    >
                                                        Info
                                                    </div>
                                                </div>
                                                <Input
                                                    type='text'
                                                    name='partnerOrderNumber'
                                                    onChange={(e) => {
                                                        setForm({
                                                            ...form,
                                                            partnerOrderNumber:
                                                            e.target.value,
                                                        })
                                                    }}
                                                    error=''
                                                    className='bg-white border-2 border-gray-300 mb-6 w-full h-14'
                                                />
                                            </div>
                                        </div>
                                        <Input
                                            type='date'
                                            label='Order Delivery Date of Return:'
                                            name='returnDate'
                                            onChange={(e) => {
                                                setForm({
                                                    ...form,
                                                    returnDate:
                                                    e.target.value,
                                                })
                                            }}
                                            error=''
                                            className='bg-white border-2 border-gray-300 min-w-[200px] w-2/3 h-14 -mb-6'
                                            // defaultValue={startDate}
                                        />
                                        <div
                                            className='flex flex-col items-center justify-center p-4 max-w-[300px] m-auto mb-4'>
                                            <div className='my-4 text-sm'>
                                                Tell us how many containers
                                                you're returning
                                            </div>
                                            <div
                                                className='border-2 rounded-full mb-3 sm:mb-0 lg:w-1/3 w-full text-center flex justify-between px-3 items-center'>
													<span
                                                        color='default'
                                                        className='text-4xl text-gray-300 w-1/3 cursor-pointer'
                                                        onClick={() => {
                                                            if (qty >= 1) {
                                                                setQty(qty - 1)
                                                            }
                                                        }}
                                                    >
														-
													</span>
                                                <div className='text-2xl'>
                                                    {qty}
                                                </div>
                                                <span
                                                    color='default'
                                                    className='text-2xl text-gray-300 w-1/3 cursor-pointer'
                                                    onClick={() => {
                                                        // if (qty < user.boxCount)
                                                        setQty(qty + 1)
                                                    }}
                                                >
														+
													</span>
                                            </div>
                                        </div>
                                        <div className='w-full flex justify-center'>
                                            <div className='lg:w-1/6 min-w-[250px]'>
                                                <Button
                                                    text='Report Return'
                                                    size='sm lg:base '
                                                    color='green'
                                                    onClick={(e) =>
                                                        submitTransaction(e)
                                                    }
                                                    className='px-1 py-1'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (

                                    <BounceLoaderCentered container="div"/>

                                ))}
                            </div>
                        )}
                    </FormGroup>
                </div>
                {/* <div
                    className='h-32 bg-red-400 w-full '
                    style={{
                        clipPath:
                            'polygon( 0% 65%, 1% 64.95%, 2% 64.8%, 3% 64.6%, 4% 64.3%, 5% 63.9%, 6% 63.45%, 7% 62.9%, 8% 62.25%, 9% 61.55%, 10% 60.8%, 11% 59.95%, 12% 59.05%, 13% 58.1%, 14% 57.1%, 15% 56.05%, 16% 55%, 17% 53.9%, 18% 52.8%, 19% 51.65%, 20% 50.5%, 21% 49.35%, 22% 48.2%, 23% 47.05%, 24% 45.9%, 25% 44.8%, 26% 43.75%, 27% 42.75%, 28% 41.75%, 29% 40.8%, 30% 39.9%, 31% 39.1%, 32% 38.35%, 33% 37.65%, 34% 37.05%, 35% 36.5%, 36% 36.05%, 37% 35.65%, 38% 35.35%, 39% 35.15%, 40% 35.05%, 41% 35%, 42% 35.05%, 43% 35.2%, 44% 35.45%, 45% 35.75%, 46% 36.15%, 47% 36.65%, 48% 37.2%, 49% 37.85%, 50% 38.55%, 51% 39.35%, 52% 40.2%, 53% 41.1%, 54% 42.05%, 55% 43.05%, 56% 44.1%, 57% 45.15%, 58% 46.3%, 59% 47.4%, 60% 48.55%, 61% 49.7%, 62% 50.85%, 63% 52%, 64% 53.15%, 65% 54.25%, 66% 55.35%, 67% 56.4%, 68% 57.45%, 69% 58.4%, 70% 59.35%, 71% 60.2%, 72% 61.05%, 73% 61.8%, 74% 62.45%, 75% 63.05%, 76% 63.6%, 77% 64.05%, 78% 64.4%, 79% 64.7%, 80% 64.85%, 81% 65%, 82% 65%, 83% 64.9%, 84% 64.75%, 85% 64.5%, 86% 64.2%, 87% 63.75%, 88% 63.25%, 89% 62.7%, 90% 62.05%, 91% 61.3%, 92% 60.5%, 93% 59.65%, 94% 58.75%, 95% 57.8%, 96% 56.8%, 97% 55.75%, 98% 54.65%, 99% 53.55%, 100% 52.4%, 100% 100%, 0% 100%)',
                    }}
                ></div> */}
            </div>
        </div>
        </>
    )
}

const mapStateToProps = ({
                             Nodes,
                             User,
                             TransactionsCreate,
                             ReverseLogistics,
                         }) => ({
    user: User.user,
    loggedIn: User.loggedIn,
    node: Nodes.node,
    loadingNode: Nodes.loadingNode,
    transactionResult: TransactionsCreate.transactionResult,
    createTransactionError: TransactionsCreate.error,
    creatingTransaction: TransactionsCreate.creating,
    partners: ReverseLogistics.partners,
    partnerError: ReverseLogistics.partnerError,
    loadingPartners: ReverseLogistics.loadingPartners,
})

const mapDispatchToProps = (dispatch) => ({
    onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
    onGetThirdPartyPartners: () => dispatch(getThirdPartyPartners()),
    onResetTransaction: () => dispatch(resetTransaction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyPickup)
