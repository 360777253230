import { call, put, takeEvery } from 'redux-saga/effects'

import {
	getGeneratedGiftCard,
	getGeneratedPromo,
	validateGiftCardCode,
	validatePromoCode,
	updateGiftCardBalance,
	getGiftCardCustomer,
	getAllPromoIds,
	updatePromoCode,
} from '../../model/promos'
import {
	generateGiftCardError,
	generateGiftCardSuccess,
	generatePromoError,
	generatePromoSuccess,
	validateGiftCardError,
	validateGiftCardSuccess,
	validatePromoError,
	validatePromoSuccess,
	updateGiftCardSuccess,
	updateGiftCardError,
	getGiftCardCustomerSuccess,
	getGiftCardCustomerError,
	getPromoIdsSuccess,
	getPromoIdsError,
	updatePromoSuccess,
	updatePromoError,
} from './actions'
import {
	GENERATE_GIFT_CARD,
	GENERATE_PROMO,
	VALIDATE_GIFT_CARD,
	VALIDATE_PROMO,
	UPDATE_GIFT_CARD,
	GET_GIFT_CARD_CUSTOMER,
	GET_PROMO_IDS,
	UPDATE_PROMO,
} from './actionTypes'

function* fetchGeneratedPromo(action) {
	try {
		// console.log('fetchGeneratedPromo')
		const generatedPromo = yield call(getGeneratedPromo, action.payload)
		yield put(generatePromoSuccess(generatedPromo))
	} catch (error) {
		yield put(generatePromoError(error))
	}
}

function* validatePromo(action) {
	try {
		// console.log('validatePromo')
		const validatedPromo = yield call(validatePromoCode, action.payload)
		yield put(validatePromoSuccess(validatedPromo))
	} catch (error) {
		yield put(validatePromoError(error))
	}
}

function* updatePromo(action) {
	try {
		// console.log('validatePromo')
		const updatedPromo = yield call(updatePromoCode, action.payload)
		yield put(updatePromoSuccess(updatedPromo))
	} catch (error) {
		yield put(updatePromoError(error))
	}
}

function* fetchGeneratedGiftCard(action) {
	try {
		// console.log('fetchGeneratedGiftCard', action.payload)
		const generatedGiftCard = yield call(
			getGeneratedGiftCard,
			action.payload
		)
		yield put(generateGiftCardSuccess(generatedGiftCard))
	} catch (error) {
		yield put(generateGiftCardError(error))
	}
}

function* validateGiftCard(action) {
	try {
		// console.log('validateGiftCard')
		const validatedGiftCard = yield call(
			validateGiftCardCode,
			action.payload
		)
		yield put(validateGiftCardSuccess(validatedGiftCard))
	} catch (error) {
		yield put(validateGiftCardError(error))
	}
}

function* updateGiftCard(action) {
	try {
		// console.log('updateGiftCard')
		const updatedGiftCard = yield call(
			updateGiftCardBalance,
			action.payload
		)
		yield put(updateGiftCardSuccess(updatedGiftCard))
	} catch (error) {
		yield put(updateGiftCardError(error))
	}
}

function* fetchGiftCardCustomer(action) {
	try {
		// console.log('fetchGiftCardCustomer')
		const giftCardCustomer = yield call(getGiftCardCustomer, action.payload)
		yield put(getGiftCardCustomerSuccess(giftCardCustomer))
	} catch (error) {
		yield put(getGiftCardCustomerError(error))
	}
}
function* fetchPromoIds(action) {
	try {
		// console.log('fetchPromoIds')
		const promoIds = yield call(getAllPromoIds, action.payload)
		yield put(getPromoIdsSuccess(promoIds))
	} catch (error) {
		yield put(getPromoIdsError(error))
	}
}

function* promosSaga() {
	yield takeEvery(GENERATE_PROMO, fetchGeneratedPromo)
	yield takeEvery(VALIDATE_PROMO, validatePromo)
	yield takeEvery(UPDATE_PROMO, updatePromo)
	yield takeEvery(GENERATE_GIFT_CARD, fetchGeneratedGiftCard)
	yield takeEvery(VALIDATE_GIFT_CARD, validateGiftCard)
	yield takeEvery(UPDATE_GIFT_CARD, updateGiftCard)
	yield takeEvery(GET_GIFT_CARD_CUSTOMER, fetchGiftCardCustomer)
	yield takeEvery(GET_PROMO_IDS, fetchPromoIds)
}

export default promosSaga
