import React from "react";
import { Button, BounceLoaderCentered } from "../Elements";
import UserProfileTransactions from "./UserProfileTransactions";
import { useNavigate } from "react-router";
import { ButtonPlay } from "pure-react-carousel";

const UsageHistoryCard = ({
  loadingTransactions,
  transactions,
  appInfo,
  onCancelFirstDelivery,
  cancelingFirstDelivery,
  transactionPage,
  setTransactionPage,
}) => {
  const navigate = useNavigate();

  const handleClickCard = () => {
    if (!loadingTransactions && (!transactions || transactions.length == 0)) {
      // navigate
      navigate("/");
    } else if (
      !loadingTransactions &&
      transactions &&
      transactions.length > 0
    ) {
      // load more
      setTransactionPage(transactionPage + 1);
    } else {
      null;
    }
  };

  return (
    <div className={"mt-5 w-5/6 flex items-center justify-center mb-20"} onClick={handleClickCard}>
      <div className="text-center justify-center items-center -mt-5 w-5/6 sm:w-2/3">
        <h3
          className={`mt-2 mb-4 block font-header text-center text-xl text-green-400`}
        >
          Usage History
        </h3>
        <div>
          {loadingTransactions && <BounceLoaderCentered container="div" />}
          {!loadingTransactions &&
            (!transactions || transactions.length == 0) && (
              <div className="mt-3">
                <div>
                  Looks like you haven't used any boxes yet!
                </div>
                <div className="mt-2 mb-6 text-sm">Let's change that.</div>
                <Button
                  className="px-10 mt-5 shadow-light-grey py-1"
                  text="Order in Reusables"
                  size="sm"
                  link="/"
                ></Button>
              </div>
            )}
          {!loadingTransactions && transactions && transactions.length > 0 && (
            <div>
              <div className="mt-3">
                <UserProfileTransactions
                  transactions={transactions}
                  returnWindow={appInfo.returnWindow}
                  disputeWindow={appInfo.disputeWindow}
                  onCancelFirstDelivery={onCancelFirstDelivery}
                  cancelingFirstDelivery={cancelingFirstDelivery}
                />
              </div>
              <div className="flex justify-center">
                <Button
                  onClick={() => {
                    setTransactionPage(transactionPage + 1);
                  }}
                  className="px-10 shadow-light-grey py-1"
                  size="sm"
                  text="Load More"
                >
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsageHistoryCard;
