import {
	GET_DROPOFF_QUOTE,
	GET_DROPOFF_QUOTE_SUCCESS,
	GET_DROPOFF_QUOTE_ERROR,
	CREATE_DROPOFF,
	CREATE_DROPOFF_SUCCESS,
	CREATE_DROPOFF_ERROR,
	GET_DROPOFF_STATUS,
	GET_DROPOFF_STATUS_SUCCESS,
	GET_DROPOFF_STATUS_ERROR,
	GET_DROPOFFS_ERROR,
	GET_DROPOFFS_SUCCESS,
	GET_DROPOFFS, CANCEL_DROPOFF, CANCEL_DROPOFF_ERROR,
	CREATE_GENTLY_DROPOFF,
	CREATE_GENTLY_DROPOFF_SUCCESS,
	CREATE_GENTLY_DROPOFF_ERROR,
	CREATE_GENTLY_DROPOFF_NO_NODE,
	CREATE_GENTLY_DROPOFF_NO_NODE_SUCCESS,
	CREATE_GENTLY_DROPOFF_NO_NODE_ERROR, GET_DROPOFF_BY_ID, GET_DROPOFF_BY_ID_ERROR, GET_DROPOFF_BY_ID_SUCCESS,
	CREATE_FLOT_DROPOFF,
	CREATE_FLOT_DROPOFF_SUCCESS,
	CREATE_FLOT_DROPOFF_ERROR,
	CREATE_FLOT_DROPOFF_NO_NODE,
	CREATE_FLOT_DROPOFF_NO_NODE_SUCCESS,
	CREATE_FLOT_DROPOFF_NO_NODE_ERROR,
	CANCEL_FLOT_DROPOFF,
	CANCEL_FLOT_DROPOFF_SUCCESS,
	CANCEL_FLOT_DROPOFF_ERROR,
	GET_ALL_FLOT_DROPOFFS,
	GET_ALL_FLOT_DROPOFFS_ERROR,
	GET_ALL_FLOT_DROPOFFS_SUCCESS,
	RESET_DROPOFF_SUCCESS,
} from './actionTypes'

const initialState = {
	error: null,
	creatingDropoffQuote:false,
	dropoffQuote:null,
	dropoffQuoteError:null,
	creatingDropoff:false,
	dropoffRequested:false,
	dropoffError:null,
	gettingDropoff:false,
	dropoff:null,
	dropoffStatus:null,
	getDropoffError:null,
	loadingDropoffs:false,
	dropoffs:[],
	dropoffsError:null,
	cancellingDropoff: false,
	cancelDropoffError:null,
	dropoffCancelled: null,
	flotDropoffs:[],
}

const admin = (state = initialState, action) => {
	switch (action.type) {
		case GET_DROPOFF_QUOTE:
			return {
				...state,
				creatingDropoffQuote: true,
				dropoffQuoteError:null,
			}
		case GET_DROPOFF_QUOTE_SUCCESS:
			return {
				...state,
				creatingDropoffQuote: false,
				dropoffQuote: action.payload,
			}
		case GET_DROPOFF_QUOTE_ERROR:
			return {
				...state,
				creatingDropoffQuote: false,
				dropoffQuoteError: action.payload,
				dropoffQuote:null,
			}
		case CREATE_DROPOFF:
			return {
				...state,
				creatingDropoff: true,
				dropoffError:null,
			}
		case CREATE_DROPOFF_SUCCESS:
			return {
				...state,
				creatingDropoff: false,
				dropoffRequested: action.payload,
			}
		case CREATE_DROPOFF_ERROR:
			return {
				...state,
				creatingDropoff: false,
				dropoffError: action.payload,
				dropoffRequested: false
			}
		case CREATE_GENTLY_DROPOFF:
			return {
				...state,
				creatingDropoff: true,
				dropoffError:null,
			}
		case CREATE_GENTLY_DROPOFF_SUCCESS:
			return {
				...state,
				creatingDropoff: false,
				dropoffRequested: action.payload,
				dropoffError: action.payload.error ? action.payload.error : null
			}
		case CREATE_GENTLY_DROPOFF_ERROR:
			return {
				...state,
				creatingDropoff: false,
				dropoffError: action.payload,
				dropoffRequested: false
			}
		case CREATE_GENTLY_DROPOFF_NO_NODE:
			return {
				...state,
				creatingDropoff: true,
				dropoffError:null,
			}
		case CREATE_GENTLY_DROPOFF_NO_NODE_SUCCESS:
			return {
				...state,
				creatingDropoff: false,
				dropoffRequested: action.payload,
				dropoffError: action.payload.error ? action.payload.error : null
			}
		case CREATE_GENTLY_DROPOFF_NO_NODE_ERROR:
			return {
				...state,
				creatingDropoff: false,
				dropoffError: action.payload,
				dropoffRequested: false
			}
		case GET_DROPOFF_STATUS:
			return {
				...state,
				gettingDropoff: true,
				getDropoffError:null,
			}
		case GET_DROPOFF_STATUS_SUCCESS:
			return {
				...state,
				gettingDropoff: false,
				dropoffStatus: action.payload,
				cancellingDropoff: false,
			}
		case GET_DROPOFF_STATUS_ERROR:
			return {
				...state,
				gettingDropoff: false,
				getDropoffError: action.payload,
				dropoffStatus:null,
				cancellingDropoff: false,
			}
		case GET_DROPOFFS:
			return {
				...state,
				loadingDropoffs: true,
			}
		case GET_DROPOFFS_SUCCESS:
			const dropoffs = action.payload[0]
			return {
				...state,
				loadingDropoffs: false,
				dropoffs: dropoffs.data,
			}
		case GET_DROPOFFS_ERROR:
			return {
				...state,
				loadingDropoffs: false,
				getDropoffError: action.payload,
				dropoffs:[],
			}
		case CANCEL_DROPOFF:
			return {
				...state,
				cancellingDropoff: true,
				cancelDropoffError: null,
			}
		case CANCEL_DROPOFF_ERROR:
			return {
				...state,
				cancellingDropoff: false,
				cancelDropoffError:action.payload,
			}
		case GET_DROPOFF_BY_ID:
			return {
				...state,
				gettingDropoff: true,
				getDropoffError:null,
			}
		case GET_DROPOFF_BY_ID_SUCCESS:
			return {
				...state,
				gettingDropoff: false,
				dropoff: action.payload,
				cancellingDropoff: false,
			}
		case GET_DROPOFF_BY_ID_ERROR:
			return {
				...state,
				gettingDropoff: false,
				getDropoffError: action.payload,
				dropoff:null,
				cancellingDropoff: false,
			}
		case CREATE_FLOT_DROPOFF:
			return {
				...state,
				creatingDropoff: true,
				dropoffError:null,
			}
		case CREATE_FLOT_DROPOFF_SUCCESS:
			return {
				...state,
				creatingDropoff: false,
				dropoffRequested: action.payload,
				dropoffError: action.payload.error ? action.payload.error : null
			}
		case CREATE_FLOT_DROPOFF_ERROR:
			return {
				...state,
				creatingDropoff: false,
				dropoffError: action.payload,
				dropoffRequested: false
			}
		case CREATE_FLOT_DROPOFF_NO_NODE:
			return {
				...state,
				creatingDropoff: true,
				dropoffError:null,
			}
		case CREATE_FLOT_DROPOFF_NO_NODE_SUCCESS:
			return {
				...state,
				creatingDropoff: false,
				dropoffRequested: action.payload,
				dropoffError: action.payload.error ? action.payload.error : null
			}
		case CREATE_FLOT_DROPOFF_NO_NODE_ERROR:
			return {
				...state,
				creatingDropoff: false,
				dropoffError: action.payload,
				dropoffRequested: false
			}
		case CANCEL_FLOT_DROPOFF:
			return {
				...state,
				cancellingDropoff: true,
				dropoffError:null,
			}
		case CANCEL_FLOT_DROPOFF_SUCCESS:
			return {
				...state,
				cancellingDropoff: false,
				dropoffCancelled: action.payload,
				dropoffError: action.payload.error ? action.payload.error : null
			}
		case CANCEL_FLOT_DROPOFF_ERROR:
			return {
				...state,
				cancellingDropoff: false,
				dropoffError: action.payload,
				dropoffCancelled: false
			}
		case GET_ALL_FLOT_DROPOFFS:
			return {
				...state,
				loadingDropoffs: true,
			}
		case GET_ALL_FLOT_DROPOFFS_SUCCESS:
			const flotDropoffs = action.payload
			return {
				...state,
				loadingDropoffs: false,
				flotDropoffs: flotDropoffs,
			}
		case GET_ALL_FLOT_DROPOFFS_ERROR:
			return {
				...state,
				loadingDropoffs: false,
				getDropoffError: action.payload,
				flotDropoffs:[],
			}   
		case RESET_DROPOFF_SUCCESS:
			return {
				...state,
				dropoffStatus:null,
				getDropoffError:null,
				loadingDropoffs:false,
				dropoffsError:null,
				cancellingDropoff: false,
				cancelDropoffError:null,
				dropoffCancelled: null,
				creatingDropoff:false,
				dropoffRequested:false,
			};
		default:
			return state
	}
}

export default admin
