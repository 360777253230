import { GET_FAQ, GET_FAQ_FAIL, GET_FAQ_SUCCESS } from './actionTypes'

export const getFAQ = () => ({
	type: GET_FAQ,
})

export const getFAQSuccess = (faq) => ({
	type: GET_FAQ_SUCCESS,
	payload: faq,
})

export const getFAQFail = (error) => ({
	type: GET_FAQ_FAIL,
	payload: error,
})
