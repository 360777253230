import React, { useState, useEffect } from "react";
import { Badge } from "../Elements";

const NodeMenuHeader = ({ node }) => {
  const daysOfWeekMap = { 0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 }; // map from Date().getDay() (0-6=Sun-Sat) to google's openHours (0-6=Mon-Sun)
  const usBankHolidays = [
    "Jan 1",
    "Jan 15",
    "Feb 19",
    "May 27",
    "Jun 19",
    "Jul 4",
    "Sep 2",
    "Oct 9",
    "Nov 11",
    "Nov 23",
    "Dec 25",
  ]; // oct 2023 - oct 2024 dates

  const [dayOfWeek, setDayOfWeek] = useState("");
  const [isHoliday, setIsHoliday] = useState(false);

  const formatTodayHours = (input) => {
    if (typeof input === "string") {
      input = input.replace(/.*day/, "Today");
      return input;
    }
    return toString(input);
  };

  useEffect(() => {
    const currentDate = new Date();
    const dateOptions = { month: "short", day: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-US", dateOptions); // in format "Jan 1"
    setDayOfWeek(currentDate.getDay());
    if (usBankHolidays.includes(formattedDate)) {
      setIsHoliday(true);
    }
  }, [node]);

  return (
    <div className="">
      <div className="h-[200px] overflow-hidden">
        <img className="w-full" src={node.thumbnail} alt="" />
      </div>
      <div className="mx-4 mt-4 tracking-wider">
        <div className="mb-2">
          <h1 className="font-header text-xl text-black md:text-3xl">
            {node.name}
          </h1>
          <p className="text-xs font-semibold md:text-base">{node.cuisine}</p>
        </div>
        {node.courierAcceptsReturns && (
          <div className="whitespace-nowrap mb-2 flex items-center">
            <Badge
              text="Courier Accepts Returns"
              color="orange-primary"
              className="cursor-pointer"
            />
          </div>
        )}
        <div className="mb-2">
          <div className="">
            <div className="text-xs md:text-sm">
              <p>
                {node.address}, {node.city}
              </p>
              <p>
                {" "}
                {(node.openHoursGoogle && node.openHoursGoogle.length>0)
                  ? formatTodayHours(
                      node.openHoursGoogle[daysOfWeekMap[dayOfWeek]]
                    )
                  : node.openHoursDescription}
              </p>
              {isHoliday && (
                <div className="text-red-400 text-xs md:text-sm">
                  Hours may differ on holidays.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NodeMenuHeader;
