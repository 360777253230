export const GET_ALL_REWARDS = 'GET_ALL_REWARDS'
export const GET_ALL_REWARDS_SUCCESS = 'GET_ALL_REWARDS_SUCCESS'
export const GET_ALL_REWARDS_FAIL = 'GET_ALL_REWARDS_FAIL'
export const REMOVE_DISCOUNT_CODE = 'REMOVE_DISCOUNT_CODE'
export const REMOVE_DISCOUNT_CODE_SUCCESS = 'REMOVE_DISCOUNT_CODE_SUCCESS'
export const REMOVE_DISCOUNT_CODE_FAIL = 'REMOVE_DISCOUNT_CODE_FAIL'
export const DEDUCT_USER_REWARD_PTS = 'DEDUCT_USER_REWARD_PTS'
export const DEDUCT_USER_REWARD_PTS_SUCCESS = 'DEDUCT_USER_REWARD_PTS_SUCCESS'
export const DEDUCT_USER_REWARD_PTS_FAIL = 'DEDUCT_USER_REWARD_PTS_FAIL'
export const UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS= 'UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS'
export const UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_SUCCESS = 'UPDATE_NON_UNIQUE_DISCOUNT_CODE_SUCCESS'
export const UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_FAIL = 'UPDATE_NON_UNIQUE_DISCOUNT_CODE_FAIL'
export const SEND_REDEEM_REWARD_EMAIL = 'SEND_REDEEM_REWARD_EMAIL'
export const SEND_REDEEM_REWARD_EMAIL_SUCCESS = 'SEND_REDEEM_REWARD_EMAIL_SUCCESS'
export const SEND_REDEEM_REWARD_EMAIL_FAIL = 'SEND_REDEEM_REWARD_EMAIL_FAIL'
