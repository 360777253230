import { applyMiddleware, compose } from 'redux'
import { legacy_createStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
// import logger from 'redux-logger'
import { rootReducer } from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const composeEnhancers = compose

const middlewares = [
	sagaMiddleware,
	// logger
]

const store = legacy_createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(...middlewares))
)

sagaMiddleware.run(rootSaga)

export default store
