import { UPDATE_PATH } from './actionTypes'
import { takeEvery } from 'redux-saga/effects'
import { updatePath } from './actions'

function* updateToPath(action) {
	updatePath(action.payload)
}

function* pathSaga() {
	yield takeEvery(UPDATE_PATH, updateToPath)
}

export default pathSaga
