import {
	GET_RESTAURANT_PAYMENTS,
	GET_RESTAURANT_PAYMENTS_FAIL,
	GET_RESTAURANT_PAYMENTS_SUCCESS,
	SEND_RESTAURANT_PAYMENTS,
	SEND_RESTAURANT_PAYMENTS_FAIL,
	SEND_RESTAURANT_PAYMENTS_SUCCESS,
	GET_RESTAURANT_CHARGES,
	GET_RESTAURANT_CHARGES_FAIL,
	GET_RESTAURANT_CHARGES_SUCCESS,
	CHARGE_RESTAURANTS,
	CHARGE_RESTAURANTS_FAIL,
	CHARGE_RESTAURANTS_SUCCESS,
} from './actionTypes'

const initialState = {
	restaurantPayments: [],
	error: null,
	loadingRestaurantPayments: false,
	sendingRestaurantPayments: false,
	restaurantPaymentsCompleted: false,
	paymentError: null,
	restaurantCharges: [],
	loadingRestaurantCharges: false,
	chargingRestaurants: false,
	restaurantChargesCompleted: false,
	chargesError: null,
}

const restaurantPayments = (state = initialState, action) => {
	switch (action.type) {
		case GET_RESTAURANT_PAYMENTS:
			return {
				...state,
				loadingRestaurantPayments: true,
			}
		case GET_RESTAURANT_PAYMENTS_SUCCESS:
			const restaurantPayments = action.payload[0]
			return {
				...state,
				loadingRestaurantPayments: false,
				restaurantPayments: restaurantPayments.data,
				error: null,
			}
		case GET_RESTAURANT_PAYMENTS_FAIL:
			return {
				...state,
				loadingRestaurantPayments: false,
				error: action.payload,
			}
		case SEND_RESTAURANT_PAYMENTS:
			return {
				...state,
				sendingRestaurantPayments: true,
				restaurantPaymentsCompleted: false,
			}
		case SEND_RESTAURANT_PAYMENTS_SUCCESS:
			return {
				...state,
				sendingRestaurantPayments: false,
				restaurantPaymentsCompleted: true,
				error: null,
			}
		case SEND_RESTAURANT_PAYMENTS_FAIL:
			return {
				...state,
				sendingRestaurantPayments: false,
				restaurantPaymentsCompleted: false,
				error: action.payload,
			}
		case GET_RESTAURANT_CHARGES:
			return {
				...state,
				loadingRestaurantCharges: true,
			}
		case GET_RESTAURANT_CHARGES_SUCCESS:
			const restaurantCharges = action.payload[0]
			return {
				...state,
				loadingRestaurantCharges: false,
				restaurantCharges: restaurantCharges.data,
				error: null,
			}
		case GET_RESTAURANT_CHARGES_FAIL:
			return {
				...state,
				loadingRestaurantCharges: false,
				error: action.payload,
			}
		case CHARGE_RESTAURANTS:
			return {
				...state,
				chargingRestaurants: true,
				restaurantChargesCompleted: false,
			}
		case CHARGE_RESTAURANTS_SUCCESS:
			return {
				...state,
				chargingRestaurants: false,
				restaurantChargesCompleted: true,
				error: null,
			}
		case CHARGE_RESTAURANTS_FAIL:
			return {
				...state,
				chargingRestaurants: false,
				restaurantChargesCompleted: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export default restaurantPayments
