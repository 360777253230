import {
	GET_ALL_REWARDS,
	GET_ALL_REWARDS_SUCCESS,
	GET_ALL_REWARDS_FAIL,
	REMOVE_DISCOUNT_CODE,
	REMOVE_DISCOUNT_CODE_SUCCESS,
	REMOVE_DISCOUNT_CODE_FAIL,
	DEDUCT_USER_REWARD_PTS,
	DEDUCT_USER_REWARD_PTS_SUCCESS,
	DEDUCT_USER_REWARD_PTS_FAIL,
	UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS,
	UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_SUCCESS,
	UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_FAIL,
	SEND_REDEEM_REWARD_EMAIL,
	SEND_REDEEM_REWARD_EMAIL_SUCCESS,
	SEND_REDEEM_REWARD_EMAIL_FAIL
} from './actionTypes'

export const getAllRewards = (payload) => ({
	type: GET_ALL_REWARDS,
	payload: payload,
})

export const getAllRewardsSuccess = (payload) => ({
	type: GET_ALL_REWARDS_SUCCESS,
	payload: payload,
})

export const getAllRewardsFail = (error) => ({
	type: GET_ALL_REWARDS_FAIL,
	payload: error,
})

export const removeDiscountCode = (payload) => ({
	type: REMOVE_DISCOUNT_CODE,
	payload: payload,
})

export const removeDiscountCodeSuccess = (payload) => ({
	type: REMOVE_DISCOUNT_CODE_SUCCESS,
	payload: payload,
})

export const removeDiscountCodeFail = (error) => ({
	type: REMOVE_DISCOUNT_CODE_FAIL,
	payload: error,
})

export const deductUserRewardPts = (payload) => ({
	type: DEDUCT_USER_REWARD_PTS,
	payload: payload,
})

export const deductUserRewardPtsSuccess = (payload) => ({
	type: DEDUCT_USER_REWARD_PTS_SUCCESS,
	payload: payload,
})

export const deductUserRewardPtsFail = (error) => ({
	type: DEDUCT_USER_REWARD_PTS_FAIL,
	payload: error,
})

export const updateNonUniqueDiscountCodeUsers = (payload) => ({
	type: UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS,
	payload: payload,
})

export const updateNonUniqueDiscountCodeUsersSuccess = (payload) => ({
	type: UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_SUCCESS,
	payload: payload,
})

export const updateNonUniqueDiscountCodeUsersFail = (error) => ({
	type: UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_FAIL,
	payload: error,
})

export const sendRedeemRewardEmail = (payload) => ({
	type: SEND_REDEEM_REWARD_EMAIL,
	payload: payload,
})

export const sendRedeemRewardEmailSuccess = (payload) => ({
	type: SEND_REDEEM_REWARD_EMAIL_SUCCESS,
	payload: payload,
})

export const sendRedeemRewardEmailFail = (error) => ({
	type: SEND_REDEEM_REWARD_EMAIL_FAIL,
	payload: error,
})