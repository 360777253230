export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]
export const dayNames = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

export function getNextTwoDatesOfDay(
	dayName,
	bufferDays,
	excludeToday = true,
	refDate = new Date()
) {
	const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(
		dayName.slice(0, 3).toLowerCase()
	)
	// console.log(dayOfWeek)
	const today = refDate.getDay()
	if (dayOfWeek < 0) return
	refDate.setHours(0, 0, 0, 0)
	refDate.setDate(
		refDate.getDate() +
			+!!excludeToday +
			((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
	)

	if(refDate.getDay()>today){
		if((refDate.getDay()-today)<bufferDays){
			refDate.setDate(refDate.getDate() + 7)
		}
	} else if(refDate.getDay()==today) {

	} else {
		if((refDate.getDay()+7-today)<bufferDays){
			refDate.setDate(refDate.getDate() + 7)
		}
	}

	let nextDate = new Date(refDate)
	nextDate.setDate(nextDate.getDate() + 7)
	return [refDate, nextDate]
}

export function sortDateArray(dateArray) {
	let sortedArray = dateArray.sort(function (a, b) {
		return new Date(a) - new Date(b)
	})
	// console.log('sorted array', sortedArray)
	return sortedArray
}
