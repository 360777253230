import {
	MEMBERSHIP_SIGNUP,
	MEMBERSHIP_SIGNUP_FAIL,
	MEMBERSHIP_SIGNUP_SUCCESS,
	GET_MEMBERSHIP_SAVINGS,
	GET_MEMBERSHIP_SAVINGS_FAIL,
	GET_MEMBERSHIP_SAVINGS_SUCCESS,
	GET_MEMBERS,
	GET_MEMBERS_FAIL,
	GET_MEMBERS_SUCCESS,
	RESET_MEMBERSHIP,
} from './actionTypes'

export const membershipSignup = (payload) => {
	return {
		type: MEMBERSHIP_SIGNUP,
		payload: payload,
	}
}

export const membershipSignupSuccess = (payload) => {
	return {
		type: MEMBERSHIP_SIGNUP_SUCCESS,
		payload: payload,
	}
}

export const membershipSignupFail = (payload) => {
	return {
		type: MEMBERSHIP_SIGNUP_FAIL,
		payload: payload,
	}
}

export const getMembershipSavings = (payload) => {
	return {
		type: GET_MEMBERSHIP_SAVINGS,
		payload: payload,
	}
}

export const getMembershipSavingsSuccess = (payload) => {
	return {
		type: GET_MEMBERSHIP_SAVINGS_SUCCESS,
		payload: payload,
	}
}

export const getMembershipSavingsFail = (payload) => {
	return {
		type: GET_MEMBERSHIP_SAVINGS_FAIL,
		payload: payload,
	}
}

export const getMembers = (payload) => {
	return {
		type: GET_MEMBERS,
		payload: payload,
	}
}

export const getMembersSuccess = (payload) => {
	return {
		type: GET_MEMBERS_SUCCESS,
		payload: payload,
	}
}

export const getMembersFail = (payload) => {
	return {
		type: GET_MEMBERS_FAIL,
		payload: payload,
	}
}

export const resetMembership = () => {
	return {
		type: RESET_MEMBERSHIP,
	}
}
