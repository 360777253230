import {
    CREATE_PENDING_PICKUP,
    CREATE_PENDING_PICKUP_SUCCESS,
    CREATE_PENDING_PICKUP_ERROR,
    GET_PENDING_PICKUPS,
    GET_PENDING_PICKUPS_SUCCESS,
    GET_PENDING_PICKUPS_ERROR,
    GET_ALL_PENDING_PICKUPS,
    GET_ALL_PENDING_PICKUPS_SUCCESS,
    GET_ALL_PENDING_PICKUPS_ERROR,
    GET_PICKUPS_BY_PHONE,
    GET_PICKUPS_BY_PHONE_SUCCESS,
    GET_PICKUPS_BY_PHONE_ERROR,
    RESET_PICKUPS,
    GET_PICKUP_BY_ID,
    GET_PICKUP_BY_ID_ERROR,
    GET_PICKUP_BY_ID_SUCCESS,
	CREATE_GENTLY_PICKUP,
	CREATE_GENTLY_PICKUP_SUCCESS,
	CREATE_GENTLY_PICKUP_ERROR,
	CREATE_GENTLY_PICKUP_NO_NODE,
	CREATE_GENTLY_PICKUP_NO_NODE_SUCCESS,
	CREATE_GENTLY_PICKUP_NO_NODE_ERROR,
} from './actionTypes';

export const createPendingPickup = (payload) => ({
    type: CREATE_PENDING_PICKUP,
    payload: payload,
});

export const createPendingPickupSuccess = (payload) => ({
    type: CREATE_PENDING_PICKUP_SUCCESS,
    payload: payload,
});

export const createPendingPickupError = (error) => ({
    type: CREATE_PENDING_PICKUP_ERROR,
    payload: error,
});

export const getAllPendingPickups = (payload) => ({
    type: GET_ALL_PENDING_PICKUPS,
    payload: payload,
});

export const getAllPendingPickupsSuccess = (payload) => ({
    type: GET_ALL_PENDING_PICKUPS_SUCCESS,
    payload: payload,
});

export const getAllPendingPickupsError = (error) => ({
    type: GET_ALL_PENDING_PICKUPS_ERROR,
    payload: error,
});

export const getPendingPickups = (payload) => ({
    type: GET_PENDING_PICKUPS,
    payload: payload,
});

export const getPendingPickupsSuccess = (payload) => ({
    type: GET_PENDING_PICKUPS_SUCCESS,
    payload: payload,
});

export const getPendingPickupsError = (error) => ({
    type: GET_PENDING_PICKUPS_ERROR,
    payload: error,
});

export const getPickupsByPhone = (payload) => ({
    type: GET_PICKUPS_BY_PHONE,
    payload: payload,
});

export const getPickupsByPhoneSuccess = (payload) => ({
    type: GET_PICKUPS_BY_PHONE_SUCCESS,
    payload: payload,
});

export const getPickupsByPhoneError = (error) => ({
    type: GET_PICKUPS_BY_PHONE_ERROR,
    payload: error,
});

export const getPickupById = (payload) => ({
    type: GET_PICKUP_BY_ID,
    payload: payload,
});

export const getPickupByIdSuccess = (payload) => ({
    type: GET_PICKUP_BY_ID_SUCCESS,
    payload: payload,
});

export const getPickupByIdError = (error) => ({
    type: GET_PICKUP_BY_ID_ERROR,
    payload: error,
});

export const resetPickups = () => {
    return { type: RESET_PICKUPS };
};

export const createGentlyPickup = (payload) => ({
	type: CREATE_GENTLY_PICKUP,
	payload: payload,
})

export const createGentlyPickupSuccess = (payload) => ({
	type: CREATE_GENTLY_PICKUP_SUCCESS,
	payload: payload,
})

export const createGentlyPickupError = (payload) => ({
	type: CREATE_GENTLY_PICKUP_ERROR,
	payload: payload,
})

export const createGentlyPickupNoNode = (payload) => ({
	type: CREATE_GENTLY_PICKUP_NO_NODE,
	payload: payload,
})

export const createGentlyPickupNoNodeSuccess = (payload) => ({
	type: CREATE_GENTLY_PICKUP_NO_NODE_SUCCESS,
	payload: payload,
})

export const createGentlyPickupNoNodeError = (payload) => ({
	type: CREATE_GENTLY_PICKUP_NO_NODE_ERROR,
	payload: payload,
})