import {
    GET_LEAD,
    GET_LEAD_SUCCESS,
    GET_LEAD_FAIL,
    ADD_LEAD,
    ADD_LEAD_SUCCESS,
    ADD_LEAD_FAIL,
    UPDATE_LEAD,
    UPDATE_LEAD_FAIL,
    UPDATE_LEAD_SUCCESS,
    GET_ALL_LEADS,
    GET_ALL_LEADS_SUCCESS,
    GET_ALL_LEADS_FAIL,
    GENERATE_DOCUSIGN,
    GENERATE_DOCUSIGN_SUCCESS,
    GENERATE_DOCUSIGN_FAIL,
    FINALIZE_NODE,
    FINALIZE_NODE_SUCCESS,
    FINALIZE_NODE_FAIL,
    GET_SERVICE_AREAS,
    GET_SERVICE_AREAS_SUCCESS,
    GET_SERVICE_AREAS_FAIL,
    CHECK_PROMO_CODE_VALIDITY,
    CHECK_PROMO_CODE_VALIDITY_SUCCESS,
    CHECK_PROMO_CODE_VALIDITY_FAIL,
} from "./actionTypes";

const initialState = {
    error: null,
    loading: false,
    lead: null,
    allLeads: null,
    updatingLead: false,
    updateLeadError: false,
    updatedLead: null,
    newLead: null,
    docuSignUrl: null,
    loadingDocuSign: false,
    docuSignError: null,
    finalizedNode: null,
    loadingFinalizeNode: false,
    finalizeNodeError: null,
    serviceAreaPolygons: null,
    loadingServiceAreas: false,
    serviceAreasError: null,
    promoCodeDoc: null,
    loadingValidatePromoCode: false,
    promoCodeError: null,
    leadsCount: null,
};

const leads = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEAD:
            return {
                ...state,
                loading: true,
            };
        case GET_LEAD_SUCCESS:
            const lead = action.payload;
            return {
                ...state,
                loading: false,
                lead: lead,
            };
        case GET_LEAD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case ADD_LEAD:
            return {
                ...state,
                loading: true,
            };
        case ADD_LEAD_SUCCESS:
            // console.log("SUCCESS", action.payload)
            return {
                ...state,
                loading: false,
                newLead: action.payload,
            };
        case ADD_LEAD_FAIL:
            // console.log("FAIL", action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_LEAD:
            return {
                ...state,
                updatingLead: true,
            };
		case UPDATE_LEAD_SUCCESS:
			return {
				...state,
				updatingLead: false,
				updatedLead: action.payload,
			}
		case UPDATE_LEAD_FAIL:
			return {
				...state,
				updatingLead: false,
				updateLeadError: action.payload,
			}
		case GENERATE_DOCUSIGN:
			return {
				...state,
				loadingDocuSign: true,
			}
		case GENERATE_DOCUSIGN_SUCCESS:
			return {
				...state,
				docuSignUrl: action.payload,
				loadingDocuSign: false,
			}
		case GENERATE_DOCUSIGN_FAIL:
			return {
				...state,
				loadingDocuSign: false,
				docuSignError: action.payload,
			}
		case FINALIZE_NODE:
			return {
				...state,
				loadingFinalizeNode: true,
			}
		case FINALIZE_NODE_SUCCESS:
			return {
				...state,
				finalizedNode: action.payload,
				loadingFinalizeNode: false,
			}
		case FINALIZE_NODE_FAIL:
			return {
				...state,
				loadingFinalizeNode: false,
				finalizeNodeError: action.payload,
			}
		case GET_SERVICE_AREAS:
			return {
				...state,
				loadingServiceAreas: true,
			}
		case GET_SERVICE_AREAS_SUCCESS:
			return {
				...state,
				serviceAreaPolygons: action.payload,
				loadingServiceAreas: false,
			}
		case GET_SERVICE_AREAS_FAIL:
			return {
				...state,
				loadingServiceAreas: false,
				serviceAreasError: action.payload,
			}
		case CHECK_PROMO_CODE_VALIDITY:
			return {
				...state,
				loadingValidatePromoCode: true,
			}
		case CHECK_PROMO_CODE_VALIDITY_SUCCESS:
			return {
				...state,
				promoCodeDoc: action.payload,
				loadingValidatePromoCode: false,
			}
		case CHECK_PROMO_CODE_VALIDITY_FAIL:
			return {
				...state,
				loadingValidatePromoCode: false,
				promoCodeError: action.payload,
			}
            return {
                ...state,
                updatingLead: false,
                updateLeadError: action.payload,
            };
        case GET_ALL_LEADS:
            return {
                ...state,
                loading: true,
                leadsCount: null,
                allLeads: null,
            };
        case GET_ALL_LEADS_SUCCESS:
            const allLeads = action.payload[0];
            // console.log("SUCCESS", action.payload);
            return {
                ...state,
                allLeads: allLeads.data,
                loading: true,
                leadsCount: allLeads.pagination[0].total,
            };
        case GET_ALL_LEADS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
				leadsCount: null,
            };
            return {
                ...state,
                loadingValidatePromoCode: false,
                promoCodeError: action.payload,
            };
        default:
            return state;
    }
};

export default leads;
