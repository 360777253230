import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Popup } from "../../components/Elements";
import { FaClipboard, FaLock } from "react-icons/fa";
import { BounceLoader } from "react-spinners";
import {
  removeDiscountCode,
  deductUserRewardPts,
  updateNonUniqueDiscountCodeUsers,
  sendRedeemRewardEmail
} from "../../store/actions";
import { MdClear } from "react-icons/md";

const RewardsTile = (props) => {
  const {
    brandName,
    brandDescription,
    rewardCaption,
    rewardPoints,
    imgSrc,
    discountCodes,
    rewardURL,
    expirationDate,
    redeemedUsers,
    user,
    nonUnique,
    onRemoveDiscountCode,
    onDeductUserRewardPts,
    onUpdateNonUniqueDiscountCodeUsers,
    onSendRedeemRewardEmail,
  } = props;

  const captionMaxLen = 60;
  const brandDescrMaxLen = 260;
  const [isOpen, setIsOpen] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [copied, setCopied] = useState(false);

  const copyToClipBoard = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
    } catch (err) {}
  };

  return (
    <div className="w-[300px] lg:w-[275px] xl:w-[350px] max-w-[300px] h-[500px] p-4 rounded-xl shadow-light-grey flex flex-col mb-4 flex-shrink-0 bg-white">
      <div className="w-full flex justify-center items-center mb-6">
        <img src={imgSrc} className="w-[130px] mt-6" />
      </div>
      <div className="h-full flex flex-col justify-between mx-5">
        <div className="flex flex-col h-full">
          <h1 className="font-header text-black text-2xl mb-2 text-center">
            {brandName}
          </h1>
          <h2 className="whitespace-wrap font-body text-black text-lg sm:text-xl mb-2">
            {rewardCaption.length > captionMaxLen
              ? rewardCaption.slice(0, captionMaxLen - 3) + "..."
              : rewardCaption}
          </h2>
          <p className="max-h-[100px] whitespace-wrap mb-2 overflow-hidden text-sm">
            {brandDescription.length > brandDescrMaxLen
              ? brandDescription.slice(0, brandDescrMaxLen - 3) + "..."
              : brandDescription}
          </p>
          <h1 className="font-body text-xl sm:mb-2 xl:mb-8">
            <span className="font-header text-2xl text-green-600">
              {rewardPoints}
            </span>{" "}
            reward points
          </h1>{" "}
        </div>
        {user.userId &&
        user.rewardPoints >= rewardPoints &&
        !redeemedUsers.includes(user.userId) ? (
          <Button
            text="Redeem"
            size="sm"
            color="green"
            className="mx-auto mb-8 py-1 min-w-[130px]"
            onClick={() => {
              setIsOpen(true);
              setDiscountCode(discountCodes[0]);
            }}
          ></Button>
        ) : (
          <>
            <Button
              icon={<FaLock />}
              iconPosition="center"
              text=""
              size="sm"
              color="disabled"
              className="mx-10 mb-8 py-1 min-w-[150px]"
            ></Button>
            {nonUnique && redeemedUsers.includes(user.userId) && (
              <div className="text-center text-xs">Code already redeemd</div>
            )}
          </>
        )}

        {!confirmClicked ? (
          <Popup isOpen={isOpen}>
            <div className="fixed top-[4rem] right-3">
              <MdClear
                className="text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl"
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className="flex flex-col items-center justify-center pt-12">
              <h2 className="whitespace-wrap">Would you like to exchange </h2>
              <span className="font-header pt-2">
                {rewardPoints} reward points
              </span>
              <span className="font-header text-green-600 text-xl pt-2 text-center">
                {" "}
                for {rewardCaption}{" "}
              </span>
              <span className="pt-2">from {brandName}?</span>
            </div>
            <div className="w-full flex justify-center items-center pt-3">
              <img src={imgSrc} className="w-[170px] rounded" />
            </div>
            <Button
              text="Exchange points to redeem"
              size="sm"
              color="green"
              className="mt-5 px-5 py-2 !w-1/2 text-sm"
              onClick={() => {
                if (nonUnique) {
                  onUpdateNonUniqueDiscountCodeUsers({
                    brand: brandName,
                    userId: user.userId,
                  });
                } else {
                  onRemoveDiscountCode({
                    brand: brandName,
                    discountCode: discountCode,
                  });
                }
                onDeductUserRewardPts({
                  userId: user._id,
                  deduct: rewardPoints,
                });
                onSendRedeemRewardEmail({
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  caption: rewardCaption,
                  brandName: brandName,
                  brandLink: rewardURL,
                  discountCode: discountCode,
                });
                setConfirmClicked(true);
              }}
            />
          </Popup>
        ) : (
          <Popup isOpen={isOpen}>
            <div className="fixed top-[4rem] right-3">
              <MdClear
                className="text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl"
                onClick={() => {
                  setIsOpen(false);
                  setConfirmClicked(false);
                  setCopied(false);
                }}
              />
            </div>
            <p className="whitespace-wrap sm:mb-2 text-black pt-12">
              Success! Use discount code below
            </p>
            <div className="flex flex-row items-center justify-center mt-2">
              <h2
                className="whitespace-wrap font-body text-green-600 text-3xl sm:mb-2 hover:text-green-400 hover:cursor-pointer"
                onClick={() => copyToClipBoard(discountCode)}
              >
                {discountCode}
              </h2>
              <FaClipboard
                className="text-green-600 hover:text-green-400 hover:cursor-pointer ml-2"
                onClick={() => copyToClipBoard(discountCode)}
              />
            </div>
            {copied && (
              <div className="text-xs text-center">
                Code Copied to Clipboard!
              </div>
            )}
            <p className="whitespace-wrap mt-2 sm:mb-2 text-center">
              Redeem at{" "}
              <a
                href={rewardURL}
                target="_blank"
                className="whitespace-wrap sm:mb-2 underline text-green-600"
                rel="noreferrer"
              >
                {rewardURL}{" "}
              </a>
            </p>
            {expirationDate < new Date("2099-12-30") && (
              <p className="whitespace-wrap my-2 text-center">
                Please redeem by {expirationDate}
              </p>
            )}
            <p className="whitespace-wrap mt-2 font-bold text-lg text-green-600 text-center">
              Please write down or save code as it's only viewable one time
            </p>
          </Popup>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ AllRewards }) => ({
  codesUpdatedReward: AllRewards.codesUpdatedReward,
  updatedUser: AllRewards.updatedUser,
  usersUpdatedReward: AllRewards.usersUpdatedReward,
});

const mapDispatchToProps = (dispatch) => ({
  onRemoveDiscountCode: (payload) => dispatch(removeDiscountCode(payload)),
  onDeductUserRewardPts: (payload) => dispatch(deductUserRewardPts(payload)),
  onUpdateNonUniqueDiscountCodeUsers: (payload) =>
    dispatch(updateNonUniqueDiscountCodeUsers(payload)),
  onSendRedeemRewardEmail: (payload) =>
    dispatch(sendRedeemRewardEmail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsTile);
