import { pad } from 'redux-logger/src/helpers'

export const parseMenu = (menu) => {
	let parsedMenu = {}

	if (menu.storefrontInfo.storeState !== 'OPEN') return {}

	parsedMenu.menuIds = getCurrentMenuIds(menu.menuActiveHoursLookup)
	if (!parsedMenu.menuIds) return {}
	parsedMenu.menuInfo = getMenuInfo(menu.menus, parsedMenu.menuIds)

	parsedMenu.activeMenus = getMenuCategories(
		menu.categories,
		parsedMenu.menuInfo
	)

	parsedMenu.menuItems = getCategoryItems(menu.items, parsedMenu.activeMenus)

	parsedMenu.menuItems = getItemModifiers(
		menu.modifierGroups,
		menu.items,
		parsedMenu.menuItems
	)

	parsedMenu.menuItems = getItemNestedModifiers(menu.modifierGroups, menu.items, parsedMenu.menuItems)

	parsedMenu.menuItems = getItemPictures(menu.photos, parsedMenu.menuItems)

	return parsedMenu
}

export const getCurrentMenuIds = (activehours) => {
	const currentDay = new Date().getDay()
	const currentHour = new Date().getHours()
	let currentMinute = new Date().getMinutes()
	// TIMEZONE!!!
	if (currentMinute < 15) {
		currentMinute = 0
	} else if (currentMinute >= 15 && currentMinute < 30) {
		currentMinute = 15
	} else if (currentMinute >= 30 && currentMinute < 45) {
		currentMinute = 30
	} else {
		currentMinute = 45
	}

	return activehours[currentDay][currentHour][currentMinute].length
		? activehours[currentDay][currentHour][currentMinute]
		: null
}

export const getMenuInfo = (menus, currentMenuIds) => {
	let menuInfo = []
	currentMenuIds.forEach((menuId) => menuInfo.push(menus[menuId]))
	return menuInfo
}

export const getMenuCategories = (categories, menuInfo) => {
	const activeCategories = []
	let menus = {}
	menuInfo.forEach((menu) => {
		menus = { name: '', categories: [] }
		menus.name = menu.name
		menu.categoryIds.forEach((id) => menus.categories.push(categories[id]))
		activeCategories.push(menus)
	})
	return activeCategories
}

export function containsObject(obj, list) {
	let i
	for (i = 0; i < list.length; i++) {
		if (list[i] === obj) {
			return true
		}
	}

	return false
}

export const getCategoryItems = (menuItems, activeMenus) => {
	let itemsByCategory = []
	let menuCategory = {}
	activeMenus.forEach((menu) =>
		menu.categories.forEach((category) => {
			// console.log(category)
			menuCategory = { categoryName: '', categoryId: '', items: [] }
			menuCategory.categoryName = category.name
			menuCategory.categoryId = category.id
			category.itemIds.forEach((id) => {
				menuItems[id].categoryName = category.name
				menuItems[id].categoryId = category.id
				// console.log(menuItems[id].status.saleStatus)
				if (menuItems[id].status.saleStatus === 'FOR_SALE') {
					menuCategory.items.push(menuItems[id])
				}
			})

			if (
				!itemsByCategory.find(
					(cat) => cat.categoryId === menuCategory.categoryId
				)
			) {
				itemsByCategory.push(menuCategory)
			}
		})
	)
	return itemsByCategory
}

export const getItemModifiers = (modifiers, menuItems, parsedMenuItems) => {
	let mods = []
	let modItems = []
	parsedMenuItems.map((category) => {
		category.items.map((item) => {
			mods = []
			item.modifierGroupIds.forEach((modId) => {
				modItems = []
				modifiers[modId].itemIds.forEach((modItemId) => {
					if (menuItems[modItemId].status.saleStatus === 'FOR_SALE') {
						let modItemObj = structuredClone(menuItems[modItemId])
						modItemObj.modGroupName = modifiers[modId].name
						modItemObj.modGroupId = modifiers[modId].id
						modItemObj.modGroupRequired =
							modifiers[modId].minimumSelections > 0
						modItems.push(modItemObj)
					}
				})
				if(modItems.length > 0) {
					mods.push({
						modifierGroup: modifiers[modId],
						modItems: modItems,
					})
				}
			})
			item.modifiers = mods
		})
	})
	return parsedMenuItems
}

export const getItemNestedModifiers = (modifiers, menuItems, parsedMenuItems) => {
	let nestedModItems = []
	parsedMenuItems.map((category) => {
		category.items.map((item) => {
			item.modifiers.forEach((mod, i) => {
				mod.modItems.forEach((modItem, j) => {
					item.modifiers[i].modItems[j].nestedModifiers = [];

					if (menuItems[modItem.id].modifierGroupIds.length > 0) {
						// nested modifier
						menuItems[modItem.id].modifierGroupIds.forEach((nestedModId) => {
							let nestedModItems = []; // Initialize inside the loop to create a new array for each nested modifier group

							modifiers[nestedModId].itemIds.forEach((nestedModItemId) => {
								if (menuItems[nestedModItemId].status.saleStatus === 'FOR_SALE') {
									let nestedModItemObj = structuredClone(menuItems[nestedModItemId]);
									nestedModItemObj.nestedModGroupName = modifiers[nestedModId].name;
									nestedModItemObj.nestedModGroupId = modifiers[nestedModId].id;
									nestedModItemObj.nestedModGroupRequired = modifiers[nestedModId].minimumSelections > 0;
									nestedModItems.push(nestedModItemObj);
								}
							});
							if(nestedModItems.length > 0) {
								item.modifiers[i].modItems[j].nestedModifiers.push({
									nestedModifierGroup: modifiers[nestedModId],
									nestedModItems: nestedModItems,
								});
							}
						});
					}
				});
			});
		});
	});
	return parsedMenuItems
}

export const getItemPictures = (pictures, menuItems) => {
	let pics = []
	menuItems.map((category) => {
		category.items.map((item) => {
			pics = []
			item.photoIds.forEach((picId) => pics.push(pictures[picId]))
			item.pictures = pics
		})
	})
	return menuItems
}

export const createHoursDescription = (menu) => {
	const menuIds = Object.keys(menu.menus)
	const now = new Date()
	const today = now.getDay()
	const hours = menu.menuActiveHoursLookup
	const todaysHours = hours[today]
	const hoursByMenuObjectLocal = {}
	menuIds.map((menuId) => {
		let startSched = false
		hoursByMenuObjectLocal[menuId] = ''
		let afterMidnight = ''
		for (let h = 3; h < 24; h++) {
			for (let m = 0; m < 60; m = m + 5) {
				if (h === 23 && m === 55) {
					let tomorrowDate = new Date()
					tomorrowDate.setDate(tomorrowDate.getDate() + 1)
					const tomorrowDay = tomorrowDate.getDay()
					const tomorrowHours = hours[tomorrowDay]
					let startSched2 = startSched
					for (let h2 = 0; h2 < 4; h2++) {
						for (let m2 = 0; m2 < 60; m2 = m2 + 5) {
							const menusAtThisTime = tomorrowHours[h2][m2]
							if (menusAtThisTime.includes(menuId)) {
								startSched2 = true
							} else {
								if (startSched2) {
									if (h2 === 0) {
										h2 = 12
									}
									afterMidnight =
										' - ' + h2 + ':' + pad(m2, 2) + ' AM'
									hoursByMenuObjectLocal[menuId] =
										hoursByMenuObjectLocal[menuId] +
										afterMidnight
								}
								startSched2 = false
							}
						}
					}
				} else {
					const menusAtThisTime = todaysHours[h][m]
					if (menusAtThisTime.includes(menuId)) {
						if (!startSched) {
							let hourString = h
							let xm = ''
							if (h > 12) {
								hourString = h - 12
								xm = 'PM'
							} else if (h === 12) {
								xm = 'PM'
							} else {
								xm = 'AM'
							}
							hoursByMenuObjectLocal[menuId] =
								hoursByMenuObjectLocal[menuId] +
								hourString +
								':' +
								pad(m, 2) +
								' ' +
								xm
						}
						startSched = true
					} else {
						if (startSched) {
							let hourString = h
							let xm = ''
							if (h > 12) {
								hourString = h - 12
								xm = 'PM'
							} else if (h === 12) {
								xm = 'PM'
							} else {
								xm = 'AM'
							}
							hoursByMenuObjectLocal[menuId] =
								hoursByMenuObjectLocal[menuId] +
								' - ' +
								hourString +
								':' +
								pad(m, 2) +
								' ' +
								xm +
								'; '
						}
						startSched = false
					}
				}
			}
		}
		if (hoursByMenuObjectLocal[menuId] === '') {
			delete hoursByMenuObjectLocal[menuId]
		}
	})
	return hoursByMenuObjectLocal
}
