import {
	GET_DROPOFF_QUOTE,
	GET_DROPOFF_QUOTE_SUCCESS,
	GET_DROPOFF_QUOTE_ERROR,
	CREATE_DROPOFF,
	CREATE_DROPOFF_SUCCESS,
	CREATE_DROPOFF_ERROR,
	CREATE_GENTLY_DROPOFF,
	CREATE_GENTLY_DROPOFF_SUCCESS,
	CREATE_GENTLY_DROPOFF_ERROR,
	CREATE_GENTLY_DROPOFF_NO_NODE,
	CREATE_GENTLY_DROPOFF_NO_NODE_SUCCESS,
	CREATE_GENTLY_DROPOFF_NO_NODE_ERROR,
	GET_DROPOFF_STATUS,
	GET_DROPOFF_STATUS_SUCCESS,
	GET_DROPOFF_STATUS_ERROR,
	GET_DROPOFFS,
	GET_DROPOFFS_SUCCESS, 
	GET_DROPOFFS_ERROR, 
	CANCEL_DROPOFF, 
	CANCEL_DROPOFF_ERROR, 
	GET_DROPOFF_BY_ID,
	GET_DROPOFF_BY_ID_ERROR,
	GET_DROPOFF_BY_ID_SUCCESS,
	CREATE_FLOT_DROPOFF,
	CREATE_FLOT_DROPOFF_SUCCESS,
	CREATE_FLOT_DROPOFF_ERROR,
	CREATE_FLOT_DROPOFF_NO_NODE,
	CREATE_FLOT_DROPOFF_NO_NODE_SUCCESS,
	CREATE_FLOT_DROPOFF_NO_NODE_ERROR,
	CANCEL_FLOT_DROPOFF,
	CANCEL_FLOT_DROPOFF_SUCCESS,
	CANCEL_FLOT_DROPOFF_ERROR,
	GET_ALL_FLOT_DROPOFFS,
	GET_ALL_FLOT_DROPOFFS_ERROR,
	GET_ALL_FLOT_DROPOFFS_SUCCESS,
	RESET_DROPOFF_SUCCESS,
} from './actionTypes'

export const getDropoffQuote = (payload) => ({
	type: GET_DROPOFF_QUOTE,
	payload: payload,
})

export const getDropoffQuoteSuccess = (payload) => ({
	type: GET_DROPOFF_QUOTE_SUCCESS,
	payload: payload,
})

export const getDropoffQuoteError = (payload) => ({
	type: GET_DROPOFF_QUOTE_ERROR,
	payload: payload,
})

export const createDropoff = (payload) => ({
	type: CREATE_DROPOFF,
	payload: payload,
})

export const createDropoffSuccess = (payload) => ({
	type: CREATE_DROPOFF_SUCCESS,
	payload: payload,
})

export const createDropoffError = (payload) => ({
	type: CREATE_DROPOFF_ERROR,
	payload: payload,
})

export const createGentlyDropoff = (payload) => ({
	type: CREATE_GENTLY_DROPOFF,
	payload: payload,
})

export const createGentlyDropoffSuccess = (payload) => ({
	type: CREATE_GENTLY_DROPOFF_SUCCESS,
	payload: payload,
})

export const createGentlyDropoffError = (payload) => ({
	type: CREATE_GENTLY_DROPOFF_ERROR,
	payload: payload,
})

export const createGentlyDropoffNoNode = (payload) => ({
	type: CREATE_GENTLY_DROPOFF_NO_NODE,
	payload: payload,
})

export const createGentlyDropoffNoNodeSuccess = (payload) => ({
	type: CREATE_GENTLY_DROPOFF_NO_NODE_SUCCESS,
	payload: payload,
})

export const createGentlyDropoffNoNodeError = (payload) => ({
	type: CREATE_GENTLY_DROPOFF_NO_NODE_ERROR,
	payload: payload,
})

export const getDropoffStatus = (payload) => ({
	type: GET_DROPOFF_STATUS,
	payload: payload,
})

export const getDropoffStatusSuccess = (payload) => ({
	type: GET_DROPOFF_STATUS_SUCCESS,
	payload: payload,
})

export const getDropoffStatusError = (payload) => ({
	type: GET_DROPOFF_STATUS_ERROR,
	payload: payload,
})

export const getDropoffs = (payload) => ({
	type: GET_DROPOFFS,
	payload: payload,
})

export const getDropoffsSuccess = (payload) => ({
	type: GET_DROPOFFS_SUCCESS,
	payload: payload,
})

export const getDropoffsError = (payload) => ({
	type: GET_DROPOFFS_ERROR,
	payload: payload,
})

export const cancelDropoff = (payload) => ({
	type: CANCEL_DROPOFF,
	payload: payload,
})

export const cancelDropoffError = (payload) => ({
	type: CANCEL_DROPOFF_ERROR,
	payload: payload,
})

export const getDropoffById = (payload) => ({
	type: GET_DROPOFF_BY_ID,
	payload: payload,
})

export const getDropoffByIdSuccess = (payload) => ({
	type: GET_DROPOFF_BY_ID_SUCCESS,
	payload: payload,
})

export const getDropoffByIdError = (payload) => ({
	type: GET_DROPOFF_BY_ID_ERROR,
	payload: payload,
})

export const createFlotDropoff = (payload) => ({
	type: CREATE_FLOT_DROPOFF,
	payload: payload,
})

export const createFlotDropoffSuccess = (payload) => ({
	type: CREATE_FLOT_DROPOFF_SUCCESS,
	payload: payload,
})

export const createFlotDropoffError = (payload) => ({
	type: CREATE_FLOT_DROPOFF_ERROR,
	payload: payload,
})

export const createFlotDropoffNoNode = (payload) => ({
	type: CREATE_FLOT_DROPOFF_NO_NODE,
	payload: payload,
})

export const createFlotDropoffNoNodeSuccess = (payload) => ({
	type: CREATE_FLOT_DROPOFF_NO_NODE_SUCCESS,
	payload: payload,
})

export const createFlotDropoffNoNodeError = (payload) => ({
	type: CREATE_FLOT_DROPOFF_NO_NODE_ERROR,
	payload: payload,
})

export const cancelFlotDropoff = (payload) => ({
	type: CANCEL_FLOT_DROPOFF,
	payload: payload,
})

export const cancelFlotDropoffSuccess = (payload) => ({
	type: CANCEL_FLOT_DROPOFF_SUCCESS,
	payload: payload,
})

export const cancelFlotDropoffError = (payload) => ({
	type: CANCEL_FLOT_DROPOFF_ERROR,
	payload: payload,
})

export const getAllFlotDropoffs = (payload) => ({
	type: GET_ALL_FLOT_DROPOFFS,
	payload: payload,
})

export const getAllFlotDropoffsSuccess = (payload) => ({
	type: GET_ALL_FLOT_DROPOFFS_SUCCESS,
	payload: payload,
})

export const getAllFlotDropoffsError = (payload) => ({
	type: GET_ALL_FLOT_DROPOFFS_ERROR,
	payload: payload,
})

export const resetDropoffSuccess = () => ({
    type: RESET_DROPOFF_SUCCESS,
});
