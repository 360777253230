import { GET_OFFICE, GET_OFFICE_SUCCESS, GET_OFFICE_ERROR } from './actionTypes';

export const getOffice = (payload) => ({
    type: GET_OFFICE,
    payload: payload,
});

export const getOfficeSuccess = (payload) => ({
    type: GET_OFFICE_SUCCESS,
    payload: payload,
});

export const getOfficeError = (payload) => ({
    type: GET_OFFICE_ERROR,
    payload: payload,
});