import {
	ADD_CART_TO_USER,
	ADD_TO_CART,
	REMOVE_CART_ITEM,
	REDUCE_CART_ITEM,
	CLEAR_CART,
	GET_CART,
	TOTAL_CART,
	UPDATE_DELIVERY_OPTION,
	GET_CART_NODE_BY_SLUG,
	GET_CART_NODE_BY_SLUG_SUCCESS,
	GET_CART_NODE_BY_SLUG_FAIL,
	CLEAR_CART_SUCCESS, CLEAR_CART_FAIL, GET_CART_SUCCESS, GET_CART_ERROR,
} from './actionTypes'
import {
	handleAddToCart,
	handleRemoveCartItem,
	handleReduceCartItem,
	totalCart,
} from '../../model/cart'

const initialState = {
	cartItems: [],
	cartTotal: 0,
	lastActive: new Date(),
	nodeSlug: '',
	cartNode:null,
	cartNodeError:null,
	deliveryOption:null,
	activeCart: {},
	clearingCart:false,
	clearedCart:false,
	clearCartError:null,
	gettingCart:false,
	getCartError:null,
}

const cart = (state = initialState, action) => {
	switch (action.type) {
		case ADD_TO_CART:
			return {
				...state,
				lastActive: new Date(),
				nodeSlug: action.payload.nodeSlug,
				cartItems: handleAddToCart({
					prevCartItems: state.cartItems,
					nextCartItem: action.payload,
				}),
				clearedCart: false,
			}

		case REDUCE_CART_ITEM:
			return {
				...state,
				lastActive: new Date(),
				cartItems: handleReduceCartItem({
					prevCartItems: state.cartItems,
					cartItemToReduce: action.payload,
				}),
			}

		case REMOVE_CART_ITEM:
			return {
				...state,
				lastActive: new Date(),
				cartItems: handleRemoveCartItem({
					prevCartItems: state.cartItems,
					cartItemToRemove: action.payload,
				}),
			}

		case CLEAR_CART:
			// console.log('CLEAR CART', action.payload)
			return {
				...state,
				clearingCart: true,
				clearedCart: false,
			}
		case CLEAR_CART_SUCCESS:
			// console.log('CLEAR CART', action.payload)
			return {
				...state,
				clearingCart: false,
				clearedCart:true,
			}
		case CLEAR_CART_FAIL:
			// console.log('CLEAR CART', action.payload)
			return {
				...state,
				clearingCart: false,
				clearCartError: action.payload,
				clearedCart: false,
			}

		case TOTAL_CART:
			// console.log("TOTAL CART", action.payload)
			return {
				...state,
				cartTotal: totalCart(action.payload),
			}

		case GET_CART:
			// console.log("GET CART", action.payload)
			return {
				...state,
				gettingCart: true,
			}

		case GET_CART_SUCCESS:
			// console.log("GET CART", action.payload)
			return {
				...state,
				gettingCart: false,
				cartItems: action.payload.cartItems,
				nodeSlug: action.payload.nodeSlug,
				deliveryOption: action.payload.deliveryOption
			}
		case GET_CART_ERROR:
			// console.log("GET CART", action.payload)
			return {
				...state,
				gettingCart: false,
				getCartError: action.payload,
			}

		case ADD_CART_TO_USER:
			return {
				...state,
				cartItems: action.payload.activeCart.cartItems,
				activeCart: action.payload.activeCart,
				deliveryOption: action.payload.activeCart.deliveryOption
			}

		case UPDATE_DELIVERY_OPTION:
			return {
				...state,
				deliveryOption: action.payload,
			}

		case GET_CART_NODE_BY_SLUG:
			return {
				...state,
			}

		case GET_CART_NODE_BY_SLUG_SUCCESS:
			return {
				...state,
				cartNode: action.payload,
			}

		case GET_CART_NODE_BY_SLUG_FAIL:
			return {
				...state,
				cartNodeError: action.payload,
			}

		default:
			return state
	}
}

export default cart
