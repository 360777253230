import { call, put, takeEvery } from 'redux-saga/effects'
import {
	getRestaurantPaymentsSuccess,
	getRestaurantPaymentsFail,
	sendRestaurantPaymentsSuccess,
	sendRestaurantPaymentsFail,
	getRestaurantChargesSuccess,
	chargeRestaurantsSuccess,
	chargeRestaurantsFail,
} from './actions'
import {
	CHARGE_RESTAURANTS,
	GET_RESTAURANT_CHARGES,
	GET_RESTAURANT_PAYMENTS,
	SEND_RESTAURANT_PAYMENTS,
} from './actionTypes'
import {
	getRestaurantPayments,
	sendRestaurantPayments,
	getRestaurantCharges,
	chargeRestaurants,
} from '../../model/restaurantPayments'

function* fetchRestaurantPayments(action) {
	try {
		const restaurantPayments = yield call(
			getRestaurantPayments,
			action.payload
		)
		yield put(getRestaurantPaymentsSuccess(restaurantPayments))
	} catch (error) {
		yield put(getRestaurantPaymentsFail(error))
	}
}

function* payRestaurants(action) {
	try {
		const restaurantPayments = yield call(
			sendRestaurantPayments,
			action.payload
		)
		yield put(sendRestaurantPaymentsSuccess(restaurantPayments))
	} catch (error) {
		yield put(sendRestaurantPaymentsFail(error))
	}
}

function* fetchRestaurantCharges(action) {
	try {
		const restaurantCharges = yield call(
			getRestaurantCharges,
			action.payload
		)
		yield put(getRestaurantChargesSuccess(restaurantCharges))
	} catch (error) {
		yield put(getRestaurantPaymentsFail(error))
	}
}

function* chargeTheRestaurants(action) {
	try {
		const restaurantPayments = yield call(chargeRestaurants, action.payload)
		yield put(chargeRestaurantsSuccess(restaurantPayments))
	} catch (error) {
		yield put(chargeRestaurantsFail(error))
	}
}

function* restaurantPaymentsSaga() {
	yield takeEvery(GET_RESTAURANT_PAYMENTS, fetchRestaurantPayments)
	yield takeEvery(SEND_RESTAURANT_PAYMENTS, payRestaurants)
	yield takeEvery(GET_RESTAURANT_CHARGES, fetchRestaurantCharges)
	yield takeEvery(CHARGE_RESTAURANTS, chargeTheRestaurants)
}

export default restaurantPaymentsSaga
