import {
    getUser,
    updateUser,
    getAppInfo,
    submitOrder,
    getNodeBySlug,
    createTransaction,
    resetTransaction,
    addCartToUser,
    clearCart,
    updateDeliveryOption,
    getPaymentMethods,
    resetCardAdded,
    storePaymentMethod,
    validateGiftCard,
	validatePromo,
	updateGiftCard,
	updatePromo, 
    resetSubmitOrder,
    getMenuBySlug,
    getDispatchAvailability,
} from '../../store/actions'

import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {isAddressInsideDeliveryPolygon} from '../../helpers/googleMapsAPI'

import ReactConfetti from 'react-confetti'
import {GiPartyPopper} from 'react-icons/gi'
import {
    BounceLoaderCentered,
    Button,
    CheckboxGroup,
    Input,
    RadioGroup,
    TextArea,
    DeliveryPickupToggle,
} from '../../components/Elements'
import {CartItem, DeliveryAddressEditForm} from '../../components'

const Checkout = (props) => {
    const {
        cartItems,
        user,
        onGetUser,
        loadingUser,
        loadingAddition,
        onStorePaymentMethod,
        paymentMethods,
        loadingPaymentMethods,
        cartTotal,
        nodeSlug,
        node,
        onAddCartToUser,
        onUpdateUser,
        updateUserLoading,
        userUpdateCompleted,
        submittingOrder,
        submittedOrder,
        submitOrderError,
        onUpdateDeliveryOption,
        deliveryOption,
        onClearCart,
        transaction,
        creatingTransaction,
        onCreateTransaction,
        onResetTransaction,
        promoError,
        onGetNodeBySlug,
        onValidatePromo,
        onValidateGiftCard,
		giftCardIsActive,
		promoMessageType,
		giftCard,
		onUpdateGiftCard,
		promoIsActive,
		promo,
		onUpdatePromo,
        // cardAdded,
        // cardAddedError,
        // onResetCardAdded,
        // onGetPaymentMethods,
        // loadingNode,
        // appInfo,
        onSubmitOrder,
        onResetSubmitOrder,
        // createTransactionError,
        // onGetCart,
        onGetDispatchAvailability,
		deliverectDispatch,
        onGetMenuBySlug,
        menu
    } = props

    // const [finalDeliveryOption, setFinalDeliveryOption] = useState('delivery')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [city, setCity] = useState('')
    const [stateName, setStateName] = useState('')
    const [showAddressForm, setShowAddressForm] = useState(false)
    const [saveAsDefaultAddress, setSaveAsDefaultAddress] = useState(false)
    const [geoCodedAddress, setGeoCodedAddress] = useState(null)
    const [fullGeoCodedAddress, setFullGeoCodedAddress] = useState(null)
    const [addressLat, setAddressLat] = useState(null)
    const [addressLng, setAddressLng] = useState(null)
    const [streetNumber, setStreetNumber] = useState('')
    const [streetName, setStreetName] = useState('')
    const [pickupOnly, setPickupOnly] = useState(false)
    // const [addCardListener, setAddCardListener] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [cardRadios, setCardRadios] = useState([])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
    const [deliveryNote, setDeliveryNote] = useState('')
    const [giveUtensils, setGiveUtensils] = useState(false)
    const [giveCondiments, setGiveCondiments] = useState(false)
    const tipOptions = [0.15, 0.18, 0.2, 0.25]
    const [selectedTipOption, setSelectedTipOption] = useState(0.15)
    const [promoMessage, setPromoMessage] = useState('')
    const [cartItemsNodeSlug, setCartItemsNodeSlug] = useState(null)

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)
    const stripe = useStripe()
    const elements = useElements()
    const cardElementOptions = {
        style: {
            base: {
                color: '#504E63',
                borderRadius: 5,
                backgroundColor: '#e9effd',
                lineHeight: '2.5',
                fontFamily: '"Poppins", "sans-serif"',
                fontWeight: 'bold',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#f38686',
                iconColor: '#f38686',
            },
        },
    }
    // const [addCardError, setAddCardError] = useState(false)
    const [stripeLoading, setStripeLoading] = useState(false)
    const [showCardForm, setShowCardForm] = useState(false)
    // const [orderTotal, setOrderTotal] = useState(false)
    const [showCustomTipField, setShowCustomTipField] = useState(false)
    const [customTipSelected, setCustomTipSelected] = useState(false)
    const [zeroFoodPrint, setZeroFoodPrint] = useState(0.01)
    const [taxes, setTaxes] = useState(0.0)
    const [serviceFee, setServiceFee] = useState(0.0)
    const [deliveryFee, setDeliveryFee] = useState(0.0)
    const [tipAmount, setTipAmount] = useState(0.0)
    const [totalTotal, setTotalTotal] = useState(0.0)
    const [zeroFoodPrintBox, setZeroFoodPrintBox] = useState(true)
    const [settingGeocodedAddress, setSettingGeocodedAddress] = useState(false)
    const [showSuccessfulOrder, setShowSuccessfulOrder] = useState(false)
    const [showReturnSuccess, setShowReturnSuccess] = useState(false)
    const [qty, setQty] = useState(0)
    const [returnErrorMessage, setReturnErrorMessage] = useState('')
    const [address2Final, setAddress2Final] = useState('')
    const [providedCode, setProvidedCode] = useState('')
	const [giftCardDiscount, setGiftCardDiscount] = useState(0.0)
	const [promoDollarAmount, setPromoDollarAmount] = useState(0.0)
    const google = window.google
    const navigate = useNavigate()

    const [changedDeliveryAddress, setChangedDeliveryAddress] = useState(null)
    const [changedDeliveryAddress2, setChangedDeliveryAddress2] = useState(null)
    const [deliverectValidationId, setDeliverectValidationId] = useState(null) // for deliverect dispatch validation
    const [invalidAddress, setInvalidAddress] = useState(false);

    useEffect(() => {
        const lastSearchedAddress = JSON.parse(
            localStorage.getItem('lastSearchedAddress')
        )
        if (user && user.activeCart && user.activeCart.lastSearchedAddress) {
            geocodeAddress(user.activeCart.lastSearchedAddress)
            setAddress(user.activeCart.lastSearchedAddress)
        } else if (lastSearchedAddress && lastSearchedAddress.address) {
            geocodeAddress(lastSearchedAddress.address)
            setAddress(lastSearchedAddress.address)
            if(lastSearchedAddress.address2){
                setAddress2(lastSearchedAddress.address2)
            }
        } else if(user && user.lastSearchedAddress){
            geocodeAddress(user.lastSearchedAddress)
            setAddress(user.lastSearchedAddress)
            if(user.lastSearchedAddress2){
                setAddress2(user.lastSearchedAddress2)
            }
        } else if (user && user.address) {
            geocodeAddress(user.address)
            setAddress(user.address)
            setAddress2(user.address2)
        }
    }, [user])

    useEffect(() => {
        // console.log('user payment useeffect triggered')
        if (user && user.paymentMethods) {
            makeCardRadios(user.paymentMethods)
        }
    }, [user?.paymentMethods])

    useEffect(() => {
        if (cartItems && cartItems[0]?.nodeSlug) {
            onGetNodeBySlug(cartItems[0].nodeSlug)
            onGetMenuBySlug(cartItems[0].nodeSlug)
        }
    }, [nodeSlug])

    useEffect(() => {
		if (menu && geoCodedAddress && deliveryOption==="delivery") {
			if (menu.deliverectStoreId && user) {
				// deliverect resto so check for dispatch availability
				const dispatchPayload = {
					channelLinkId: menu.deliverectStoreId,
					customerName: user.firstName + ' ' + user.lastName,
					address: geoCodedAddress,
					street: streetName,
					postalCode: zipCode,
					phone: user.phone,
					addressLng: addressLng,
					addressLat: addressLat,
				}

				onGetDispatchAvailability(dispatchPayload)
			} else {
                validateAddress();
            }
		} else if (deliveryOption === "pickup") {
            setInvalidAddress(false);
        }
	}, [menu, geoCodedAddress, deliveryOption])

    useEffect(() => {
        if (deliveryOption === "delivery") {
            validateAddress();
        } else if (deliveryOption === "pickup") {
            setInvalidAddress(false);
            setShowAddressForm(false);
        }
    }, [deliveryOption]);

    const validateAddress = () => {
        if (
            !streetName ||
            streetName === "" ||
            !streetNumber ||
            streetNumber === ""
        ) {
            setInvalidAddress(true);
            setShowAddressForm(true);
            if (address && address !== geoCodedAddress) {
                geocodeAddress();
            }
        } else {
            setInvalidAddress(false);
            if (address && address !== geoCodedAddress) {
                geocodeAddress();
            }
        }
    };

    useEffect(() => {
        if (changedDeliveryAddress) {
            geocodeAddress(changedDeliveryAddress)
            setAddress2(changedDeliveryAddress2)
            validateAddress(changedDeliveryAddress);
        }
    }, [changedDeliveryAddress])

    const geocodeAddress = async (addy) => {
        // Geocode the address
        let geocoder = new google.maps.Geocoder()
        await geocoder.geocode(
            {
                address: addy,
            },
            function (results, status) {
                if (
                    status === google.maps.GeocoderStatus.OK &&
                    results.length > 0
                ) {
                    // set it to the correct, formatted address if it's valid
                    setFullGeoCodedAddress(results[0])
                    setGeoCodedAddress(results[0].formatted_address)
                    results[0].address_components.forEach((element) => {
                        // console.log(element)
                        if (element.types.includes('postal_code')) {
                            setZipCode(element.short_name)
                        } else if (element.types.includes('route')) {
                            setStreetName(element.short_name)
                        } else if (element.types.includes('street_number')) {
                            setStreetNumber(element.short_name)
                        } else if (element.types.includes('sublocality')) {
                            setCity(element.short_name)
                        } else if (
                            element.types.includes(
                                'administrative_area_level_1'
                            )
                        ) {
                            setStateName(element.short_name)
                        }
                    })
                    setAddressLat(results[0].geometry.location.lat())
                    setAddressLng(results[0].geometry.location.lng())
                } else {
                    setAddressError(true)
                    setSettingGeocodedAddress(false)
                }
            }
        )
    }

    useEffect(() => {
        // console.log('in addresslng useeffect')
        if (node && node.deliveryPolygon && addressLng && cartItems && cartItems[0] && cartItems[0].nodeSlug === node.slug) {
            if (
                isAddressInsideDeliveryPolygon(
                    [addressLat, addressLng],
                    node.deliveryPolygon
                )
            ) {
                if(deliverectDispatch) {
                    if(deliverectDispatch == 'NO AVAILABILITY') {
                        setPickupOnly(true)
                        onUpdateDeliveryOption('pickup')
                    } else {
                        setPickupOnly(false)
                        setDeliverectValidationId(deliverectDispatch)
                        if (deliveryOption == '') {
                            onUpdateDeliveryOption('delivery')
                        }
                    }
                } else {
                    setPickupOnly(false)
                    if (deliveryOption == '') {
                        onUpdateDeliveryOption('delivery')
                    }
                }
            } else {
                setPickupOnly(true)
                onUpdateDeliveryOption('pickup')
            }
        }
        if (saveAsDefaultAddress) {
            // console.log('saving address', geoCodedAddress)
            onUpdateUser({
                address: geoCodedAddress,
                address2: address2,
            })
            const lastSearchedAddress = {
                address: geoCodedAddress,
                lat: addressLat,
                lng: addressLng,
                address2: address2,
            }
            localStorage.setItem(
                'lastSearchedAddress',
                JSON.stringify(lastSearchedAddress)
            )
        }
        setSettingGeocodedAddress(false)
        setShowAddressForm(false)
        setSaveAsDefaultAddress(false)
    }, [addressLng, node, deliverectDispatch])

    useEffect(() => {
        if (userUpdateCompleted) {
            onGetUser()
        }
    }, [userUpdateCompleted])

    const makeCardRadios = (paymentMethods) => {
        // console.log(paymentMethods)
        let radios = []
        paymentMethods.forEach(function (paymentMethod, index) {
            radios.push({
                value: paymentMethod.id,
                label: `${paymentMethod.card.brand.toUpperCase()} ....${
                    paymentMethod.card.last4
                } - Exp. ${paymentMethod.card.exp_month}/${
                    paymentMethod.card.exp_year
                }`,
                checked: index === 0,
            })
            if (index === 0) {
                setSelectedPaymentMethod(paymentMethod.id)
            }
        })
        setCardRadios(radios)
    }

    const handleAddCardSubmit = async (event) => {
        setStripeLoading(true)
        event.preventDefault()
        // setAddCardError(false)
        if (elements == null) {
            return
        }
    
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        })
        if (result.error) {
            setStripeLoading(false)
            // setAddCardError(true)
        } else {
            setStripeLoading(false)
            onStorePaymentMethod({
                stripeCustomerId: user.stripeId,
                paymentMethodId: result.paymentMethod.id,
            })

            setShowCardForm(false)
        }
    }

    useEffect(() => {
        if (paymentMethods.length > 0) {
            onGetUser()
            makeCardRadios(paymentMethods)
        }
    }, [paymentMethods])

    const selectTipOption = (tipOption) => {
        setSelectedTipOption(tipOption)
    }

    const handleCustomTip = (tipDollarAmount) => {
        setSelectedTipOption(tipDollarAmount.target.value / cartTotal)
    }

    const handleZeroFoodPrint = () => {
        setZeroFoodPrintBox(!zeroFoodPrintBox)
    }

    const handleUtensils = () => {
        setGiveUtensils(!giveUtensils)
    }

    const handleCondiments = () => {
        setGiveCondiments(!giveCondiments)
    }

    useEffect(() => {
        if (zeroFoodPrintBox) {
            setZeroFoodPrint(0.01)
        } else {
            setZeroFoodPrint(0.0)
        }
    }, [zeroFoodPrintBox])

    useEffect(() => {
        if (cartItems && cartItems.length) {
            const lastCartItem = cartItems[cartItems.length - 1]
            const activeCart = {
                cartItems: cartItems,
                nodeSlug: lastCartItem.nodeSlug,
                lastActive: new Date(),
                deliveryOption: deliveryOption,
                lastSearchedAddress: geoCodedAddress,
            }
            localStorage.setItem('activeCart', JSON.stringify(activeCart))
            if (user) {
                onAddCartToUser({
                    activeCart: activeCart,
                    user: user,
                })
            }
        }
    }, [cartItems, deliveryOption, geoCodedAddress])

    useEffect(() => {
        if (node && node.taxToUser) {
            setTaxes(cartTotal * node.taxToUser)
            setServiceFee(cartTotal * node.serviceFeeToUser)
            setTipAmount(cartTotal * selectedTipOption)
            if (deliveryOption === 'delivery') {
                setDeliveryFee(node.deliveryFeeToUser)
            } else {
                setDeliveryFee(0.0)
            }
        }
    }, [cartTotal, node, selectedTipOption, deliveryOption])

    const handleSubmitOrder = async (e) => {
        e.preventDefault()
        // Calculate total order amount
        const zeroFoodPrintTotal = cartTotal * zeroFoodPrint
        const totalOrderAmount =
            zeroFoodPrintTotal +
            cartTotal +
            taxes +
            serviceFee +
            deliveryFee +
            tipAmount

        // console.log('orderPayload', {
        //     cartItems: cartItems,
        //     nodeSlug: `${cartItems ? cartItems[0].nodeSlug : nodeSlug}`,
        //     aggregator: node.directOrderingClient,
        //     subtotal: cartTotal,
        //     taxes: taxes,
        //     tip: tipAmount,
        //     serviceFee: serviceFee,
        //     deliveryFee: deliveryFee,
        //     zeroFoodPrint: zeroFoodPrintTotal,
        //     orderTotal: totalOrderAmount,
        //     giftCard: giftCard ? giftCard : null,
        //     giftCardCode: giftCard ? providedCode : 0,
		// 	giftCardAmount: giftCard ? giftCardDiscount : 0,
		// 	promo: promo ? promo : null,
        //     promoCode: promo ? providedCode : null,
		// 	promoAmount: promo ? promoDollarAmount : 0,
        //     deliveryOption: deliveryOption,
        //     address: geoCodedAddress,
        //     address2: address2,
        //     zipCode: zipCode,
        //     city: city,
        //     addressLat: addressLat,
        //     addressLng: addressLng,
        //     stateName: stateName,
        //     fullGeoCodedAddress: fullGeoCodedAddress,
        //     paymentMethodId: selectedPaymentMethod,
        //     userPhone: user.phone,
        //     deliveryNote: deliveryNote,
        //     streetNumber: streetNumber,
        //     streetName: streetName,
        // })

        let orderPayload = {
            cartItems: cartItems,
            nodeSlug: nodeSlug,
            aggregator: node.directOrderingClient,
            subtotal: cartTotal,
            taxes: taxes,
            tip: tipAmount,
            serviceFee: serviceFee,
            deliveryFee: deliveryFee,
            zeroFoodPrint: zeroFoodPrintTotal,
            orderTotal: totalOrderAmount,
            giftCard: giftCard ? giftCard : null,
            giftCardCode: giftCard ? providedCode : 0,
			giftCardAmount: giftCard ? giftCardDiscount : 0,
			promo: promo ? promo : null,
            promoCode: promo ? providedCode : null,
			promoAmount: promo ? promoDollarAmount : 0,
            deliveryOption: deliveryOption,
            address: geoCodedAddress,
            address2: address2,
            streetNumber: streetNumber,
            streetName: streetName,
            zipCode: zipCode,
            city: city,
            addressLat: addressLat,
            addressLng: addressLng,
            stateName: stateName,
            fullGeoCodedAddress: fullGeoCodedAddress,
            paymentMethodId: selectedPaymentMethod,
            userPhone: user.phone,
            deliveryNote: deliveryNote,
            condimentNote: giveCondiments
                ? ''
                : "Please don't give condiment packets.",
            utensilsNote: giveUtensils ? '' : 'Please do not provide utensils.',
        }

        if(node.directOrderingClient == "deliverect" && deliverectValidationId && deliveryOption == "delivery") {
            // console.log(deliverectValidationId)
            orderPayload.deliverectValidationId = deliverectValidationId
        }

        onSubmitOrder(orderPayload)
    }

    useEffect(() => {
        if (submittedOrder) {

            if (promoIsActive) {
                onUpdatePromo({ promo: promo, promoCode: providedCode, userPhone: user.phone })
                // console.log("updating promo")
            }

            if (giftCardIsActive) {
                onUpdateGiftCard({ giftCardCode: providedCode, amountUsed: giftCardDiscount })
                // console.log("updating giftcard")
            }

            onClearCart()
            setShowSuccessfulOrder(true)
            localStorage.setItem('activeCart', JSON.stringify({}))
            if (user) {
                onAddCartToUser({
                    activeCart: {},
                    user: user,
                })
            }
            onResetSubmitOrder()
        }
    }, [submittedOrder])

    const submitReturnTransaction = (e) => {
        e.preventDefault()
        setReturnErrorMessage('')
        const transactionPayload = {
            timestamp: new Date(),
            type: 'boxesIn',
            boxCount: qty,
            user: {
                phone: user.phone,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                pickupAddress:
                    address2 != ''
                        ? 'Unit: ' + address2 + '; ' + geoCodedAddress
                        : geoCodedAddress,
            },
            node: nodeSlug,
            returnWithNewOrder: true,
        }
        if (qty > 0) {
            onCreateTransaction(transactionPayload)
        } else if (qty === 0) {
            setReturnErrorMessage('Please enter a quantity greater than 0')
        }
    }

    useEffect(() => {
        if (transaction) {
            setShowReturnSuccess(true)
        }
        return function cleanup() {
            onResetTransaction()
        }
    }, [transaction])

    // ! VALIDATE PROMO/GC CODE
	const handleCodeValidation = (e) => {
		e.preventDefault()
		// console.log('CODE SLICE', providedCode.slice(0, 4))
		if (providedCode.slice(0, 4) === 'DZGC') {
			// console.log('GIFTCARD VALIDATE')
			onValidateGiftCard({ code: providedCode })
		} else {
			// console.log('PROMO CODE VALIDATE')
			onValidatePromo({ promoCode: providedCode, phone: user.phone, node: node.slug })
		}
	}

	const promoMessages = {
		promoValid: 'Promo code applied!',
		promoInvalid: 'Promo code is invalid',
		promoExpired: 'Promo code has expired',
		promoAlreadyUsed: 'Promo code is no longer valid',
		promoNotYetActive: 'Promo code is not yet active',
		promoNotValidForUser: 'Promo code is not valid',
		promoNotValidForNode: 'Promo code is not valid for this restaurant',
		giftCardValid: 'Gift card applied!',
		giftCardInvalid: 'Gift card is invalid',
		giftCardBalanceZero: 'Gift card balance is $0.00',
		promoNotValidForUberDirect: 'Promo code not valid for on demand pickups',
		promoOnlyValidForUberDirect: 'Promo code is only valid for on demand pickups',
	}

	useEffect(() => {
		if (promoMessageType) {
			setPromoMessage(promoMessages[promoMessageType])
		}
	}, [promoMessageType])

    // ! CALCULATE TOTAL ORDER AMOUNT
	useEffect(() => {
		const zeroFoodPrintTotal = cartTotal * zeroFoodPrint
		let totalOrderAmount =
			zeroFoodPrintTotal +
			cartTotal +
			taxes +
			serviceFee +
			deliveryFee +
			tipAmount
		// console.log('TOTAL ORDER AMOUNT', totalOrderAmount)
		if (giftCardIsActive && giftCard.balance >= totalOrderAmount) {
			setGiftCardDiscount(totalOrderAmount)
			// console.log('GIFTCARD DISCOUNT BALANCE >', totalOrderAmount)
		} else if (giftCardIsActive && giftCard.balance < totalOrderAmount) {
			setGiftCardDiscount(giftCard.balance)
			// console.log('GIFTCARD DISCOUNT BALANCE <', totalOrderAmount)
		}
		if (promo && promoIsActive && promo.discountType === 'percent') {
			setPromoDollarAmount(cartTotal * (parseInt(promo.amount) / 100))
		}
		if (promo && promoIsActive && promo.discountType === 'cash') {
			setPromoDollarAmount(parseInt(promo.amount))
		}
	}, [
		giftCardIsActive,
		promoIsActive,
		promo,
		giftCard,
		cartTotal,
		zeroFoodPrint,
		taxes,
		serviceFee,
		deliveryFee,
		tipAmount,
	])

    if (showSuccessfulOrder) {
        return (
            <div className='w-full h-full lg:h-screen-3/4 flex flex-col justify-center items-center overflow-hidden'>
                <ReactConfetti
                    className='w-full'
                    colors={['#f38b74']}
                />
                <h1 className='font-vollkorn text-green-600 text-4xl text-center mt-8'>
                    Order Submitted!
                </h1>
                <GiPartyPopper className='text-orange-600 text-[250px]'/>
                <div className="text-center">Visit your account to track the status of your order.</div>
                <Button
                    link='/user/accountLanding'
                    className='px-10 w-[260px] mt-5 shadow-light-grey py-1'
                    text='View My Account'
                />
            </div>
        )
    } 
    else if (node && user && cartItems) {
        return (
            <div className='flex flex-col md:flex-row md:min-h-screen-no-header-mobile sm:min-h-screen-no-header'>
                <div className='md:w-3/5 lg:w-2/3 ml-5 lg:ml-10 overflow md:overflow-y-auto md:scrollbar'>
                    <div className='divide-y-2 divide-solid mb-2'>
                        <div className='my-5 pt-0 md:pt-7 pb-1 md:pb-5'>
                            <div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
                                Contact Information
                            </div>
                            <div className='flex flex-col sm:flex-row'>
                                <div className='flex flex-col mr-4 max-w-300'>
                                    <div className='font-semibold'>
                                        {user.firstName} {user.lastName}
                                    </div>
                                    <div className=''>{user.email}</div>
                                    <div className=''>{user.phone}</div>
                                </div>
                                <div className='w-[200px]'>
                                    {user.address && user.address!='' && (
                                        <div>{user.address}</div>
                                    )}
                                    {user.address2 && user.address2 != '' && (
                                        <div>Unit: {user.address2}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 pt-3 md:pt-7 mb-2 pb-1'>
                            <div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
                                Delivery Options
                            </div>
                            {!deliveryOption ? (
                                <BounceLoaderCentered container='div'/>
                            ) : (
                                <DeliveryPickupToggle
                                    deliveryOption={deliveryOption}
                                    pickupOnly={pickupOnly}
                                    onSendToParent={onUpdateDeliveryOption}
                                    // setDeliveryOption={onUpdateDeliveryOption}
                                />
                            )}
                            {pickupOnly && (
                                <div className='w-full justify-self-end text-red-400 text-base'>
                                    Only pickup available for this address
                                </div>
                            )}
                            <div className='mb-2'>
                                {geoCodedAddress != '' && !showAddressForm && (
                                    <div
                                        className="underline text-green-600 text-sm cursor-pointer"
                                        onClick={() => setShowAddressForm(true)}
                                    >Change Delivery Address</div>
                                )}
                            </div>
                            {deliveryOption == 'delivery' && (
                                <div>
                                    <div className='mt-3 text-[22px] font-semibold'>
                                        Delivery Address:
                                    </div>
                                </div>
                            )}

                            {showAddressForm || invalidAddress  ? (
                                <div>
                                    {!invalidAddress && (
                                        <div
                                            className="underline text-green-600 text-sm cursor-pointer"
                                            onClick={() => setShowAddressForm(false)}
                                        >Cancel Change Delivery Address
                                        </div>
                                    )}
                                    <DeliveryAddressEditForm
                                        user={user}
                                        onGetUser={onGetUser}
                                        setShowAddressForm={setShowAddressForm}
                                        setChangedDeliveryAddress={setChangedDeliveryAddress}
                                        setChangedDeliveryAddress2={setChangedDeliveryAddress2}
                                        invalidAddress={invalidAddress}
                                        setInvalidAddress={setInvalidAddress}
                                        validateAddress={validateAddress}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <div>{geoCodedAddress}</div>
                                    {address2 && address2 != '' && (
                                        <div>Unit: {address2}</div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className='mt-5 pt-3 md:pt-7 mb-2 pb-1'>
                            <div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
                                Payment
                            </div>
                            <div className='mt-1 mb-2 text-lg font-semibold'>
                                Select a card for this order
                            </div>
                            {!loadingAddition &&
                            !stripeLoading &&
                            !loadingUser &&
                            !loadingPaymentMethods && (
                                <div>
                                    {user.paymentMethods &&
                                    cardRadios.length > 0 ? (
                                        <div>
                                            <RadioGroup
                                                className='mt-1 sm:mt-0'
                                                name='sortBy'
                                                radios={cardRadios}
                                                onChange={(
                                                    paymentMethodId
                                                ) => {
                                                    setSelectedPaymentMethod(
                                                        paymentMethodId
                                                    )
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <div className='font-semibold'>
                                                No cards on file
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {loadingPaymentMethods && (
                                <div className='mt-3 text-center'>
                                    <BounceLoaderCentered container='div'/>
                                </div>
                            )}
                            {loadingAddition && (
                                <div className='mt-3 text-center'>
                                    <BounceLoaderCentered container='div'/>
                                </div>
                            )}
                            {loadingUser && (
                                <div className='mt-3 text-center'>
                                    <BounceLoaderCentered container='div'/>
                                </div>
                            )}
                            {stripeLoading && (
                                <div className='mt-3 text-center'>
                                    <BounceLoaderCentered container='div'/>
                                </div>
                            )}
                            <div className='w-[90%] max-w-[440px] mb-3'>
                                <div>
                                    {!showCardForm && (
                                        <div>
                                            <button
                                                className='my-3 text-lg underline text-green-600 font-semibold'
                                                onClick={() => {
                                                    setShowCardForm(true)
                                                }}
                                            >
                                                Add a card
                                            </button>
                                        </div>
                                    )}
                                    {showCardForm && (
                                        <div>
                                            <div className='mt-4'>
                                                <div className='text-sm'>
                                                    Enter card info below
                                                </div>

                                                <form
                                                    onSubmit={
                                                        handleAddCardSubmit
                                                    }
                                                >
                                                    <CardElement
                                                        options={
                                                            cardElementOptions
                                                        }
                                                    />
                                                    {!loadingAddition &&
                                                    !stripeLoading &&
                                                    !loadingUser && (
                                                        <div>
                                                            <Button
                                                                className='mt-2 py-1'
                                                                text='Add Card'
                                                                size='sm'
                                                                submit={
                                                                    true
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </form>
                                            </div>
                                            <div>
                                                {!loadingAddition &&
                                                !stripeLoading &&
                                                !loadingUser && (
                                                    <button
                                                        className='mt-6 text-[16px] underline text-green-600 font-semibold'
                                                        onClick={() => {
                                                            setShowCardForm(
                                                                false
                                                            )
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* PROMO */}
                        <div className='mt-5 pt-3 md:pt-7 mb-4 md:mb-10 pb-3'>
							<div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>Giftcard / Promo</div>
							<div className='w-full h-full'>
								<Input label='Code' className='w-[80%] md:w-1/2 mb-4' onChange={(e)=>setProvidedCode(e.target.value)}/>
								<Button text='Apply' size='sm' className='w-[80%] md:w-1/2' onClick={(e)=>handleCodeValidation(e)} />
							</div>
                            {promoMessage && (
								<div className='mt-4'>{promoMessage}</div>
							)}
						</div>
                        <div className='mt-5 pt-3 md:pt-7 mb-4 md:mb-10 pb-3'>
                            <div className='font-header mb-2 text-black-default text-2xl md:text-3xl'>
                                Add a tip
                            </div>
                            <div className='flex flex-row mr-3 overflow-x-auto'>
                                {tipOptions.map((tipOption, i) => {
                                    return (
                                        <div key={i}>
                                            {selectedTipOption == tipOption ? (
                                                <div
                                                    onClick={() => {
                                                        setCustomTipSelected(
                                                            false
                                                        )
                                                        setShowCustomTipField(
                                                            false
                                                        )
                                                        selectTipOption(
                                                            tipOption
                                                        )
                                                    }}
                                                    className='w-[85px] h-[75px] lg:w-[150px] lg:h-[110px]
                            justify-center items-center rounded-[10px]
                            border-green-600 border-4 cursor-pointer flex flex-col'
                                                >
                                                    <div className='font-semibold'>
                                                        {tipOption * 100}%
                                                    </div>
                                                    <div>
                                                        $
                                                        {(
                                                            tipOption *
                                                            cartTotal
                                                        ).toFixed(2)}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCustomTipSelected(
                                                            false
                                                        )
                                                        setShowCustomTipField(
                                                            false
                                                        )
                                                        selectTipOption(
                                                            tipOption
                                                        )
                                                    }}
                                                    className='w-[85px] h-[75px] lg:w-[150px] lg:h-[110px]
                            justify-center items-center rounded-[10px]
                            border-gray-200 border-2 cursor-pointer flex flex-col'
                                                >
                                                    <div className='font-semibold'>
                                                        {tipOption * 100}%
                                                    </div>
                                                    <div>
                                                        $
                                                        {(
                                                            tipOption *
                                                            cartTotal
                                                        ).toFixed(2)}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                                {customTipSelected ? (
                                    <div
                                        onClick={() => {
                                            setCustomTipSelected(true)
                                            setShowCustomTipField(
                                                !showCustomTipField
                                            )
                                        }}
                                        className='w-[100px] h-[75px] lg:w-[150px] lg:h-[110px]
                            justify-center items-center rounded-[10px]
                            border-green-600 border-4 cursor-pointer flex flex-col'
                                    >
                                        <div className='font-semibold'>
                                            Custom
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => {
                                            setCustomTipSelected(true)
                                            setShowCustomTipField(true)
                                        }}
                                        className='w-[100px] h-[75px] lg:w-[150px] lg:h-[110px]
                            justify-center items-center rounded-[10px]
                            border-gray-200 border-2 cursor-pointer flex flex-col'
                                    >
                                        <div className='font-semibold px-2'>
                                            Custom
                                        </div>
                                    </div>
                                )}
                            </div>
                            {showCustomTipField && (
                                <div className='mt-3 flex flex-row items-center'>
                                    <div className='text-[20px]'>$</div>
                                    <div className='w-[180px]'>
                                        <div>CUSTOM TIP ($)</div>
                                        <Input
                                            type='number'
                                            name='address'
                                            onChange={handleCustomTip}
                                            min='0.00'
                                            step='0.25'
                                            placeholder='3.00'
                                            defaultValue={(
                                                selectedTipOption * cartTotal
                                            ).toFixed(2)}
                                            className='bg-white border-2 border-gray-300 mb-3 w-5/6 md:w-full lg:w-5/6'
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {node.courierAcceptsReturns && (
                            <div className='mt-5 pt-3 md:pt-7 mb-4 md:mb-10 pb-3'>
                                <div className='w-full md:w-3/5 mt-3 md:mt-0'>
                                    <div className='font-header mb-1 text-black-default text-xl md:text-2xl'>
                                        Are you returning containers with this
                                        order?
                                    </div>
                                    <div className='mb-2 text-black-default text-sm md:text-base'>
                                        If so, tell us how many you're returning
                                        and hit "Submit Return"
                                    </div>
                                    <div
                                        className='mt-3 border-2 rounded-full mb-3 sm:mb-0 sm:w-[175px] w-[80%] text-center flex flex-row justify-between px-3 items-center'>
										<span
                                            color='default'
                                            className='text-4xl text-gray-300 w-1/3 cursor-pointer'
                                            onClick={() => {
                                                if (qty >= 2) {
                                                    setQty(qty - 1)
                                                }
                                            }}
                                        >
											-
										</span>
                                        <div className='text-2xl'>{qty}</div>
                                        <span
                                            color='default'
                                            className='text-2xl text-gray-300 w-1/3 cursor-pointer'
                                            onClick={() => {
                                                // if (qty < user.boxCount)
                                                setQty(qty + 1)
                                            }}
                                        >
											+
										</span>
                                    </div>
                                    {showReturnSuccess ? (
                                        <div className='text-green-600 font-semibold mt-2'>
                                            Success! Please hand containers to
                                            courier upon delivery
                                        </div>
                                    ) : (
                                        <div>
                                            {creatingTransaction ? (
                                                <BounceLoaderCentered container='div'/>
                                            ) : (
                                                <Button
                                                    text='Submit Return'
                                                    size='sm'
                                                    color='green'
                                                    onClick={(e) =>
                                                        submitReturnTransaction(
                                                            e
                                                        )
                                                    }
                                                    className='px-3 py-1 mt-2 w-[175px]'
                                                />
                                            )}
                                        </div>
                                    )}
                                    {returnErrorMessage !== '' && (
                                        <div className='text-red-400 mt-2 text-left font-semibold text-sm'>
                                            {returnErrorMessage}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='shadow-lighter-grey md:w-2/5 lg:w-1/3 md:overflow-y-auto md:scrollbar'>
                    {updateUserLoading && (
                        <div className='flex items-center h-screen-no-header-mobile sm:h-screen-no-header'>
                            <BounceLoaderCentered container='div'/>
                        </div>
                    )}

                    <div className='w-[90%] m-auto max-w-[500px] divide-y-2 divide-solid mb-2'>
                        <div>
                            <div className='font-header my-2 text-black-default text-2xl md:text-3xl'>
                                Your order summary
                            </div>
                            <div className='my-2 text-black-default text-base md:text-lg'>
                                Order for {node.name}
                            </div>
                        </div>
                        <CartItem 
                            cartItems={cartItems}
                            setCartItemsNodeSlug={setCartItemsNodeSlug}
                        />
                        <div>
                            <CheckboxGroup
                                label='Provide single-use utensils, if applicable'
                                name='default-address'
                                className='mt-2 mb-1'
                                defaultChecked={false}
                                onChange={handleUtensils}
                            />
                            <CheckboxGroup
                                label='Provide single-use condiment packets, if applicable'
                                name='default-address'
                                className='mt-2 mb-2'
                                defaultChecked={false}
                                onChange={handleCondiments}
                            />
                            <TextArea
                                name='Delivery Note'
                                label='Delivery Note'
                                placeholder='Include any special delivery instructions here'
                                description='Maximum of 250 characters'
                                className='!h-24 !mt-1'
                                onChange={(e) => {
                                    setDeliveryNote(e)
                                }}
                            />
                        </div>
                        <div>
                            <div className='flex flex-col mt-5'>
                                <div className='flex flex-row justify-between my-2'>
                                    <div className=''>Subtotal</div>
                                    <div>${cartTotal.toFixed(2)}</div>
                                </div>
                                {promoIsActive && (
									<div className='flex flex-row justify-between my-2 text-green-600'>
										<div className=''>Promo</div>
										<div>
											- ${promoDollarAmount.toFixed(2)}
										</div>
									</div>
								)}
								{giftCardIsActive && (
									<div className='flex flex-row justify-between my-2 text-green-600'>
										<div className=''>GiftCard</div>
										<div>
											- ${giftCardDiscount.toFixed(2)}
										</div>
									</div>
								)}
                                <div className='flex flex-row justify-between my-2'>
                                    <div className=''>Taxes</div>
                                    <div>${taxes.toFixed(2)}</div>
                                </div>
                                <div className='flex flex-row justify-between my-2'>
                                    <div className=''>Service Fee</div>
                                    <div>${serviceFee.toFixed(2)}</div>
                                </div>
                                <div className='flex flex-row justify-between my-2'>
                                    <div className=''>Delivery Fee</div>
                                    <div>${deliveryFee.toFixed(2)}</div>
                                </div>
                                <div className='flex flex-row justify-between my-2'>
                                    <div className=''>Tip</div>
                                    <div>${tipAmount.toFixed(2)}</div>
                                </div>
                                <div className='flex flex-row justify-between mt-2 mr-3'>
                                    <CheckboxGroup
                                        label='Zero Foodprint Donation (1% of Subtotal)'
                                        name='default-address'
                                        className=''
                                        defaultChecked={true}
                                        onChange={handleZeroFoodPrint}
                                    />
                                    <div className=''></div>
                                    <div>
                                        $
                                        {(cartTotal * zeroFoodPrint).toFixed(2)}
                                    </div>
                                </div>
                                <a
                                    href='https://www.zerofoodprint.org/'
                                    target='_blank'
                                    className='mb-2 text-xs underline text-green-600'
                                    rel='noreferrer'
                                >
                                    Learn more about how ZFP tackles climate
                                    change with regenerative farming
                                </a>
                                <div className='flex flex-row justify-between my-2 mb-[80px] sm:mb-2'>
                                    <div className='font-semibold text-[28px]'>
                                        Total
                                    </div>
                                    <div className='font-semibold text-[28px]'>
                                        $
                                        {(
                                            cartTotal * zeroFoodPrint +
                                            cartTotal +
                                            taxes +
                                            serviceFee +
                                            deliveryFee +
                                            tipAmount -
											giftCardDiscount -
											promoDollarAmount
                                        ).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='justify-center mx-4 flex flex-col my-2 sticky bottom-20 sm:bottom-6 text-4xl'>
                        {submitOrderError && (
                            <div
                                className='rounded-[5px] mb-1 px-2 bg-red-400 w-full justify-self-end text-white text-base shadow-light-grey'>
                                An error occured while submitting your order.
                                Try again or contact us.
                            </div>
                        )}
                        {submittingOrder ? (
                            <BounceLoaderCentered container="div" />
                        ) : invalidAddress ? (
                            <Button
                                text='Place Order'
                                size='sm'
                                className='text-lg shadow-light-grey w-full py-1'
                                color='disabled'
                            />
                        ) : (
                            <Button
                                text='Place Order'
                                size='sm'
                                className='text-lg shadow-light-grey w-full py-1'
                                onClick={handleSubmitOrder}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    } else if (!cartItems) {
        return (
            <div className='flex flex-col items-center h-screen-no-header-mobile sm:h-screen-no-header'>
                <div className='mt-10 text-[24px]'>Your cart is empty!</div>
                <div className='mt-2 mb-3 text-[24px]'>Let's change that.</div>
                <Button
                    className='m-auto mt-3 px-4 py-1'
                    text='Order Food in Reusables'
                    size='sm'
                    link='/'
                ></Button>
            </div>
        )
    } else {
        return (
            <div className='flex items-center h-screen-no-header-mobile sm:h-screen-no-header'>
                <BounceLoaderCentered container='div'/>
            </div>
        )
    }
}

const mapStateToProps = ({
                             User,
                             Cart,
                             Payments,
                             AppInfo,
                             Nodes,
                             Orders,
                             TransactionsCreate,
                             Promos,
                             Menu
                         }) => ({
    user: User.user,
    loadingUser: User.loading,
    updateUserLoading: User.updateUserLoading,
    userUpdateCompleted: User.userUpdateCompleted,
    cartItems: Cart.cartItems,
    cartTotal: Cart.cartTotal,
    nodeSlug: Cart.nodeSlug,
    deliveryOption: Cart.deliveryOption,
    cardAdded: Payments.cardAdded,
    loadingAddition: Payments.loadingAddition,
    cardAddedError: Payments.error,
    paymentMethods: Payments.paymentMethods,
    loadingPaymentMethods: Payments.loadingPaymentMethods,
    appInfo: AppInfo.appInfo,
    node: Nodes.node,
    loadingNode: Nodes.loadingNode,
    submittingOrder: Orders.submittingOrder,
    submittedOrder: Orders.submittedOrder,
    submitOrderError: Orders.submitOrderError,
    transaction: TransactionsCreate.transactionResult,
    createTransactionError: TransactionsCreate.error,
    creatingTransaction: TransactionsCreate.creating,
    promoError: Promos.error,
	giftCardIsActive: Promos.giftCardIsActive,
	promoIsActive: Promos.promoIsActive,
	promoLoading: Promos.loading,
	promoMessageType: Promos.promoMessageType,
	giftCard: Promos.giftCard,
	promo: Promos.promo,
    deliverectDispatch: Menu.dispatch,
    menu: Menu.menu
})

const mapDispatchToProps = (dispatch) => ({
    onGetNode: (payload) => dispatch(getNodeBySlug(payload)),
    onGetUser: () => dispatch(getUser()),
    onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
    onGetAppInfo: () => dispatch(getAppInfo()),
    onStorePaymentMethod: (paymentMethodId) =>
        dispatch(storePaymentMethod(paymentMethodId)),
    onResetCardAdded: () => dispatch(resetCardAdded()),
    onGetPaymentMethods: (userStripeId) =>
        dispatch(getPaymentMethods(userStripeId)),
    onGetNodeBySlug: (nodeSlug) => dispatch(getNodeBySlug(nodeSlug)),
    onSubmitOrder: (submitOrderPayload) =>
        dispatch(submitOrder(submitOrderPayload)),
    onUpdateDeliveryOption: (deliveryOption) =>
        dispatch(updateDeliveryOption(deliveryOption)),
    onClearCart: () => dispatch(clearCart()),
    onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
    onResetTransaction: () => dispatch(resetTransaction()),
    onValidatePromo: (promoCode) => dispatch(validatePromo(promoCode)),
	onValidateGiftCard: (giftCardCode) =>
		dispatch(validateGiftCard(giftCardCode)),
	onUpdateGiftCard: (payload) => dispatch(updateGiftCard(payload)),
	onUpdatePromo: (payload) => dispatch(updatePromo(payload)),
    onAddCartToUser: (payload) => dispatch(addCartToUser(payload)),
    onResetSubmitOrder: () => dispatch(resetSubmitOrder()),
    onGetDispatchAvailability: (payload) =>
		dispatch(getDispatchAvailability(payload)),
    onGetMenuBySlug: (slug) => dispatch(getMenuBySlug(slug)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
