import { realm } from '../helpers/realm'
import { BSON } from 'realm-web'

export const insertTransaction = async (payload) => {
	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')
	// console.log(payload)
	return transactions.insertOne(payload)
}

export const getTransactions = (payload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const { filter, options } = payload
	const { sort, skip, limit } = options
	const page = skip / limit

	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')

	const stages = [
		{
			$addFields: {
				orderObjectId: {
					$toObjectId: '$orderCollectionId',
				},
			},
		},
		{
			$lookup: {
				from: 'nodes',
				localField: 'node',
				foreignField: 'slug',
				as: 'nodeInfo',
			},
		},
		{
			$lookup: {
				from: 'orders',
				localField: 'orderObjectId',
				foreignField: '_id',
				as: 'orderInfo',
			},
		},
		{
			$unwind: {
				path: '$nodeInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
		{
			$unwind: {
				path: '$orderInfo',
				preserveNullAndEmptyArrays: true,
			},
		},
	]

	if (filter) {
		stages.push({ $match: filter })
	}

	stages.push({
		$facet: {
			pagination: [ 
				{
					$match: {
					  adjustedBoxCount: { $ne: true }
					}
				},
				{
					$count: 'total',
				},
				{
					$addFields: { page, limit },
				},
			],
			data: [
				{ $skip: skip },
				{ $limit: limit },
				{
					$match: {
						adjustedBoxCount: { $ne: true },
					},
				},
			],
		},
	})

	if (sort) {
		stages.push({ $sort: sort })
	}

	return transactions.aggregate(stages)
}

export const getOneTransaction = async (transactionPayload) => {
	const transactionId = transactionPayload.transactionId
	const phone = transactionPayload.phone
	const admin = !!transactionPayload.admin
	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')
	if (admin) {
		const transaction = await transactions.findOne({
			_id: BSON.ObjectId(transactionId),
		})
		return transaction
	} else {
		const transaction = await transactions.findOne({
			_id: BSON.ObjectId(transactionId),
			'user.phone': phone,
		})
		return transaction
	}
}

export const disputeTransaction = async (transactionPayload) => {
	const transactionId = transactionPayload._id.toString()
	const newItems = transactionPayload.items
	// console.log(transactionId)
	// console.log(newItems)
	const transaction = await realm.currentUser.functions.disputeBoxesHandler({
		transactionId: transactionId,
		newItems: newItems,
		userId: realm.currentUser.id,
	})
	return transaction
}

export const uploadOrders = async (orderUploadPayload) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const orderUploadResponse = await realm.currentUser.callFunction(
		'api/manualOrderUpload',
		orderUploadPayload
	)

	return orderUploadResponse
}

export const updateTransaction = async (updatePayload) => {
	const transactionId = updatePayload.transactionId.toString()
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const transactions = client.db('caas').collection('transactions')
	const update = await transactions.updateOne(
		{ _id: BSON.ObjectId(transactionId) },
		{ $set: updatePayload.update }
	)
	// console.log(transactionId)
	const transaction = await transactions.findOne({ _id: BSON.ObjectId(transactionId) })
	// console.log(transaction)
	return transaction
}
