import React, { useState, useEffect } from "react"
import { Input } from './Elements'
import { connect } from "react-redux"
import { FaMapMarkerAlt, FaSearch, FaMapMarkedAlt } from 'react-icons/fa'
import { MdClear } from 'react-icons/md'
import {getGPSLocation, captureGPS} from "../store/actions"
import {AiOutlineClose} from "react-icons/ai";
import {BsPinFill} from "react-icons/bs";
import { updateUser } from "../store/auth/user/actions";

const DropOffSearch = (props) => {
	const {
		onSelectAddress,
		changeMapBoolToFalse,
		deliveryAddress,
		setDeliveryAddress,
		nameValue,
		onNameQuery,
		userGPSLocation,
		userGPSLocationError,
		onGetGPSLocation,
		user,
		onUpdateUser,
		onCaptureGPS,
	} = props

	const [address, setAddress] = useState('')
	const [geoCodedAddress, setGeoCodedAddress] = useState('')
	const [geoCodedLat, setGeoCodedLat] = useState();
	const [geoCodedLng, setGeoCodedLng] = useState();

	const [savedAddresses, setSavedAddresses] = useState([]);
	const [currentlyTypedAddressValue, setCurrentlyTypedAddressValue] =
		useState(deliveryAddress || '')
	const [searchByGPS, setSearchByGPS] = useState(false)
	const [currentlyTypedNameValue, setCurrentlyTypedNameValue] = useState('')
	const [toggleIcon, setToggleIcon] = useState(false)
	const [showSavedAddresses, setShowSavedAddresses] = useState(false);

	const handleToggleMap = () => {
	  props.onClickToggleMap()
	  setToggleIcon(prevState => !prevState)
	};

	const handleSubmitAddress = () => {
		changeMapBoolToFalse()
		geocodeAddress(address)
		setShowSavedAddresses(false);
	}

	const handleAddressClear = () => {
		changeMapBoolToFalse()
		let latLng = ''
		let address = ''
		onSelectAddress(address, latLng)
	}

	const handleSubmitName = (e) => {
		onNameQuery(e)
	}

	const autoCompleteQuery = (address, coords) => {
		changeMapBoolToFalse()
		onSelectAddress(address, coords)
	}

	const handleGPSRequest = () => {
		onGetGPSLocation()
		setShowSavedAddresses(false);
	}

	const geocodeAddress = async (addressToGeoCode) => {
		// Geocode the address
		let geocoder = new google.maps.Geocoder();
		await geocoder.geocode(
			{
				address: addressToGeoCode,
			},
			function (results, status) {
				if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
					// console.log(results[0])
					// set it to the correct, formatted address if it's valid
					setGeoCodedLat(results[0].geometry.location.lat())
					setGeoCodedLng(results[0].geometry.location.lng());
					setGeoCodedAddress(results[0].formatted_address);
				} else {
					// // show an error if it's not
					alert("Invalid address");
				}
			}
		);
		// console.log("geocode function", geoCodedAddress)
	};

	const reverseGeocodeAddress = async (coords) => {
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode({ location: coords }, function (results, status) {
		  if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
			onSelectAddress(results[0].formatted_address, coords)
			setDeliveryAddress(results[0].formatted_address, coords)
		  } else {
			alert("Invalid address")
		  }
		})
	}

	useEffect(() => {
		if(userGPSLocation){
			reverseGeocodeAddress({
				lat:userGPSLocation.lat,
				lng:userGPSLocation.lng,
			})
		}
	}, [userGPSLocation])

	useEffect(() => {
		const lastSearchedAddress = JSON.parse(
            localStorage.getItem("lastSearchedAddress")
        )
        if (lastSearchedAddress && lastSearchedAddress.address) {
            setCurrentlyTypedAddressValue(lastSearchedAddress.address);
            setAddress(lastSearchedAddress.address)
        } else if (deliveryAddress){
			setCurrentlyTypedAddressValue(deliveryAddress)
			setAddress(deliveryAddress)
		}
	}, [deliveryAddress])

	useEffect(() => {
		// console.log(address)
		if(address && address !=''){
			handleSubmitAddress()
		}
	}, [address])

	useEffect(() => {
		if(geoCodedAddress && geoCodedLng){
			// console.log(geoCodedLng)
			onSelectAddress(geoCodedAddress,
				{lat:geoCodedLat,lng:geoCodedLng})
		}
	}, [geoCodedAddress,geoCodedLng])

	useEffect(() => {
		if (user && user?.savedAddresses) {
			const filteredAddresses = user?.savedAddresses.filter((item) => item.savedAddress)
			setSavedAddresses(filteredAddresses);
		}
	}, [user, user?.savedAddresses]);

	useEffect(() => {
		// console.log('inside gecode address sueeffect')
		if (geoCodedAddress) {
		  const lastSearchedAddress = {
			address: geoCodedAddress,
			lat: geoCodedLat,
			lng: geoCodedLng,
			address2: "",
		  };
		  localStorage.setItem(
			"lastSearchedAddress",
			JSON.stringify(lastSearchedAddress)
		  );
		  if (user && user.userId) {
			if (
			  user && user.savedAddresses &&
			  !user?.savedAddresses.find(
				(address) => address.savedAddress === geoCodedAddress
			  )
			) {
			  // new address so add to front of array
			  onUpdateUser({
				savedAddresses: [
				  {
					savedAddress: geoCodedAddress,
					savedAddress2: "",
				  },
				  ...user.savedAddresses,
				],
				lastSearchedAddress: geoCodedAddress,
			  });
			} else if (user && !user.savedAddresses) {
				onUpdateUser({
					savedAddresses: [
					  {
						savedAddress: geoCodedAddress,
						savedAddress2: "",
					  },
					],
					lastSearchedAddress: geoCodedAddress,
				  });
			} else {
			  // exisiting address so need to shift it to front to update ordering
			  const existingAddressIdx = user.savedAddresses.findIndex(
				(address) => address.savedAddress === geoCodedAddress
			  );
			  const savedAddress2 =
				user.savedAddresses[existingAddressIdx].savedAddress2;
			  const updatedSavedAddresses = [...user.savedAddresses];
			  updatedSavedAddresses.splice(existingAddressIdx, 1);
			  updatedSavedAddresses.unshift({
				savedAddress: geoCodedAddress,
				savedAddress2: savedAddress2,
			  });
			  onUpdateUser({
				savedAddresses: updatedSavedAddresses,
				lastSearchedAddress: geoCodedAddress,
				lastSearchedAddress2: savedAddress2,
			  });
			}
		  }
		}
	  }, [geoCodedAddress]);

	return (
		<div className='flex flex-col items-center w-screen px-4 '>
            <div className='w-full h-15 mt-3 relative items-center justify-between flex'>
				<div className='w-full'>
					<Input
						placeholder={'Search by address'}
						className="px-10 !text-sm"
						name='nodeAddress'
						error={false}
						autoCompleteAddress={true}
						autoComplete='address'
						onClick={() => {
							setShowSavedAddresses(true);
						}}
						onChange={(e) => {
							const newValue = e.target ? e.target.value : e.formatted_address;
							setCurrentlyTypedAddressValue(newValue);
						}}
						onAddressSelect={(addr)=>{
							setShowSavedAddresses(false);
							setAddress(addr)
						}}
						value={currentlyTypedAddressValue}
					/>
                    <FaMapMarkerAlt className='absolute left-0 ml-4 top-1/2 transform -translate-y-1/2 text-green-400 text-lg sm:text-xl' onClick={handleGPSRequest}/>
				</div>
                {(currentlyTypedAddressValue || address) && (
                    <MdClear
						className="absolute right-[60px] top-1/2 transform -translate-y-1/2 text-white text-lg cursor-pointer bg-green-400 rounded-full w-4 h-4 flex items-center justify-center sm:w-5 sm:h-5 sm:right-[20px]"
						onClick={() => {
							setCurrentlyTypedAddressValue('');
							setAddress('')
							setDeliveryAddress('')
							handleAddressClear()
						}}
                    />
                )}
				<div
					className={`flex items-center justify-center ml-2 h-9 w-11 rounded-full sm:hidden ${
						toggleIcon ? 'bg-white shadow-md' : 'bg-white border-2 border-green-600'
					}`}
					onClick={handleToggleMap}
					>
					<FaMapMarkedAlt className={`text-lg  sm:text-xl ${toggleIcon ? 'text-gray-400' : 'text-green-400'}`} />
				</div>
				{showSavedAddresses && (
					<div
						className="rounded-xl bg-white fixed top-[110px] sm:top-[112px] left-0 right-0 bottom-0 cursor-pointer z-40 shadow-xl overflow-y-auto outline-none pb-[300px]">
						<div
							className="text-xl text-green-600 flex text-center items-center justify-between border-b border-gray-200 py-4 px-5">
							Saved Addresses
							<AiOutlineClose
								className={`font-semibold text-green-600 cursor-pointer align-self-end m-2`}
								onClick={() => {
									setShowSavedAddresses(false);
								}}
							/>
						</div>
						{!userGPSLocationError && (
							<div
								onClick={handleGPSRequest}
								className="text-base flex text-center items-center border-b border-gray-200 py-4 px-5"
							>
								<BsPinFill className={`text-green-400 text-sm mr-2`}/>
								Use current location
							</div>
						)}
						{savedAddresses && savedAddresses != []
							? savedAddresses.map((addr, i) => {
								return (
									<div
										key={i}
										className="text-sm italic flex text-center items-center border-b border-gray-200 py-3 px-5"
										onClick={() => {
											setAddress(addr.savedAddress);
											setCurrentlyTypedAddressValue(addr.savedAddress);
											setShowSavedAddresses(false);
										}}
									>
										<FaMapMarkerAlt
											className={`text-gray-400 text-sm mr-2`}
										/>
										{addr.savedAddress}
									</div>
								);
							})
							: null}
					</div>
				)}

			</div>
			<div className='w-full h-15 mt-1 relative items-center justify-between flex'>
				<div className='w-full'>
					<Input
						placeholder='Search by name'
						className='px-10 !text-sm'
						name='nodeName'
						error={false}
						onChange={(e) => {
							setCurrentlyTypedNameValue(e.target.value)
							if (e.target.value.length > 3) {
								handleSubmitName(e)
							} else {
								handleSubmitName({ target: { value: '' } })
							}
						}}
						onKeyDown={(e) => {
							e.code === 'Enter' ? handleSubmitName(e) : null
						}}
						value={nameValue ? nameValue : currentlyTypedNameValue}
					/>
                    <FaSearch className='absolute left-0 ml-4 top-1/2 transform -translate-y-1/2 text-green-400 text-lg sm:text-xl' />
				</div>
                {currentlyTypedNameValue && (
                    <MdClear
                        className='absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-lg cursor-pointer bg-green-400 rounded-full w-4 h-4 flex items-center justify-center sm:w-5 sm:h-5 sm:right-[20px]'
                        onClick={() => {
                            setCurrentlyTypedNameValue('')
                            handleSubmitName({ target: { value: '' } })
                        }}
                    />
                )}
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Location }) => ({
	user: User.user,
	userGPSLocation: Location.userLocation,
	userGPSLocationError: Location.userLocationError,
});

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onGetGPSLocation: () => dispatch(getGPSLocation()),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropOffSearch);


