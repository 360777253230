import {
	GET_LEAD,
	GET_LEAD_SUCCESS,
	GET_LEAD_FAIL,
	ADD_LEAD,
	ADD_LEAD_SUCCESS,
	ADD_LEAD_FAIL,
	UPDATE_LEAD,
	UPDATE_LEAD_FAIL,
	UPDATE_LEAD_SUCCESS,
	GET_ALL_LEADS,
	GET_ALL_LEADS_SUCCESS,
	GET_ALL_LEADS_FAIL,
	GENERATE_DOCUSIGN,
	GENERATE_DOCUSIGN_SUCCESS,
	GENERATE_DOCUSIGN_FAIL,
	FINALIZE_NODE,
	FINALIZE_NODE_SUCCESS,
	FINALIZE_NODE_FAIL,
	GET_SERVICE_AREAS,
	GET_SERVICE_AREAS_SUCCESS,
	GET_SERVICE_AREAS_FAIL,
	CHECK_PROMO_CODE_VALIDITY,
	CHECK_PROMO_CODE_VALIDITY_SUCCESS,
	CHECK_PROMO_CODE_VALIDITY_FAIL,
} from './actionTypes'

export const getLead = (payload) => {
	return {
		type: GET_LEAD,
		payload: payload,
	}
}

export const getLeadSuccess = (lead) => {
	return {
		type: GET_LEAD_SUCCESS,
		payload: lead,
	}
}

export const getLeadFail = (error) => {
	return {
		type: GET_LEAD_FAIL,
		payload: error,
	}
}

export const addLead = (payload) => {
	return {
		type: ADD_LEAD,
		payload: payload,
	}
}

export const addLeadSuccess = (lead) => {
	return {
		type: ADD_LEAD_SUCCESS,
		payload: lead,
	}
}

export const addLeadFail = (error) => {
	return {
		type: ADD_LEAD_FAIL,
		payload: error,
	}
}

export const updateLead = (payload) => {
	return {
		type: UPDATE_LEAD,
		payload: payload,
	}
}

export const updateLeadSuccess = (lead) => {
	return {
		type: UPDATE_LEAD_SUCCESS,
		payload: lead,
	}
}

export const updateLeadFail = (error) => {
	return {
		type: UPDATE_LEAD_FAIL,
		payload: error,
	}
}

export const getAllLeads = (payload) => {
	return {
		type: GET_ALL_LEADS,
		payload: payload,
	}
}

export const getAllLeadsSuccess = (allLeads) => {
	return {
		type: GET_ALL_LEADS_SUCCESS,
		payload: allLeads,
	}
}

export const getAllLeadsFail = (error) => {
	return {
		type: GET_ALL_LEADS_FAIL,
		payload: error,
	}
}

export const generateDocuSign = (payload) => {
	return {
		type: GENERATE_DOCUSIGN,
		payload: payload,
	}
}

export const generateDocuSignSuccess = (res) => {
	return {
		type: GENERATE_DOCUSIGN_SUCCESS,
		payload: res,
	}
}

export const generateDocuSignFail = (error) => {
	return {
		type: GENERATE_DOCUSIGN_FAIL,
		payload: error,
	}
}

export const finalizeNode = (payload) => {
	return {
		type: FINALIZE_NODE,
		payload: payload,
	}
}

export const finalizeNodeSuccess = (res) => {
	return {
		type: FINALIZE_NODE_SUCCESS,
		payload: res,
	}
}

export const finalizeNodeFail = (error) => {
	return {
		type: FINALIZE_NODE_FAIL,
		payload: error,
	}
}

export const getServiceAreas = () => {
	return {
		type: GET_SERVICE_AREAS,
	}
}

export const getServiceAreasSuccess = (res) => {
	return {
		type: GET_SERVICE_AREAS_SUCCESS,
		payload: res,
	}
}

export const getServiceAreasFail = (error) => {
	return {
		type: GET_SERVICE_AREAS_FAIL,
		payload: error,
	}
}

export const checkPromoCodeValidity = (payload) => {
	return {
		type: CHECK_PROMO_CODE_VALIDITY,
		payload: payload
	}
}

export const checkPromoCodeValiditySuccess = (res) => {
	return {
		type: CHECK_PROMO_CODE_VALIDITY_SUCCESS,
		payload: res,
	}
}

export const checkPromoCodeValidityFail = (error) => {
	return {
		type: CHECK_PROMO_CODE_VALIDITY_FAIL,
		payload: error,
	}
}