import {
	GENERATE_GIFT_CARD,
	GENERATE_GIFT_CARD_ERROR,
	GENERATE_GIFT_CARD_SUCCESS,
	GENERATE_PROMO,
	GENERATE_PROMO_ERROR,
	GENERATE_PROMO_SUCCESS,
	VALIDATE_GIFT_CARD,
	VALIDATE_GIFT_CARD_ERROR,
	VALIDATE_GIFT_CARD_SUCCESS,
	VALIDATE_PROMO,
	VALIDATE_PROMO_ERROR,
	VALIDATE_PROMO_SUCCESS,
	UPDATE_GIFT_CARD,
	UPDATE_GIFT_CARD_ERROR,
	UPDATE_GIFT_CARD_SUCCESS,
	UPDATE_PROMO,
	UPDATE_PROMO_ERROR,
	UPDATE_PROMO_SUCCESS,
	GET_GIFT_CARD_CUSTOMER,
	GET_GIFT_CARD_CUSTOMER_ERROR,
	GET_GIFT_CARD_CUSTOMER_SUCCESS,
	GET_PROMO_IDS,
	GET_PROMO_IDS_SUCCESS,
	GET_PROMO_IDS_ERROR,
	RESET_PROMO,
} from './actionTypes'

const initialState = {
	loading: false,
	promoError: null,
	promo: {},
	promoCreated: null,
	giftCard: {},
	promoIsActive: false,
	giftCardIsActive: false,
	promoMessageType: null,
	newBalance: null,
	promoIds: [],
	loadingIds: false,
	// stripeId: null,
	// email: null,
	stripeCustomerId: null,
	updatedPromo: null,
}

const promos = (state = initialState, action) => {
	switch (action.type) {
		case GENERATE_PROMO:
			return {
				...state,
				loading: true,
			}
		case GENERATE_PROMO_SUCCESS:
			return {
				...state,
				loading: false,
				promoCreated: true,
			}
		case GENERATE_PROMO_ERROR:
			return {
				...state,
				loading: false,
				promoError: action.payload,
			}
		case VALIDATE_PROMO:
			return {
				...state,
				loading: true,
			}
		case VALIDATE_PROMO_SUCCESS:
			return {
				...state,
				loading: false,
				promo: action.payload.promo,
				promoIsActive: action.payload.promoIsActive,
				promoMessageType: action.payload.promoMessageType,
			}
		case VALIDATE_PROMO_ERROR:
			return {
				...state,
				loading: false,
				promoError: action.payload,
				promoIsActive: false,
			}
		case GENERATE_GIFT_CARD:
			return {
				...state,
				loading: true,
			}
		case GENERATE_GIFT_CARD_SUCCESS:
			return {
				...state,
				loading: false,
				giftCard: action.payload,
				promoMessageType: 'giftCardCreated',
			}
		case GENERATE_GIFT_CARD_ERROR:
			return {
				...state,
				loading: false,
				promoError: action.payload,
				promoMessageType: 'giftCardError',
			}
		case VALIDATE_GIFT_CARD:
			return {
				...state,
				loading: true,
			}
		case VALIDATE_GIFT_CARD_SUCCESS:
			return {
				...state,
				loading: false,
				giftCard: action.payload.giftCard,
				giftCardIsActive: action.payload.giftCard ? true : false,
				promoMessageType: action.payload.promoMessageType,
			}
		case VALIDATE_GIFT_CARD_ERROR:
			return {
				...state,
				loading: false,
				promoError: action.payload,
				giftCardIsActive: false,
			}

		case UPDATE_GIFT_CARD:
			return {
				...state,
			}
		case UPDATE_GIFT_CARD_SUCCESS:
			return {
				...state,
				loading: false,
				newBalance: action.payload,
			}
		case UPDATE_GIFT_CARD_ERROR:
			return {
				...state,
				loading: false,
				promoError: action.payload,
			}
		case UPDATE_PROMO:
			return {
				...state,
				updatedPromo: null,
			}
		case UPDATE_PROMO_SUCCESS:
			return {
				...state,
				updatedPromo: action.payload,
			}
		case UPDATE_PROMO_ERROR:
			return {
				...state,
				promoError: action.payload,
				updatedPromo: null,
			}
		case GET_GIFT_CARD_CUSTOMER:
			return {
				...state,
				loading: true,
			}
		case GET_GIFT_CARD_CUSTOMER_SUCCESS:
			return {
				...state,
				stripeCustomerId: action.payload,
			}
		case GET_GIFT_CARD_CUSTOMER_ERROR:
			return {
				...state,
				loading: false,
				promoError: action.payload,
			}
		case GET_PROMO_IDS:
			return {
				...state,
				loadingIds: true,
			}
		case GET_PROMO_IDS_SUCCESS:
			return {
				...state,
				promoIds: action.payload,
				loadingIds: false,
			}
		case GET_PROMO_IDS_ERROR:
			return {
				...state,
				promoError: action.payload,
				loadingIds: false,
			}
		case RESET_PROMO:
			return {	
				loading: false,
				promoError: null,
				promo: {},
				promoCreated: null,
				giftCard: {},
				promoIsActive: false,
				giftCardIsActive: false,
				promoMessageType: null,
				newBalance: null,
				promoIds: [],
				loadingIds: false,
				stripeCustomerId: null,
				updatedPromo: null,
			}
		default:
			return state
	}
}

export default promos
