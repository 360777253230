import { call, put, takeEvery } from 'redux-saga/effects'
import {SEND_SMS} from "./actionTypes";
import {sendSMSNotification} from "../../model/notifications";
import {sendSMSFailure, sendSMSSuccess} from "./actions";

function* dispatchSMS(action) {
	try {
		// console.log('IN SAGA')
		const smsResponse = yield call(sendSMSNotification,action.payload)
		yield put(sendSMSSuccess(smsResponse))
	} catch (error) {
		yield put(sendSMSFailure(error))
	}
}

function* notificationsSaga() {
	yield takeEvery(SEND_SMS, dispatchSMS)
}

export default notificationsSaga
