import React from "react";
import { Button, BounceLoaderCentered } from "../Elements";
import UserProfileOrders from "./UserProfileOrders";

const OrderHistoryCard = ({
  loadingOrders,
  orders,
  orderPage,
  setOrderPage,
}) => {
  return (
    // <div className='mb-10 overflow-y-auto scrollbar mx-auto md:ml-10 min-w-[300px] md:min-w-[360px] lg:min-w-[720px] h-[350px] shadow-lighter-grey max-w-xs w-full flex sm:flex-row justify-between rounded-xl bg-white p-6'>
    <div className={"mt-5 w-11/12 flex flex-col items-center justify-center mb-20"}>
      <h3 className="mt-2 mb-2 block font-header text-center text-xl text-green-400">
        Order History
      </h3>
	  <span className="text-sm">(Orders made on the DeliverZero Website)</span>
      <div className="w-full">
        {loadingOrders && <BounceLoaderCentered container="div" />}
        {!loadingOrders && (!orders || orders.length == 0) && (
          <div className="mt-[10%] text-center items-center text-xl">
            <div>Looks like you haven't ordered food yet!</div>
            <div className="mt-2 mb-6">Let's change that.</div>
            <Button
              className="px-10 mt-5 py-1 shadow-light-grey"
              text="Order in Reusables"
              size="sm"
              link="/"
            ></Button>
          </div>
        )}
        {!loadingOrders && orders && orders.length > 0 && (
          <div>
            <div className="mt-3">
              <UserProfileOrders orders={orders} />
            </div>
            <div className="flex justify-center">
              <Button
                onClick={() => {
                  setOrderPage(orderPage + 1);
                }}
                className="px-10 py-1 shadow-light-grey"
                size="sm"
                text="Load More"
              ></Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderHistoryCard;
