import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Header, EnvironmentalImpactCard } from "../../components/";
import { getUser, getAppInfo, getUserBoxCount, } from "../../store/actions";
import { BounceLoaderCentered } from "../../components/Elements";

const EnvironmentalImpact = (props) => {
  const { 
    user, 
    onGetUser, 
    userBoxCount,
		loadingUserBoxCount,
		boxCountError,
		onGetUserBoxCount,
    appInfo, 
    onGetAppInfo
  } = props;

  useEffect(() => {
    onGetUser();
  }, [onGetUser]);

  useEffect(() => {
    onGetAppInfo();
  }, []);

  useEffect(() => {
    if (user && user.phone) {
      onGetUserBoxCount(user.phone)
    }
  }, [user, user?.boxCount]);

  if (!user) return <BounceLoaderCentered container="screen" />;
  return (
    <div className="h-auto w-screen">
      <Header label="Environmental Impact" showBackButton={true} />
      <div className="flex flex-col items-center justify-center pt-5 mt-[3.25rem]">
        {userBoxCount ? (
          <EnvironmentalImpactCard
              packagingPrevented={userBoxCount.totalPackagingPrevented}
            environmentalImpact={appInfo.environmentalImpact}
          />
          ) : (
            <BounceLoaderCentered/>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ User, AppInfo }) => ({
  user: User.user,
  userBoxCount: User.userBoxCount,
	loadingUserBoxCount: User.loadingUserBoxCount,
	boxCountError: User.boxCountError,
  appInfo: AppInfo.appInfo,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUser: () => dispatch(getUser()),
  onGetUserBoxCount: (payload) => dispatch(getUserBoxCount(payload)),
  onGetAppInfo: () => dispatch(getAppInfo()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentalImpact);
