import React, {useState} from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaShoppingCart, FaMedal } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { useEffect, useRef } from "react";
import { getCart, getUser } from "../store/actions";
import { CartItem } from "./";
import useOutsideClick from "./Elements/DetectOutsideClick";

const MobileBottomNav = (props) => {
  const {
    cartNodeSlug,
    onGetCart,
    user,
    onGetUser,
    cartItems,
    path,
    cartTotal,
    clearedCart,
  } = props;

  const navigate = useNavigate();

  const [cartDropUp, setCartDropUp] = useState(false);
  const [cartItemsNodeSlug, setCartItemsNodeSlug] = useState(null)

  const { nodeSlug } = useParams();

  useEffect(() => {
    onGetUser();
  }, []);

  useEffect(() => {
    if (user && user.activeCart) {
      // console.log('getting new cart')
      onGetCart();
    }
  }, [user, nodeSlug, JSON.stringify(user?.activeCart)]);

  useEffect(() => {
    if (clearedCart) {
      // console.log('getting new user after clear')
      onGetUser();
    }
  }, [clearedCart]);

  const impactRef = useRef(null);

  useOutsideClick(impactRef, () => {
    setCartDropUp(false);
  });
  // console.log(path)


	useEffect(() => {
		if (cartItems){
          if(cartItems[0]){
            setCartItemsNodeSlug(cartItems[0].nodeSlug)
          }
		}
	}, [cartItems, JSON.stringify(cartItems)])

  return (
    <div className="w-full h-[60px] fixed bottom-0 bg-white z-50 shadow-lighter-grey">
      <div className="flex justify-between items-center text-center h-full px-4 relative">
        <Link to="/">
          <div className="w-[50px] flex flex-col cursor-pointer justify-center items-center text-center mx-0">
            <img
              src="/images/HowItWorks/Order.png"
              alt="an icon of deliverzero containers"
              className="h-[24px] w-[24px]"
            />
            <p className="text-xs">Order</p>
          </div>
        </Link>
        <Link to="returns">
          <div className="w-[50px] cursor-pointer flex flex-col justify-center items-center text-center">
            <img
              src="/images/HowItWorks/House.png"
              alt="an icon of deliverzero containers"
              className="h-[24px] w-[24px]"
            />
            <p className="text-xs">Return</p>
          </div>
        </Link>
        {path != "/marketplace/test-api-restaurant" ? (
          <Link
            to={
              cartItemsNodeSlug && cartItems && cartItems.length > 0
                ? `/marketplace/${cartItemsNodeSlug}`
                : "/marketplace/cart"
            }
          >
            <div className="w-[50px] flex flex-col justify-between items-center text-center relative">
              <div className="flex flex-row items-center justify-center">
                <FaShoppingCart className="text-2xl text-green-600 hover:text-green-400" />{" "}
                {cartItems && (
                    <div className="text-green-600 font-semibold ml-1">
                      {cartItems.length}
                    </div>
                )}
              </div>
              <p className="text-xs">Cart</p>
            </div>
          </Link>
        ) : (
          <div
            className="flex flex-col justify-between items-center text-center cursor-pointer "
            onClick={() => setCartDropUp(true)}
          >
            {cartDropUp && path === "/marketplace/test-api-restaurant" && (
              <div
                ref={impactRef}
                className="max-h-[400px] overflow-y-auto absolute w-[90%] bottom-16 right-0 shadow-xl rounded-xl bg-white "
                onMouseLeave={() => setCartDropUp(false)}
              >
                <CartItem 
                    cartItems={cartItems}
                    setCartItemsNodeSlug={setCartItemsNodeSlug}
                />
                <p className="text-sm pl-4 pb-2">{`Subtotal: ${new Intl.NumberFormat(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                ).format(cartTotal)}`}</p>
              </div>
            )}
            <FaShoppingCart className="text-2xl text-green-600 hover:text-green-400" />{" "}
            <p className="text-xs">Cart</p>
            {cartItems && (
              <div className="text-green-600 font-semibold absolute transfrom translate-x-6">
                {cartItems.length}
              </div>
            )}
          </div>
        )}
        <Link to="/rewards-marketplace">
          <div className="w-[50px] cursor-pointer flex flex-col justify-center items-center text-center">
            <FaMedal className="text-2xl text-green-600 hover:text-green-400" />
            <p className="text-xs">Rewards</p>
          </div>
        </Link>
        <Link to="/user/accountLanding">
          <div className="w-[50px] cursor-pointer flex flex-col justify-center items-center text-center">
            <MdAccountCircle className="text-2xl text-green-600 hover:text-green-400" />
            <p className="text-xs">Account</p>
          </div>
        </Link>
      </div>
    </div>
  );
};
const mapStateToProps = ({ User, Cart, Path }) => ({
  cartNodeSlug: Cart.nodeSlug,
  user: User.user,
  cartItems: Cart.cartItems,
  cartTotal: Cart.cartTotal,
  path: Path.path,
  clearedCart:Cart.clearedCart,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCart: (cart) => dispatch(getCart(cart)),
  onGetUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileBottomNav);
