import {
  GET_ALL_REWARDS,
  GET_ALL_REWARDS_SUCCESS,
  GET_ALL_REWARDS_FAIL,
  REMOVE_DISCOUNT_CODE,
  REMOVE_DISCOUNT_CODE_SUCCESS,
  REMOVE_DISCOUNT_CODE_FAIL,
  DEDUCT_USER_REWARD_PTS,
  DEDUCT_USER_REWARD_PTS_SUCCESS,
  DEDUCT_USER_REWARD_PTS_FAIL,
  UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS,
  UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_SUCCESS,
  UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_FAIL,
  SEND_REDEEM_REWARD_EMAIL,
  SEND_REDEEM_REWARD_EMAIL_SUCCESS,
  SEND_REDEEM_REWARD_EMAIL_FAIL,
} from "./actionTypes";

const initialState = {
  allRewards: [],
  codesUpdatedReward: null,
  updatedUser: null,
  usersUpdatedReward: null,
  emailSentRes: null,
  loadingRemoval: false,
  loadingRewards: false,
  loadingDeduction: false,
  loadingUsersUpdatedReward: false,
  loadingEmailSent: false,
  removalError: null,
  error: null,
  deductionError: null,
  usersUpdatedRewardError: null,
  emailSentError: null,
};

const rewards = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REWARDS:
      return {
        ...state,
        loadingRewards: true,
      };

    case GET_ALL_REWARDS_SUCCESS:
      const allRewards = action.payload;
      return {
        ...state,
        loadingRewards: false,
        allRewards: allRewards,
        error: null,
      };

    case GET_ALL_REWARDS_FAIL:
      return {
        ...state,
        loadingRewards: false,
        error: action.payload,
      };
    case REMOVE_DISCOUNT_CODE:
      return {
        ...state,
        loadingRemoval: true,
      };
    case REMOVE_DISCOUNT_CODE_SUCCESS:
      const codesUpdatedReward = action.payload;
      return {
        ...state,
        loadingRemoval: false,
        codesUpdatedReward: codesUpdatedReward,
        removalError: null,
      };
    case REMOVE_DISCOUNT_CODE_FAIL:
      return {
        ...state,
        loadingRemoval: false,
        removalError: action.payload,
      };
    case DEDUCT_USER_REWARD_PTS:
      return {
        ...state,
        loadingDeduction: true,
      };
    case DEDUCT_USER_REWARD_PTS_SUCCESS:
      const updatedUser = action.payload;
      return {
        ...state,
        loadingDeduction: false,
        updatedUser: updatedUser,
        deductionError: null,
      };
    case DEDUCT_USER_REWARD_PTS_FAIL:
      return {
        ...state,
        loadingDeduction: false,
        deductionError: action.payload,
      };
    case UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS:
      return {
        ...state,
        loadingUsersUpdatedReward: true,
      };
    case UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_SUCCESS:
      const usersUpdatedReward = action.payload;
      return {
        ...state,
        loadingUsersUpdatedReward: false,
        usersUpdatedReward: usersUpdatedReward,
        usersUpdatedRewardError: null,
      };
    case UPDATE_NON_UNIQUE_DISCOUNT_CODE_USERS_FAIL:
      return {
        ...state,
        loadingUsersUpdatedReward: false,
        usersUpdatedRewardError: action.payload,
      };
    case SEND_REDEEM_REWARD_EMAIL:
      return {
        ...state,
        loadingEmailSent: true,
      };
    case SEND_REDEEM_REWARD_EMAIL_SUCCESS:
      const emailSentRes = action.payload;
      return {
        ...state,
        loadingEmailSent: false,
        emailSentRes: emailSentRes,
        emailSentError: null,
      };
    case SEND_REDEEM_REWARD_EMAIL_FAIL:
      return {
        ...state,
        loadingEmailSent: false,
        emailSentError: action.payload,
      };

    default:
      return state;
  }
};

export default rewards;
