// import PropTypes from "prop-types"
import React, { createRef, useEffect, useState } from "react";

import { connect } from "react-redux";
import {getNodes, getNodeBySlug, updateSearchNodes, getGPSLocation, getUser, captureGPS, updateUser} from "../store/actions";
import {
  calculateDistanceBetweenCoords,
  googleMapsLibraries,
  isAddressInsideDeliveryPolygon,
} from "../helpers/googleMapsAPI";
import { Header } from "../components";
import { Badge, AddressBar, Alert } from "../components/Elements";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { BounceLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import { NodeTypes } from "../model/nodes";
import {
  FaMapMarkerAlt,
  FaRegWindowMinimize,
  FaGripLines,
  FaCaretDown,
  FaCaretUp,
  FaRoute,
} from "react-icons/fa";
import { MdClear } from "react-icons/md";
import RestaurantSearchBar from "../components/Homepage/RestaurantSearchBar";
import Draggable from "react-draggable";
import RedirectPopUp from '../components/Marketplace/RedirectPopUp';

const Home = (props) => {
  const {
    onGetNodes,
    onGetNodeBySlug,
    nodes,
    node,
    loadingNode,
    searchedAddressLatLng,
    user,
    onUpdateUser,
    onGetGPSLocation,
    gpsLocationFound,
    userGPSLocation,
    userGPSLocationError,
    userLocationError,
    finishedNodesPull,
    onGetUser,
		onCaptureGPS,
  } = props;
  const navigate = useNavigate();
  const [loadingSearchNodes, setLoadingSearchNodes] = useState(true);
  const [mapBool, setMapBool] = useState(false);
  const [postSearchNodes, setPostSearchNodes] = useState([]);
  const [restaurantInput, setRestaurantInput] = useState("");
  const [selectedMapNode, setSelectedMapNode] = useState({ name: "" });
  const [selectedZoom, setSelectedZoom] = useState(13);
  //CHANGING THE SELECTEDCENTERCOORDS WILL RUN THE SEARCH FOR NODES
  const [selectedCenterCoords, setSelectedCenterCoords] = useState({
    lat: 40.7128,
    lng: -74.006,
  });
  const [showLocationWarning, setShowLocationWarning] = useState(false);
  const [mapFilter, setMapFilter] = useState({});
  const [searchFilter, setSearchFilter] = useState({});
  const radiusAroundPoint = 25;
  const [sortByType, setSortByType] = useState("distance");
  const [filter, setFilter] = useState({
    type: {
      $nin: [NodeTypes.bin],
    },
    enabled: {
      $nin: [false],
    },
    $or: [
      { "instoreOnly": true },
      { $and: [
          { "thirdPartyClient": { $exists: true } },
          { "thirdPartyClient": { $ne: {} } }
        ]
      }
    ]
  });
  const [sortedActiveNodeCuisines, setSortedActiveNodeCuisines] = useState([]);
  const [alphaActiveNodeCuisines, setAlphaActiveNodeCuisines] = useState([]);
  const [activeClients, setActiveClients] = useState([]);
  const [clientFilter, setClientFilter] = useState({});
  const [selectedClient, setSelectedClient] = useState("none");
  const [courierFilter, setCourierFilter] = useState({});
  const [courierFilterChecked, setCourierFilterChecked] = useState(false);
	const [openNowFilter, setOpenNowFilter] = useState({})
	const [openNowFilterChecked, setOpenNowFilterChecked] = useState(false)
  const [deliveryOnlyFilterChecked, setDeliveryOnlyFilterChecked] =
    useState(false);
  const [searchedAddress, setSearchedAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [geoCodedAddress, setGeoCodedAddress] = useState();
  const [geoCodedLat, setGeoCodedLat] = useState();
  const [geoCodedLng, setGeoCodedLng] = useState();
  const [toggleMap, setToggleMap] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const google = window.google;
  const resultRef = createRef();
  const bounceLoaderColor = "#507f74";
  const { thirdPartyFilter } = useParams();

  const [sectionHeight, setSectionHeight] = useState(400);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [openHoursDropDown, setOpenHoursDropDown] = useState({});
  const [isHoliday, setIsHoliday] = useState(false);
	const [openPopUp, setOpenPopUp] = useState(false);
	const [popUpNode, setPopUpNode] = useState();
	const [distPopupVisible, setDistPopupVisible] = useState(false)
	const [distanceInfo, setDistanceInfo] = useState('')
  const smallScreenBreakpoint = 640;
  const isSmallScreen = window.innerWidth < smallScreenBreakpoint;

  const usBankHolidays = [
    "Jan 1",
    "Jan 15",
    "Feb 19",
    "May 27",
    "Jun 19",
    "Jul 4",
    "Sep 2",
    "Oct 9",
    "Nov 11",
    "Nov 23",
    "Dec 25",
  ]; // oct 2023 - oct 2024 dates

  const daysOfWeekMap = { 0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 }; // map from Date().getDay() (0-6=Sun-Sat) to google's openHours (0-6=Mon-Sun)

  // LOAD GOOGLE MAPS API
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: googleMapsLibraries,
  });

  useEffect(() => {
    onGetUser()
  }, []);


  // initial location services check
  useEffect(() => {
    const currentDate = new Date();
    const dateOptions = { month: "short", day: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-US", dateOptions); // in format "Jan 1"
    setInitialLoad(true);
    setDayOfWeek(currentDate.getDay());
    if (usBankHolidays.includes(formattedDate)) {
      setIsHoliday(true);
    }
    // console.log(navigator)
    if ("geolocation" in navigator) {
      setShowLocationWarning(false);
    } else if (isLoaded) {
      setShowLocationWarning(true);
      setInitialLoad(false);
    } else {
      setInitialLoad(false);
    }
  }, []);

  useEffect(() => {
    if (userGPSLocationError) {
      setShowLocationWarning(true);
    }
  }, [userGPSLocationError]);

  useEffect(() => {
    // console.log('inside gecode address sueeffect')
    if (geoCodedAddress) {
      const lastSearchedAddress = {
        address: geoCodedAddress,
        lat: geoCodedLat,
        lng: geoCodedLng,
        address2: "",
      };
      localStorage.setItem(
        "lastSearchedAddress",
        JSON.stringify(lastSearchedAddress)
      );
      if (user && user.userId) {
        if (
          user &&
          !user?.savedAddresses.find(
            (address) => address.savedAddress === geoCodedAddress
          )
        ) {
          // new address so add to front of array
          onUpdateUser({
            savedAddresses: [
              {
                savedAddress: geoCodedAddress,
                savedAddress2: "",
              },
              ...user.savedAddresses,
            ],
            lastSearchedAddress: geoCodedAddress,
            lastSearchedAddress2: address2,
          });
        } else {
          // exisiting address so need to shift it to front to update ordering
          const existingAddressIdx = user.savedAddresses.findIndex(
            (address) => address.savedAddress === geoCodedAddress
          );
          const savedAddress2 =
            user.savedAddresses[existingAddressIdx].savedAddress2;
          const updatedSavedAddresses = [...user.savedAddresses];
          updatedSavedAddresses.splice(existingAddressIdx, 1);
          updatedSavedAddresses.unshift({
            savedAddress: geoCodedAddress,
            savedAddress2: savedAddress2,
          });
          onUpdateUser({
            savedAddresses: updatedSavedAddresses,
            lastSearchedAddress: geoCodedAddress,
            lastSearchedAddress2: savedAddress2,
          });
        }
      }
      // setting the center coords to trigger the get Nodes function
      setSelectedCenterCoords({
        lat: geoCodedLat,
        lng: geoCodedLng,
      });
    }
  }, [geoCodedAddress]);

  const getNearestNodes = () => {
    // console.log(selectedCenterCoords)
    const geoFilter = {
      coordinates: {
        $near: {
          $geometry: {
            type: "Point",
            coordinates: [selectedCenterCoords.lng, selectedCenterCoords.lat],
          },
          // $minDistance: 3000000,
          $maxDistance: radiusAroundPoint * 1609.34,
        },
      },
    };
    return geoFilter;
  };

  //////////////////////////////////////////////////////////////////
  // RUN A NEW NODE SEARCH WHENEVER A THIRD PARTY FILTER or CLIENT IS SELECTED
  useEffect(() => {
    if (thirdPartyFilter) {
      const cFilt = {};
      setSelectedClient(thirdPartyFilter);
      if (thirdPartyFilter !== "deliverzero") {
        cFilt[`thirdPartyClient.${thirdPartyFilter}`] = {
          $exists: true,
        };
      } else if (thirdPartyFilter === "deliverzero") {
        cFilt["directOrdering"] = { $eq: true };
      }
      setClientFilter(cFilt);
    }
  }, [thirdPartyFilter]);

  const onClientFilter = (clientQuery) => {
    const cFilt = {};
    //  console.log(clientQuery)
    setSelectedClient(clientQuery);
    if (clientQuery !== "none" && clientQuery !== "DeliverZero") {
      cFilt[`thirdPartyClient.${clientQuery}`] = { $exists: true };
    } else if (clientQuery === "DeliverZero") {
      cFilt["directOrdering"] = { $eq: true };
    }

    //  console.log(cFilt)
    setClientFilter(cFilt);
  };

  ///////////////////////////////////////////////////////////
  // RUN A NEW NODE SEARCH WHENEVER A NODE IS SELECTED ON MAP
  const onMapSelect = (node) => {
    setSelectedMapNode(node);
    setMapBool(true);
    setMapFilter({
      coordinates: {
        $near: {
          $geometry: {
            type: "Point",
            coordinates: [node.coordinates[0], node.coordinates[1]],
          },
        },
      },
    });
    document.getElementById("results-scroller").scroll(0, 0);
  };

  // TRIGGER CREATE FILTER
  useEffect(() => {
    if (geoCodedAddress) {
      createFilter();
    }
  }, [
    filter,
    mapFilter,
    searchFilter,
    sortByType,
    clientFilter,
    courierFilter,
    openNowFilter,
    deliveryOnlyFilterChecked,
    selectedCenterCoords,
  ]);

  // COMBINE FILTER ARRAY AND GET NODES
  const createFilter = () => {
    setLoadingSearchNodes(true);
    const combinedFilter = [filter];
    const geoFilter = getNearestNodes();
    if (mapBool) {
      combinedFilter.push(searchFilter, mapFilter, clientFilter, courierFilter, openNowFilter);
    } else if (searchedAddress && searchedAddress !== "") {
      if (geoFilter) {
        combinedFilter.push(
          geoFilter,
          searchFilter,
          clientFilter,
          courierFilter,
          openNowFilter,
        );
      } else {
        combinedFilter.push(
          geoFilter,
          searchFilter,
          clientFilter,
          courierFilter
        );
      }
    } else {
      if (geoFilter) {
        combinedFilter.push(
          geoFilter,
          searchFilter,
          clientFilter,
          courierFilter,
          openNowFilter
        );
      } else {
        combinedFilter.push(searchFilter, clientFilter, courierFilter, openNowFilter);
      }
    }
    onGetNodes(combinedFilter);
    // console.log("nodes fetched")
  };

  const addressCheck = (address, latLng) => {
    setLoadingSearchNodes(true);
    const tempArray = [];
    if (latLng) {
      nodes.map((node) => {
        // For the returns map page
        const distanceToCoordMiles =
          calculateDistanceBetweenCoords(
            [latLng.lat, latLng.lng],
            node.coordinates
          ) / 1609.34;
        node.distanceToCoord = distanceToCoordMiles;
        // Filter by distance again in case they've clicked by
        if (distanceToCoordMiles <= radiusAroundPoint) {
          tempArray.push(node);
        }
        // For the get food map page
        // console.log(node)
        if (
          isAddressInsideDeliveryPolygon(
            [latLng.lat, latLng.lng],
            node.deliveryPolygon
          )
        ) {
        } else {
          node.outsideDeliveryArea = true;
          if (deliveryOnlyFilterChecked) {
            const index = tempArray.indexOf(node);
            if (index > -1) {
              // only splice array when item is found
              tempArray.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
        }
      });

      let sortedNodes = tempArray;
      if (!mapBool) {
        if (sortByType == "name") {
          sortedNodes = sortedNodes.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
        } else if (sortByType == "distance") {
          sortedNodes = sortedNodes.sort(
            (a, b) => a.distanceToCoord - b.distanceToCoord
          );
        }
      }
      setPostSearchNodes(sortedNodes);
      const initialOpenHoursDropDowns = {};
      sortedNodes.forEach((node) => {
        initialOpenHoursDropDowns[node.name] = false;
      });
      setOpenHoursDropDown(initialOpenHoursDropDowns);
      setInitialLoad(false);
      if (mapBool == false) {
        // setSelectedCenterCoords({
        //     lat: latLng.lat,
        //     lng: latLng.lng,
        // });
        setSelectedZoom(13);
      }
    } else {
      let sortedNodes = nodes;
      if (!mapBool) {
        if (sortByType == "name") {
          sortedNodes = sortedNodes.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
        } else if (sortByType == "distance") {
          sortedNodes = sortedNodes.sort(
            (a, b) => a.distanceToCoord - b.distanceToCoord
          );
        }
      }
      // console.log(sortedNodes)
      setPostSearchNodes(sortedNodes);
      const initialOpenHoursDropDowns = {};
      sortedNodes.forEach((node) => {
        initialOpenHoursDropDowns[node.name] = false;
      });
      setOpenHoursDropDown(initialOpenHoursDropDowns);
    }
    // }
  };

  useEffect(() => {
    // console.log(nodes)
    addressCheck(geoCodedAddress, selectedCenterCoords);
  }, [nodes, initialLoad, geoCodedAddress, isLoaded]);

  useEffect(() => {
    if (postSearchNodes.length > 0) {
      setLoadingSearchNodes(false);
    } else {
      if (thirdPartyFilter && finishedNodesPull) {
        setClientFilter({});
      }
      if (openNowFilterChecked) {
        onOpenNowFilter(true)
      } else {
        setOpenNowFilter({})
        setOpenNowFilterChecked(false)
      }
    }
  }, [postSearchNodes]);

  const onSearchChange = (query) => {
    setMapBool(false);
    if (selectedZoom > 13) {
      setSelectedZoom(13);
    }
    setSelectedMapNode({ name: "" });

    setRestaurantInput(query.target.value);
    if (query.target.value !== "") {
      setSearchFilter({
        $or: [
          {
            name: {
              $regex: query.target.value,
              $options: "i",
            },
          },
          {
            slug: {
              $regex: query.target.value,
              $options: "i",
            },
          },
          {
            city: {
              $regex: query.target.value,
              $options: "i",
            },
          },
          {
            address: {
              $regex: query.target.value,
              $options: "i",
            },
          },
          {
            cuisine: { $in: [new RegExp(query.target.value, "i")] },
          },
        ],
      });
    } else {
      setSearchFilter({});
    }
  };

  const onCourierFilter = (courierQuery) => {
    const cFilt = {};
    setCourierFilterChecked(courierQuery);
    if (courierQuery) {
      cFilt["courierAcceptsReturns"] = { $eq: true };
    }
    setCourierFilter(cFilt);
  };

	const getCurrentTimeAndDay = () => {
		const now = new Date()
		const daysOfWeek = [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday',
		]
		const dayOfWeekIndex = now.getDay()
		const dayOfWeek = daysOfWeek[dayOfWeekIndex]
		const currentTime = now.getHours() * 60 + now.getMinutes()
		return { dayOfWeekIndex, dayOfWeek, currentTime }
	}

	const isNodeOpenNow = (node, dayOfWeekIndex, dayOfWeek, currentTime) => {
		if (!node.openHoursGoogle) {
			return true 
		}

		const hoursMap = {}
		const hoursString = node.openHoursGoogle[dayOfWeekIndex - 1]
		if (hoursString) {
			if (hoursString.includes('24')) {
				return true
			} else if (hoursString.toLowerCase().includes('closed')) {
				return false
			} else {
				const [day, hours] = hoursString.split(': ')
				if (hours.includes(',')) {
					const [firstTimeChunk, secondTimeChunk] = hours.split(', ')
					const [firstOpening, firstClosing] =
						firstTimeChunk.split('–')
					const [secondOpening, secondClosing] =
						secondTimeChunk.split('–')
					if (
						parseTimeToMinutes(secondClosing) <
						parseTimeToMinutes(secondOpening)
					) {
						return true 
					} else {
						hoursMap[day] = {
							opening: parseTimeToMinutes(firstOpening),
							closing: parseTimeToMinutes(firstClosing),
							secondOpening: parseTimeToMinutes(secondOpening),
							secondClosing: parseTimeToMinutes(secondClosing),
						}
					}
				} else {
					const [opening, closing] = hours.split('–')
					if (
						parseTimeToMinutes(closing) <
						parseTimeToMinutes(opening)
					) {
						return true 
					}
					hoursMap[day] = {
						opening: parseTimeToMinutes(opening),
						closing: parseTimeToMinutes(closing),
					}
				}
			}
		} else {
			return false
		}

		const todayHours = hoursMap[dayOfWeek]
		if (!todayHours) {
			return false
		} else {
			const isOpen =
				currentTime >= todayHours.opening &&
				currentTime <= todayHours.closing
			if (isOpen) {
				return true
			} else if (todayHours.secondOpening && todayHours.secondClosing) {
				return (
					currentTime >= todayHours.secondOpening &&
					currentTime <= todayHours.secondClosing
				)
			} 
		}
	}

	const parseTimeToMinutes = (timeString) => {
		const [hours, minutes] = timeString
			.split(':')
			.map((part) => parseInt(part, 10))
		let totalMinutes = hours * 60
		if (!timeString.includes('AM') && !timeString.includes('PM')) {
			if (hours === 12 || hours < 8) {
				totalMinutes += 12 * 60 // assume PM
			}
		} else if (timeString.includes('AM') && hours === 12) {
			totalMinutes = 0 
		} else if (timeString.includes('PM') && hours !== 12) {
			totalMinutes += 12 * 60 
		}
		totalMinutes += minutes
		return totalMinutes
	}

	const onOpenNowFilter = (openNowQuery) => {
		const cFilt = {}
		setOpenNowFilterChecked(openNowQuery)
		const openArray = []

		if (openNowQuery) {
			const { dayOfWeekIndex, dayOfWeek, currentTime } =
				getCurrentTimeAndDay()

			nodes.forEach((node) => {
				if (
					isNodeOpenNow(node, dayOfWeekIndex, dayOfWeek, currentTime)
				) {
					openArray.push(node.slug)
				}
			})
			cFilt['slug'] = { $in: openArray }
		}

		setOpenNowFilter(cFilt)
	}

  const handleSortBy = (sortBy) => {
    setMapBool(false);
    setSortByType(sortBy);
  };

  const compare = (a, b) => {
    if (a[0] < b[0]) {
      return 1;
    } else if (a[0] > b[0]) {
      return -1;
    } else if (a[0] === b[0]) {
      if (a[1] < b[1]) {
        return -1;
      } else {
        return 1;
      }
    }
  };

  const sortArrFreq = (arr, length) => {
    let indexCount = {};
    for (let i = 0; i < length; i++) {
      indexCount[arr[i]] = 0;
    }

    for (let i = 0; i < length; i++) {
      indexCount[arr[i]]++;
    }

    const vec = new Array(length);
    for (let i = 0; i < length; i++) {
      vec[i] = [];
    }

    let k = 0;
    for (var it in indexCount) {
      vec[k] = [indexCount[it], it];
      k += 1;
    }

    vec.sort(compare);
    let sortedArr = [];
    for (let i = 0; i < k; i++) {
      sortedArr.push(vec[i][1]);
    }
    return sortedArr;
  };

  const alphabetize = (arr) => {
    const alphaArr = [...arr].sort((a, b) => a.localeCompare(b));
    return alphaArr;
  };

  useEffect(() => {
    if (nodes) {
      const cuisinesSet = new Set();
      nodes
        .filter((node) => typeof node.cuisine === "string")
        .forEach((node) => {
          const splitArray = node.cuisine.split("/");
          splitArray.forEach((cuisine) => {
            const cleanedCuisine = cuisine.trim();
            cuisinesSet.add(cleanedCuisine);
          });
        });
      const sortedCuisinesArr = Array.from(cuisinesSet);
      sortedCuisinesArr.sort();
      setSortedActiveNodeCuisines(
        sortedCuisinesArr.length < 5
          ? sortedCuisinesArr
          : sortedCuisinesArr.slice(0, 7)
      );
      setAlphaActiveNodeCuisines(alphabetize(sortedCuisinesArr));
      if (activeClients.length <= 2) {
        let clientsArr = ["DeliverZero"];
        nodes.map((node) => {
          if (node.thirdPartyClient) {
            Object.keys(node.thirdPartyClient).forEach((client) =>
              clientsArr.push(client)
            );
          }
        });

        clientsArr = sortArrFreq(clientsArr, clientsArr.length);
        setActiveClients(clientsArr);
      }
    }
  }, [nodes]);

  const handleDismiss = () => {
    setShowLocationWarning(false);
  };

  useEffect(() => {
    makeGPSRequest();
  }, []);

  useEffect(() => {
		if (user && user.phone) {
			onCaptureGPS({phone: user.phone})
		}
	}, [user])

  const makeGPSRequest = async () => {
    await onGetGPSLocation();
  };

  const handleGPSRequest = () => {
    makeGPSRequest();
  };

  const handleDrag = (e, data) => {
    const changeInHeight = data.y - position.y
    let newHeight = sectionHeight + changeInHeight;
    if (newHeight > 545){
      setPosition({y: 545})
      setSectionHeight(545);
    }else {
      setPosition({ y: newHeight })
      setSectionHeight(newHeight);
    }
  };

  const formatTodayHours = (input) => {
    if (typeof input === "string") {
      input = input.replace(/.*day/, "Today's Hours");
      return input;
    }
    return toString(input);
  };

  const shortenDay = (day) => {
    if (typeof day === "string") {
      const shortform = day.slice(0, 3);
      day = day.replace(/.*day/, shortform);
    }
    return day;
  };

	const handleOpenPopUp = (node) => {
		setOpenPopUp(!openPopUp);
    setDistPopupVisible(openPopUp);
		setPopUpNode(node);
	};

	const handleMarkerClick = async (node) => {
		const originLat = geoCodedLat || userGPSLocation.lat
		const originLng = geoCodedLng || userGPSLocation.lng

		if (!originLat || !originLng) {
			console.error('Origin location is not available')
			return
		}

		onMapSelect(node)
		setSelectedZoom(15)
		setSelectedCenterCoords({
			lat: node.coordinates[1],
			lng: node.coordinates[0],
		})
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
		setDistanceInfo({
			originLat: originLat,
			originLng: originLng,
			destLat: node.coordinates[1],
			destLng: node.coordinates[0],
			distance: node.distanceToCoord
				.toFixed(2)
				.toString()
				.substring(0, 4),
		})
		setDistPopupVisible(true)
	}

  if (!isLoaded) return "loading...";
  if (initialLoad) {
    return (
      <>
        <Header label={"Loading..."} />
        <div className="h-full flex flex-col items-center justify-center my-10">
          <BounceLoader
            className="m-auto"
            color={bounceLoaderColor}
          ></BounceLoader>
        </div>
      </>
    );
  }
  return (
    <div className="h-screen-marketplace sm:h-full w-full flex flex-col justify-between">
      {showLocationWarning && (
        <>
          <Alert
            content="You have location services turned off. You may continue by searching an address below, but certain map features may behave differently."
            type="warning"
            onClick={handleGPSRequest}
          />
          <MdClear
            className="mt-2 mr-2 absolute right-2 sm:right-[5px] top-3 sm:top-4 transform -translate-y-1/2 text-white text-lg cursor-pointer text-yellow-400 bg-yellow-800 rounded-full
            w-5 h-5 sm:w-4 sm:h-4 flex items-center justify-center z-10"
            onClick={handleDismiss}
          />
        </>
      )}
      <div
        className={`flex ${
          isSmallScreen ? "flex-col" : "static"
        } relative sticky top-0 z-20 w-full`}
        style={
          isSmallScreen && toggleMap
            ? { height: `${sectionHeight}px` }
            : { height: "110px" }
        }
      >
        {isLoaded && toggleMap && (
          <>
          <div
            className={`w-full md:w-1/2 mx-auto flex flex-col md:flex-row border-red-700 sm:hidden h-full`}
          >
            <GoogleMap
              mapContainerStyle={{
                overflow: "hidden",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              zoom={selectedZoom}
              center={selectedCenterCoords}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
              }}
            >
              {postSearchNodes.map((node) => {
                if (!node.coordinates) return;
                const [lng, lat] = node.coordinates;
                if (node.name === selectedMapNode.name) {
                  return (
                    <Marker
                      onClick={() => {
                        handleMarkerClick(node);
                      }}
                      key={node._id.toString()}
                      title={node.name}
                      icon={{
                        url:
                          node.type === "whole-foods"
                            ? "https://i.imgur.com/ppyvkNZ.png"
                            : "https://i.imgur.com/b33G8C4.png",
                        scaledSize:
                          node.type === "whole-foods"
                            ? new google.maps.Size(30, 30)
                            : new google.maps.Size(30, 30),
                      }}
                      name={node.name}
                      position={{
                        lat: Number(lat.toString()),
                        lng: Number(lng.toString()),
                      }}
                    ></Marker>
                  );
                } else {
                  return (
                    <Marker
                      onClick={() => {
                        handleMarkerClick(node);
                      }}
                      key={node._id.toString()}
                      title={node.name}
                      icon={{
                        url:
                          node.type === "whole-foods"
                            ? "https://i.imgur.com/ppyvkNZ.png"
                            : "https://i.imgur.com/ci46o90.png",
                        scaledSize:
                          node.type === "whole-foods"
                            ? new google.maps.Size(30, 30)
                            : new google.maps.Size(30, 30),
                      }}
                      name={node.name}
                      position={{
                        lat: Number(lat.toString()),
                        lng: Number(lng.toString()),
                      }}
                    ></Marker>
                  );
                }
              })}
              {geoCodedAddress && geoCodedLng && (
                <Marker
                  key="user-location"
                  title="Your address"
                  icon={{
                    url: "https://i.imgur.com/Y0Wiacr.png",
                    scaledSize: new google.maps.Size(30, 30),
                  }}
                  name="Searched address"
                  position={{
                    lat: geoCodedLat,
                    lng: geoCodedLng,
                  }}
                />
              )}
            </GoogleMap>
          </div>
          {distPopupVisible && distanceInfo && (
            <div
              className={`absolute bottom-[25px] left-[8px] bg-white border-2 border-solid border-green-600 rounded-lg p-2 z-50 flex flex-col text-sm sm:hidden`}
            >
              <div className='flex flex-row justify-start items-center my-auto'>
                <FaRoute/>
                <div className='ml-2'>
                  {distanceInfo.distance} miles
                </div>
              </div>
              <a
                href={`https://www.google.com/maps/dir/?api=1&origin=${distanceInfo.originLat},${distanceInfo.originLng}&destination=${distanceInfo.destLat},${distanceInfo.destLng}`}
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500 underline'
              >
                Open in Google Maps
              </a>
            </div>
          )}
          </>
        )}
        <div
          className={`w-full ${
            isSmallScreen ? "absolute top-0" : "h-[110px]"
          } h-[100px] ${toggleMap ? "bg-transparent" : "bg-white"} z-20`}
        >
          <div className="flex flex-col items-center justify-center">
            <AddressBar
              onClickToggleMap={() => {
                setToggleMap(!toggleMap)
                setDistPopupVisible(toggleMap)
              }}
              setGeoCodedLat={setGeoCodedLat}
              setGeoCodedLng={setGeoCodedLng}
              setGeoCodedAddress={setGeoCodedAddress}
              geoCodedAddress={geoCodedAddress}
              setAddress2={setAddress2}
            />
            {isLoaded && (
              <RestaurantSearchBar
                activeNodeCuisines={sortedActiveNodeCuisines}
                onNameQuery={onSearchChange}
                onSelectQuery={onSearchChange}
                handleSortBy={handleSortBy}
                sortByType={sortByType}
                alphaCuisines={alphaActiveNodeCuisines}
                nameValue={restaurantInput}
                activeClients={activeClients}
                onClientFilter={onClientFilter}
                onCourierFilter={onCourierFilter}
                courierFilterChecked={courierFilterChecked}
								onOpenNowFilter={onOpenNowFilter}
								openNowFilterChecked={openNowFilterChecked}
                selectedClient={selectedClient}
                deliveryOnlyFilterChecked={deliveryOnlyFilterChecked}
                setDeliveryOnlyFilterChecked={setDeliveryOnlyFilterChecked}
                setDistPopupVisible={setDistPopupVisible}
              />
            )}
          </div>
        </div>
        {/* {isLoaded && toggleMap && (
          <div className={`w-full absolute bottom-0 sm:hidden drop-shadow-md`}>
            <Draggable
              axis="y"
              position={position}
              onDrag={handleDrag}
              bounds={{ top: 0, bottom: 545 }}
            >
              <div className="h-4 w-full bg-white text-green-400 absolute bottom-0 sm:hidden rounded-b-xl mb-[-5px]">
                <div className="text-gray-400 flex items-center justify-center absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-[-8.5px]">
                  <FaGripLines />
                </div>
              </div>
            </Draggable>
          </div>
        )} */}
      </div>
      <div className="h-[100vh] sm:h-[120vh] md:h-screen-marketplace min-h-[50em] overflow-hidden pt-2 md:pt-6">
        <div className="w-full md:min-h-[32em] md:h-screen-no-header-mobile sm:h-screen-no-header flex flex-col-reverse md:flex-row sm:justify-between border-t border">
          <div className="w-full md:w-1/2 md:h-full h-3/4 pt-0 md:pt-4 md:overflow-hidden ">
            {loadingSearchNodes && (
              <div className="mt-3 text-center">
                <BounceLoader
                  className="m-auto"
                  color={bounceLoaderColor}
                ></BounceLoader>
              </div>
            )}
            {postSearchNodes.length == 0 && isLoaded && (
              <div
                className="h-[600px] md:h-full overflow-scroll"
                id="results-scroller"
                ref={resultRef}
              >
                {/* <div className="mt-3 text-center">
                                    <BounceLoader
                                        className="m-auto"
                                        color={bounceLoaderColor}
                                    ></BounceLoader>
                                </div> */}
                <div className="mt-3 mx-2 text-center">
                  <span className="font-bold">
                    No restaurants fit your search!
                  </span>{" "}
                  Try changing your search or choosing a different address.
                </div>
              </div>
            )}
            {postSearchNodes.length > 0 && (
              <div
                className="h-[800px] md:h-full overflow-y-scroll"
                id="results-scroller"
                ref={resultRef}
              >
                {postSearchNodes.map((node, index) => {
                  // const [preferredThirdPartyClient,setPreferredThirdPartyClient]=useState('')
                  return (
                    <div key={index} className="w-full">
                      <div
                        className="flex h-45px w-full items-center p-2 border-b tracking-wider cursor-pointer relative "
                        key={index}
                      >
                        <div
                          className="text-center w-24 shrink-0"
                          onClick={() => {
                            if (
                             (node.thirdPartyClient &&
                              Object.keys(node.thirdPartyClient).length > 0) || node.instoreOnly
                            ) {
                                handleOpenPopUp(node);
                            } else {
                              navigate(`/marketplace/${node.slug}`);
                            }
                          }}
                        >
                          <img
                            className="h-18 w-18 md:h-24 md:w-24 shrink-0 border rounded-lg"
                            src={node.thumbnail}
                            alt="restaurant-thumbnail"
                          />
                          {node.distanceToCoord ? (
                            <div className="text-sm text-gray-400">
                              {node.distanceToCoord
                                .toFixed(2)
                                .toString()
                                .substring(0, 4)}{" "}
                              miles
                            </div>
                          ) : (
                            <div className="text-sm text-gray-400">0 miles</div>
                          )}
                        </div>
                        <div className="pl-4 w-full flex flex-col ">
                          <div className="w-full flex">
                            <div className="w-full">
                              <div className="flex flex-col sm:flex-row justify-between w-full">
                                <div className="text-green-600 font-header mb-1 w-full flex flex-wrap sm:flex-col">
                                  <span
                                    onClick={() => {
                                      if (
                                        (node.thirdPartyClient &&
                                        Object.keys(node.thirdPartyClient)
                                          .length > 0) || node.instoreOnly
                                      ) {
                                          handleOpenPopUp(node);
                                      } else {
                                        navigate(`/marketplace/${node.slug}`);
                                      }
                                    }}
                                  >
                                    {node.name}
                                  </span>
                                  <span
                                    className="flex items-center z-10"
                                    onClick={() => {
                                      setToggleMap(true);
                                      handleMarkerClick(node);
                                    }}
                                  >
                                    <FaMapMarkerAlt className="text-green-400 ml-1" />
                                    <p className="text-xs text-green-400">
                                      View on map
                                    </p>
                                  </span>
                                </div>
                                {node.courierAcceptsReturns &&
                                  (!node.thirdPartyClient ||
                                    (node.thirdPartyClient &&
                                      Object.keys(node.thirdPartyClient)
                                        .length === 0)) && (
                                    <div
                                      className="w-full mb-2"
                                      onClick={() => {
                                        if (
                                          (node.thirdPartyClient &&
                                          Object.keys(node.thirdPartyClient)
                                            .length > 0) || node.instoreOnly
                                        ) {
                                            handleOpenPopUp(node);
                                        } else {
                                          navigate(`/marketplace/${node.slug}`);
                                        }
                                      }}
                                    >
                                      <Badge
                                        text="Courier Accepts Returns"
                                        color="orange-primary"
                                        className="cursor-pointer "
                                      />
                                    </div>
                                  )}
                              </div>
                              {node.instoreOnly && (
                                  <div
                                      className="mb-1 text-xs text-red-400"
                                      onClick={() => {
                                        if (
                                            (node.thirdPartyClient &&
                                                Object.keys(node.thirdPartyClient).length >
                                                0) || node.instoreOnly
                                        ) {
                                            handleOpenPopUp(node);
                                        } else {
                                          navigate(`/marketplace/${node.slug}`);
                                        }
                                      }}
                                  >
                                    Available instore only
                                  </div>
                              )}
                              <div
                                className="mb-2 text-xs text-gray-400"
                                onClick={() => {
                                  if (
                                   (node.thirdPartyClient &&
                                    Object.keys(node.thirdPartyClient).length >
                                      0) || node.instoreOnly
                                  ) {
                                      handleOpenPopUp(node);
                                  } else {
                                    navigate(`/marketplace/${node.slug}`);
                                  }
                                }}
                              >
                                {node.cuisine}
                              </div>
                              <div
                                className="text-xs text-gray-400 mb-1"
                                onClick={() => {
                                  if (
                                    (node.thirdPartyClient &&
                                    Object.keys(node.thirdPartyClient).length >
                                      0) || node.instoreOnly
                                  ) {
                                    handleOpenPopUp(node);
                                  } else {
                                    navigate(`/marketplace/${node.slug}`);
                                  }
                                }}
                              >
                                {node.address} {node.city}, {node.state}
                              </div>
                              {/* <div 
                                                                className="text-xs text-gray-400"
                                                                onClick={() => {
                                                                    if (node.thirdPartyClient && Object.keys(node.thirdPartyClient).length > 0) {
                                                                        if (node.type === "whole-foods") {
                                                                            navigate(`/wholefoods`)
                                                                        } else {
                                                                            navigate(`/redirect/${node.slug}`);
                                                                        }
                                                                    } else {
                                                                        navigate(`/marketplace/${node.slug}`);
                                                                    }
                                                                }}
                                                            >
                                                                {node.city}, {node.state}{" "}
                                                            </div> */}

                              <div
                                className="text-xs text-gray-400 mb-3"
                                onClick={() => {
                                  if (
                                    (node.thirdPartyClient &&
                                    Object.keys(node.thirdPartyClient).length >
                                      0) || node.instoreOnly
                                  ) {
                                    handleOpenPopUp(node);
                                  } else {
                                    navigate(`/marketplace/${node.slug}`);
                                  }
                                }}
                              >
                                {isHoliday && (
                                  <div className="text-red-300 text-xs">
                                    Hours may differ on holidays.
                                  </div>
                                )}
                                {node.openHoursGoogle && Object.keys(node.openHoursGoogle).length>0 ? (
                                    <div
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          const updatedOpenHoursDropDowns = {
                                            ...openHoursDropDown,
                                          };
                                          updatedOpenHoursDropDowns[node.name] =
                                              !updatedOpenHoursDropDowns[node.name];
                                          setOpenHoursDropDown(
                                              updatedOpenHoursDropDowns
                                          );
                                        }}
                                    >
                                      <div className="flex flex-row items-center w-[250px] mb-2">
                                        <div>
                                          {node.openHoursGoogle
                                              ? formatTodayHours(
                                                  node.openHoursGoogle[
                                                      daysOfWeekMap[dayOfWeek]
                                                      ]
                                              )
                                              : node.openHoursDescription}
                                        </div>
                                        {node.openHoursGoogle &&
                                        !openHoursDropDown[node.name] && (
                                            <FaCaretDown className="text-green-400 text-[22px] text-center" />
                                        )}
                                        {node.openHoursGoogle &&
                                        openHoursDropDown[node.name] && (
                                            <FaCaretUp className="text-green-400 text-[22px] text-center" />
                                        )}
                                      </div>
                                      {node.openHoursGoogle &&
                                      openHoursDropDown[node.name] &&
                                      node.openHoursGoogle.map((day) => {
                                        return (
                                            <div
                                                className="mb-2 text-green-400 ml-2"
                                                key={day}
                                            >
                                              {shortenDay(day)}
                                            </div>
                                        );
                                      })}
                                    </div>
                                ) : (
                                    <div className='text-xs text-gray-400 mb-3 '>
                                      {
                                        node.openHoursDescription
                                      }
                                    </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {node.outsideDeliveryArea && (
                            <div
                              onClick={() => {
                                if (
                                  (node.thirdPartyClient &&
                                  Object.keys(node.thirdPartyClient).length > 0)  || node.instoreOnly
                                ) {
                                  handleOpenPopUp(node);
                                } else {
                                  navigate(`/marketplace/${node.slug}`);
                                }
                              }}
                            >
                              <div className="text-red-400 text-xs">
                                Address outside delivery area.
                              </div>
                              <div className="text-red-400 text-xs mb-4">
                                Pickup still available
                              </div>
                            </div>
                          )}
                          <div className="flex w-full">
                            <div
                              className="grow"
                              onClick={() => {
                                if (
                                  (node.thirdPartyClient &&
                                  Object.keys(node.thirdPartyClient).length > 0)  || node.instoreOnly
                                ) {
                                    handleOpenPopUp(node);
                                  // } else if (!node.thirdPartyClient || Object.keys(node.thirdPartyClient).length === 0) {
                                  //     navigate(`/marketplace/${node.slug}`);
                                  // }
                                } else {
                                  navigate(`/marketplace/${node.slug}`);
                                }
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {isLoaded && toggleMap && (
            <div className="w-full md:w-1/2 h-[90%] mx-auto flex flex-col md:flex-row relative border-red-700 hidden sm:block">
              <div className="">
                <GoogleMap
                  mapContainerStyle={{
                    overflow: "hidden",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                  zoom={selectedZoom}
                  center={selectedCenterCoords}
                  options={{
                    disableDefaultUI: true,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                  }}
                >
                  {postSearchNodes.map((node) => {
                    if (!node.coordinates) return;
                    const [lng, lat] = node.coordinates;
                    if (node.name === selectedMapNode.name) {
                      return (
                        <Marker
													onClick={() => {
														handleMarkerClick(node)
													}}
                          key={node._id.toString()}
                          title={node.name}
                          icon={{
                            url:
                              node.type === "whole-foods"
                                ? "https://i.imgur.com/ppyvkNZ.png"
                                : "https://i.imgur.com/b33G8C4.png",
                            scaledSize:
                              node.type === "whole-foods"
                                ? new google.maps.Size(30, 30)
                                : new google.maps.Size(30, 30),
                          }}
                          name={node.name}
                          position={{
                            lat: Number(lat.toString()),
                            lng: Number(lng.toString()),
                          }}
                        ></Marker>
                      );
                    } else {
                      return (
                        <Marker
													onClick={() => {
														handleMarkerClick(node)
													}}
                          key={node._id.toString()}
                          title={node.name}
                          icon={{
                            url:
                              node.type === "whole-foods"
                                ? "https://i.imgur.com/ppyvkNZ.png"
                                : "https://i.imgur.com/ci46o90.png",
                            scaledSize:
                              node.type === "whole-foods"
                                ? new google.maps.Size(30, 30)
                                : new google.maps.Size(30, 30),
                          }}
                          name={node.name}
                          position={{
                            lat: Number(lat.toString()),
                            lng: Number(lng.toString()),
                          }}
                        ></Marker>
                      );
                    }
                  })}
                  {geoCodedAddress && geoCodedLng && (
                    <Marker
                      key="user-location"
                      title="Your address"
                      icon={{
                        url: "https://i.imgur.com/Y0Wiacr.png",
                        scaledSize: new google.maps.Size(30, 30),
                      }}
                      name="Searched address"
                      position={{
                        lat: geoCodedLat,
                        lng: geoCodedLng,
                      }}
                    />
                  )}
                </GoogleMap>
              </div>
							{distPopupVisible && distanceInfo && (
								<div
									className={`hidden sm:block absolute top-[10px] left-[10px] bg-white border-2 border-solid border-green-600 rounded-lg p-4 z-50 flex flex-col`}
								>
									<div className='flex flex-row justify-start items-center my-auto'>
										<FaRoute/>
										<div className='ml-2'>
											{distanceInfo.distance} miles
										</div>
									</div>
									<a
										href={`https://www.google.com/maps/dir/?api=1&origin=${distanceInfo.originLat},${distanceInfo.originLng}&destination=${distanceInfo.destLat},${distanceInfo.destLng}`}
										target='_blank'
										rel='noopener noreferrer'
										className='text-blue-500 underline'
									>
										Open in Google Maps
									</a>
								</div>
							)}
            </div>
          )}
        </div>
      </div>
			{openPopUp && (
				<RedirectPopUp
          handleOpenPopUp={handleOpenPopUp}
          popUpNode={popUpNode}
          openPopUp={openPopUp}
        />
			)}
    </div>
  );
};

const mapStateToProps = ({ Nodes, User, Location }) => ({
  nodes: Nodes.nodes,
  loadingNodes: Nodes.loadingNodes,
  nodesError: Nodes.nodesError,
	node: Nodes.node,
	loadingNode: Nodes.loadingNode,
  user: User.user,
  loadingUser: User.loading,
  loggedIn: User.loggedIn,
  searchedAddressLatLng: Nodes.searchedAddressLatLng,
  userGPSLocation: Location.userLocation,
  allUniqueCuisines: Nodes.allUniqueCuisines,
  updateUserLoading: User.updateUserLoading,
  userUpdateCompleted: User.userUpdateCompleted,
  gpsLocationFound: Location.gpsLocationFound,
  userGPSLocationError: Location.userLocationError,
  finishedNodesPull: Nodes.finishedNodesPull,
});

const mapDispatchToProps = (dispatch) => ({
  onGetNodes: (filter) => dispatch(getNodes(filter)),
	onGetNodeBySlug: (filter) => dispatch(getNodeBySlug(filter)),
  onUpdateSearchAddress: (address) => dispatch(updateSearchNodes(address)),
  onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
  onGetGPSLocation: () => dispatch(getGPSLocation()),
  onGetUser: () => dispatch(getUser()),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
