import React, { useRef } from 'react'
import { BounceLoaderCentered, Button } from '../Elements'
import { BiLinkExternal } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { thirdPartyDict } from '../../helpers/clientInfo'
import { MdClear } from 'react-icons/md'
import useOutsideClick from '../Elements/DetectOutsideClick'


const RedirectPopup = (props) => {
    const { popUpNode, handleOpenPopUp, openPopUp } = props
    
    const handleClosePopUp = () => {
        handleOpenPopUp(false)
	}
    
    const impactRef = useRef(null)

    useOutsideClick(impactRef, () => {
        handleOpenPopUp(false)
    })

    if(popUpNode.type==="whole-foods"){
        return (
            <>
                {openPopUp && (
                    <>
                        <div
                            className='justify-center flex w-full bg-white fixed inset-0 z-40 shadow-xl sm:bg-gray-800 overflow-x-hidden sm:bg-gray-800 sm:bg-opacity-25 sm:backdrop-filter sm:backdrop-blur-sm sm:py-10'>
                            <div
                                ref={impactRef}
                                className='sm:h-fit h-full min-h-[800px] border-0 shadow-xl relative w-full overflow-y-auto sm:bg-white sm:w-[500px] sm:rounded-xl sm:pb-20 z-50'
                            >
                                <div className='relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                                    <div className='relative w-full'>
                                        <img
                                            className='max-h-[250px] w-full object-cover'
                                            src='https://i.imgur.com/a7MCHFw.png'
                                            alt=''
                                        />
                                        <div className='absolute top-4 right-4'>
                                            <MdClear
                                                className='text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl'
                                                onClick={() => {
                                                    handleClosePopUp()
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className='flex flex-col items-start justify-between px-4 py-4 border-b border-solid border-slate-200 rounded-t mx-3'>
                                        <h3 className='text-2xl font-semibold mb-1 text-green-600 font-header'>
                                            DeliverZero at Whole Foods Market
                                        </h3>
                                        <p className='mt-3 text-slate-500 leading-relaxed text-base'>
                                            DeliverZero is partnering with Whole Foods Market to offer reusables for the hot bar, salad bar, deli counter, and
                                            more.
                                            <br/><br/>
                                            <b>1.</b> Visit {popUpNode.name}, grab a DeliverZero container, and fill it up as you would normally.
                                            <br/><br/>
                                            <b>2.</b> Checkout and pay a small, fully refundable deposit.
                                            <br/><br/>
                                            <b>3.</b> Rinse and return containers at your next visit to get your deposit back.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                        </div>
                    </>
                )}
            </>
        )
    } else {
        return (
            <>
                {openPopUp && (
                    <>
                        <div
                            className='justify-center flex w-full bg-white fixed inset-0 z-40 shadow-xl sm:bg-gray-800 overflow-x-hidden sm:bg-gray-800 sm:bg-opacity-25 sm:backdrop-filter sm:backdrop-blur-sm sm:py-10'>
                            <div
                                ref={impactRef}
                                className='sm:h-fit h-full min-h-[800px] border-0 shadow-xl relative w-full overflow-y-auto sm:bg-white sm:w-[500px] sm:rounded-xl sm:pb-20 z-50'
                            >
                                <div className='relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                                    <div className='relative w-full'>
                                        <img
                                            className='max-h-[250px] w-full object-cover mt-10'
                                            src='https://i.imgur.com/2GrmDIr.png'
                                            alt=''
                                        />
                                        <div className='absolute top-4 right-4'>
                                            <MdClear
                                                className='text-green-600 text-[20px] cursor-pointer bg-white rounded-full p-2 border border-green-600 h-10 w-10 z-50 shadow-xl'
                                                onClick={() => {
                                                    handleClosePopUp()
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className='flex flex-col items-start justify-between px-4 py-4 border-b border-solid border-slate-200 rounded-t mx-3'>
                                        <h3 className='text-2xl font-semibold mb-1 text-green-600 font-header'>
                                            Before we send you to {popUpNode.name}
                                        </h3>
                                        <p className='mt-3 text-slate-500 leading-relaxed text-base'>
                                            Remember you must add "Use DeliverZero Reusable
                                            Containers" to your cart!
                                        </p>
                                    </div>

                                    <div className="flex flex-col justify-center items-center">
                                        {popUpNode.directOrdering && popUpNode.directOrderingClient && (
                                            <div className="mb-1 sm:mb-3">
                                                <div className="mt-1 sm:mt-4 mb-3 text-lg text-center">Order Directly
                                                </div>
                                                <Button
                                                    size='sm'
                                                    className='text-[16px] sm:text-[20px] shadow-light-grey font-semibold mx-auto py-2 px-6 mb-3 flex items-center w-[300px]'
                                                    text='Order with DeliverZero'
                                                    link={`/marketplace/${popUpNode.slug}`}
                                                />
                                            </div>
                                        )}
                                        {popUpNode.instoreOnly && !popUpNode.thirdPartyClient && (
                                            <div className="mb-1 sm:mb-3">
                                                <div className="mt-1 sm:mt-4 mb-8 text-lg font-bold text-red-400">Only
                                                    available in store.
                                                </div>
                                            </div>
                                        )}
                                        {popUpNode.thirdPartyClient && (
                                            <div>
                                                <div className="mt-1 sm:mt-4 mb-6 text-lg text-center">Order Through
                                                </div>
                                                {Object.keys(popUpNode.thirdPartyClient).map((client) => {
                                                    const enabledClients = popUpNode.enabledClients
                                                    if (enabledClients && !enabledClients[client]) {
                                                    } else {
                                                        return (
                                                            <div
                                                                className='mb-1 sm:mb-3 flex flex-row justify-center items-center my-auto'>
                                                                <Button
                                                                    href={popUpNode.thirdPartyClient[client]}
                                                                    color={client}
                                                                    size='sm'
                                                                    className='text-[16px] sm:text-[20px] shadow-light-grey font-semibold mx-auto py-2 px-6 mb-3 flex items-center w-[300px]'
                                                                    icon={<BiLinkExternal/>}
                                                                    iconPosition='right'
                                                                    text={<img
                                                                        src={`/images/PlatformPartners/${thirdPartyDict[client].name.replace(/\s+/g, '').toLowerCase()}.png`}
                                                                        alt={`${thirdPartyDict[client].name}`}
                                                                        className={`w-auto ${thirdPartyDict[client].name === 'ChowNow' ? 'h-[30px]' : 'h-[20px]'} mr-2 brightness-0 invert`}
                                                                    />}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        )}
                                        <hr className="mb-3 flex flex-row justify-center items-center my-auto"/>
                                    </div>
                                </div>
                            </div>
                            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                        </div>
                    </>
                )}
            </>
        )
    }
}

export default RedirectPopup
