import { call, put, takeEvery } from 'redux-saga/effects'
import { insertTransaction } from '../../../model/transactions'
import { createTransactionSuccess, createTransactionFail } from './actions'
import { CREATE_TRANSACTION } from './actionTypes'

function* createTransaction(action) {
	try {
		// console.log(action.payload)
		const transaction = yield call(insertTransaction, action.payload)
		// console.log(transaction)
		yield put(createTransactionSuccess(transaction))
	} catch (error) {
		yield put(createTransactionFail(error))
	}
}

function* createTransactionSaga() {
	yield takeEvery(CREATE_TRANSACTION, createTransaction)
}

export default createTransactionSaga
