import { connect } from 'react-redux'
import React, { createRef, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
	createTransaction,
	getNodeBySlug,
	resetTransaction,
	validatePhone,
	getNodes,
    updateSearchNodes,
    getAddressLocation,
    getGPSLocation,
	captureGPS,
} from '../../store/actions'
import { Header } from '../../components'
import {useJsApiLoader} from '@react-google-maps/api'
import {
    calculateDistanceBetweenCoords,
    googleMapsLibraries,
} from '../../helpers/googleMapsAPI'
import { Alert, Button } from '../../components/Elements'
import { FaCloudUploadAlt, FaCameraRetro } from 'react-icons/fa'
import { BounceLoader } from 'react-spinners'
import ReactConfetti from 'react-confetti'
import { GiPartyPopper } from 'react-icons/gi'
import { MdClear } from 'react-icons/md'
import { initializeUseSelector } from 'react-redux/es/hooks/useSelector'
import { realm } from '../../helpers/realm'
import PhoneInput from "react-phone-number-input/input"
import LZString from 'lz-string'

const RegisterReturn = (props) => {
	const {
		onGetNodeBySlug,
		loadingNode,
		nodeNotFound,
		node,
		user,
		loggedIn,
		onCreateTransaction,
		transaction,
		createTransactionError,
		creatingTransaction,
		onResetTransaction,
		onGetGPSLocation,
        gpsLocationFound,
        userLocationError,
		userLocation,
		onGetUserAddressLocation,
		onCaptureGPS,
	} = props

	const { nodeSlug } = useParams()
	const [showSuccess, setShowSuccess] = useState(false)
	const [qty, setQty] = useState(1)
	const [returnImage, setReturnImage] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const [submitLoading, setSubmitLoading] = useState(false)
	const [initialLoad, setInitialLoad] = useState(true)
	const [locationWarning, setLocationWarning] = useState(false)
	const [activeGPS, setActiveGPS] = useState(true)
    const [showLocationWarning, setShowLocationWarning] = useState(false)
    const [showTooFarWarning, setShowTooFarWarning] = useState(false)
    const [tooFar, setTooFar] = useState(false)
	const [locationServices, setLocationServices] = useState(userLocation)
	const [phone, setPhone] = useState('')
	const bounceLoaderColor = '#507f74'
	const google = window.google
	const encryptionKey = "image_storage_key"

	// LOAD GOOGLE MAPS API
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: googleMapsLibraries,
	})

	// initial location services check
	useEffect(() => {
		setInitialLoad(true)
		if (!userLocation) {
			makeGPSRequest()
		// } else if (gpsLocationFound) {
        //     setLocationWarning(false)
        //     setShowLocationWarning(false)
        //     setLocationServices(false)
        } else if ('geolocation' in navigator) {
		  	navigator.geolocation.getCurrentPosition(
				(position) => {
					setLocationWarning(false)
					setLocationServices(true)
					setShowLocationWarning(false)
					setTooFar(true)
					locationValidation()
				},
				(error) => {
					setLocationWarning(true)
					setShowLocationWarning(true)
					setLocationServices(false)
				}
			)
		} else {
			setLocationWarning(true)
			setShowLocationWarning(true)
		}
	}, [userLocation])

	// initial node retrieval
	useEffect(() => {
		if (nodeSlug) {
			onGetNodeBySlug(nodeSlug)
		}
	}, [nodeSlug])

	 // GET USER LOCATION ON LOAD
    useEffect(() => {
        if(!userLocation && userLocationError==="User denied Geolocation"){
            onGetUserAddressLocation()
        }
        if (activeGPS) {
            setActiveGPS(false)
        }

        if (gpsLocationFound && userLocation) {
            setActiveGPS(false)
        }
    }, [gpsLocationFound, activeGPS, userLocationError])

	const handleGPSRequest = () => {
		makeGPSRequest()
	}

    const makeGPSRequest = async () => {
        await onGetGPSLocation()
        setActiveGPS(true)
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                setLocationWarning(false)
				setShowLocationWarning(false)
				setLocationServices(true)
              },
              (error) => {
                  setLocationWarning(true)
                  setShowLocationWarning(true)
				  setLocationServices(false)
              }
          )
      } else {
          setLocationWarning(true)
      }
    }

	useEffect(() => {
		if (user && user.phone) {
			onCaptureGPS({phone: user.phone})
		}
	}, [user])

	useEffect(() => {
		if (node) {
			locationValidation()
		} else {
			if (nodeSlug) {
				onGetNodeBySlug(nodeSlug)
			}
        }
	}, [node])
		
	// "too far" warning
	useEffect(() => {
		if (tooFar) {
			setShowTooFarWarning(true)
		} else {
			setShowTooFarWarning(false)
		}
	}, [tooFar])

	const locationValidation = () => {
		// console.log("userLocationError", userLocationError)
		// console.log("userLocation", userLocation)
		// console.log("node", node)
		if (node && userLocation) {
			const restaurantCoords = node.coordinates
			const userLat = userLocation.lat
			const userLng = userLocation.lng
			const distanceToRestaurant = calculateDistanceBetweenCoords([userLat, userLng], restaurantCoords)
			// console.log("distanceToRestaurant", distanceToRestaurant)
			if (distanceToRestaurant > 100) {
				if (!locationWarning) {
					setShowTooFarWarning(true)
					setTooFar(true)
				}
				setInitialLoad(false)
				return false
			} else {
				setShowTooFarWarning(false)
				setTooFar(false)
				setInitialLoad(false)
				return true
			}
		} else {
			if (node) {
				setInitialLoad(false)
			} else {
				onGetNodeBySlug(nodeSlug)
			}
			return false
		}
    }
	
	// transaction submission and success
	useEffect(() => {
		if (creatingTransaction) {
			setSubmitLoading(true)
		} 
		if (transaction) {
			setShowSuccess(true)
		}
		return function cleanup() {
			onResetTransaction()
		}
	}, [creatingTransaction, transaction])

	const submitTransaction = async (e) => {
		e.preventDefault()
		setSubmitLoading(true)
		if (!loggedIn) {
			if (!validatePhone()) {
				return
			} 
			const client = realm.currentUser.mongoClient('RealmService')
			const users = client.db('caas').collection('users')
			const userDoc = await users.findOne({ phone: phone })
			if (userDoc) {
				const transactionPayload = {
					timestamp: new Date(),
					type: 'boxesIn',
					boxCount: qty,
					items: {},
					client: 'deliverzero',
					node: node.slug,
					user: {
						phone: phone,
						email: userDoc.email ? userDoc.email : '',
						firstName: userDoc.firstName ? userDoc.firstName : '',
						lastName: userDoc.lastName ? userDoc.lastName : '',
					},
					returnImageSource: returnImage,
				}
				if (qty > 0) {
					onCreateTransaction(transactionPayload)
				}
				else if (locationWarning && !returnImage) {
					setErrorMessage('Please allow location services or take a photo of your return to verify')
				}
			} else {
				setErrorMessage('Please enter a valid phone number associated with an order')
				return
			}
		} else {
			const transactionPayload = {
				timestamp: new Date(),
				type: 'boxesIn',
				boxCount: qty,
				items: {},
				client: 'deliverzero',
				node: node.slug,
				user: {
					phone: user.phone,
					email: user.email,
					firstName: user.firstName,
					lastName: user.lastName,
				},
				returnImageSource: returnImage,
			}
			if (qty > 0) {
				onCreateTransaction(transactionPayload)
			}
			else if (locationWarning && !returnImage) {
				setErrorMessage('Please allow location services or take a photo of your return to verify')
			}
		}
	}

	const openSupport = () => {
		window.Intercom('show')
	}

	const takePhotoRef = useRef(null)
	
	const handleTakePhoto = () => {
		takePhotoRef.current.click()
	  
		takePhotoRef.current.onchange = (event) => {
		  const selectedFile = event.target.files[0]
		  if (selectedFile) {
			const fileReader = new FileReader()
			fileReader.onload = function (event) {
				const base64String = event.target.result.split(',')[1]
				const compressedData = LZString.compressToBase64(base64String)
				setReturnImage(`data:image/jpeg;base64,${compressedData}`)
			}
			fileReader.readAsDataURL(selectedFile)
		  }
		}
	}

	const decompressImage = () => {
		const compressedData = returnImage.split('data:image/jpeg;base64,')[1]
		const umcompressedData = LZString.decompressFromBase64(compressedData)
		return `data:image/jpeg;base64,${umcompressedData}`
	}

	// useEffect(() => {
	// 	console.log("returnImage", returnImage)
	// }, [returnImage])

	const handleDismissLocation = () => {
        setShowLocationWarning(false)
    }

	const handleDismissTooFar = () => {
        setShowTooFarWarning(false)
    }

	const handlePhone = (e) => {
		setPhone(e)
	}	

	const validatePhone = () => {
		if (phone === '' || phone.length !== 12) {
			setErrorMessage('Please enter a 10 digit phone number')
		} else {
			setErrorMessage('')
		}
		if(phone.length===12){
			return true
		} else {
			return false
		}
	}

	if (node) {
		if (initialLoad || creatingTransaction) {
			return (
				<>
				<Header label={"Return In Person"} showBackButton={true} />
				<div className='h-full flex flex-col items-center justify-center my-10'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>

			</>
			)
		}
		return (
			<div className=' h-full w-full flex flex-col justify-between '>
				<Header label={"Return In Person"} showBackButton/>
				{!showSuccess && (
					<div className='h-full mt-[3.25rem] flex flex-col justify-between items-center'>
						{locationWarning && showLocationWarning && (
							<>
							<Alert
								content='You have location services turned off. Please turn on location services or submit a photo to register your return.'
								type='warning'
								className='h-[150px] flex items-center justify-center z-10'
								onClick={handleGPSRequest}
							/>
							<MdClear
								className='absolute right-2 sm:right-[5px] top-[70px] sm:top-[100px] transform -translate-y-1/2 text-white text-lg cursor-pointer text-yellow-400 bg-yellow-800 rounded-full w-3 h-3 sm:w-4 sm:h-4 flex items-center justify-center z-20'
								onClick={handleDismissLocation}
							/>
							</>
						)}
						{!(locationWarning && showLocationWarning) && tooFar && showTooFarWarning && (
							<>
							<Alert
								content='You are too far to register your return. Please try again once you have arrived at the drop-off location or submit a photo to register your return.'
								type='warning'
								className='h-[150px] flex items-center justify-center z-10'
							/>
							<MdClear
								className='absolute right-2 sm:right-[5px] top-[70px] sm:top-[100px] transform -translate-y-1/2 text-white text-lg cursor-pointer text-yellow-400 bg-yellow-800 rounded-full w-3 h-3 sm:w-4 sm:h-4 flex items-center justify-center z-20'
								onClick={handleDismissTooFar}
							/>
							</>
						)}
						{errorMessage && (
							<Alert
								content={errorMessage}
								type='danger'
							/>
						)}
						{loadingNode && <BounceLoader />}
						{!loadingNode && (
							<>
								<div className='flex flex-col w-[90%] items-center justify-center container mb-2 pt-3 z-0'>
									<div className='text-center'>
										<div className='text-2xl font-bold font-header text-green-600'>
											{node?.name}
										</div>
										{node.returnInstructions && (
												<div className='text-sm text-center font-header mb-1'>
														{
															node?.returnInstructions
														}
												</div>
										)}
									</div>
								</div>
								<div className='shadow-2xl flex flex-col lg:flex-row w-[85%] min-h-fit mx-auto my-2 sm:my-6 items-center justify-center container rounded-xl relative mb-[50px] flex-grow'>
									<div  className={`flex flex-col justify-center h-full w-full px-6 sm:w-2/3 pb-5 pt-2 sm:px-8 sm:py-12 z-10 bg-white text-center lg:text-left`}>
									{(locationWarning || tooFar) && (
										<>
										<div className=' my-3 lg:mb-3'>Make sure all of the containers are visible to
                                            properly verify your return!
                                        </div>
										<div className='flex flex-col lg:flex-row w-full items-center justify-around'>
                                            {returnImage && (
                                                <div className='lg:max-w-100 lg:max-h-100 my-2 mx-auto'>
													<img
														className='max-w-[200px] lg:max-w-full max-h-[250px] object-cover'
														src={decompressImage(returnImage)}
														alt='Return Image'
													/>
                                                    <Button text='Retry' size='sm lg:base' horizontal
                                                            onClick={() => setReturnImage(null)}
                                                            className='px-3 py-1 my-2'/>{' '}
                                                </div>
                                            )}
                                            {!returnImage && (
												<div className='w-full lg:h-full my-3 mx-3'>
													<input
														type="file"
														accept="image/*"
														ref={takePhotoRef}
														style={{ display: 'none' }}
														onChange={handleTakePhoto}
														capture
													/>
													<Button text='Take a Photo' size='sm lg:base' onClick={handleTakePhoto}
                                                            // icon={<FaCameraRetro/>} 
															className='px-3 py-1'/>
												</div>
                                            )}
                                        </div>
										</>
									)}
										<div className='flex flex-col w-full lg:flex-row items-center justify-center'>
											<div className='my-4 lg:mr-4'>
												Tell us how many containers
												you're returning
											</div>
											<div className='border-2 rounded-full mb-5 sm:mb-0 lg:w-1/3 w-full text-center flex justify-between px-3 items-center'>
												<span
													color='default'
													className='text-4xl text-gray-300 w-1/3 cursor-pointer'
													onClick={() => {
														if (qty >= 2) {
															setQty(qty - 1)
														}
													}}
												>
													-
												</span>
												<div className='text-2xl'>
													{qty}
												</div>
												<span
													color='default'
													className='text-2xl text-gray-300 w-1/3 cursor-pointer'
													onClick={() => {
														setQty(qty + 1)
													}}
												>
													+
												</span>
											</div>
										</div>
										{!loggedIn && (
										<>
										<div className='w-full'>
											<div className='font-header text-lg text-start ml-5'>
												<label htmlFor='phone'>Phone</label>
											</div>
											{/* <p className='text-xs text-start ml-5'>
													If you've ordered in DeliverZero boxes from
													a third-party, please use the phone number
													from those orders
											</p> */}
											<PhoneInput
												onChange={handlePhone}
												country='US'
												value={phone}
												id='phone'
												name='phone'
												className={`bg-white border-2 w-full rounded-full px-4 py-2 text-base outline-none transition-colors duration-150 ease-in-out tracking-wide focus:border-gray-300 focus:drop-shadow`}
												type='phone'
											/>
										</div>
										</>
										)}
										<div className='lg:w-1/6 min-w-32 mt-5 sm:mt-10 mx-auto'>
											{submitLoading && (
												<BounceLoader
													className='m-auto'
													color={bounceLoaderColor}
												></BounceLoader>
											)}
											{!submitLoading && (
												<>
												{(!loggedIn && phone === '') || ((tooFar && !returnImage) || (locationWarning && !returnImage)) ? (
													<Button
														text='Submit'
														size='sm lg:base'
														color='gray'
														className='px-3 py-1'
														disabled
													/>
												) : (
													<Button
														text='Submit'
														size='sm lg:base'
														color='red'
														onClick={(e) => {
															setSubmitLoading(true)
															submitTransaction(e)
														}}
														className='px-3 py-1'
													/>
												)}
												</>
											)}
										</div>
									</div>
								</div>
								<div className='flex justify-between w-2/3 items-center my-3 sm:my-4'>
									<div className='mx-2 text-center hidden sm:inline-block'>
										Need Help?{' '}
										<a
											className='text-green-600 font-bold hover:text-green-400'
											onClick={openSupport}
										>
											Chat with us
										</a>
									</div>
								</div>
								<div className='mx-2 text-center inline-block sm:hidden pb-20'>
									Need Help?{' '}
									<a
										className='text-green-600 font-bold hover:text-green-400'
										onClick={openSupport}
									>
										Chat with us
									</a>
								</div>
							</>
						)}
					</div>
				)}
				{showSuccess && (
					<div className='h-full lg:h-screen-3/4 my-5 flex flex-col justify-center items-center overflow-hidden'>
						<ReactConfetti colors={['#f38b74']} />
						<h1 className='font-vollkorn text-green-600 text-4xl text-center'>
							You're all set!
						</h1>
						<GiPartyPopper className='text-orange-600 text-[250px]' />
						<Button
							link='/user/accountLanding'
							className='px-10 w-[260px] mt-5 py-1 shadow-light-grey'
							text='View My Account'
						/>
					</div>
				)}
			</div>
		)
	} else {
		return (
			<>
				<Header label={"Return In Person"} showBackButton={true} />
				<div className='h-full flex flex-col items-center justify-center my-10'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
					<div className='p-6'>
						Oops it appears there's nothing here. Let's take you
						back to the beginning of the returns process
					</div>
					<Button
						size='base'
						className='text-sm px-6 static z-40 relative py-1'
						text='Return Boxes'
						link='/returns'
					/>
				</div>
			</>
		)
	}
}

const mapStateToProps = ({ Nodes, User, TransactionsCreate, Location }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	node: Nodes.node,
	loadingNode: Nodes.loadingNode,
	transaction: TransactionsCreate.transactionResult,
	createTransactionError: TransactionsCreate.error,
	creatingTransaction: TransactionsCreate.creating,
	userLocation: Location.userLocation,
    gpsLocationFound: Location.gpsLocationFound,
    userLocationError: Location.userLocationError,
	
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetNodeBySlug: (slug) => dispatch(getNodeBySlug(slug)),
	onCreateTransaction: (payload) => dispatch(createTransaction(payload)),
	onResetTransaction: () => dispatch(resetTransaction()),
	onGetGPSLocation: () => dispatch(getGPSLocation()),
	onGetUserAddressLocation: () => dispatch(getAddressLocation()),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterReturn)