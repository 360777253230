import { realm } from '../helpers/realm'

export const NodeTypes = {
	bin: 'bin',
	grocery: 'grocery',
	wholeFoods:'whole-foods',
	warehouse: 'warehouse',
	dummy: 'dummy',
}

export const getNodes = async (filter) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	//console.log('running Get nodes', filter)
	const client = realm.currentUser.mongoClient('RealmService')
	const nodes = client.db('caas').collection('nodes')

	return nodes.find({ $and: filter }, { $limit: 100 })
}

export const getNode = async (_id) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const nodes = client.db('caas').collection('nodes')
	return nodes.findOne({ _id })
}

export const getNodeBySlug = async (slug) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const nodes = client.db('caas').collection('nodes')
	return nodes.findOne({ slug })
}

export const getNodeByQR = async (qr) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const nodes = client.db('caas').collection('nodes')
	return nodes.findOne({ qr: { $in: [qr] } })
}

export const addNode = async (nodeDoc) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const nodes = client.db('caas').collection('nodes')
	const existingNode = await nodes.findOne({ slug: nodeDoc.slug })
	if (existingNode) {
		throw 'node already exists'
	} else {
		return nodes.insertOne(nodeDoc)
	}
}

export const updateNode = async (updateObject) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const nodes = client.db('caas').collection('nodes')
	const update = await nodes.updateOne(
		{ slug: updateObject.nodeSlug },
		{ $set: updateObject.update }
	)
	const node = await nodes.findOne({ slug: updateObject.nodeSlug })
	return node
}

export const getNodeByAdminCode = async (adminCodeOrSlug) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const nodes = client.db('caas').collection('nodes')
	return nodes.findOne({$or: [{'adminPinCode': adminCodeOrSlug.toLowerCase()}, {'slug': adminCodeOrSlug.toLowerCase()}]},)
}

export const getMealDeliveryServices = async (filter) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}

	const client = realm.currentUser.mongoClient('RealmService')
	const mealDeliveryServices = client.db('caas').collection('meal_delivery_services')

	return mealDeliveryServices.find({ $and: filter }, { $limit: 100 })
}

export const restaurantAdminSubmitRequest = async (request) => {
	if (!realm.currentUser) {
		throw new Error('Unauthorized')
	}
	const response = await realm.currentUser.callFunction(
		'restaurantAdmin/submitRequest',
		request
	)

	return response
}