import {
	GET_USER_ADDRESS_LOCATION,
	GET_USER_ADDRESS_LOCATION_SUCCESS,
	GET_USER_ADDRESS_LOCATION_FAIL,
	GET_GPS_LOCATION,
	GET_GPS_LOCATION_SUCCESS,
	GET_GPS_LOCATION_FAIL,	
	CAPTURE_GPS,
	CAPTURE_GPS_FAIL,
	CAPTURE_GPS_SUCCESS,
} from './actionTypes'

export const getAddressLocation = (payload) => ({
	type: GET_USER_ADDRESS_LOCATION,
	payload: payload,
})

export const getAddressLocationSuccess = (payload) => ({
	type: GET_USER_ADDRESS_LOCATION_SUCCESS,
	payload: payload,
})

export const getAddressLocationFail = (payload) => ({
	type: GET_USER_ADDRESS_LOCATION_FAIL,
	payload: payload,
})

export const getGPSLocation = (payload) => ({
	type: GET_GPS_LOCATION,
	payload: payload,
})

export const getGPSLocationSuccess = (payload) => ({
	type: GET_GPS_LOCATION_SUCCESS,
	payload: payload,
})

export const getGPSLocationFail = (payload) => ({
	type: GET_GPS_LOCATION_FAIL,
	payload: payload,
})

export const captureGPS = (payload) => ({
	type: CAPTURE_GPS,
	payload: payload,
})

export const captureGPSSuccess = (user) => ({
	type: CAPTURE_GPS_SUCCESS,
	payload: user,
})

export const captureGPSFail = (error) => ({
	type: CAPTURE_GPS_FAIL,
	payload: error,
})




// export const loadGoogleMapAPI = (payload) => ({
// 	type: GET_USER_ADDRESS_LOCATION,
// 	payload: payload,
// })

// export const loadGoogleMapAPISuccess = (payload) => ({
// 	type: GET_USER_ADDRESS_LOCATION_SUCCESS,
// 	payload: payload,
// })

// export const loadGoogleMapAPIFail = (payload) => ({
// 	type: GET_USER_ADDRESS_LOCATION_FAIL,
// 	payload: payload,
// })
