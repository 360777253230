import React, {useState, useEffect} from "react";
import {Input} from "./index";
import {connect} from "react-redux";
import {FaMapMarkedAlt, FaMapMarkerAlt} from "react-icons/fa";
import {AiOutlineClose} from "react-icons/ai";
import {BsPinFill} from "react-icons/bs";
import {updateUser} from "../../store/auth/user/actions";
import {BounceLoader} from "react-spinners";
import {getGPSLocation, getUser, captureGPS} from "../../store/actions";
import {MdClear} from "react-icons/md";

const AddressBar = (props) => {
    const google = window.google;
    const {
        makeGPSRequest,
        user,
        onGetUser,
        onUpdateUser,
        setGeoCodedAddress,
        setGeoCodedLat,
        setGeoCodedLng,
        geoCodedAddress,
        userGPSLocation,
        gpsLocationFound,
        userGPSLocationError,
        onGetGPSLocation,
        setAddress2,
		onCaptureGPS,
    } = props;

    const [showSavedAddresses, setShowSavedAddresses] = useState(false);
    const [savedAddresses, setSavedAddresses] = useState([]);
    const [searchedAddress, setSearchedAddress] = useState("");
    const [addressError, setAddressError] = useState(false);
    const [searchByGPS, setSearchByGPS] = useState(false);
    const [currentlyTypedAddressValue, setCurrentlyTypedAddressValue] =
        useState(searchedAddress || '')
    const [toggleIcon, setToggleIcon] = useState(false)

    const bounceLoaderColor = "#507f74";

    //////GEOCODE THE SELECTED ADDRESS
    const geocodeAddress = async (addressToGeoCode) => {
        // Geocode the address
        let geocoder = new google.maps.Geocoder();
        await geocoder.geocode(
            {
                address: addressToGeoCode,
            },
            function (results, status) {
                if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                    // console.log(results[0])
                    // set it to the correct, formatted address if it's valid
                    setGeoCodedAddress(results[0].formatted_address);
                    setGeoCodedLat(results[0].geometry.location.lat());
                    setGeoCodedLng(results[0].geometry.location.lng());
                } else {
                    setAddressError(true);
                    // // show an error if it's not
                    alert("Invalid address");
                }
            }
        );
        // console.log("geocode function", geoCodedAddress)
    };

    //////////////////////////////////////////////////////////////////////////////////////
    //WHEN USER SELECTS USE MY LOCATION, WE SEARCH THEIR COORDINATES TO PULL UP AN ADDRESS
    const reverseGeocodeAddress = async (coords) => {
        // Geocode the address
        let geocoder = new google.maps.Geocoder();
        await geocoder.geocode({location: coords}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                // set it to the correct, formatted address if it's valid
                setGeoCodedAddress(results[0].formatted_address);
                setGeoCodedLat(results[0].geometry.location.lat());
                setGeoCodedLng(results[0].geometry.location.lng());
                setSearchedAddress(results[0].formatted_address);
                setCurrentlyTypedAddressValue(results[0].formatted_address);
                // geocodeAddress(results[0].formatted_address);
                setShowSavedAddresses(false);
            } else {
                setAddressError(true);
                // // show an error if it's not
                alert("Invalid address");
            }
        });
    };

    /////////////////////////////////////
    // WHEN USER CLICKS "USE MY LOCATION"
    const handleGPSRequest = () => {
        if (userGPSLocation) {
            reverseGeocodeAddress(userGPSLocation)
        }
        setSearchByGPS(true)
        onGetGPSLocation();
    };

    useEffect(() => {
        if (userGPSLocation && searchByGPS) {
            reverseGeocodeAddress({
                lat: userGPSLocation.lat,
                lng: userGPSLocation.lng,
            })
        }
    }, [userGPSLocation, gpsLocationFound]);

    /////////////////////////////////////
    // WHEN USER STARTS TYPING AN ADDRESS
    const handleAddressChange = (newAddress) => {
        setGeoCodedAddress(null);
        setCurrentlyTypedAddressValue(newAddress)
        if (newAddress.target) {
            setSearchedAddress(newAddress.target.value);
        } else if (newAddress.formatted_address) {
            setSearchedAddress(newAddress.formatted_address);
        }
        setShowSavedAddresses(false);
    };

    ///////////////////////////////////////////////
    // WHEN USER HITS ENTER ON THEIR ADDRESS SEARCH
    const handleAddressSubmit = async (e) => {
        // if (e) e.preventDefault()
        if (searchedAddress && searchedAddress != "") {
            geocodeAddress(searchedAddress);
        }
    };

    ///////////////////////////////////////////////////////
    // WHEN USER SELECTS AN AUTOCOMPLETED SUGGESTED ADDRESS
    const handleAddressSelect = (address) => {
        setSearchedAddress(address);
        geocodeAddress(address)
    };

    ///////////////////////////////////////////////////////
    // WHEN USER SELECTS A SAVED ADDRESS
    const handleSavedAddressSelect = (selectedSavedAddress) => {
        setSearchedAddress(selectedSavedAddress.savedAddress);
        geocodeAddress(selectedSavedAddress.savedAddress);
        setAddress2(selectedSavedAddress.savedAddress2)
        // set local storage
        setShowSavedAddresses(false);
    };

    const handleToggleMap = () => {
        props.onClickToggleMap()
        setToggleIcon(prevState => !prevState)
    };

    useEffect(() => {
        onGetUser();
    }, [onGetUser]);

    useEffect(() => {
        if (user && user?.savedAddresses) {
            const filteredAddresses = user?.savedAddresses.filter((item) => item.savedAddress)
            setSavedAddresses(filteredAddresses);
        }
    }, [user, user?.savedAddresses]);

    const loadInitialAddress = () => {
        const lastSearchedAddress = JSON.parse(
            localStorage.getItem("lastSearchedAddress")
        );
        if (lastSearchedAddress && lastSearchedAddress.address) {
            // console.log('pulling from localStorage',lastSearchedAddress.address)
            setSearchedAddress(lastSearchedAddress.address);
            geocodeAddress(lastSearchedAddress.address)
        } else if (user && user.lastSearchedAddress) {
            // console.log('pulling from user.lastSearchedAddress', user.lastSearchedAddress)
            setSearchedAddress(user.lastSearchedAddress);
            geocodeAddress(user.lastSearchedAddress)
        } else if (user && user.address) {
            // console.log('pulling from user.address',user.address)
            setSearchedAddress(user.address);
            geocodeAddress(user.address)
        } else if (!userGPSLocationError) {
            // console.log('searching by GPS')
            handleGPSRequest()
        } else {
            geocodeAddress('43 Park Row, New York, NY 10038')
        }
    }
    
    useEffect(() => {
        loadInitialAddress()
    }, [user]);

    useEffect(() => {
        if(userGPSLocationError){
            loadInitialAddress()
        }
    }, [userGPSLocationError]);

    if (!user)
        return (
            <div className="flex items-center h-screen">
                <BounceLoader
                    className="m-auto"
                    color={bounceLoaderColor}
                ></BounceLoader>
            </div>
        );
    else
        return (
            <div className='flex flex-col items-center w-screen px-4'>
                <div className='w-full h-10 mt-3 relative items-center justify-between flex'>
                    <div className='w-full'>
                        <Input
                            placeholder="Enter Your Address..."
                            className="px-10 !text-sm"
                            value={searchedAddress}
                            autoCompleteAddress={true}
                            onClick={() => {
                                setShowSavedAddresses(true);
                            }}
                            onChange={handleAddressChange}
                            // onFocus={() => setShowSavedAddresses(false)}
                            onAddressSelect={handleAddressSelect}
                            onSearch={handleAddressSubmit}
                        />
                        <FaMapMarkerAlt
                            className='absolute left-0 ml-4 top-1/2 transform -translate-y-1/2 text-green-400 text-lg sm:text-xl'
                            onClick={handleGPSRequest}
                        />

                    </div>
                    {(currentlyTypedAddressValue || searchedAddress) && (
                        <MdClear
                            className="absolute right-[60px] top-1/2 transform -translate-y-1/2 text-white text-lg cursor-pointer bg-green-400 rounded-full w-4 h-4 flex items-center justify-center sm:w-5 sm:h-5 sm:right-[20px]"
                            onClick={() => {
                                setSearchedAddress("");
                                setCurrentlyTypedAddressValue('');
                            }}
                        />
                    )}
                    <div
                        className={`flex items-center justify-center ml-2 h-9 w-11 rounded-full sm:hidden ${
                            toggleIcon ? 'bg-white shadow-md' : 'bg-white border-2 border-green-600'
                        }`}
                        onClick={handleToggleMap}
                    >
                        <FaMapMarkedAlt className={`text-lg  sm:text-xl ${toggleIcon ? 'text-gray-400' : 'text-green-400'}`}/>
                    </div>
                    {showSavedAddresses && (
                        <div
                            className="rounded-xl bg-white fixed top-[57px] sm:top-[72px] left-0 right-0 bottom-0 cursor-pointer z-40 shadow-xl overflow-y-auto outline-none pb-[300px]">
                            <div
                                className="text-xl text-green-600 flex text-center items-center justify-between border-b border-gray-200 py-4 px-5">
                                Saved Addresses
                                <AiOutlineClose
                                    className={`font-semibold text-green-600 cursor-pointer align-self-end m-2`}
                                    onClick={() => {
                                        setShowSavedAddresses(false);
                                    }}
                                />
                            </div>
                            {!userGPSLocationError && (
                                <div
                                    onClick={handleGPSRequest}
                                    className="text-base flex text-center items-center border-b border-gray-200 py-4 px-5"
                                >
                                    <BsPinFill className={`text-green-400 text-sm mr-2`}/>
                                    Use current location
                                </div>
                            )}
                            {savedAddresses && savedAddresses != []
                                ? savedAddresses.map((addr, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="text-sm italic flex text-center items-center border-b border-gray-200 py-3 px-5"
                                            onClick={() => {
                                                handleSavedAddressSelect(addr);
                                            }}
                                        >
                                            <FaMapMarkerAlt
                                                className={`text-gray-400 text-sm mr-2`}
                                            />
                                            {addr.savedAddress}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    )}
                </div>
            </div>
        );
};

const mapStateToProps = ({User, Location}) => ({
    user: User.user,
    userGPSLocation: Location.userLocation,
    gpsLocationFound: Location.gpsLocationFound,
    userGPSLocationError: Location.userLocationError,
});

const mapDispatchToProps = (dispatch) => ({
    onGetUser: () => dispatch(getUser()),
    onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
    onGetGPSLocation: () => dispatch(getGPSLocation()),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressBar);
