const platforms = {
	CAVIAR: {
		key: 'CAVIAR',
		urlBase: 'https://www.trycaviar.com/',
		deepLinkBase: null,
		logo: '/images/companies/caviar.png',
		merchantDeepLinkKey: 'deepLinkCaviar',
		merchantUrlKey: 'urlCaviar',
		name: 'Caviar',
	},
	DIRECT: {
		key: 'DIRECT',
		urlBase: null,
		deepLinkBase: null,
		logo: null,
		merchantDeepLinkKey: null,
		merchantUrlKey: null,
		name: 'Merchant website',
	},
	DOORDASH: {
		key: 'DOORDASH',
		urlBase: 'https://www.doordash.com/',
		deepLinkBase: null,
		logo: '/images/companies/doordash.png',
		merchantDeepLinkKey: 'deepLinkDoordash',
		merchantUrlKey: 'urlDoordash',
		name: 'Doordash',
	},
	GRUBHUB: {
		key: 'GRUBHUB',
		urlBase: 'https://www.grubhub.com/',
		deepLinkBase: null,
		logo: '/images/companies/grubhub.png',
		merchantDeepLinkKey: 'deepLinkGrubhub',
		merchantUrlKey: 'urlGrubhub',
		name: 'Grubhub',
	},
	OTHER: {
		key: 'OTHER',
		urlBase: null,
		deepLinkBase: null,
		logo: null,
		merchantDeepLinkKey: null,
		merchantUrlKey: null,
		name: 'Other',
	},
	POSTMATES: {
		key: 'POSTMATES',
		urlBase: 'https://www.postmates.com',
		deepLinkBase: '',
		logo: '/images/companies/postmates.png',
		merchantDeepLinkKey: 'deepLinkPostmates',
		merchantUrlKey: 'urlPostmates',
		name: 'Postmates',
	},
	SEAMLESS: {
		key: 'SEAMLESS',
		urlBase: 'https://www.seamless.com',
		deepLinkBase: '',
		logo: '/images/companies/seamless.svg',
		merchantDeepLinkKey: 'deepLinkSeamless',
		merchantUrlKey: 'urlSeamless',
		name: 'Seamless',
	},
	UBER_EATS: {
		key: 'UBER_EATS',
		urlBase: 'https://www.ubereats.com',
		deepLinkBase: '',
		logo: '/images/companies/uber-eats.svg',
		merchantDeepLinkKey: 'deepLinkUberEats',
		merchantUrlKey: 'urlUberEats',
		name: 'UberEats',
	},
	WALK_IN: {
		key: 'WALK_IN',
		urlBase: null,
		deepLinkBase: null,
		logo: null,
		merchantDeepLinkKey: null,
		merchantUrlKey: null,
		name: 'In person / telephone',
	},
}

export const getPlatforms = () => {
	return platforms
}

export const getPlatformByKey = (key) => {
	return platforms[key]
}

export const platformLogoByKey = (key) => {
	if (platforms[key]) {
		return platforms[key].logo ?? null
	}
	return null
}

export const platformMerchantUrlKey = (key) => {
	if (platforms[key]) {
		return platforms[key].merchantUrlKey ?? null
	}
	return null
}
