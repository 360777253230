import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Button } from '../Elements'
import React, { useEffect, useState } from 'react'
import { getUser, updateUser } from '../../store/auth/user/actions'
import {
	getPaymentMethods,
	resetCardAdded,
	storePaymentMethod,
} from '../../store/payments/actions'

import { connect } from 'react-redux'
import { BounceLoader } from 'react-spinners'

const CreditCardForm = (props) => {
	const {

		onGetUser,
		user,
		loadingUser,
		updateUserLoading,
		userUpdateCompleted,
		cardAdded,
		loadingAddition,
		cardAddedError,
		paymentMethods,
		loadingPaymentMethods,
		onStorePaymentMethod,
		buttonClass,
	} = props
	const stripe = useStripe()
	const elements = useElements()
	const cardElementOptions = {
		style: {
			base: {
				color: '#504E63',
				borderRadius: 5,
				backgroundColor: '#e9effd',
				lineHeight: '2.5',
				fontFamily: '"Poppins", "sans-serif"',
				fontWeight: 'bold',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#f38686',
				iconColor: '#f38686',
			},
		},
	}
	const [addCardError, setAddCardError] = useState(false)
	const [stripeLoading, setStripeLoading] = useState(false)
	const bounceLoaderColor = '#507f74'

	const handleAddCardSubmit = async (event) => {
		setStripeLoading(true)
		event.preventDefault()
		setAddCardError(false)
		if (elements == null) {
			return
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		})
		if (result.error) {
			setStripeLoading(false)
			setAddCardError(true)
		} else {
			setStripeLoading(false)
			onStorePaymentMethod({
				phone:user.phone,
				stripeCustomerId: user.stripeId,
				paymentMethodId: result.paymentMethod.id,
			})
		}
	}

	useEffect(() => {
		if (paymentMethods.length > 0) {
			onGetUser()
		}
	}, [paymentMethods])

	return (
		<div>
			<div className=''>
				<div className='text-sm'>Enter card info below</div>

				<form onSubmit={handleAddCardSubmit}>
					<CardElement options={cardElementOptions} />
					{!loadingAddition && !stripeLoading && !loadingUser && (
						<div>
							<Button
								className="px-10 mt-4 py-1"
								text='Add Card'
								size='sm'
								submit={true}
							/>
						</div>
					)}
					{loadingAddition && (
						<div>
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						</div>
					)}

					{stripeLoading && (
						<div>
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						</div>
					)}
					{loadingUser && (
						<div>
							<BounceLoader
								className='m-auto'
								color={bounceLoaderColor}
							></BounceLoader>
						</div>
					)}
				</form>
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Payments }) => ({
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
	onStorePaymentMethod: (paymentMethodId) =>
		dispatch(storePaymentMethod(paymentMethodId)),
	onResetCardAdded: () => dispatch(resetCardAdded()),
	onGetPaymentMethods: (userStripeId) =>
		dispatch(getPaymentMethods(userStripeId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm)
