import { call, put, takeEvery } from 'redux-saga/effects'
import { getFAQ } from '../../model/faq'
import { getFAQSuccess, getFAQFail } from './actions'
import { GET_FAQ } from './actionTypes'

function* fetchFAQ(action) {
	try {
		const faq = yield call(getFAQ, action.payload)
		yield put(getFAQSuccess(faq))
	} catch (error) {
		yield put(getFAQFail(error))
	}
}

function* faqSaga() {
	yield takeEvery(GET_FAQ, fetchFAQ)
}

export default faqSaga
