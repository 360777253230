// import PropTypes from 'prop-types'
import React, {useEffect} from 'react'

import { connect } from 'react-redux'

import {
	getUser,
	registerUser,
	socialLoginGoogle,
	socialRegisterGoogle,
} from '../../store/actions'
import Header from '../../components/Header'
import RegisterCard from '../../components/RegisterCard'
import { Footer } from '../../components'
import { useNavigate } from 'react-router-dom'

const Register = (props) => {
	const navigate = useNavigate()

	const RandomizedRedirectRoute = () => {
		const randomValue = Math.random()
	  
		// Redirect 50% of users to '/sign-up-b'
		if (randomValue < 0.25) {
			navigate('/sign-up-b')
		} 

	}

	useEffect(() => {
		const currentPath = location.pathname
		if (!currentPath.includes('/membership')) {
			RandomizedRedirectRoute()
		}
	}, [location?.pathname])

	return (
		<>
			<Header label="Sign Up" showBackButton={false} />
			<div className='h-full w-full flex'>
				<div className='w-full h-full flex flex-col items-center justify-center bg-gradient-to-tr to-yellow-400 from-yellow-600 lg:w-1/2'>
					<div className=''>
						<RegisterCard />
					</div>
				</div>
				<div className='h-full w-full hidden lg:overflow-hidden lg:w-1/2 lg:flex'>
					<img
						className='overflow-hidden object-cover h-full w-full'
						src='https://i.imgur.com/31JfaBe.jpg'
						alt='DeliverZero Reusable Containers'
					/>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = ({ User, Account, Path, Login }) => ({
	user: User.user,
	loggedIn: User.loggedIn,
	registrationLoading: Account.loading,
	registrationAttemptStatus: Account.registrationAttemptStatus,
	userLoading: User.loading,
	registrationError: Account.registrationError,
	path: Path.path,
	loginLoading: Login.loading,
	loginError: Login.error,
	loginAttemptStatus: Login.loginAttemptStatus,
})

const mapDispatchToProps = (dispatch) => ({
	onRegisterUser: (user) => dispatch(registerUser(user)),
	onGoogleLogin: (res) => dispatch(socialLoginGoogle(res)),
	onGoogleRegister: (payload) => dispatch(socialRegisterGoogle(payload)),
	onGetUser: () => dispatch(getUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
