import {
	GET_RAAS_MERCHANT,
	GET_RAAS_MERCHANT_SUCCESS,
	GET_RAAS_MERCHANT_ERROR,
	GET_RAAS_CUSTOMER,
	GET_RAAS_CUSTOMER_SUCCESS,
	GET_RAAS_CUSTOMER_ERROR,
	INSERT_RAAS_TRANSACTION,
	INSERT_RAAS_TRANSACTION_SUCCESS,
	INSERT_RAAS_TRANSACTION_ERROR,
	GET_ALL_RAAS_MERCHANTS,
	GET_ALL_RAAS_MERCHANTS_SUCCESS,
	GET_ALL_RAAS_MERCHANTS_ERROR,
	RUN_AGGREGATION,
	RUN_AGGREGATION_ERROR,
	RUN_AGGREGATION_SUCCESS,
} from './actionTypes'

// Get RAAS Merchant
export const getRAASMerchant = () => ({
	type: GET_RAAS_MERCHANT
})

// Get RAAS Merchant Success
export const getRAASMerchantSuccess = (payload) => ({
	type: GET_RAAS_MERCHANT_SUCCESS,
	payload: payload
})

// Get RAAS Merchant Error
export const getRAASMerchantError = (error) => ({
	type: GET_RAAS_MERCHANT_ERROR,
	error: error
})

// Get RAAS Customer
export const getRAASCustomer = () => ({
	type: GET_RAAS_CUSTOMER
})

// Get RAAS Customer Success
export const getRAASCustomerSuccess = (payload) => ({
	type: GET_RAAS_CUSTOMER_SUCCESS,
	payload: payload
})

// Get RAAS Customer Error
export const getRAASCustomerError = (error) => ({
	type: GET_RAAS_CUSTOMER_ERROR,
	error: error
})

// Insert RAAS Transaction
export const insertRAASTransaction = (payload) => ({
	type: INSERT_RAAS_TRANSACTION,
	payload: payload
})

// Insert RAAS Transaction Success
export const insertRAASTransactionSuccess = (payload) => ({
	type: INSERT_RAAS_TRANSACTION_SUCCESS,
	payload: payload
})

// Insert RAAS Transaction Error
export const insertRAASTransactionError = (error) => ({
	type: INSERT_RAAS_TRANSACTION_ERROR,
	error: error
})

// Get All RAAS Merchants
export const getAllRAASMerchants = () => ({
	type: GET_ALL_RAAS_MERCHANTS
})

// Get All RAAS Merchants Success
export const getAllRAASMerchantsSuccess = (payload) => ({
	type: GET_ALL_RAAS_MERCHANTS_SUCCESS,
	payload: payload
})

// Get All RAAS Merchants Error
export const getAllRAASMerchantsError = (error) => ({
	type: GET_ALL_RAAS_MERCHANTS_ERROR,
	error: error
})
