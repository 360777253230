import React from "react";
import { Link } from "react-router-dom";

function UserProfileOrders({ orders = [] }) {
  if (!orders.length) return <div className="text-center">No orders yet</div>;
  return (
    <div className="">
      {orders.map((order, i) => {
        const dateString2 = order.timestamp.toLocaleString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        return (
          <div
            key={i}
            className="card flex mb-5 h-auto px-2 lg:px-5 py-4 w-full overflow-hidden"
          >
            <div className="flex flex-row w-full px-3">
              <div className="flex flex-col w-full lg:w-3/4 ">
                <div className="flex h-2/3 items-center justify-between w-full md:mb-3">
                  <span className="text-green-600 text-xl md:text-4xl font-header">
                    ${order.dzTotal.toFixed(2)}
                  </span>
                  <Link
                    className="flex items-center text-end my-3 ml-2 text-xs md:text-[14px] lg:text-[16px] underline text-green-600 font-semibold"
                    to={`/order/${order._id}`}
                  >
                    View Order
                  </Link>
                </div>
                <div className="flex w-full justify-between">
                  <div className="font-header text-sm md:text-xl lg:text-[16px]">
                    {order.nodeInfo.name}{" "}
                  </div>
                  <div className="font-header text-sm md:text-xl lg:text-[16px]">
                    {dateString2}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default UserProfileOrders;
