import React, { useState } from 'react'

const Popup = ({ children, isOpen }) => {
	if (!isOpen) {
		return null
	}

	return (
		<div className='fixed inset-0 flex flex-col bg-gray-800 bg-opacity-25 backdrop-filter backdrop-blur-sm'>
			<div className='fixed inset-0 flex flex-col items-center justify-center z-50'>
				<div className='bg-white p-2 flex flex-col rounded shadow-lg w-full h-screen mt-[3.25rem] px-5 items-center'>{children}</div>
			</div>
		</div>
	)
}

export default Popup
