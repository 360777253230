import { GET_HIW, GET_HIW_FAIL, GET_HIW_SUCCESS } from './actionTypes'

export const getHIW = () => ({
	type: GET_HIW,
})

export const getHIWSuccess = (howItWorks) => ({
	type: GET_HIW_SUCCESS,
	payload: howItWorks,
})

export const getHIWFail = (error) => ({
	type: GET_HIW_FAIL,
	payload: error,
})
