import React from "react";

import Home from "../pages/Home";
import Login from "../pages/Authentication/Login";
import Privacy from "../pages/Utility/Privacy";
import AccountLanding from "../pages/User/AccountLanding";
// import Account from "../pages/User/Account";
import MyUsage from "../pages/User/MyUsage";
import EnvironmentalImpact from "../pages/User/EnvironmentalImpact";
import ProfileInfo from "../pages/User/ProfileInfo";
import ManageAccount from "../pages/User/ManageAccount";
import LatePayments from "../pages/User/LatePayments";
import DZDirectOrders from "../pages/User/DZDirectOrders";
import Register from "../pages/Authentication/Register";
// import ReturnLogin from "../pages/Returns/ReturnLogin";
import ReturnLanding from "../pages/Returns/ReturnLanding";
import ReturnLocations from "../pages/Returns/ReturnLocations";
import ReturnPhoto from "../pages/Returns/ReturnPhoto";
import Terms from "../pages/Utility/Terms";
import NodeMenu from "../pages/Marketplace/NodeMenu";
import ViewDirectOrders from "../pages/User/ViewDirectOrders";
import DisputeBoxes from "../pages/User/DisputeBoxes";
import CheckoutPage from "../pages/Marketplace/CheckoutPage";
import ReturnAtDoor from "../pages/Returns/ReturnAtDoor";
import HowItWorks from "../pages/Utility/HowItWorks";
import WhyReuse from "../pages/Utility/WhyReuse";
// import RestaurantPartners from "../pages/Utility/RestaurantPartners";
import AboutUs from "../pages/Utility/AboutUs";
// import EnterprisePartners from "../pages/Utility/EnterprisePartners";
// import Press from "../pages/Utility/Press";
import RedirectPage from "../pages/Marketplace/RedirectPage";
// import AdminLogin from "../pages/Admin/AdminLogin";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
// import AdminHome from "../pages/Admin/AdminHome";
// import AdminRestaurants from "../pages/Admin/Restaurants";
// import AddRestaurant from "../pages/Admin/AddRestaurant";
// import AdminReturnFeed from "../pages/Admin/ReturnFeed";
// import RestaurantProfile from "../pages/Admin/RestaurantProfile";
// import RestaurantPayments from "../pages/Admin/RestaurantPayments";
// import AdminOrders from "../pages/Admin/Orders";
// import ViewOneOrder from "../pages/Admin/ViewOneOrder";
// import AdminReturns from "../pages/Admin/AdminReturns";
// import AdminCustomers from "../pages/Admin/Customers";
// import ViewOneCustomer from "../pages/Admin/ViewOneCustomer";
// import OAuthFlow from "../pages/Authentication/OAuthFlow";
// import RestaurantMenuMap from "../pages/Admin/RestaurantMenuMap";
// import AdminAnalytics from "../pages/Admin/AdminAnalytics";
// import AdminAnalyticsOrders from "../pages/Admin/AnalyticsOrders";
// import AdminAnalyticsEnvironmental from "../pages/Admin/AnalyticsEnvironment";
// import AdminCreateReturn from "../pages/Admin/CreateReturn";
// import AdminRaas from "../pages/Admin/AdminRaas";
import GiftCardPurchase from "../pages/Marketplace/GiftCardPurchase";
// import PromoCreate from "../pages/Admin/PromoCreate";
// import RestaurantCharges from "../pages/Admin/RestaurantCharges";
// import RestaurantAdminHome from "../pages/RestaurantAdmin/RestaurantAdminHome";
// import RestaurantAdminOrders from "../pages/RestaurantAdmin/RestaurantAdminOrders";
// import RestaurantViewTransaction from "../pages/RestaurantAdmin/RestaurantViewTransaction";
// import RestaurantImpact from "../pages/RestaurantAdmin/RestaurantImpact";
// import RestaurantViewPayments from "../pages/RestaurantAdmin/RestaurantViewPayments";
// import RestaurantViewCharges from "../pages/RestaurantAdmin/RestaurantViewCharges";
// import RestaurantAdminVerification from "../pages/RestaurantAdmin/RestaurantAdminVerification";
// import RestaurantCreditCardPage from "../pages/RestaurantAdmin/RestaurantCreditCardPage";
// import RestaurantRequestContainers from "../pages/RestaurantAdmin/RestaurantRequestContainers";
import ThirdPartyPickup from "../pages/Returns/ThirdPartyPickup";
// import CheckBoxesOutModule from "../pages/RestaurantAdmin/CheckBoxesOutModule";
// import OrderUpload from "../pages/Admin/OrderUpload";
// import MenuUpload from "../pages/Admin/MenuUpload";
// import DropoffWidget from "../pages/Admin/DropoffWidget";
// import DropoffHome from "../pages/Admin/DropoffHome";
import WholeFoods from "../pages/Marketplace/WholeFoodsLanding";
import ToastPressRelease from "../pages/Utility/ToastPressRelease";
// import RouteCreator from "../pages/Admin/RouteCreator";
import RewardsMarketplace from "../pages/Marketplace/RewardsMarketplace";
// import OfficeAdminHome from "../pages/OfficeOrdering/OfficeAdminHome";
// import OfficeOrders from "../pages/OfficeOrdering/OfficeOrders";
// import OfficeCustomers from "../pages/OfficeOrdering/OfficeCustomers";
// import OfficeViewOneOrder from "../pages/OfficeOrdering/OfficeViewOneOrder";
// import OfficeRegistration from "../pages/OfficeOrdering/OfficeRegistration";
// import OfficeViewOneCustomer from "../pages/OfficeOrdering/OfficeViewOneCustomer";
// import OfficeLogin from "../pages/OfficeOrdering/OfficeLogin";
import EmptyCart from "../pages/Marketplace/EmptyCart";
import RegisterCC from "../pages/Authentication/RegisterCC";
import Membership from "../pages/User/Membership";
import MembershipLanding from "../pages/Utility/MembershipLanding";
import MembershipJoin from "../pages/Utility/MembershipJoin";

const userRoutes = [
  // { path: "/user/account", component: <Account /> },
  { path: "/returns/pickup", component: <ReturnAtDoor /> },
  { path: "/returns/third-party", component: <ThirdPartyPickup /> },
  { path: "/order/:orderId", component: <ViewDirectOrders /> },
  { path: "/dispute/:transactionId", component: <DisputeBoxes /> },
  { path: "/checkout", component: <CheckoutPage /> },
  { path: "/marketplace/:nodeSlug", component: <NodeMenu /> },
  // { path: "/admin/login", component: <AdminLogin /> },
  // { path: "/admin/home", component: <AdminHome /> },
  // { path: "/admin/restaurants", component: <AdminRestaurants /> },
  // { path: "/admin/restaurants/payments", component: <RestaurantPayments /> },
  // { path: "/admin/restaurants/charges", component: <RestaurantCharges /> },
  // { path: "/admin/restaurants/:nodeSlug", component: <RestaurantProfile /> },
  // {
  //   path: "/admin/restaurants/:nodeSlug/menu-map",
  //   component: <RestaurantMenuMap />,
  // },
  // { path: "/admin/restaurants/add", component: <AddRestaurant /> },
  // { path: "/admin/customers", component: <AdminCustomers /> },
  // { path: "/admin/customers/:userId", component: <ViewOneCustomer /> },
  // { path: "/admin/orders", component: <AdminOrders /> },
  // { path: "/admin/orders/:orderId", component: <ViewOneOrder /> },
  // { path: "/admin/returns", component: <AdminReturns /> },
  // { path: "/admin/returns/create", component: <AdminCreateReturn /> },
  // { path: "/admin/return-feed", component: <AdminReturnFeed /> },
  // { path: "/admin/analytics", component: <AdminAnalytics /> },
  // { path: "/admin/dropoff", component: <DropoffHome /> },
  // { path: "/admin/dropoff-create", component: <DropoffWidget /> },
  // { path: "/admin/analytics/orders", component: <AdminAnalyticsOrders /> },
  // {
  //   path: "/admin/analytics/environment",
  //   component: <AdminAnalyticsEnvironmental />,
  // },
  // { path: "/admin/route-creator", component: <RouteCreator /> },
  // { path: "/admin/promocreate", component: <PromoCreate /> },
  // { path: "/admin/order-upload", component: <OrderUpload /> },
  // { path: "/admin/menu-upload", component: <MenuUpload /> },
  // { path: "/admin/raas", component: <AdminRaas /> },
  // { path: "/restaurant-admin/orders/", component: <RestaurantAdminOrders /> },
  // {
  //   path: "/restaurant-admin/orders/:transactionId",
  //   component: <RestaurantViewTransaction />,
  // },
  // { path: "/restaurant-admin/home/", component: <RestaurantAdminHome /> },
  // {
  //   path: "/restaurant-admin/verification/",
  //   component: <RestaurantAdminVerification />,
  // },
  // { path: "/restaurant-admin/impact/", component: <RestaurantImpact /> },
  // {
  //   path: "/restaurant-admin/payments/",
  //   component: <RestaurantViewPayments />,
  // },
  // {
  //   path: "/restaurant-admin/charges/",
  //   component: <RestaurantViewCharges />,
  // },
  // {
  //   path: "/restaurant-admin/charges/credit-card-form",
  //   component: <RestaurantCreditCardPage />,
  // },
  // {
  //   path: "/restaurant-admin/orders/date/:startDateParam/:endDateParam",
  //   component: <RestaurantAdminOrders />,
  // },
  // {
  //   path: "/restaurant-admin/request-containers/",
  //   component: <RestaurantRequestContainers />,
  // },
  // {
  //   path: "/restaurant-admin/checkout-containers/",
  //   component: <CheckBoxesOutModule />,
  // },
  // {
  //   path: "/office-ordering/home",
  //   component: <OfficeAdminHome />,
  // },
  // {
  //   path: "/office-ordering/orders",
  //   component: <OfficeOrders />,
  // },
  // {
  //   path: "/office-ordering/orders/:transactionId",
  //   component: <OfficeViewOneOrder />,
  // },
  // {
  //   path: "/office-ordering/customers",
  //   component: <OfficeCustomers />,
  // },
  // {
  //   path: "/office-ordering/customers/:phone",
  //   component: <OfficeViewOneCustomer />,
  // },
  { path: "/user/myusage", component: <MyUsage /> },
  { path: "/user/environmentalimpact", component: <EnvironmentalImpact /> },
  { path: "/user/profileinfo", component: <ProfileInfo /> },
  { path: "user/manageaccount", component: <ManageAccount /> },
  { path: "/user/dzdirectorders", component: <DZDirectOrders /> },
];

const authRoutes = [
  { path: "/returns/locations", component: <ReturnLocations /> },
  { path: "/", component: <Home /> },
  { path: "/orders", component: <Home /> },
  { path: "/login", component: <Login /> },
  { path: "/sign-up", component: <Register /> },
  { path: "/login/membership", component: <Login /> },
  { path: "/sign-up/membership", component: <Register /> },
	{ path: '/sign-up-b', component: <RegisterCC /> },
  { path: "/returns", component: <ReturnLanding /> },
  { path: "/returns/:nodeSlug", component: <ReturnPhoto /> },
  // { path: "/returns/login", component: <ReturnLogin /> },
  { path: "/privacy", component: <Privacy /> },
  { path: "/terms", component: <Terms /> },
  { path: "/howitworks", component: <HowItWorks /> },
  { path: "/whyreuse", component: <WhyReuse /> },
  // { path: "/restaurantpartners", component: <RestaurantPartners /> },
  { path: "/aboutus", component: <AboutUs /> },
  // { path: "/enterprise", component: <EnterprisePartners /> },
  // { path: "/press", component: <Press /> },
  {
    path: "/marketplace/cart",
    component: <EmptyCart />,
  },
  {
    path: "/redirect/:nodeSlug",
    component: <RedirectPage />,
  },
  { path: "/marketplace/:nodeSlug", component: <NodeMenu /> },
  { path: "/checkout", component: <CheckoutPage /> },
  { path: "/forgotpassword", component: <ForgotPassword /> },
  { path: "/reset", component: <ResetPassword /> },
  // { path: "/:client/oauth/authorize", component: <OAuthFlow /> },
  { path: "/giftCard", component: <GiftCardPurchase /> },
  { path: "/wholefoods", component: <WholeFoods /> },
  // { path: "/toast-press-release", component: <ToastPressRelease /> },
  { path: "/rewards-marketplace", component: <RewardsMarketplace /> },
  // {
  //   path: "/office-ordering/sign-up",
  //   component: <OfficeRegistration />,
  // },
  // {
  //   path: "/office-ordering/login",
  //   component: <OfficeLogin />,
  // },
  { path: "/user/accountLanding", component: <AccountLanding /> },
  { path: '/late-payments', component: <LatePayments /> },
  { path: "/user/membership", component: <Membership /> },
  { path: "/membership", component: <MembershipLanding /> },
  { path: "/membership/join", component: <MembershipJoin /> },
];

export { userRoutes, authRoutes };
