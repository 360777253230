import React from "react";
import { BounceLoaderCentered } from "../Elements";
import { BsFillTreeFill } from "react-icons/bs";
import { IoIosWater } from "react-icons/io";

const EnvironmentalImpactCard = ({
  packagingPrevented = 0,
  environmentalImpact = null,
}) => {
  if (environmentalImpact != null) {
    const ghgReduction =
      packagingPrevented *
      environmentalImpact.gramsEmissionsReductionPerPackage;
    const waterReduction =
      packagingPrevented * environmentalImpact.gallonsWaterReductionPerPackage;
    return (
      <div className="w-5/6">
        <div className="mt-3 text-xl">So far you've prevented</div>
        <div className="flex flex-row">
          <div className="text-[80px] font-header w-min lg:pb-1 mr-3 text-green-600">
            {packagingPrevented}
          </div>
          <div className="my-auto w-[250px]">
            single-use boxes from harming our planet
          </div>
        </div>
        {environmentalImpact && (
          <div>
            <div className="text-xl mb-3 mt-5">
              That's equivalent to avoiding an estimated
            </div>
            {ghgReduction.toString().length > 3 && (
              <div className="mt-5 flex items-center">
                <BsFillTreeFill className="mr-2 inline-block text-green-600 text-[32px]" />
                <span className="text-2xl font-header w-min mr-1 text-green-600">
                  {ghgReduction / 1000}
                </span>
                <span className="text-base">
                  {" "}
                  kg of greenhouse gas emissions
                </span>
              </div>
            )}
            {ghgReduction.toString().length <= 3 && (
              <div className="mt-5 flex items-center">
                <BsFillTreeFill className="mr-2 inline-block text-green-600 text-[32px]" />
                <span className="text-2xl font-header w-min mr-1 text-green-600">
                  {ghgReduction}
                </span>
                <span className="text-base">
                  g of greenhouse gas emissions
                </span>
              </div>
            )}
            <div className="mt-5 flex items-center">
              <IoIosWater className="mr-2 inline-block text-blue-600 text-[32px] " />
              <span className="text-2xl leading-none font-header w-min mr-1 text-blue-600">
                {waterReduction}
              </span>
              <span className="text-base">
                gallons of water{" "}
              </span>
            </div>
            <div className="mt-1 text-[12px]">
              The above values are estimates based on best available research
            </div>
          </div>
        )}
        {!environmentalImpact && (
          <div className="mt-3 text-center">
            <BounceLoaderCentered container="div" />
          </div>
        )}

        {/*<div className="hidden mt-5 lg:inline-block">*/}
        {/*    <Button link="/why-reuse" text="Why Reuse?" className="mt-6"/></div>*/}
        {/*<Link to="/why-reuse" className="inline-block lg:hidden my-3 text-[16px] underline text-green-600 font-semibold">Find out more about how reuse benefits our climate and planet.</Link>*/}
      </div>
    );
  }
};

export default EnvironmentalImpactCard;
