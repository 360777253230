import { connect } from 'react-redux'
import { Footer, Header } from '../../components'
import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Checkout from '../../components/Marketplace/Checkout'

const CheckoutPage = (props) => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

	// if (user && cartItems > 0) {
	return (
		<div>
			<Header label="Review Your Order" showBackButton={true}/>
			<div className="mt-[3.25rem]">
				<Elements stripe={stripePromise}>
					<Checkout />
				</Elements>
			</div>
		</div>
	)
}

const mapStateToProps = ({ User, Cart, Payments }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage)
