import React, {createRef, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {
    getNodes,
    getGPSLocation,
    getUser,
    captureGPS,
} from '../../store/actions'
import {
    calculateDistanceBetweenCoords,
    googleMapsLibraries,
} from '../../helpers/googleMapsAPI'
import {
    Alert,
    Button,
    BounceLoaderCentered,
} from '../../components/Elements'
import {BounceLoader} from "react-spinners";
import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api'
import {useNavigate} from 'react-router-dom'
import {realm} from '../../helpers/realm'
import DropOffSearch from '../../components/DropOffSearch'
import DistanceBar from '../../components/DistanceBar'
import {FaRegWindowMinimize, FaGripLines, FaCaretDown, FaCaretUp, FaRoute, FaMapMarkerAlt} from 'react-icons/fa'
import { Header } from '../../components'
import { MdClear } from 'react-icons/md'
import Draggable from "react-draggable";

const ReturnLocations = (props) => {
    const {
        onGetNodes,
        userLocation,
        nodes,
        user,
        onGetUser,
        onGetGPSLocation,
        gpsLocationFound,
        userLocationError,
        loadingLocation,
		onCaptureGPS,
    } = props

    let navigate = useNavigate()

    const [deliveryAddress, setDeliveryAddress] = useState('')
    const [deliveryLatLng, setDeliveryLatLng] = useState(null)
    const [mapBool, setMapBool] = useState(false)
    const [postSearchNodes, setPostSearchNodes] = useState([])
    const [restaurantInput, setRestaurantInput] = useState('')
    const [selectedMapNode, setSelectedMapNode] = useState({name: ''})
    const [selectedZoom, setSelectedZoom] = useState(12)
    const [selectedCenterCoords, setSelectedCenterCoords] = useState({
        lat: 40.7128,
        lng: -74.006,
    })
    const [locationWarning, setLocationWarning] = useState(false)
    const [mapFilter, setMapFilter] = useState({})
    const [searchFilter, setSearchFilter] = useState({})
    const [addressFilter, setAddressFilter] = useState({})
    const [cupsFilter, setCupsFilter] = useState(false)
    const [distanceBarValue, setDistanceBarValue] = useState(10)
    const defaultDistanceBarValue = distanceBarValue
    const [sortByType, setSortByType] = useState('distance')
    const [email, setEmail] = useState('')
    const [submittingEmail, setSubmittingEmail] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [activeGPS, setActiveGPS] = useState(true)
    const [toggleMap, setToggleMap] = useState(true)
    const [showWarning, setShowWarning] = useState(false)
    const [filter, setFilter] = useState({
        // type: {
        //   $in: [NodeTypes.bin],
        // },
        returnBinEnabled: {
            $in: [true],
        },
        enabled: {
            $nin: [false],
        },
    })
    const [initialLoad, setInitialLoad] = useState(true)
    const google = window.google
    const bounceLoaderColor = "#507f74"
    let resultRef = createRef()

    const [sectionHeight, setSectionHeight] = useState(400); 
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const smallScreenBreakpoint = 640;
    const isSmallScreen = window.innerWidth < smallScreenBreakpoint;
    const [dayOfWeek, setDayOfWeek] = useState("");
    const [openHoursDropDown, setOpenHoursDropDown] = useState({});
    const [isHoliday, setIsHoliday] = useState(false);
	const [distPopupVisible, setDistPopupVisible] = useState(false)
	const [distanceInfo, setDistanceInfo] = useState('')
    const usBankHolidays = [
        "Jan 1",
        "Jan 15",
        "Feb 19",
        "May 27",
        "Jun 19",
        "Jul 4",
        "Sep 2",
        "Oct 9",
        "Nov 11",
        "Nov 23",
        "Dec 25",
    ]; // oct 2023 - oct 2024 dates
    const daysOfWeekMap = { 0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 }; // map from Date().getDay() (0-6=Sun-Sat) to google's openHours (0-6=Mon-Sun)

    // LOAD GOOGLE MAPS API
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        libraries: googleMapsLibraries,
    })

    useEffect(() => {
        onGetUser();
    }, [onGetUser]);

    // initial location services check
	useEffect(() => {
        const currentDate = new Date()
        const dateOptions = { month: "short", day: "numeric" }
        const formattedDate = currentDate.toLocaleDateString("en-US", dateOptions)
        setDayOfWeek(currentDate.getDay())
        if (usBankHolidays.includes(formattedDate)) {
            setIsHoliday(true)
        }
		setInitialLoad(true)
        if(userLocation) {
            setSelectedCenterCoords({
                lat: userLocation.lat,
                lng: userLocation.lng,
            })
            reverseGeocodeAddress({
                lat: userLocation.lat,
                lng: userLocation.lng,
            })
            setDeliveryLatLng({
                lat: userLocation.lat,
                lng: userLocation.lng,
            })
            setLocationWarning(false)
            setInitialLoad(false)
        } else {
            onGetGPSLocation()
			setLocationWarning(true)
            setInitialLoad(false)
		}
	}, [])

    //* STEP ONE: GET USER LOCATION ON LOAD
    useEffect(() => {
        if(!userLocation && userLocationError==="User denied Geolocation"){
            const lastSearchedAddress = JSON.parse(
                localStorage.getItem("lastSearchedAddress")
            );
            if (lastSearchedAddress && lastSearchedAddress.address) {
                setDeliveryAddress(lastSearchedAddress.address)
            } else if (user && user.lastSearchedAddress) {
                setDeliveryAddress(user.lastSearchedAddress)
            } else if (user && user.address) {
                setDeliveryAddress(user.address)
            }  else {
                setDeliveryAddress('43 Park Row, New York, NY 10038')
            }
        } else {
            setLocationWarning(false)
            setShowWarning(false)
        }
        if (activeGPS) {
            setActiveGPS(false)
        }

        if (gpsLocationFound && userLocation) {
            setActiveGPS(false)
            setSelectedCenterCoords({
                lat: userLocation.lat,
                lng: userLocation.lng,
            })
        }
    }, [gpsLocationFound, activeGPS, userLocationError, user, navigator, JSON.stringify(navigator)])

    const makeGPSRequest = async () => {
        await onGetGPSLocation()
        setActiveGPS(true)
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                setSelectedCenterCoords({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
                reverseGeocodeAddress({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
                setDeliveryLatLng({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
                setLocationWarning(false)
              },
              (error) => {
                  setLocationWarning(true)
                  setShowWarning(true)
                  setInitialLoad(false)
              }
          )
      } else {
          setLocationWarning(true)
          setInitialLoad(false)
      }
    }

    const handleGPSRequest = () => {
		makeGPSRequest()
	}

    // CENTER MAP ON USER LOCATION, IF NO USER LOCATION, CENTER ON DEFAULT LOCATION & ZOOM, SHOW ALERT
    useEffect(() => {
        if (userLocation) {
            setSelectedCenterCoords({
                lat: userLocation.lat,
                lng: userLocation.lng,
            })
            if (gpsLocationFound) {
                setLocationWarning(false)
                setShowWarning(false)
            } else {
                setLocationWarning(true)
                setShowWarning(true)
            }
            setSelectedZoom(13)
        } else if (deliveryAddress) {

        } else {
            setSelectedCenterCoords({
                lat: 40.7128,
                lng: -74.006,
            })
            setSelectedZoom(6)
            setLocationWarning(true)
            setShowWarning(true)
        }
        // createFilter()
    }, [userLocation])

    useEffect(() => {
		if (user && user.phone) {
			onCaptureGPS({phone: user.phone})
		}
	}, [user])

    // GET NODES NEAR USER
    useEffect(() => {
        if (userLocation) {
            createFilter()
        } else if (deliveryAddress) {
            createFilter()
        }
    }, [
        filter,
        mapFilter,
        searchFilter,
        userLocation,
        sortByType,
        addressFilter,
        distanceBarValue,
        cupsFilter,
    ])

	const handleReturnCups = () => {
		setCupsFilter(!cupsFilter)
	}

    // COMBINE FILTER ARRAY AND GET NODES
    const createFilter = () => {
        const combinedFilter = [filter]
        const geoFilter = getNearestNodes();
        if (mapBool) {
            combinedFilter.push(searchFilter, mapFilter)
        } else if (deliveryAddress && deliveryAddress != '') {
            combinedFilter.push(searchFilter, addressFilter)
        } else {
            if (geoFilter) {
                combinedFilter.push(geoFilter, searchFilter)
            } else {
                combinedFilter.push(searchFilter)
            }
        }

		if (user && user.franchiseeSlug) {
			combinedFilter.push({
				franchiseeSlug: user.franchiseeSlug
			})
		}
        
		if (cupsFilter) {
			combinedFilter.push({
				cupReturns: true
			})
		}
        // console.log(combinedFilter)
        onGetNodes(combinedFilter)
    }

    // MAP FILTER ON SELECT POINT
    const onMapSelect = (node) => {
        // setDeliveryAddress('')
        setSelectedMapNode(node)
        setMapBool(true)
        setMapFilter({
            coordinates: {
                $near: {
                    $geometry: {
                        type: 'Point',
                        coordinates: [node.coordinates[0], node.coordinates[1]],
                    },
                },
            },
        })
        document.getElementById('results-scroller').scroll(0, 0)
    }

    const locationValidation = (node) => {
        navigate(`/returns/${node.slug}`)
    }

    const getNearestNodes = () => {
        const geoFilter = {
            coordinates: {
                $near: {
                    $geometry: {
                        type: 'Point',
                        coordinates: [
                            selectedCenterCoords.lng,
                            selectedCenterCoords.lat,
                        ],
                    },
                    // $minDistance: 3000000,
                    $maxDistance: distanceBarValue * 1609.34,
                },
            },
        }
        if (userLocation) {
            geoFilter.coordinates.$near.$geometry.coordinates = [
                userLocation.lng,
                userLocation.lat,
            ]
            // setDeliveryLatLng({lat: userLocation.lat, lng: userLocation.lng})
        } else {
            geoFilter.coordinates.$near.$geometry.coordinates = [
                selectedCenterCoords.lng,
                selectedCenterCoords.lat,
            ]
            // setDeliveryLatLng({
            //     lat: selectedCenterCoords.lat,
            //     lng: selectedCenterCoords.lng,
            // })
        }
        return geoFilter
    }

    const addressHandler = (address, latLng) => {
        setDeliveryAddress(address)
        setDeliveryLatLng(latLng)
        if (address) {
            // if(latLng) {
            setSearchFilter({})
            setRestaurantInput('')
            setAddressFilter({
                coordinates: {
                    $near: {
                        $geometry: {
                            type: 'Point',
                            coordinates: [latLng.lng, latLng.lat],
                        },
                        // $minDistance: 3000000,
                    },
                },
            })
            // } else {
            //     latLng = window.google.maps.Geocoder(address)
            //     console.log(latLng)
            // }
        } else {}
    }

    const addressCheck = (address, latLng) => {
        setDeliveryAddress(address)
        setDeliveryLatLng(latLng)
        let tempArray = []
        if (latLng) {
            nodes.map((node) => {
                //For the returns map page
                const distanceToCoordMiles =
                    calculateDistanceBetweenCoords(
                        [latLng.lat, latLng.lng],
                        node.coordinates
                    ) / 1609.34
                //Filter by distance again in case they've clicked by
                if (distanceToCoordMiles <= distanceBarValue) {
                    node['distanceToCoord'] = distanceToCoordMiles
                    tempArray.push(node)
                }
            })
            let sortedNodes = tempArray
            if (!mapBool) {
                if (sortByType == 'name') {
                    sortedNodes = sortedNodes.sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                } else if (sortByType == 'distance') {
                    sortedNodes = sortedNodes.sort(
                        (a, b) => a.distanceToCoord - b.distanceToCoord
                    )
                }
            }

            setPostSearchNodes(sortedNodes)
            if (mapBool == false) {
                setSelectedCenterCoords({
                    lat: latLng.lat,
                    lng: latLng.lng,
                })
                setSelectedZoom(12)
            }
        } else {
            let sortedNodes = nodes
            if (!mapBool) {
                if (sortByType == 'name') {
                    sortedNodes = sortedNodes.sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                } else if (sortByType == 'distance') {
                    sortedNodes = sortedNodes.sort(
                        (a, b) => a.distanceToCoord - b.distanceToCoord
                    )
                }
            }
            setPostSearchNodes(sortedNodes)
        }
        // }
    }

    useEffect(() => {
        if(deliveryAddress) {
            // console.log(deliveryAddress)
            // console.log(deliveryLatLng)
            addressCheck(deliveryAddress, deliveryLatLng)
        }
    }, [nodes, initialLoad, deliveryLatLng, deliveryAddress])

    const onSearchChange = (query) => {
        setMapBool(false)
        if (selectedZoom > 12) {
            setSelectedZoom(12)
        }
        setSelectedMapNode({name: ''})
        setRestaurantInput(query.target.value)
        if (query.target.value !== '') {
            setSearchFilter({
                $or: [
                    {
                        name: {
                            $regex: query.target.value,
                            $options: 'i',
                        },
                    },
                    {
                        slug: {
                            $regex: query.target.value,
                            $options: 'i',
                        },
                    },
                    {
                        city: {
                            $regex: query.target.value,
                            $options: 'i',
                        },
                    },
                    {
                        address: {
                            $regex: query.target.value,
                            $options: 'i',
                        },
                    },
                    {
                        cuisines: {
                            $in: [new RegExp(query.target.value, 'i')],
                        },
                    },
                ],
            })
        } else {
            setSearchFilter({})
        }
    }

    const changeMapBoolToFalse = () => {
        setMapBool(false)
    }

    const onNameQuery = (name) => {
        onSearchChange(name)
    }

    const handleEmailChange = (emailChange) => {
        setEmail(emailChange.target.value)
    }

    const handleSubscribe = async () => {
        setEmailError('')
        setEmailSuccess(false)
        setSubmittingEmail(true)
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (re.test(email)) {
            const subscriberStatus = await realm.currentUser.callFunction(
                'mailChimp/AddUserToEmailList',
                {
                    email: email,
                    phoneNumber: user ? user.phone : '',
                    firstName: user ? user.firstName : '',
                    lastName: user ? user.lastName : '',
                }
            )
            if (subscriberStatus == 'error') {
                setSubmittingEmail(false)
                setEmailError('An error occured. Try again.')
            } else {
                setEmailSuccess(true)
                setSubmittingEmail(false)
            }
        } else {
            // invalid email, maybe show an error to the user.
            setSubmittingEmail(false)
            setEmailError('Invalid email address. Try again.')
        }
    }

    const handleDismiss = () => {
        setShowWarning(false)
    }

    const reverseGeocodeAddress = async (coords) => {
		let geocoder = new google.maps.Geocoder()
		await geocoder.geocode({ location: coords }, function (results, status) {
		  if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            setDeliveryAddress(results[0].formatted_address)
		  } else {
			alert("Invalid address")
		  }
		})
	}

    const handleDrag = (e, data) => {
        const changeInHeight = data.y - position.y
        let newHeight = sectionHeight + changeInHeight
        if (newHeight > 545){
            setPosition({y: 545})
            setSectionHeight(545);
        }else {
            setPosition({ y: newHeight })
            setSectionHeight(newHeight);
        }
    }

    const formatTodayHours = (input) => {
        if (typeof input === "string") {
            input = input.replace(/.*day/, "Today's Hours");
            return input;
        }
        return toString(input);
    };

    const shortenDay = (day) => {
        if (typeof day === "string") {
            const shortform = day.slice(0, 3);
            day = day.replace(/.*day/, shortform);
        }
        return day;
    };

	const handleMarkerClick = async (node) => {
		const originLat = deliveryLatLng.lat || userLocation.lat
		const originLng = deliveryLatLng.lng || userLocation.lng

		if (!originLat || !originLng) {
			console.error('Origin location is not available')
			return
		}

		onMapSelect(node)
		setSelectedZoom(15)
		setSelectedCenterCoords({
			lat: node.coordinates[1],
			lng: node.coordinates[0],
		})
        window.scrollTo({
        top: 0,
        behavior: "smooth",
        })
		setDistanceInfo({
			originLat: originLat,
			originLng: originLng,
			destLat: node.coordinates[1],
			destLng: node.coordinates[0],
			distance: node.distanceToCoord
				.toFixed(2)
				.toString()
				.substring(0, 4),
		})
		setDistPopupVisible(true)
	}

    if (!isLoaded && !userLocation) return 'loading...'
    if (initialLoad || loadingLocation) {
        return (
            <>
            <Header label={"Return In Person"} showBackButton/>
            <div className='h-full flex flex-col items-center justify-center my-10'>
                <BounceLoader
                    className='m-auto'
                    color={bounceLoaderColor}
                ></BounceLoader>
            </div>

        </>
        )
    }
    return (
        <div className='h-full w-full flex flex-col justify-between'>
            <Header label={"Return In Person"} showBackButton/>
            <div className='mt-[3.25rem]'></div>
            {locationWarning && showWarning && (
                <>
                <Alert
                    content='You have location services turned off. You may continue by searching an address below, but certain map features may behave differently.'
                    type='warning'
                    onClick={handleGPSRequest}
                />
                <MdClear
                    className='mt-2 mr-2 absolute right-2 sm:right-[5px] top-[70px] sm:top-[100px] transform -translate-y-1/2 text-white text-lg cursor-pointer text-yellow-400 bg-yellow-800 rounded-full w-5 h-5 sm:w-4 sm:h-4 flex items-center justify-center z-50'
                    onClick={handleDismiss}
                />
                </>
            )}
            <div className={`flex relative sticky top-[52px] w-full z-20 ${isSmallScreen ? 'flex-col' : 'static'}`} style={isSmallScreen && toggleMap ? { height: `${sectionHeight}px` } : { height: '110px' }}>
                {isLoaded && toggleMap && (
                    <div className='w-full md:w-1/2 mx-auto flex flex-col md:flex-row border-red-700 sm:hidden' style={ {height: `${sectionHeight}px` }}>
                        <GoogleMap
                            mapContainerStyle={{
                                overflow: 'hidden',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            zoom={selectedZoom}
                            center={selectedCenterCoords}
                            options={{
                                disableDefaultUI: true,
                                zoomControl: true, 
                                mapTypeControl: false, 
                                scaleControl: false, 
                                streetViewControl: false,
                                rotateControl: false, 
                                fullscreenControl: false, 
                            }}
                        >
                            {postSearchNodes.map((node) => {
                                if (!node.coordinates) return
                                const [lng, lat] = node.coordinates
                                if (
                                    node.name === selectedMapNode.name
                                ) {
                                    return (
                                        <Marker
                                            onClick={() => {
                                                handleMarkerClick(node)
                                            }}
                                            key={node._id.toString()}
                                            title={node.name}
                                            icon={{
                                                url: 'https://i.imgur.com/YjB79uU.png',
                                                scaledSize:
                                                    new google.maps.Size(
                                                        30,
                                                        30
                                                    ),
                                            }}
                                            name={node.name}
                                            position={{
                                                lat: Number(
                                                    lat.toString()
                                                ),
                                                lng: Number(
                                                    lng.toString()
                                                ),
                                            }}
                                        ></Marker>
                                    )
                                } else {
                                    return (
                                        <Marker
                                            onClick={() => {
                                                handleMarkerClick(node)
                                            }}
                                            key={node._id.toString()}
                                            title={node.name}
                                            icon={{
                                                url: 'https://i.imgur.com/ci46o90.png',
                                                scaledSize:
                                                    new google.maps.Size(
                                                        30,
                                                        30
                                                    ),
                                            }}
                                            name={node.name}
                                            position={{
                                                lat: Number(
                                                    lat.toString()
                                                ),
                                                lng: Number(
                                                    lng.toString()
                                                ),
                                            }}
                                        ></Marker>
                                    )
                                }
                            })}
                        </GoogleMap>
                    </div>
                )}
                <div className={`w-full ${isSmallScreen ? 'absolute top-0' : 'h-[110px] pb-2'} ${toggleMap ? 'bg-transparent' : 'bg-white'} z-20`}>
                    {isLoaded && (
                        <DropOffSearch
                            onNameQuery={onNameQuery}
                            makeGPSRequest={makeGPSRequest}
                            onSelectAddress={addressHandler}
                            changeMapBoolToFalse={changeMapBoolToFalse}
                            deliveryAddress={deliveryAddress}
                            setDeliveryAddress={setDeliveryAddress}
                            nameValue={restaurantInput}
                            onClickToggleMap={() => setToggleMap(!toggleMap)}
                        />
                    )}
                </div>
                {/*{isLoaded && toggleMap && (*/}
                {/*    <div  className={`w-full absolute bottom-0 sm:hidden drop-shadow-md`}>*/}
                {/*        <Draggable*/}
                {/*            axis="y"*/}
                {/*            position={position}*/}
                {/*            onDrag={handleDrag}*/}
                {/*            bounds={{top: 0, bottom: 545}}*/}
                {/*        >*/}
                {/*            <div className="h-4 w-full bg-white absolute bottom-0 sm:hidden rounded-b-xl mb-[-5px] z-20">*/}
                {/*                <div className="text-gray-400 flex items-center justify-center absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-[-8.5px]">*/}
                {/*                    <FaGripLines />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </Draggable>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            <div className='md:h-screen-no-header min-h-[50em] pt-3'>
                <div className='pb-2'>
                    <div className='flex flex-col items-center justify-center'>
                        <div className='text-center tracking-wider'>
                            <h1 className='text-2xl md:text-4xl font-bold font-vollkorn text-green-600 mb-1 md:mt-[40px]'>
                                Select a drop-off location
                            </h1>
                            {/*<p className='font-poppins px-4 text-xs md:text-sm mx-3'>*/}
                            {/*    NEW: To report via text, send the number of containers and your return location to <a href='sms:+17204879754'>720-487-9754</a>*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='w-[80%] max-w-[350px] flex justify-center items-center mx-auto'>
                            <a
                                href="#"
                                onClick={handleReturnCups}
                                className='text-green-600 text-base text-center my-2 w-full px-2 underline cursor-pointer'
                            >
                                {cupsFilter
                                ? 'Filter by locations that accept food containers'
                                : 'Filter by locations that accept cups'}
                            </a>
                        </div>
                        <div className='mt-2 mb-0 md:mt-4'>
                            {isLoaded && (
                            <DistanceBar
                                defaultDistanceBarValue={
                                    defaultDistanceBarValue
                                }
                                setDistanceBarValue={setDistanceBarValue}
                                distanceBarValue={distanceBarValue}
                                setMapBool={setMapBool}
                            />
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className='w-full h-full md:min-h-[32em] flex flex-col-reverse md:flex-row sm:justify-between border-t border z-0'>
                    <div className='w-full md:w-1/2 h-full px-2 md:overflow-hidden'>
                        <div className='flex justify-between items-center border-b mr-4'>
                        </div>
                        {postSearchNodes.length == 0 && (
                            <div
                                className='h-full w-full md:h-[2/3]'
                                id='results-scroller'
                                ref={resultRef}
                            >
                                <div className='mt-3 text-center'>
                                    <BounceLoaderCentered container='div'/>
                                </div>
                                <div className='mt-3'>
                                    No restaurants fit your search! Try changing
                                    your search, choosing a different address,
                                    or adjusting the distance bar.
                                </div>
                                <div className='mt-6'>
                                    Sign up for our newsletter to be informed
                                    when we are available in your area!
                                </div>
                                <div className='w-full flex flex-col justify-center items-center p-8'>
                                    {submittingEmail ? (
                                        <BounceLoaderCentered container='div'/>
                                    ) : (
                                        <>
                                            <input
                                                onChange={handleEmailChange}
                                                type='email'
                                                name='email'
                                                id='email'
                                                className='rounded-full w-full bg-[#E9EFFD] py-3 pl-4 mb-2'
                                                placeholder='Enter your email'
                                            />
                                            <Button
                                                onClick={handleSubscribe}
                                                color='red'
                                                size='sm'
                                                className='mb-4 w-full py-1'
                                                text='Sign Up'
                                            />
                                        </>
                                    )}
                                    {emailError !== '' && (
                                        <div className='text-red-400 my-2 font-semibold text-sm'>
                                            {emailError}
                                        </div>
                                    )}
                                    {emailSuccess && (
                                        <div className='text-green-400 my-2 font-semibold text-sm'>
                                            You've successfully subscribed!
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {postSearchNodes.length > 0 && (
                            <div
                                className='mb-10 sm:overflow-y-scroll scrollbar md:h-[95%] pb-[50px]'
                                id='results-scroller'
                                ref={resultRef}
                            >
                                {postSearchNodes.map((node, index) => {
                                    return (
                                        <div key={index}>
                                            <div
                                                className='flex h-45px w-full items-center p-2 border-b tracking-wider cursor-pointer relative'
                                                key={index}
                                            >
                                                <div
                                                    className='text-center w-24 shrink-0'
                                                    onClick={() => {
                                                        handleMarkerClick(node)
                                                    }}
                                                >
                                                    <img
                                                        className="h-18 w-18 md:h-24 md:w-24 shrink-0 border rounded-lg"
                                                        src={node.thumbnail}
                                                        alt="restaurant-thumbnail"
                                                    />
                                                    {node.distanceToCoord ? (
                                                        <div className="text-sm text-gray-400">
                                                            {node.distanceToCoord
                                                                .toFixed(2)
                                                                .toString()
                                                                .substring(0, 4)}{" "}
                                                            miles
                                                        </div>
                                                    ) : (
                                                        <div className="text-sm text-gray-400">
                                                            0 miles
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='pl-4 w-full flex flex-col'>
                                                    <div className='w-full flex flex-row'>
                                                    <div
                                                        className='w-full sm:w-2/3'
                                                        onClick={() => {
                                                            setToggleMap(true)
                                                            handleMarkerClick(node)
                                                        }}
                                                    >
                                                        <div className='font-medium mb-1'>
                                                            {node.name}
                                                        </div>
                                                        {node.returnInstructions && (
															<div className='text-sm font-header mb-1'>
																{
																	node?.returnInstructions
																}
															</div>
														)}
                                                        <div className='hidden lg:block'>
                                                            <div className='text-sm text-gray-400 flex flex-row items-center my-auto mb-1'>
                                                                <div>
                                                                    <FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
                                                                </div>
                                                                <div className='ml-1 text-sm text-gray-400 flex flex-col whitespace-nowrap'>
                                                                    <div className=''>
                                                                        {
                                                                            node.address
                                                                        }
                                                                        ,{' '}
                                                                        {
                                                                            node.city
                                                                        }
                                                                        ,{' '}
                                                                        {
                                                                            node.state
                                                                        }{' '}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='lg:hidden'>
                                                            {node.address &&
                                                            node.address
                                                                .length >
                                                                15 ? (
                                                                <div className='text-sm text-gray-400 flex flex-row items-center my-auto mb-1'>
                                                                    <div>
                                                                        <FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
                                                                    </div>
                                                                    <div className='ml-1 text-sm text-gray-400 flex flex-col'>
                                                                        <div className='whitespace-nowrap'>
                                                                            {
                                                                                node.address
                                                                            }
                                                                        </div>
                                                                        <div className='whitespace-nowrap'>
                                                                            {
                                                                                node.city
                                                                            }
                                                                            ,{' '}
                                                                            {
                                                                                node.state
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='text-sm text-gray-400 flex flex-row items-center my-auto mb-1'>
                                                                    <div>
                                                                        <FaMapMarkerAlt className='text-green-400 text-[14px] text-center' />
                                                                    </div>
                                                                    <div className='ml-1 text-sm text-gray-400 whitespace-nowrap'>
                                                                        <div className=''>
                                                                            {
                                                                                node.address
                                                                            }
                                                                            ,{' '}
                                                                            {
                                                                                node.city
                                                                            }
                                                                            ,{' '}
                                                                            {
                                                                                node.state
                                                                            }{' '}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {isHoliday && (
                                                            <div className="text-red-300 text-xs">
                                                                Hours may differ on holidays.
                                                            </div>
                                                        )}
                                                        {node.openHoursGoogle && Object.keys(node.openHoursGoogle).length>0 ? (
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    const updatedOpenHoursDropDowns = {
                                                                        ...openHoursDropDown,
                                                                    };
                                                                    updatedOpenHoursDropDowns[node.name] =
                                                                        !updatedOpenHoursDropDowns[node.name];
                                                                    setOpenHoursDropDown(
                                                                        updatedOpenHoursDropDowns
                                                                    );
                                                                }}
                                                            >
                                                                <div className="flex flex-row items-center text-xs w-[250px] mb-2">
                                                                    <div>
                                                                        {node.openHoursGoogle
                                                                            ? formatTodayHours(
                                                                                node.openHoursGoogle[
                                                                                    daysOfWeekMap[dayOfWeek]
                                                                                    ]
                                                                            )
                                                                            : node.openHoursDescription}
                                                                    </div>
                                                                    {node.openHoursGoogle &&
                                                                    !openHoursDropDown[node.name] && (
                                                                        <FaCaretDown className="text-green-400 text-[22px] text-center" />
                                                                    )}
                                                                    {node.openHoursGoogle &&
                                                                    openHoursDropDown[node.name] && (
                                                                        <FaCaretUp className="text-green-400 text-[22px] text-center" />
                                                                    )}
                                                                </div>
                                                                {node.openHoursGoogle &&
                                                                openHoursDropDown[node.name] &&
                                                                node.openHoursGoogle.map((day) => {
                                                                    return (
                                                                        <div
                                                                            className="mb-2 text-sm text-green-400 ml-2"
                                                                            key={day}
                                                                        >
                                                                            {shortenDay(day)}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        ) : (
                                                            <div className='text-xs text-gray-400 mb-3 '>
                                                                {
                                                                    node.openHoursDescription
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className='w-0 sm:w-1/3'
                                                        onClick={() => {
                                                            setToggleMap(true)
                                                            handleMarkerClick(node)
                                                        }}
                                                    ></div>
                                                    {/* </a> */}
                                                    </div>
                                                    <div className='flex flex-row w-full'>
                                                        <div className='w-1/2 xl:w-[215px]'>
                                                            <Button
                                                                size='sm'
                                                                className='text-sm px-6 static z-10 relative py-1'
                                                                text="Report Return"
                                                                onClick={() => {
                                                                    locationValidation(
                                                                        node
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className='grow'
                                                            onClick={() => {
                                                                setToggleMap(true)
                                                                handleMarkerClick(node)
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    {isLoaded && toggleMap && (
                        <div
                            className='w-full md:w-1/2 h-[80%] mx-auto flex flex-col md:flex-row relative border-red-700 hidden sm:block'>
                            <div className=''>
                                <GoogleMap
                                    mapContainerStyle={{
                                        overflow: 'hidden',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                    }}
                                    zoom={selectedZoom}
                                    center={selectedCenterCoords}
                                >
                                    {postSearchNodes.map((node) => {
                                        if (!node.coordinates) return
                                        const [lng, lat] = node.coordinates
                                        if (
                                            node.name === selectedMapNode.name
                                        ) {
                                            return (
                                                <Marker
                                                    onClick={() => {
                                                        handleMarkerClick(node)
                                                    }}
                                                    key={node._id.toString()}
                                                    title={node.name}
                                                    icon={{
                                                        url: 'https://i.imgur.com/YjB79uU.png',
                                                        scaledSize:
                                                            new google.maps.Size(
                                                                30,
                                                                30
                                                            ),
                                                    }}
                                                    name={node.name}
                                                    position={{
                                                        lat: Number(
                                                            lat.toString()
                                                        ),
                                                        lng: Number(
                                                            lng.toString()
                                                        ),
                                                    }}
                                                ></Marker>
                                            )
                                        } else {
                                            return (
                                                <Marker
                                                    onClick={() => {
                                                        handleMarkerClick(node)
                                                    }}
                                                    key={node._id.toString()}
                                                    title={node.name}
                                                    icon={{
                                                        url: 'https://i.imgur.com/ci46o90.png',
                                                        scaledSize:
                                                            new google.maps.Size(
                                                                30,
                                                                30
                                                            ),
                                                    }}
                                                    name={node.name}
                                                    position={{
                                                        lat: Number(
                                                            lat.toString()
                                                        ),
                                                        lng: Number(
                                                            lng.toString()
                                                        ),
                                                    }}
                                                ></Marker>
                                            )
                                        }
                                    })}
                                </GoogleMap>
                            </div>
							{distPopupVisible && distanceInfo && (
								<div
									className={`absolute top-[10px] left-[10px] bg-white border-4 border-solid border-green-600 rounded-lg p-4 z-50 flex flex-col`}
								>
									<div className='flex flex-row justify-start items-center my-auto'>
										<FaRoute />
										<div className='ml-2'>
											{distanceInfo.distance} miles
										</div>
									</div>
									<a
										href={`https://www.google.com/maps/dir/?api=1&origin=${distanceInfo.originLat},${distanceInfo.originLng}&destination=${distanceInfo.destLat},${distanceInfo.destLng}`}
										target='_blank'
										rel='noopener noreferrer'
										className='text-blue-500 underline'
									>
										Open in Google Maps
									</a>
								</div>
							)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({Nodes, User, Location}) => ({
    nodes: Nodes.nodes,
    loadingNodes: Nodes.loadingNodes,
    nodesError: Nodes.nodesError,
    user: User.user,
    loadingUser: User.loading,
    loggedIn: User.loggedIn,
    userLocation: Location.userLocation,
    gpsLocationFound: Location.gpsLocationFound,
    loadingLocation: Location.loading,
    userLocationError: Location.userLocationError,
})

const mapDispatchToProps = (dispatch) => ({
    onGetNodes: (filter) => dispatch(getNodes(filter)),
    onGetGPSLocation: () => dispatch(getGPSLocation()),
    onGetUser: () => dispatch(getUser()),
	onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnLocations)
