import {
	SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAILURE
} from './actionTypes'

const initialState = {
	sendingSMS: false,
	sentSMS: null,
	sendSMSError: null,
}

const notifications = (state = initialState, action) => {
	switch (action.type) {
		case SEND_SMS:
			return {
				...state,
				sendingSMS:true,
				sentSMS: null,
				sendSMSError: null,
			}
		case SEND_SMS_SUCCESS:
			return {
				...state,
				sendingSMS:false,
				sentSMS: action.payload,
			}
		case SEND_SMS_FAILURE:
			return {
				...state,
				sendingSMS:false,
				sendSMSError: action.payload,
			}

		default:
			return state
	}
}

export default notifications