import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getAppInfo, getCart, getUser, updatePath, captureIP, captureGPS } from "../store/actions";
import { FaShoppingCart, FaChevronDown, FaBars } from "react-icons/fa";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { Button, Dropdown } from "./Elements";
import { realm } from "../helpers/realm";
import { getTransactions } from "../model/transactions";
import useOutsideClick from "./Elements/DetectOutsideClick";
import { useJsApiLoader } from "@react-google-maps/api";
import { googleMapsLibraries } from "../helpers/googleMapsAPI";
import { BounceLoader } from "react-spinners";
import { AddressBar } from "./Elements";
import { parseUserAgent } from "react-device-detect";

const Header = (props) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const {
    onGetUser,
    user,
    onUpdatePath,
    loggedIn,
    path,
    onGetCart,
    cartItems,
    cartTotal,
    lastActive,
    cartNodeSlug,
    label,
    showBackButton,
		onCaptureIP,
    onCaptureGPS,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const bounceLoaderColor = "#507f74";

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: googleMapsLibraries,
  });

  useEffect(() => {
    onGetUser();
  }, []);

  useEffect(() => {
    if (user && user.activeCart) {
      onGetCart(user.activeCart);
    }

		if (user && user.phone) {
			onCaptureIP({ phone: user.phone });
		} 
  }, [user]);

  useEffect(() => {
    if (user && user.activeCart) {
      onGetCart(user.activeCart);
    }
  }, [user?.activeCart]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (
      currentPath !== "/login" &&
      currentPath !== "/sign-up" &&
      currentPath !== '/sign-up-b' &&
      currentPath !== "/office-ordering/login" &&
      currentPath !== "/office-ordering/sign-up"
    ) {
      onUpdatePath(currentPath);
    }
  }, [onUpdatePath]);

  useEffect(() => {
    if (window.google && window.google.maps) {
      setShowSearchBar(true);
    }
  }, [window.google?.maps, isLoaded]);

  const handleLogout = () => {
    if (realm.currentUser) {
      realm.currentUser.logOut().then(() => {
        if (path == "/") {
          window.location.reload();
        } else {
          navigate("/");
          window.location.reload();
        }
      });
    }
  };

  const openSupport = () => {
    window.Intercom("show");
  };
  // console.log('in header')

  const headerRef = useRef();

  useOutsideClick(headerRef, () => {
    setOpenDropdown(false);
    setOpenAvatarDropdown(false);
    setOpenAvatarDropdown2(false);
  });

  return (
    <div className="fixed top-0 z-50">
      {/*/////////////
         Header all below sm
         ////////////////*/}
      <header className="h-[3.25rem] w-screen flex px-2 py-2 items-center justify-between font-poppins bg-white border-b-2 border-gray-200">
        <div className="cursor-pointer flex justify-center items-center text-center w-full">
          <div className="flex-none">
            {showBackButton && (
              <MdOutlineArrowBackIosNew
                onClick={() => props.redirectPage ? navigate("/orders") : navigate(-1)}
                className="text-xl text-dark-grey"
              />
            )}
          </div>
          <div className="flex-auto text-center">
            <div className="text-2xl text-green-600 font-header">{label}</div>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = ({ User, AppInfo, Path, Cart }) => ({
  user: User.user,
  loadingUser: User.loading,
  loggedIn: User.loggedIn,
  appInfo: AppInfo.appInfo,
  path: Path.path,
  cartItems: Cart.cartItems,
  cartTotal: Cart.cartTotal,
  lastActive: Cart.lastActive,
  cartNodeSlug: Cart.nodeSlug,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTransactions: (payload) => dispatch(getTransactions(payload)),
  onGetUser: () => dispatch(getUser()),
  onGetAppInfo: () => dispatch(getAppInfo()),
  onUpdatePath: (path) => dispatch(updatePath(path)),
  onGetCart: (cart) => dispatch(getCart(cart)),
	onCaptureIP: (payload) => dispatch(captureIP(payload)),
  onCaptureGPS: (payload) => dispatch(captureGPS(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
