import {
	CREATE_TRANSACTION,
	CREATE_TRANSACTION_EMAIL,
	CREATE_TRANSACTION_SUCCESS,
	CREATE_TRANSACTION_FAIL,
	RESET_TRANSACTION,
} from './actionTypes'

export const createTransaction = (payload) => ({
	type: CREATE_TRANSACTION,
	payload: payload,
})

export const createTransactionEmail = (payload) => ({
	type: CREATE_TRANSACTION_EMAIL,
	payload: payload,
})

export const createTransactionSuccess = (payload) => ({
	type: CREATE_TRANSACTION_SUCCESS,
	payload: payload,
})

export const createTransactionFail = (error) => ({
	type: CREATE_TRANSACTION_FAIL,
	payload: error,
})

export const resetTransaction = () => ({
	type: RESET_TRANSACTION,
})
