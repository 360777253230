import { RUN_AGGREGATION, RUN_AGGREGATION_SUCCESS, RUN_AGGREGATION_ERROR } from './actionTypes';

const initialState = {
    runningAggregation: false,
    aggregationResult: null,
    aggregationError: null,
};

const aggregationReducer = (state = initialState, action) => {
    switch (action.type) {
        case RUN_AGGREGATION:
            return {
                ...state,
                runningAggregation: true,
                aggregationResult: null,
                aggregationError: null,
            };
        case RUN_AGGREGATION_SUCCESS:
            return {
                ...state,
                runningAggregation: false,
                aggregationResult: action.payload,
                aggregationError: null,
            };
        case RUN_AGGREGATION_ERROR:
            return {
                ...state,
                runningAggregation: false,
                aggregationResult: null,
                aggregationError: action.payload,
            };
        default:
            return state;
    }
};

export default aggregationReducer;
