import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
	reduceCartItem,
	addProduct,
	removeCartItem,
	totalCart,
	clearCart,
} from '../../store/actions'

const CartItem = (props) => {
	const {
		cartItems,
		onReduceCartItem,
		onClearCart,
		onAddProduct,
		onRemoveCartItem,
		show,
		onTotalCart,
		setCartItemsNodeSlug,
	} = props

	const handleReduceCartItem = (item) => {
		if(cartItems.length === 1 && item.quantity === 1) {
			onClearCart()
		}
		else {
			onReduceCartItem(item)
		}
	}

	const handleRemoveCartItem = (item) => {
		onRemoveCartItem(item)
	}

	const handleAddProduct = (item) => {
		onAddProduct(item)
	}

	useEffect(() => {
		if (cartItems){
			onTotalCart(cartItems)
			if (cartItems[0]?.nodeSlug){
				setCartItemsNodeSlug(cartItems[0].nodeSlug)
			}
		}
	}, [cartItems])

	return (
		<div className="w-full flex flex-col justify-center items-center px-5">
		  {cartItems &&
			cartItems.map((item) => (
			  <div
				className="w-full flex flex-col border-2 my-2 p-4 rounded-lg"
				key={item.id}
			  >
				<div className="flex">
				  {item.product.pictures?.length > 0 ? (
					<div>
					  <img
						className="h-[60px] w-[80px] rounded-md"
						src={item.product.pictures[0].url}
						alt=""
					  />
					</div>
				  ) : null}
				  <div className={`flex ${item.product.pictures?.length > 0 ? 'w-[275px] md:w-[400px] ml-2' : 'w-full'} justify-between`}>
					<div className="justify-top flex flex-col">
					  <p className="text-sm font-bold inline">
						{item.product.name}
					  </p>
					  <p className="text-sm font-semibold">
						{new Intl.NumberFormat("en-US", {
						  style: "currency",
						  currency: item.product.price.currencyCode,
						}).format(item.product.price.amount)}
					  </p>
					</div>
					<div className="border-2 rounded-full text-center flex justify-between items-center my-4 h-[30px]">
					  <span
						color="default"
						className="text-gray-300 cursor-pointer text-center justify-center pr-2 pl-4"
						onClick={() => {
						  // if (item.quantity >= 0) {
						  handleReduceCartItem(item);
						  // } else if (item.quantity === 1) {
						  // 	handleRemoveCartItem(item)
						  // }
						}}
					  >
						-
					  </span>
					  <div className="text-center">{item.quantity}</div>
					  <span
						color="default"
						className="pl-2 pr-4 text-gray-300 cursor-pointer text-center justify-center"
						onClick={() => {
						  handleAddProduct(item);
						}}
					  >
						+
					  </span>
					</div>
				  </div>
				</div>
				{item.modifiers.length > 0 && (
				  <div className="mt-3">
					<div className="text-xs font-semibold text-left pb-1">
					  Modifications:
					</div>
					{item.modifiers.map((item) => {
					  return (
						<div className="flex flex-row justify-between">
						  <div className="text-xs w-3/5 text-gray-500">{item.name}</div>
						  <div className="text-xs text-gray-500">
							${item.price.amount.toFixed(2)}
						  </div>
						</div>
					  );
					})}
				  </div>
				)}
				{item.note !== "" && (
				  <div className="mt-3">
					<div className="text-xs font-semibold text-left pb-1">
					  Notes:
					</div>
					<div className="text-xs text-gray-500">{item.note}</div>
				  </div>
				)}
			  </div>
			))}
		</div>
	  );
	}
	

const mapStateToProps = ({ Cart }) => ({
	cartTotal: Cart.cartTotal,
	cartItems: Cart.cartItems,
})

const mapDispatchToProps = (dispatch) => ({
	onReduceCartItem: (item) => dispatch(reduceCartItem(item)),
	onRemoveCartItem: (item) => dispatch(removeCartItem(item)),
	onAddProduct: (product) => dispatch(addProduct(product)),
	onTotalCart: (cartItems) => dispatch(totalCart(cartItems)),
	onClearCart: () => dispatch(clearCart())
})

export default connect(mapStateToProps, mapDispatchToProps)(CartItem)
