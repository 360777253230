import {
	CREATE_TRANSACTION,
	CREATE_TRANSACTION_SUCCESS,
	CREATE_TRANSACTION_FAIL,
	RESET_TRANSACTION,
	CREATE_TRANSACTION_EMAIL,
} from './actionTypes'

const initialState = {
	transactionResult: null,
	error: null,
	creating: false,
}

const createTransaction = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_TRANSACTION:
			return {
				...state,
				creating: true,
			}

		case CREATE_TRANSACTION_EMAIL:
			return {
				...state,
				creating: true,
			}

		case CREATE_TRANSACTION_SUCCESS:
			return {
				...state,
				creating: false,
				transactionResult: action.payload,
			}

		case CREATE_TRANSACTION_FAIL:
			return {
				...state,
				creating: false,
				error: action.payload,
			}

		case RESET_TRANSACTION:
			return {
				transactionResult: null,
				error: null,
				creating: false,
			}

		default:
			return state
	}
}

export default createTransaction
