import { call, put, takeEvery } from 'redux-saga/effects'
import { getPlatforms, getPlatformByKey } from '../../model/platforms'
import {
	getPlatformsSuccess,
	getPlatformsFail,
	getPlatformByKeySuccess,
	getPlatformByKeyFail,
} from './actions'
import { GET_PLATFORMS, GET_PLATFORM_BY_KEY } from './actionTypes'

function* fetchPlatforms(action) {
	try {
		const platforms = yield call(getPlatforms, action.payload)
		yield put(getPlatformsSuccess(platforms))
	} catch (error) {
		yield put(getPlatformsFail(error))
	}
}

function* fetchPlatformByKey(action) {
	try {
		const platform = yield call(getPlatformByKey, action.payload)
		yield put(getPlatformByKeySuccess(platform))
	} catch (error) {
		yield put(getPlatformByKeyFail(error))
	}
}

function* platformsSaga() {
	yield takeEvery(GET_PLATFORMS, fetchPlatforms)
	yield takeEvery(GET_PLATFORM_BY_KEY, fetchPlatformByKey)
}

export default platformsSaga
