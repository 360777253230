import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getUser, updateUser, getAppInfo } from '../../store/actions'
import { useNavigate, Link } from 'react-router-dom'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Button } from '../../components/Elements'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import MembershipSignUpForm from '../../components/Stripe/MembershipSignUpForm'
import {
	FaDollarSign,
	FaPeopleArrows,
	FaShoppingBasket,
	FaMoneyBillWave,
	FaHome,
} from 'react-icons/fa'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const MembershipJoin = (state) => {
	const {
		onGetUser,
		onUpdateUser,
		user,
		path,
		loadingUser,
		loggedIn,
		updateUserLoading,
		userUpdateCompleted,
		onGetAppInfo,
		appInfo,
	} = state

	const bounceLoaderColor = '#507f74'
	const [existingMember, setExistingMember] = useState(false)
	let navigate = useNavigate()

	useEffect(() => {
		onGetUser()
		onGetAppInfo()
	}, [onGetUser])

	useEffect(() => {
		if (
			loggedIn &&
			user &&
			user.dzMemberSubscription &&
			user.dzMemberSubscription.enabled
		) {
			setExistingMember(true)
		}
	}, [loggedIn, user])

	useEffect(() => {
		if (userUpdateCompleted) {
			onGetUser()
		}
	}, [userUpdateCompleted])

	return (
		<>
            <Header label="DeliverZero+" showBackButton={true} />
			<div className='className="min-h-screen-no-header sm:h-screen w-full flex flex-col justify-between mt-[3.25rem]'>
				<div
					className='pb-[3rem] w-full'
				>
					{/* Sign up form */}
					<div
						id='subscribe'
						className='z-20 flex flex-col mb-12 mx-auto overflow-auto scrollbar'
					>
						{!(existingMember && loggedIn) && (
							<div
								id='signup'
								className='w-full'
							>
								<div className='z-20 flex flex-col mx-auto bg-white overflow-y-auto'>
									<Elements stripe={stripePromise}>
										<MembershipSignUpForm
											existingMember={existingMember}
											loggedIn={loggedIn}
										/>
									</Elements>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = ({ User, Path, Payments, AppInfo }) => ({
	loggedIn: User.loggedIn,
	userError: User.error,
	user: User.user,
	loadingUser: User.loading,
	updateUserLoading: User.updateUserLoading,
	userUpdateCompleted: User.userUpdateCompleted,
	path: Path.path,
	appInfo: AppInfo.appInfo,
	cardAdded: Payments.cardAdded,
	loadingAddition: Payments.loadingAddition,
	cardAddedError: Payments.error,
	paymentMethods: Payments.paymentMethods,
	loadingPaymentMethods: Payments.loadingPaymentMethods,
})

const mapDispatchToProps = (dispatch) => ({
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onUpdateUser: (updatePayload) => dispatch(updateUser(updatePayload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MembershipJoin)
