import React, { useState, useEffect, useCallback } from 'react'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { googleMapsLibraries } from '../../helpers/googleMapsAPI'
import { connect } from 'react-redux'
import { BounceLoaderCentered } from '../../components/Elements'
import {getNodes, getWholeFoods} from '../../store/actions'
import {NodeTypes} from "../../model/nodes";
import {Widget} from "@typeform/embed-react";

const mapContainerStyle = {
    width: '100%',
    height: '100%',
}

function WholeFoods(props) {
    const { loadingNodes, nodes, nodeError, onGetNodes } = props
    const [map, setMap] = useState(null)
    const [mapFilter, setMapFilter] = useState({})
    const [selectedWFM, setSelectedWFM] = useState("")
    const [center, setCenter]= useState({
        lat: 39.84583023872753,
        lng: -105.11429982023125,
    })
    const [wfmFilter, setWfmFilter] = useState({
        type: {
              $in: [NodeTypes.wholeFoods],
            },
        enabled: {
            $nin: [false],
        },
    })
    const google = window.google

    // LOAD GOOGLE MAPS API
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        libraries: googleMapsLibraries,
    })

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds()
        map.fitBounds(bounds)
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback() {
        setMap(null)
    }, [])

    // LOAD WHOLE FOODS LOCATIONS
    useEffect(() => {
        if(mapFilter){
            onGetNodes([wfmFilter,mapFilter])
        } else {
            onGetNodes([wfmFilter])
        }
    }, [map,mapFilter])

    useEffect(() => {
    }, [nodes])

    const onMapMarkerClick = (coords) => {
        setCenter({
                lat: coords[1],
                lng: coords[0],
            })
    }

    return (
        <>
            <Header label="DZ at Whole Foods Market" />
            {/*<div className='bg-yellow-400'>*/}
            {/*    <div className='ml-4 md:ml-28 py-5 md:py-10 text-green-600 text-xl sm:text-2xl md:text-[40px] lg:text-[50px] font-header whitespace-nowrap'>*/}
            {/*        DeliverZero at Whole Foods Market*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div
                className='bg-cover h-[8rem] sm:h-[28rem] bg-top'
                style={{
                    backgroundImage: 'url(https://i.imgur.com/CIQTsT5.png)',
                }}
            ></div>
            <div className='flex flex-col justify-center items-center text-center bg-yellow-400'>
                <div className='container px-4 sm:px-12 mb-12 flex flex-col justify-center items-center text-center'>
                    <h1 className='text-2xl md:text-5xl font-header text-green-600 text-center mt-8 sm:mt-12 mb-4'>
                        Fight packaging waste, get your food in reusable
                        packaging
                    </h1>
                    <p className='max-w-[600px] bg-white rounded-[10px] shadow-light-grey px-4 sm:px-8 py-9'>
                        Every time you choose reuse, you prevent packaging waste
                        from piling up in landfills and littering our
                        environment. Plus, you help significantly reduce the
                        emissions and water consumption that come with
                        manufacturing and shipping single-use containers. <br />
                        <br />
                        Learn more about the{' '}
                        <Link
                            className='text-green-600 decoration underline'
                            to='/whyreuse'
                        >
                            benefits of reuse.
                        </Link>
                    </p>
                    <h1 className='text-5xl font-header text-green-600 text-center w-full mt-12 mb-4'>
                        How it works:
                    </h1>
                    <ul className='flex flex-col lg:flex-row text-center justify-center items-center mb-4 '>
                        <li className='bg-white rounded-[10px] shadow-light-grey px-4 lg:w-1/3 m-2 py-9 l'>
                            <div className="font-header font-bold text-xl text-green-600">Step 1</div>
                            <img src="https://i.imgur.com/pwakb4x.png" className="rounded-[10px] my-2 w-3/4 mx-auto"/>
                            <div>Grab a DeliverZero reusable container and fill</div>
                        </li>
                        <li className='bg-white rounded-[10px] shadow-light-grey  px-4  lg:w-1/3 m-2 py-9 l'>
                            <div className="font-header font-bold text-xl text-green-600">Step 2</div>
                            <img src="https://i.imgur.com/a7MCHFw.png" className="rounded-[10px] my-2 w-3/4 mx-auto"/>
                            Check out and pay a small, fully refundable deposit
                        </li>
                        <li className='bg-white rounded-[10px] shadow-light-grey  px-4  lg:w-1/3 m-2 py-9 l'>
                            <div className="font-header font-bold text-xl text-green-600">Step 3</div>
                            <img src="https://i.imgur.com/J5TF4rI.png" className="rounded-[10px] my-2 w-3/4 mx-auto"/>
                            Rinse and return containers at your next visit to
                            get your deposits back!
                        </li>
                    </ul>
                    <p className='max-w-[600px] bg-white rounded-[10px] shadow-light-grey  px-4 sm:px-8 py-9'>
                        When you return containers to a participating store,{` `}
                        <span className="font-bold">your deposit will be refunded</span>. The containers will be
                        <span className="font-bold">{` `}thoroughly sanitized and reused</span>.
                    </p>
                    <h1 className='text-4xl font-header text-green-600 text-center mt-12 mb-4'>
                        Find a participating Whole Foods location
                    </h1>
                    <p className=' mb-4 text-xs'>
                        Map of participating Whole Foods locations
                    </p>
                    {!isLoaded && (
                    // (loadingNodes && (
                        <BounceLoaderCentered container={'div'} />
                    )}
                    {nodeError && (
                        <div className='text-red-600'>
                            <p>
                                {nodeError.statusCode} - {nodeError.error}
                            </p>
                            <p>{nodeError.statusText}</p>
                        </div>
                    )}
                    <div className='flex flex-col lg:flex-row-reverse w-full sm:w-4/5'>
                        {isLoaded && !nodeError && (
                            <div className='h-96 sm:card w-full  lg:w-2/3 '>
                                <GoogleMap
                                    id='wholeFoodsMap'
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={9}
                                    center={center}
                                    onload={onLoad}
                                    onUnmount={onUnmount}
                                >
                                    {nodes.length &&
                                    nodes.map((wholeFood) => {
                                        const [lng, lat] =
                                            wholeFood.coordinates
                                        return (
                                            <Marker
                                                key={wholeFood._id}
                                                icon={{
                                                    url: 'https://i.imgur.com/ppyvkNZ.png',
                                                    scaledSize:
                                                        new google.maps.Size(
                                                            35,
                                                            45
                                                        ),
                                                }}
                                                onClick={()=>{
                                                    onMapMarkerClick(wholeFood.coordinates)
                                                    setSelectedWFM(wholeFood.slug)
                                                    setMapFilter({
                                                        coordinates: {
                                                            $near: {
                                                                $geometry: {
                                                                    type: 'Point',
                                                                    coordinates: [wholeFood.coordinates[0], wholeFood.coordinates[1]],
                                                                },
                                                            },
                                                        },
                                                    })
                                                }}
                                                name={wholeFood.name}
                                                position={{
                                                    lat: Number(
                                                        lat.toString()
                                                    ),
                                                    lng: Number(
                                                        lng.toString()
                                                    ),
                                                }}
                                            />
                                        )
                                    })}
                                </GoogleMap>
                            </div>
                        )}
                        {isLoaded && (
                            <div className='flex-row justify-center items-center flex-nowrap md:flex-row md:mr-8 overflow-scroll scrollbar overflow-x-visible max-h-96'>
                                {nodes.map((node) => {
                                    // console.log(node.slug)
                                    // console.log(selectedWFM)
                                    return (
                                        <>

                                            <div
                                                key={node._id}
                                                onClick={()=>{
                                                    onMapMarkerClick(node.coordinates)
                                                    setSelectedWFM(node.slug)
                                                    setMapFilter({
                                                        coordinates: {
                                                            $near: {
                                                                $geometry: {
                                                                    type: 'Point',
                                                                    coordinates: [node.coordinates[0], node.coordinates[1]],
                                                                },
                                                            },
                                                        },
                                                    })
                                                }}
                                                className={`${selectedWFM===node.slug ? 'border-4 border-green-600' : ""} bg-white w-9/10 py-8 px-4 shadow-lighter-grey hover:shadow-light-grey 
                                                rounded-xl justify-between flex flex-no-wrap items-center m-4 !min-h-fit-content`}
                                            >
                                                <div className=''>
                                                    <img
                                                        className='w-28'
                                                        src={node.thumbnail}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <h1>{node.name}</h1>
                                                    <div className='text-xs'>
                                                        <p>{node.address}</p>
                                                        <p>
                                                            {node.city},{' '}
                                                            {node.state}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    <h1 className='text-2xl font-header text-green-600 text-center mt-12'>
                        Take a second to tell us about your Whole Foods Market experience
                    </h1>
                    <Widget id="JHdIplYA" className="mt-6 w-full sm:w-2/3 h-[700px]" />
                    {/*<p>SURVEY</p>*/}
                    {/*<p>SURVEY</p>*/}
                    {/*<p>SURVEY</p>*/}
                    {/*<p>SURVEY</p>*/}
                    {/*<p>SURVEY</p>*/}
                    {/*<p>SURVEY</p>*/}
                    {/*<p>SURVEY</p>*/}
                </div>
            </div>
            {/*<Footer />*/}
        </>
    )
}

const mapStateToProps = ({ Nodes }) => ({
    nodes: Nodes.nodes,
    loadingNodes: Nodes.loadingNodes,
    nodeError: Nodes.nodeError,
})

const mapDispatchToProps = (dispatch) => ({
    onGetNodes: (filter) => dispatch(getNodes(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WholeFoods)
